import {entityStateRequestedBulk} from "gui-common/requestEntityState/requestEntityStateReducer";
import {genericFetch} from "gui-common/api/apiCommon";
import {enrichUrlAndLogWithId, enrichUrlAndLogWithParameter} from "features/agreement/agreementApi";
import {reFixAgreementsSelector} from "features/agreement/agreementSelectors";
import {globalApiHandle} from "gui-common/api/apiConstants";

const actionToUrlMap = {
    'close'    : 'close',
    'expire'   : 'expire',
    'dispatch' : 'dispatch',
}

// First level is the entity's current state, next level is the event happening (action), last level is expected target state.
const listRateStateMachine = {
    'Active': {
        'close'   : ['Expired'],
        'suspend' : ['Expired'],
        'expire'  : ['InExpiry', 'Expired'],
    },
    'InExpiry': {
        'close'   : ['Expired'],
        'suspend' : ['Expired'],
    },
    'Expired': {
    },
}

// First level is the entity's current state, next level is the event happening (action), last level is expected target state.
const dispatchTradeStateMachine = {
    'Active': {
        'close'   : ['SentToBook', 'ManualBook', 'Booked', 'ClosedWithoutOrders', 'ClosedWithoutNetVolume'],
        'suspend' : ['SentToBook', 'ManualBook', 'Booked', 'ClosedWithoutOrders', 'ClosedWithoutNetVolume'],
        'dispatch': ['SentToBook', 'ManualBook', 'Booked', 'ClosedWithoutOrders', 'ClosedWithoutNetVolume', 'Timeout'],
    },
}
// First level is the entity's current state, next level is the event happening (action), last level is expected target state.
const agreementStateMachine = {
    'Ready': {
        'suspend' : ['Suspended', 'Active', 'Ready'],
    },
    'Active': {
        'suspend' : ['Suspended', 'Active', 'Ready'],
    },
    'Suspended': {
        'resume'  : ['Active', 'Ready'],
    },
}

const modelToStateMachineMap = {
    'Agreement'     : agreementStateMachine,
    'ListRate'      : listRateStateMachine,
    'DispatchTrade' : dispatchTradeStateMachine,
}

// Different match filter compared to natchFilter in agreementsApi since the filer parameters are stated on list rate level
function agreementMatchFilter(item, filterParams) {
    if (item.type !== 'ListRateAgreement') return false;
    if (!filterParams || filterParams.id) return true; // If listRate id is provided in the filter all agreements must be checked.
    if (filterParams.agreementId && (item.id !== filterParams.agreementId)) return false;
    if (filterParams.currencyId && (item.currencyPair.baseCurrency.id !== filterParams.currencyId) && (item.currencyPair.quoteCurrency.id !== filterParams.currencyId)) return false;
    if (filterParams.legalEntityUnitId && (item.legalEntityUnitId !== filterParams.legalEntityUnitId)) return false;
    return true;
}

export function addRequestStateIfExpected(model, item ,action, requestArray) {
    const stateMachine = modelToStateMachineMap[model];
    if (!stateMachine) return;
    if (!stateMachine[item.status]) return;
    if (!stateMachine[item.status][action]) return;

    requestArray.push({model: model, entityId: item.id, acceptedStatesToClearRequest: stateMachine[item.status][action], propertyToMonitor: 'status'})
}

function getAffectedListRates(filterParams, getState) {
    let agreements = reFixAgreementsSelector(getState())

    let affectedListRates = [];

    for (let agreement of agreements) {
        if (agreement.exemptFromGlobalSuspend) {
            continue;
        }
        if (!agreementMatchFilter(agreement, filterParams)) continue;

        if (!agreement.listRates) continue;
        for (let listRate of agreement.listRates) {
            if (filterParams.id && (listRate.id !== filterParams.id)) continue;
            affectedListRates.push(listRate);
        }
    }
    return affectedListRates;
}

export function getRequestStatesOnListRates(action, listRates) {
    let stateRequestArray = [];

    for (let listRate of listRates) {
        addRequestStateIfExpected('ListRate', listRate, action, stateRequestArray);

        if (!listRate.dispatchTrades) continue;
        for (let dispatchTrade of listRate.dispatchTrades) {
            if ((dispatchTrade.openAmount === 0) && (action === 'dispatch')) continue; // Dispatch trade with no openAmount will not be affected by normal dispatch. Close and suspend will book the trade.
            addRequestStateIfExpected('DispatchTrade', dispatchTrade, action, stateRequestArray);
        }
    }
    return stateRequestArray;
}

function setRequestedStateOnListRates(action, listRates, dispatch) {
    let stateRequestArray = getRequestStatesOnListRates(action, listRates);
    dispatch(entityStateRequestedBulk(stateRequestArray));
}




/* -----------------------------------------------------------------------------------------------------------------
* Functions to operate with actions on Agreements with filter parameters.
* -----------------------------------------------------------------------------------------------------------------*/
export function putListRateActionToApi(action, filterParams, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!action) {
                console.error("No action in putListRateActionToApi");
                return;
            }
            if (!actionToUrlMap[action]) {
                console.error("Unsupported action in putListRateActionToApi", action);
                return;
            }

            let logText = "Put list rate action :" + actionToUrlMap[action];
            let requestUrl = globalApiHandle.baseUrls.ListRate + '/';

            [requestUrl, logText] = enrichUrlAndLogWithId(requestUrl, logText, filterParams)

            requestUrl = requestUrl + actionToUrlMap[action] + '?';

            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'agreement-id'          , 'agreementId');
            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'currency-id'           , 'currencyId');
            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'legal-entity-unit-id'  , 'legalEntityUnitId');

            dispatch(genericFetch(
                requestUrl,
                logText,
                responseHandler(action, filterParams),
                failHandler(action, filterParams),
                failHandler(action, filterParams),
                'PUT',
                {comment: comment},
            )).then(result => {resolve(result)}).catch(err => {})
        })
    }
}


function responseHandler(action, filterParams) {
    return (resp) => {
        return (dispatch, getState) => {
            setRequestedStateOnListRates(action, getAffectedListRates(filterParams, getState), dispatch);
        }
    }
}
function failHandler(action, filterParams) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}

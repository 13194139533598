import {
    createStandardAuditFields2,
    xpColCreate, xpColHeadCreate
} from "gui-common/xpGrid/xpGridFunctions";
import {getNestedIdProperty, setNestedIdProperty} from "gui-common/functions/functions";
import {marginGroupsPriceTypes} from "features/marginGroup/marginGroupConstants";
import {useSelector} from "react-redux";
import {marginGroupsSelector} from "./marginGroupSelectors";
import {useMemo} from "react";


function priceMarginTransformer(item) {
    return {
        ...item,
        currencyPairId: getNestedIdProperty(item, 'currencyPair'),
        currencyPairName: item.currencyPair.baseCurrency.currency + item.currencyPair.quoteCurrency.currency,
    }
}
export function marginGroupTransformer(item) {
    return {
        ...item,
        default              : item.isDefault,
        askMarginBps         : item.priceMargin?.askMarginBps,
        bidMarginBps         : item.priceMargin?.bidMarginBps,
        priceType            : item.priceMargin?.priceType,
        runConfiguration     : {
            marginGroupOverride: Array.isArray(item.priceMarginOverrides) ? item.priceMarginOverrides.map(item => priceMarginTransformer(item)) : undefined,
        }
    }
}

function createPriceMarginBody(item) {
    return {
        currencyPair : setNestedIdProperty(item, 'currencyPairId'),
        bidMarginBps : item.bidMarginBps,
        askMarginBps : item.askMarginBps,
        priceType    : item.priceType,
        isActive     : item.isActive,
        id           : item.priceMargin?.id,
    }
}

export function useMarginGroupSelectList(filter) {
    const marginGroups  = useSelector(marginGroupsSelector);

    return useMemo(
        () => {
            return marginGroups.filter(filter ? filter : item => true).map(mg => ({
                ...mg,
                renderTooltip: option => {
                    let retText = mg.priceType + ': ' + mg.bidMarginBps + ', ' + mg.askMarginBps;
                    mg.priceMarginOverrides.forEach(mgOverride => {
                        retText += '\n' + mgOverride.currencyPair.baseCurrency.currency + mgOverride.currencyPair.quoteCurrency.currency + ': ' + mgOverride.bidMarginBps + ', ' + mgOverride.askMarginBps;
                    })
                    return retText
                },
            }));
        },
        [marginGroups]
    );
}

export function createMarginGroupBody(marginGroup) {
    let priceMarginOverrides = [];
    if (marginGroup.runConfiguration?.marginGroupOverride && Array.isArray(marginGroup.runConfiguration.marginGroupOverride)) {
        marginGroup.runConfiguration.marginGroupOverride.forEach(overrideItem => {
            if (overrideItem.isDeleted) return;
            priceMarginOverrides.push({...createPriceMarginBody(overrideItem), priceType: marginGroup.priceType}); // Use same price type as in base margin group
        });
    }
    return {
        id                   : marginGroup.id,
        name                 : marginGroup.name,
        description          : marginGroup.description,
        isDefault            : (marginGroup.default === undefined) ? false : marginGroup.default,
        sortIndex            : marginGroup.sortIndex,
        priceMargin          : {...createPriceMarginBody(marginGroup), isActive: true},
        priceMarginOverrides : priceMarginOverrides,
    }
}

export function prepareMarginGroupsGridColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState,'id'          , 100, 50, 'marginGroupList.id');
    xpColCreate(translate, subArray, hiddenState,'name'        , 120, 50, 'marginGroupList.name');
    xpColCreate(translate, subArray, hiddenState,'description' , 200, 50, 'marginGroupForm.description.label');
    xpColHeadCreate(translate, outArray, subArray, 'marginGroupList.marginGroupInfoHeader');

    xpColCreate(translate, subArray, hiddenState,'numberOfAgreements', 100, 50, 'marginGroupList.numberOfAgreements', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'numberOfLeus'      , 100, 50, 'marginGroupList.numberOfLeus'      , {cellStyle: {textAlign: "right"}});
    xpColHeadCreate(translate, outArray, subArray, 'marginGroupList.usageInfoHeader');

    xpColCreate(translate, subArray, hiddenState,'priceTypeT'  , 100, 50, 'runConfiguration.marginGroupOverride.priceType.label', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: marginGroupsPriceTypes.map(item => 'runConfiguration.marginGroupOverride.values.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState,'bidMarginBps', 100, 50, 'runConfiguration.marginGroupOverride.bidMarginBps.label', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'askMarginBps', 100, 50, 'runConfiguration.marginGroupOverride.askMarginBps.label', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'overrides'   , 200, 50, 'marginGroupList.overrides');
    xpColHeadCreate(translate, outArray, subArray, 'marginGroupList.priceInfoHeader');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}

import React, {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ComponentLoading from "gui-common/components/ComponentLoading";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import CurrencyPairConfigList from "gui-common/currencyPairConfig/CurrencyPairConfigList";
import {userSelector} from "gui-common/orm/ormSelectors";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {currencyPairConfigReset} from "gui-common/currencyPairConfig/currencyPairConfigReducer";
import {ormItemsLoading} from "gui-common/orm/ormLoadingSelectors";
import {
    getCurrencyPairConfigTypeSelector,
    savedCurrencyPairConfigSelector
} from "gui-common/currencyPairConfig/currencyPairConfigSelectors";
import {putOrmItemToApi} from "gui-common/orm/ormApi";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {XP_FORM_EDIT, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {xpFormLoadForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {useXpFormRootFormData} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormContainsErrors} from "gui-common/xpForm/core/xpFormFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


// Todo: Support for multiple config types is not yet implemented in CurrencyPairConfigType.
const thisInstanceId = 'currencyPairConfig';
function CurrencyPairConfigType (props) {

    const [gridApi, setGridApi] = useState(undefined);

    const currencyPairConfigState = useSelector(state => state.currencyPairConfigState);
    const currencyPairConfigStateRef   = useRef(currencyPairConfigState); // Needed to transport updated currencyPairConfigState to modal scope.
    currencyPairConfigStateRef.current = currencyPairConfigState;

    const currencyPairOrmConfigs = useSelector(savedCurrencyPairConfigSelector);
    const [itemEditing, setItemEditing] = useState(false);
    const translate         = useSelector(selectTranslateFunction);
    const thisUser          = useSelector(userSelector);
    const configsLoading    = useSelector(state => ormItemsLoading(state, {ormModel: 'CurrencyPairConfig'}))

    const ormConfigItems    = useSelectorInstance(getCurrencyPairConfigTypeSelector, {configType: props.configType})

    const formModel = 'currencyPairConfigForm.' + props.configType;
    const thisFormData = useXpFormRootFormData(formModel)

    const dispatch  = useDispatch();

    const userCanUpdate = useMemo(() => {
        if (props.auditMode) return false;
        return userCan('Update', 'CurrencyPairConfig', thisUser);
        },
        [thisUser]
    );

    const anyItemLoading = useMemo(() => {
        if (!configsLoading) return false;
        if (!currencyPairOrmConfigs || !currencyPairOrmConfigs.length) return false;

        let loading = false;
        currencyPairOrmConfigs.forEach(item => {
            if (!configsLoading[item.id]) return;
            if (configsLoading[item.id].loadingUpdate) loading = true;
        })
        return loading;
        }, [currencyPairOrmConfigs, configsLoading]
    );

    function saveConfig () {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'fxRejectConfig.confirmSaveConfig'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    let modifiedObjects = [];
                    for (let key in currencyPairConfigStateRef.current.changedItems) modifiedObjects.push(currencyPairConfigStateRef.current.changedItems[key]);
                    if (!modifiedObjects.length) return;

                    Promise.all(modifiedObjects.map(item => dispatch(putOrmItemToApi('CurrencyPairConfig', item))))
                        .then(result => {
                            resetConfig();
                            setItemEditing(false);
                        })
                        .catch(result =>{
                            console.error("Error saving CurrencyPairConfig to API. ", result);
                        });
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    function resetConfig () {
        dispatch(currencyPairConfigReset());
        setItemEditing(false);
        dispatch(xpFormLoadForm(formModel, {}))
        if (gridApi) gridApi.refreshCells({force: true});
    }

    const activeErrors = useMemo(
        () => {
            return (xpFormContainsErrors(thisFormData));
        },
        [thisFormData]
    );
    const currentData =  useMemo(
        () => {
            if (props.auditMode && props.currentData) {
                return {
                    [props.currentData.id]: props.currentData
                }
            }

            let currentDataObject = {};
            let sourceData = props.currentData ? props.currentData : ormConfigItems;
            if (!sourceData) return undefined;

            sourceData.forEach(item => {
                currentDataObject[item.id] = item;
            })
            return currentDataObject;
        },
        [ormConfigItems, props.currentData, props.auditMode]
    );
    const beforeChangeData = useMemo(
        () => {
            if (!props.auditMode || !props.beforeChangeData) return undefined;
            return {
                [props.beforeChangeData.id]: props.beforeChangeData
            };
        },
        [props.beforeChangeData, props.auditMode]
    );

    if (currencyPairConfigState.loading) return (
        <ComponentLoading loadingMessage={translate('currencyPairConfigPage.loadingMessage')}/>
    )

    return (
        <div>
            <XpForm
                formModel={formModel}
                onSubmit={(data) => saveConfig(data)}
                initialUseState={props.auditMode ? XP_FORM_VIEW : XP_FORM_EDIT}
                currentData={currentData}
                beforeChangeData={beforeChangeData}
                auditMode={props.auditMode}
            >
                <CurrencyPairConfigList
                    instanceId={props.instanceId ? props.instanceId : thisInstanceId}
                    setEditingCallback={setItemEditing}
                    onGridReady={params => setGridApi(params.api)}
                    inputData={props.auditMode ? [props.currentData] : undefined}
                    auditMode={props.auditMode}
                />
            </XpForm>
            {userCanUpdate &&
            <WaitingXpoolButton
                onClickCallback = {() => dispatch(xpFormSubmitForm(formModel))}
                disabled        = {(!Object.keys(currencyPairConfigState.changedItems).length && !itemEditing) || activeErrors}
                labelKey        = 'fxRejectConfig.saveButton'
                toolTipKey      = {activeErrors ? 'currencyPairConfigPage.errorInGrid' : undefined}
                waiting         = {anyItemLoading}
            />}
            {userCanUpdate &&
            <WaitingXpoolButton
                onClickCallback = {() => resetConfig()}
                disabled        = {(!Object.keys(currencyPairConfigState.changedItems).length) || anyItemLoading}
                labelKey        = 'fxRejectConfig.cancelButton'
                modalKey        = 'fxRejectConfig.confirmCancel'
            />}
            <hr/>
        </div>
    )
}
export default CurrencyPairConfigType

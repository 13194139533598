import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {AuditEntriesList} from "gui-common/audit/AuditEntriesList2"
import cloneDeep from "lodash/cloneDeep";

import {ormModelLoading, ormItemLoading} from "gui-common/orm/ormLoadingSelectors";
import spinnerGif from "gui-common/resources/spinner.gif";
import "gui-common/audit/audit.css"
import XpAccordion from "gui-common/xpAccordion/XpAccordion";

import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import {globalOrmConfig} from "gui-common/orm/ormModels";
import {auditConfig} from "gui-common/audit/auditConstants";
import AuditActionRenderer from "gui-common/audit/AuditActionRenderer";
import XpFormStaticField from "gui-common/xpForm/core/XpFormStaticField";
import {XpDateTime} from "gui-common/components/XpDateTime";
import {entityIsActive} from "gui-common/audit/auditFunctions";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {HistoricAuditRecordRenderer} from "gui-common/audit/HistoricAuditRecordRenderer";
import AuditRecordRawDataView from "gui-common/audit/AuditRecordRawDataView";

export const auditEntriesListInstanceId = "auditEntriesList";

function getSubEntityArray(object, subEntityModel) {
    if (!subEntityModel.property) return [];
    if (!object[subEntityModel.property]) return [];
    if (Array.isArray(object[subEntityModel.property])) return object[subEntityModel.property];
    return [object[subEntityModel.property]];
}

function fullAuditEntryRequestRequired(selectedEntry, auditItemLoading) {
    if (auditItemLoading) return false;

    if (selectedEntry.rootModel === 'AuditUserActionType') {
        return !selectedEntry.actionData;
    }
    return (!selectedEntry.after && !selectedEntry.before);
}

function AuditViewContent(props) {
    const entryGridState     = useSelector(state => selectMyXpGridState(state, {instanceId: props.listInstanceId}));
    const selectedEntryId    = entryGridState ? entryGridState.selectedId : undefined;
    const auditItemsLoading  = useSelector(state => ormModelLoading(state, {ormModel: 'AuditEntry'}));
    const auditItemLoading   = useSelector(state => ormItemLoading(state, {ormModel: 'AuditEntry', itemId: selectedEntryId}));
    const currentApiVersion  = useAppEnvProperty('currentApiVersion');
    const historicAuditOnly  = useAppEnvProperty('historicAuditOnly');
    const devMode            = useAppEnvProperty('devMode');

    function propagateAndEnrichRunConfigurations(thisObject, thisModel, parentRc, idToModelMap) {
        if (!thisObject) {
            return;
        }
        const subEntityModelArray = globalOrmConfig.nestedEntities[thisModel];
        if (!subEntityModelArray?.length) return;

        const thisRc = thisObject.runConfiguration ? thisObject.runConfiguration : {};
        for (const key in parentRc) {
            if (key === 'id') {
                continue;
            }
            if (!thisRc[key] && parentRc[key] && parentRc[key].length) {
                thisRc[key] = parentRc[key];
            }
        }
        for (const key in thisRc) {
            if (key === 'id') {
                continue;
            }
            if (!thisRc[key] || !thisRc[key].length) {
                continue;
            }
            thisRc[key] = thisRc[key].map(item => {
                const ownedByParent = item.ownedByRunConfigurationId !== thisRc.id;
                if (!ownedByParent) {
                    idToModelMap[item.id] = thisModel;
                }
                return {
                    ...item,
                    fromOrmModel: ownedByParent ? idToModelMap[item.id] : undefined,
                    ownedByParent: ownedByParent,
                    includedInRunConfiguration: true
                }
            });
        }

        thisObject.runConfiguration = thisRc;

        for (let subEntityModel of subEntityModelArray) {
            if (!subEntityModel.property || !subEntityModel.model || !thisObject[subEntityModel.property]) continue;

            for (let subEntity of getSubEntityArray(thisObject, subEntityModel)) {
                propagateAndEnrichRunConfigurations(subEntity, subEntityModel.model, thisRc, idToModelMap);
            }
        }
    }

    const selectedAuditEntry = useMemo(
        () => {
            if (!props.auditEntries || !selectedEntryId) return undefined;
            const selectedEntry = props.auditEntries.find(item => item.id === selectedEntryId)
            if (!selectedEntry) return undefined;

            if (fullAuditEntryRequestRequired(selectedEntry, auditItemLoading)) { // Only load entry data if not already loaded and not already being loaded.
                props.requestFullEntryLoad(selectedEntry);
            }
            if (historicAuditOnly || (selectedEntry?.apiVersion !== currentApiVersion)) {
                return selectedEntry;
            }
            propagateAndEnrichRunConfigurations(selectedEntry.before, selectedEntry.rootModel, undefined, {});
            propagateAndEnrichRunConfigurations(selectedEntry.after , selectedEntry.rootModel, undefined, {});
            return selectedEntry;
        },
        [props.auditEntries, selectedEntryId]
    );

    const useHistoricAudit = useMemo(
        () => {
            return historicAuditOnly || (selectedAuditEntry?.apiVersion !== currentApiVersion);
        },
        [historicAuditOnly, selectedAuditEntry, currentApiVersion]
    );

    /*

        function auditEntrySelected(entryId) {
            if (!selectedAuditEntry) return;
            if (selectedAuditEntry.id !== entryId) return;
            if (selectedAuditEntry.current.after || selectedAuditEntry.current.before) return; // No need to load entry data again
            if (auditItemLoading) return; // Request already running

            if (props.itemSelectedCallback) props.itemSelectedCallback(selectedAuditEntry.id);
        }
    */

    function getAuditedObject(thisObject, thisModel, searchId, searchModel) {
        if (!thisObject) return null; // no before object for Create on Root object.

        if ((searchModel === thisModel) && (thisObject.id === searchId)) {
            return thisObject;
        }

        const subEntityModelArray = globalOrmConfig.nestedEntities[thisModel];
        if (!subEntityModelArray || !subEntityModelArray.length) return undefined;

        for (let subEntityModel of subEntityModelArray) {
            if (!subEntityModel.model) continue;

            for (let subEntity of getSubEntityArray(thisObject, subEntityModel)) {
                const matchedObject = getAuditedObject(
                    subEntity,
                    subEntityModel.model,
                    searchId,
                    searchModel
                );
                if (matchedObject) return matchedObject;
            }
        }
        return undefined;
    }

    function removeDisabledEntities(thisObject, thisModel) {
        if (!thisObject) return null; // no before object for Create on Client.

        const subEntityModelArray = globalOrmConfig.nestedEntities[thisModel];
        if (!subEntityModelArray || !subEntityModelArray.length) return undefined;

        for (let subEntityModel of subEntityModelArray) {
            if (!subEntityModel.property || thisObject[subEntityModel.property]) continue;

            if (Array.isArray(thisObject[subEntityModel.property])) {
                thisObject[subEntityModel.property] = thisObject[subEntityModel.property].filter(entityIsActive); // remove disabled items in array
            }

            if (!subEntityModel.model) continue;

            for (let subEntity of getSubEntityArray(thisObject, subEntityModel)) {
                removeDisabledEntities(subEntity, subEntityModel.model);
            }
        }
    }

    function displayModifiedComponent() {
        const auditEntry = selectedAuditEntry;
        if (!auditEntry)                    return null;
        if (!auditEntry.modifiedObject)     return null;

        const thisAuditConfig = auditConfig[auditEntry.model];
        if (!thisAuditConfig) return null;

        if (useHistoricAudit) {
            return (<HistoricAuditRecordRenderer auditEntry={auditEntry} auditConfig={thisAuditConfig} auditProps={props} />)
        }

        const ModifiedObjectComponent = thisAuditConfig.getRenderComponent();
        if (!ModifiedObjectComponent) return null;

        let beforeObject = thisAuditConfig.overrideAndUseRoot ? auditEntry.before : getAuditedObject( auditEntry.before, auditEntry.rootModel, auditEntry.modifiedObject.id, auditEntry.model);
        let afterObject  = thisAuditConfig.overrideAndUseRoot ? auditEntry.after  : getAuditedObject( auditEntry.after , auditEntry.rootModel, auditEntry.modifiedObject.id, auditEntry.model);

        // Deleted case. Remove operation type check when disabledDateTime refactoring is completed
        if (beforeObject && (!afterObject || (auditEntry.operationType === 'Delete'))) {
            afterObject = {...beforeObject, isDeleted: true};
        }
        // New case.
        if (!beforeObject && afterObject) {
            afterObject = {...afterObject, isNew: true};
        }
        // No data at all!
        if (!beforeObject && !afterObject) {
            return null;
        }

        return (
            <div xp-test-id="auditEntryAfterChange">
                <XpAccordion
                    header={
                        <div>
                            <XpTranslated trKey={'auditView.modifiedObject.' + auditEntry.operationType}/>
                            <XpTranslated trKey={'general.modelNamesLarge.' + auditEntry.model}/>
                            {': '}
                            <span className="standardTextColor">
                                {auditEntry.modifiedObject.name}
                            </span>
                        </div>}
                    instanceId={props.contentInstanceId + "-modifiedObject"}
                >
                    <ModifiedObjectComponent
                        {...auditConfig[auditEntry.model].renderProps}
                        auditEntry              = {auditEntry}
                        ormModel                = {auditEntry.model}
                        auditMode               = {true}
                        rootAuditProps          = {selectedRootConfig.rootProps.getRootAuditProps ? selectedRootConfig.rootProps.getRootAuditProps(beforeStructure) : undefined}
                        entityId                = {auditEntry.modifiedObject.id}
                        currentData             = {afterObject}
                        beforeChangeData        = {beforeObject}
                        suppressSubEntities     = {true}
                        suppressAccordion       = {true}
                        auditModeModifiedObject = {true}
                    />
                </XpAccordion>
            </div>
        );
    }

    function displayUserAction() {
        const auditEntry = selectedAuditEntry;
        if (!auditEntry) return null;

        // const actionConfig = auditUserActionsConfig[auditEntry.actionType];
        // if (!actionConfig) return null;

        return (
            <div xp-test-id="auditEntryAfterChange">
                <h3 className={'formHeaderEditNew'}>
                    <XpTranslated trKey={'general.modelNamesLarge.AuditUserActionType'}/>
                    {': '}
                    <span className="standardTextColor">
                        <XpTranslated trKey={'auditUserActionTypes.' + auditEntry.actionType + '.label'}/>
                    </span>
                </h3>
                <div className='xpAdminFormEntityCard'>
                    <div className="adminFormContainer">
                        <div className="adminColumn auditActionBaseColumn">
                            <XpFormStaticField inLineLayout labelTrKey='userPreferencesForm.userName'                                 value={auditEntry.user.firstName + ' ' + auditEntry.user.lastName}/>
                            <XpFormStaticField inLineLayout labelTrKey='userPreferencesForm.userId.label'                             value={auditEntry.user.userId}/>
                            <XpFormStaticField inLineLayout labelTrKey='auditUserActionTypes.actionDisplayHeader.requestedActionType' value={<XpTranslated trKey={'auditUserActionTypes.' + auditEntry.actionType + '.label'}/>}/>
                            <XpFormStaticField inLineLayout labelTrKey='auditUserActionTypes.actionDisplayHeader.requestedDateTime'   value={<XpDateTime isoString={auditEntry.auditDateTime} format='lll'/>}/>
                        </div>
                        <div className="adminColumn auditActionContentColumn">
                            <AuditActionRenderer auditEntry={auditEntry}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    /*{...getModifiedComponentProps(auditEntry, beforeObject, afterObject)}*/

    let showBefore          = false;
    let ShowBeforeComponent = undefined;
    let beforeStructure     = undefined;

    const selectedRootConfig = selectedAuditEntry ? auditConfig[selectedAuditEntry.rootModel] : undefined;
    if (selectedRootConfig?.rootProps?.showBefore && !useHistoricAudit) {
        showBefore = true;
        ShowBeforeComponent = selectedRootConfig.getRenderComponent();
        beforeStructure = cloneDeep(selectedAuditEntry.before);
        removeDisabledEntities(beforeStructure, selectedAuditEntry.rootModel);
    }

    if (auditItemsLoading) {
        return (
            <div xp-test-id="auditEntriesListSpinner">
                <p className="valueText"><XpTranslated trKey='auditEntriesList.loading'/></p>
                <img className="spinner" src={spinnerGif} alt="waiting" />
            </div>
        )
    }

    return (
        <div>
            <AuditEntriesList
                inputData            = {props.auditEntries}
                // itemSelectedCallback = {(id) => auditEntrySelected(id)}
                instanceId           = {props.listInstanceId}
                fillAvailableSpace   = {false}
                rootModel            = {props.rootModel}
                hiddenState          = {props.hiddenState}
                suppressObjectAudit  = {props.suppressObjectAudit}
            />
            <div style={{minHeight: props.contentMinHeight ? props.contentMinHeight : undefined}}>
                {auditItemLoading &&
                <div xp-test-id="auditEntrySpinner" style={{display: 'absolute'}}>
                    <p className="valueText"><XpTranslated trKey="auditView.loadingEntry"/></p>
                    <img className="spinner" src={spinnerGif} alt="waiting" />
                </div>}
                {selectedAuditEntry &&
                <div style={{visibility: !auditItemLoading ? 'visible' : 'hidden'}}>
                    {(selectedAuditEntry.rootModel !== 'AuditUserActionType') ? displayModifiedComponent() : displayUserAction()}

                    {showBefore && beforeStructure && ShowBeforeComponent &&
                    <div xp-test-id="auditEntryBeforeChange">
                        <XpAccordion
                            header={
                                <div>
                                    <XpTranslated trKey={'general.modelNamesLarge.' + selectedAuditEntry.rootModel}/>
                                    <XpTranslated trKey={'auditView.beforeChange'}/>
                                </div>}
                            headerKey='auditView.beforeChange'
                            instanceId={props.contentInstanceId + "-BeforeChange"}
                            defaultShowState={false}
                        >
                            <ShowBeforeComponent
                                {...auditConfig[selectedAuditEntry.rootModel].renderProps}
                                ormModel              = {selectedAuditEntry.rootModel}
                                auditMode             = {true}
                                rootAuditProps        = {selectedRootConfig.rootProps.getRootAuditProps ? selectedRootConfig.rootProps.getRootAuditProps(beforeStructure) : undefined}
                                entityId              = {beforeStructure.id}
                                currentData           = {beforeStructure}
                                beforeChangeData      = {beforeStructure}
                                auditModeBeforeObject = {true}
                            />
                        </XpAccordion>
                    </div>}
                    {devMode && !useHistoricAudit && <AuditRecordRawDataView auditEntry={selectedAuditEntry}/>}
                </div>}
            </div>
        </div>
    );
}
export default AuditViewContent;

import React, {useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import 'react-datepicker/dist/react-datepicker.css';
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import PropTypes from "prop-types";
import {activeCurrenciesSelector, userSelector} from "gui-common/orm/ormSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {deleteOrmItemsToApi, getOrmItemsFromApi, ormItemArrayTransformer} from "gui-common/orm/ormApi";
import {ormModelLoading} from "gui-common/orm/ormLoadingSelectors";
import {bankHolidaysSelector} from "gui-common/bankHolidays/bankHolidaysSelectors";
import {ormEntitiesClearModel, ormEntitiesReceived} from "gui-common/orm/ormReducer";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import FileReaderButton from "gui-common/components/FileReaderButton";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushModalWindow} from "redux-promising-modals";
import {apiFileUpload} from "gui-common/api/apiCommon";
import {CONFIRMATION_DIALOG, INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import moment from "moment";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormDateInput from "gui-common/xpForm/core/XpFormDateInput";
import {xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";

function BankHolidaysFilterForm (props) {

    const ormCurrencies     = useSelector(activeCurrenciesSelector);
    const bankHolidays      = useSelector(bankHolidaysSelector);
    const bankHolidayConfig = useSelector(state => selectAppEnvProperty(state, 'bankHolidayConfig'));
    const itemsLoading      = useSelector(state => ormModelLoading(   state, {ormModel: 'BankHoliday'}));
    const user              = useSelector(userSelector);
    const [submitAction,   setSubmitAction]   = useState(undefined);


    const dispatch  = useDispatch();

    const yearsList = useMemo (
        () => {
            let yearsArray = [];
            for (let year = bankHolidayConfig.minYear; year <= bankHolidayConfig.maxYear; year++) yearsArray.push({id: String(year)});
            return yearsArray;
        },[],
    );
    const initialFormData = useMemo(
        () => {
            return {
                fromDate: "", toDate: "", filterCurrencyId: "", year: moment().format('YYYY')
            };
        },
        []
    );

    function handleSubmit(filter) {

        const dateValid = (date) => date && moment(date).isValid();
        const fromDate = dateValid(filter.fromDate) ? moment(filter.fromDate).format('YYYY-MM-DD') : undefined;
        const toDate   = dateValid(filter.toDate)   ? moment(filter.toDate).format(  'YYYY-MM-DD') : undefined;

        let errorModalKey = "";
        if ((toDate && !fromDate) || (!toDate && fromDate)) errorModalKey = 'bankHolidayForm.submitValidationFailModals.incompletePeriod';
        if ( filter.year   && (fromDate || toDate))         errorModalKey = 'bankHolidayForm.submitValidationFailModals.yearAndPeriod';

        if (errorModalKey) {
            dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: errorModalKey}));
            setSubmitAction(undefined);
            return;
        }

        let urlFilter = {includeDisabled: false};
        if (fromDate) urlFilter.fromDate = fromDate;
        if (toDate)   urlFilter.toDate   = toDate;
        if (filter.filterCurrencyId)  urlFilter.currencyId = filter.filterCurrencyId;
        if (filter.year ) urlFilter.year = filter.year;

        if (submitAction === 'get') {
            dispatch(getOrmItemsFromApi('BankHoliday', undefined, urlFilter));
        }
        if (submitAction === 'delete') {

            let modalParams = {...filter, fromDate: fromDate, toDate: toDate};
            let modalKey = "bankHolidayForm.deleteButtonConfirmation.";
            if (filter.filterCurrencyId) {
                let currency = ormCurrencies.find(item => Number(filter.filterCurrencyId) === item.id);
                modalParams.currency = currency ? currency.currency : "XXX";

                if      (filter.year) modalKey += 'currencyAndYear';
                else if (fromDate)    modalKey += 'currencyAndPeriod';
                else                  modalKey += 'currency';
            }
            else {
                if      (filter.year) modalKey += 'year';
                else if (fromDate)    modalKey += 'period';
                else                  modalKey += 'all';
            }

            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: modalKey, modalParams: modalParams}))
                .then(({status}) => {
                    if (status === MODAL_TYPE_CONFIRM) {
                        dispatch(deleteOrmItemsToApi('BankHoliday', urlFilter))
                            .then(resp => {
                                dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'bankHolidayForm.deleteSuccessfulDialog'}))
                                    .then(({status}) => dispatch(ormEntitiesClearModel('BankHoliday')));
                            })
                    }
                    if (status === MODAL_TYPE_CANCEL) {}
                });

        }
        setSubmitAction(undefined);
    }

    function fileContentReady(params) {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "bankHolidays.fileReadyConfirmation", modalParams: {numberOfItems: params.data.bankHolidays.length}}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    dispatch((dispatch, getState) => {
                        dispatch(apiFileUpload(
                            'BankHoliday',
                            {
                                columnDefinitions   : bankHolidayConfig.fileSpecification.levelDefinitions[0].columnDefinitions,
                                separator           : bankHolidayConfig.fileSpecification.separator,
                                commentLinePrefix   :bankHolidayConfig.fileSpecification.commentLinePrefix
                            },
                            params.fileContent,
                            'BankHolidayEntity'
                        ))
                            .then((resp)=>{
                                let transformedResponse = ormItemArrayTransformer('BankHoliday', resp.response, {getState: getState});
                                dispatch(ormEntitiesReceived('BankHoliday', transformedResponse));
                            })
                    })
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    return (
        <div xp-test-id="bankHolidaysFilterForm">
            <XpForm
                className="filterFormContainer"
                formModel="bankHolidaysFilterForm"
                formTemplate  = "bankHolidayForm"
                onSubmit={handleSubmit}
                initialUseState={XP_FORM_EDIT}
                initialFormData={initialFormData}
            >
                <div className="filterFormField bankHolidaysFilterSelect">
                    <XpFormSelectorInput
                        noMargin
                        field         = "filterCurrencyId"
                        selectList    = {ormCurrencies}
                        itemTextField = "currency"
                    />
                </div>

                <div className=" filterFormField bankHolidaysFilterSelect">
                    <XpFormSelectorInput
                        noMargin
                        field         = "year"
                        selectList    = {yearsList}
                        itemTextField = "id"
                    />
                </div>

                <div className="filterFormField bankHolidaysFilterDate">
                    <XpFormDateInput
                        noTemplate alignRight noMargin
                        field         = "fromDate"

                        dependentFields={{toDate: 'toDate'}}
                        dependentPropsFn={fields => ({maxDate: fields.toDate})}

                        // maxDate       = {formData.toDate}
                        minDate       = '2019-05-01'
                        showMonthYearDropdown
                    />
                </div>

                <div className="filterFormField bankHolidaysFilterDate">
                    <XpFormDateInput
                        noTemplate alignRight noMargin
                        field         = "toDate"

                        dependentFields={{fromDate: 'fromDate'}}
                        dependentPropsFn={fields => ({minDate: fields.fromDate})}

                        // minDate       = {formData.fromDate}
                        showMonthYearDropdown
                    />
                </div>

                <div className="filterFormButton">
                    <WaitingXpoolButton
                        labelKey        = 'bankHolidayForm.submitButton'
                        onClickCallback = {() => {
                            setSubmitAction('get');
                            dispatch(xpFormSubmitForm('bankHolidaysFilterForm'))}
                        }
                        waiting         = {itemsLoading}
                        // toolTipKey      = {'report.viewReportButtonWaitingTooltip'}
                    />
                </div>
                {userCan('Delete', 'BankHolidays', user) &&
                <div className="filterFormButton">
                    <WaitingXpoolButton
                        labelKey        = 'bankHolidayForm.deleteButton'
                        onClickCallback = {() => {
                            setSubmitAction('delete');
                            dispatch(xpFormSubmitForm('bankHolidaysFilterForm'))}
                        }
                        waiting         = {itemsLoading}
                        // toolTipKey      = {'report.viewReportButtonWaitingTooltip'}
                    />
                </div>}

                <div className="filterFormRightButtonsGroup bankHolidaysFilterButtonsGroup">
                    <div className="filterFormButton">
                        <WaitingXpoolButton
                            labelKey        = 'bankHolidayForm.clearListButton'
                            onClickCallback = {() => dispatch(ormEntitiesClearModel('BankHoliday'))}
                            disabled        = {(!bankHolidays || !bankHolidays.length)}
                            waiting         = {itemsLoading}
                            // toolTipKey      = {'report.viewReportButtonWaitingTooltip'}
                        />
                    </div>
                    {userCan('Create', 'BankHolidays', user) &&
                    <div className="filterFormButton">
                        <WaitingXpoolButton
                            labelKey        = 'bankHolidayForm.createNewButton'
                            onClickCallback = {() => props.setEditNew()}
                            waiting         = {itemsLoading}
                            // toolTipKey      = {'report.viewReportButtonWaitingTooltip'}
                        />
                    </div>}
                    {userCan('Create', 'BankHolidays', user) &&
                    <div className="filterFormButton">
                        <FileReaderButton
                            instanceId      = 'bankHolidayFilterFormFileButton'
                            fileSpec        = {bankHolidayConfig.fileSpecification}
                            labelKey        = 'bankHolidayForm.loadFileButton'
                            inLineLayout    = {true}
                            onSuccess       = {(params) => {fileContentReady(params)}}
                            onFail          = {(params) => {}}
                            onStartBrowsing = {() => {}}
                            onStartValidation = {() => {}}
                            fieldLookUps = {{
                                currencies: ormCurrencies.map(item => item.currency),
                            }}
                            debugLog
                            waiting         = {itemsLoading}
                        />
                    </div>}
                </div>

            </XpForm>
        </div>
    );
}
BankHolidaysFilterForm.propTypes = {
    reportData: PropTypes.array,
};
export default BankHolidaysFilterForm;





import {
    userRightsAdminResetSystemRight, userRightsAdminResetUserDataRights
} from "gui-common/userRights/adminUserRightsReducer"
import {genericFetch} from "gui-common/api/apiCommon";
import {
    ormEntitiesReceived, ormEntityCreated, ormEntityCreateStarted, ormEntityDeleted,
    ormEntityUpdated,
    ormEntityUpdateFailed,
    ormEntityUpdateStarted
} from "gui-common/orm/ormReducer";
import {ormItemArrayTransformer} from "gui-common/orm/ormApi";
import {getDeepModifiedDataRightsSelector} from "gui-common/userRights/adminUserRightsSelectors";
import {apiTransformIncoming} from "gui-common/api/apiFunctions";
import {globalApiHandle} from "gui-common/api/apiConstants";


function buildUserUrl(getState, ormItem, userId) {
    return globalApiHandle.baseUrls[ormItem] + '/user'
        + (userId ? ('/' + userId) : "")
        + "?";
}
function clearEntityDataRight(entityDataRight, dispatch) {
    if (!entityDataRight.id) return;
    dispatch(ormEntityDeleted('EntityDataRight', entityDataRight));
    if (!entityDataRight.childDataRightsCollection) return;
    deepClearOrmFromDataRightsCollection(entityDataRight.childDataRightsCollection, dispatch);
}
function deepClearOrmFromDataRightsCollection(dataRightsCollection, dispatch) {
    dispatch(ormEntityDeleted('DataRightsCollection', dataRightsCollection));
    for (let entityDataRight of dataRightsCollection.assigned)   clearEntityDataRight(entityDataRight, dispatch);
    for (let entityDataRight of dataRightsCollection.assignable) clearEntityDataRight(entityDataRight, dispatch);
}


/* -----------------------------------------------------------------------------------------------------------------
* Function to get PENDING version of dataRightsCollection structure including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getPendingUserRightsCollectionFromApi(userId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!userId) return;

            let logText = "Get pending DataRightsCollection for user id: " + userId;
            let requestUrl = buildUserUrl(getState, 'DataRightsCollection', userId);

            dispatch(ormEntityCreateStarted('DataRightsCollection'));
            dispatch(genericFetch(
                requestUrl,
                logText,
                getPendingUserRightsCollectionFromApiResponseHandler({id: userId}),
                getPendingUserRightsCollectionFromApiFailHandler({id: userId}),
                getPendingUserRightsCollectionFromApiFailHandler({id: userId}),
                'POST',
                undefined, // no body in http request needed for GET.
                'DataRightsCollection'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getPendingUserRightsCollectionFromApiResponseHandler(user) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            let transformedResponse = globalApiHandle.incomingTransformersMap.DataRightsCollection(resp);
            dispatch(ormEntityCreated(ormModel, transformedResponse));
        }
    }
}
function getPendingUserRightsCollectionFromApiFailHandler(user) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed('User', user));
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Function to get user data rights including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getUserDataRightsFromApi(userId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get DataRightsCollection for user id: " + userId;
            let requestUrl = buildUserUrl(getState, 'DataRightsCollection', userId);

            dispatch(ormEntityUpdateStarted('User', {id: userId})); // Should be User to control spinners in admin screen
            dispatch(genericFetch(
                requestUrl,
                logText,
                getUserDataRightsFromApiResponseHandler({id: userId}),
                getUserDataRightsFromApiFailHandler({id: userId}),
                getUserDataRightsFromApiFailHandler({id: userId}),
                'GET',
                undefined, // no body in http request needed for GET.
                'DataRightsCollection'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getUserDataRightsFromApiResponseHandler(user) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            const respArray = Array.isArray(resp) ? resp : [resp];
            let transformedResponse = ormItemArrayTransformer(ormModel, respArray, {getState: getState});
            dispatch(ormEntitiesReceived(ormModel, transformedResponse));
            if (user && user.id) dispatch(ormEntityUpdated('User', user));
        }
    }
}
function getUserDataRightsFromApiFailHandler(user) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed('User', user));
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Function to get user system rights including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getUserSystemRightFromApi(userId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get SystemRights for user id: " + userId;
            let requestUrl = buildUserUrl(getState, 'UserSystemRight', userId);

            dispatch(ormEntityUpdateStarted('User', {id: userId})); // Should be User to control spinners in admin screen
            dispatch(genericFetch(
                requestUrl,
                logText,
                getUserSystemRightFromApiResponseHandler({id: userId}),
                getUserSystemRightFromApiFailHandler({id: userId}),
                getUserSystemRightFromApiFailHandler({id: userId}),
                'GET',
                undefined, // no body in http request needed for GET.
                'UserSystemRight'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getUserSystemRightFromApiResponseHandler(user) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            let transformedResponse = ormItemArrayTransformer(ormModel, [resp], {getState: getState});
            dispatch(ormEntitiesReceived(ormModel, transformedResponse));
            if (user && user.id) dispatch(ormEntityUpdated('User', user));
        }
    }
}
function getUserSystemRightFromApiFailHandler(user) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed('User', user));
        }
    }
}


/* -----------------------------------------------------------------------------------------------------------------
* Function to save user system rights including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function putUserSystemRightFromApi(userId, userSystemRight) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Put SystemRights for user id: " + userId;
            let requestUrl = buildUserUrl(getState, 'UserSystemRight', userId);

            const transformer = globalApiHandle.outgoingTransformersMap.UserSystemRight;

            dispatch(ormEntityUpdateStarted('UserSystemRight', userSystemRight)); // Should be User to control spinners in admin screen
            dispatch(genericFetch(
                requestUrl,
                logText,
                putUserSystemRightFromApiResponseHandler({id: userId}, userSystemRight),
                putUserSystemRightFromApiFailHandler({id: userId}),
                putUserSystemRightFromApiFailHandler({id: userId}),
                'PUT',
                transformer(userSystemRight),
                'UserSystemRight'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function putUserSystemRightFromApiResponseHandler(user, userSystemRight) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            let transformedResponse = apiTransformIncoming(resp, ormModel, getState());
            dispatch(ormEntityUpdated(ormModel, transformedResponse));
            dispatch(userRightsAdminResetSystemRight(userSystemRight))
        }
    }
}
function putUserSystemRightFromApiFailHandler(user) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed(ormModel, user));
        }
    }
}


/* -----------------------------------------------------------------------------------------------------------------
* Function to save user data rights including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
const deepModifiedDataRightsSelector = getDeepModifiedDataRightsSelector()
export function putDataRightsCollectionFromApi(dataRightsCollection) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Put dataRightsCollection id: " + dataRightsCollection.id;
            let requestUrl = globalApiHandle.baseUrls.DataRightsCollection + '/' + dataRightsCollection.id + "?";

            let deepDataRightsCollection = deepModifiedDataRightsSelector(getState(), {selectId: dataRightsCollection.id}).dataRightsCollection;
            if (!deepDataRightsCollection) {
                console.error("Could not build deepDataRightsCollection in putDataRightsCollectionFromApi. ", dataRightsCollection);
                return;
            }

            const transformer = globalApiHandle.outgoingTransformersMap.DataRightsCollection;

            dispatch(ormEntityUpdateStarted('DataRightsCollection', dataRightsCollection));
            dispatch(genericFetch(
                requestUrl,
                logText,
                putDataRightsCollectionFromApiResponseHandler(deepDataRightsCollection),
                putDataRightsCollectionFromApiFailHandler(deepDataRightsCollection),
                putDataRightsCollectionFromApiFailHandler(deepDataRightsCollection),
                'PUT',
                transformer(deepDataRightsCollection),
                'DataRightsCollection'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function putDataRightsCollectionFromApiResponseHandler(deepDataRightsCollection) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            let transformedResponse = apiTransformIncoming(resp, ormModel, getState());
            deepClearOrmFromDataRightsCollection(deepDataRightsCollection, dispatch)
            dispatch(ormEntityUpdated(ormModel, transformedResponse));
            dispatch(userRightsAdminResetUserDataRights(deepDataRightsCollection, true))
        }
    }
}
function putDataRightsCollectionFromApiFailHandler(deepDataRightsCollection) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed(ormModel, deepDataRightsCollection));
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Function to activate the user data rights collection including handler functions for success and fail.
* Note: This activates the PENDING data rights collection provided. Before this is possible, the
* data rights collection must be saved with a normal ORM PUT method in the standard orm api library.
* -----------------------------------------------------------------------------------------------------------------*/
export function activateDataRightsCollectionFromApi(pendingDataRightsCollection, activeDataRightsCollection) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!pendingDataRightsCollection || !activeDataRightsCollection) return;

            let logText = "Activate dataRightsCollection id: " + pendingDataRightsCollection.id;
            let requestUrl = globalApiHandle.baseUrls.DataRightsCollection + '/' + pendingDataRightsCollection.id + "/activate?";

            // let deepPendingDataRightsCollection = deepModifiedDataRightsSelector(getState(), {selectId: pendingDataRightsCollection.basedOnDataRightsCollectionId}).dataRightsCollection;
            let deepPendingDataRightsCollection = deepModifiedDataRightsSelector(getState(), {selectId: pendingDataRightsCollection.id}).dataRightsCollection;
            let deepActiveDataRightsCollection  = deepModifiedDataRightsSelector(getState(), {selectId: activeDataRightsCollection.id}).dataRightsCollection;

            dispatch(ormEntityUpdateStarted('DataRightsCollection', pendingDataRightsCollection));
            dispatch(genericFetch(
                requestUrl,
                logText,
                activateDataRightsCollectionFromApiResponseHandler(deepPendingDataRightsCollection, deepActiveDataRightsCollection),
                activateDataRightsCollectionFromApiFailHandler(activeDataRightsCollection),
                activateDataRightsCollectionFromApiFailHandler(activeDataRightsCollection),
                'PUT',
                undefined,
                'DataRightsCollection'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function activateDataRightsCollectionFromApiResponseHandler(deepPendingDataRightsCollection, deepActiveDataRightsCollection) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            deepClearOrmFromDataRightsCollection(deepPendingDataRightsCollection, dispatch)
            deepClearOrmFromDataRightsCollection(deepActiveDataRightsCollection, dispatch)
            let transformedResponse = apiTransformIncoming(resp, 'DataRightsCollection', getState());
            dispatch(ormEntityUpdated('DataRightsCollection', transformedResponse));
        }
    }
}
function activateDataRightsCollectionFromApiFailHandler(activeDataRightsCollection) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed('DataRightsCollection', activeDataRightsCollection));
        }
    }
}


/* -----------------------------------------------------------------------------------------------------------------
* Function to delete a PENDING data rights collection including handler functions for success and fail.
* If successful, the function will also call the action creator to clear the admin user rights reducer from user data
* -----------------------------------------------------------------------------------------------------------------*/
export function deleteDataRightsCollectionFromApi(dataRightsCollection) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!dataRightsCollection) return;

            let logText = "Delete dataRightsCollection id: " + dataRightsCollection.id;
            let requestUrl = globalApiHandle.baseUrls.DataRightsCollection + '/' + dataRightsCollection.id + "?";

            let deepDataRightsCollection = deepModifiedDataRightsSelector(getState(), {selectId: dataRightsCollection.id}).dataRightsCollection;
            if (!deepDataRightsCollection) {
                console.error("Could not build deepDataRightsCollection in putDataRightsCollectionFromApi. ", dataRightsCollection);
                return;
            }

            dispatch(ormEntityUpdateStarted('DataRightsCollection', dataRightsCollection));
            dispatch(genericFetch(
                requestUrl,
                logText,
                deleteDataRightsCollectionFromApiResponseHandler(deepDataRightsCollection),
                deleteDataRightsCollectionFromApiFailHandler(deepDataRightsCollection),
                deleteDataRightsCollectionFromApiFailHandler(deepDataRightsCollection),
                'DELETE',
                undefined,
                'DataRightsCollection'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

function deleteDataRightsCollectionFromApiResponseHandler(deepDataRightsCollection) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            deepClearOrmFromDataRightsCollection(deepDataRightsCollection, dispatch)
            dispatch(userRightsAdminResetUserDataRights(deepDataRightsCollection, true))
        }
    }
}
function deleteDataRightsCollectionFromApiFailHandler(deepDataRightsCollection) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed('DataRightsCollection', deepDataRightsCollection));
        }
    }
}

import {createReducer} from 'gui-common/functions/reducer.js'

export const APPEND_TO_APP_ENV      = 'APPEND_TO_APP_ENV';
export const SET_APP_ENV_PARAMETER  = 'SET_APP_ENV_PARAMETER';

// Action creators ********************************************************
// ************************************************************************
export function appendToAppEnv(envParams) {
    return {type: APPEND_TO_APP_ENV, payload: envParams};
}
export function setAppEnvParameter(param, value) {
    return {type: SET_APP_ENV_PARAMETER, payload: {param: param, value: value}};
}

// Reducer functions ********************************************************
// ************************************************************************
function setEnv(state, payload) {
    console.log("Appending to env parameters :", payload);
    return {...state, ...payload};
}
function setEnvParam(state, payload) {
    if (!payload || !payload.param) return state;
    let newState = {...state};
    newState[payload.param] = payload.value;
    return newState;
}

export const setEnvParameter = (env, envParam, setParam, defaultValue) => {
    if (window && window.oxygen_env && window.oxygen_env[envParam] !== undefined) env[setParam] = window.oxygen_env[envParam];
    else env[setParam] = defaultValue;
};
export const getEnvParameter = (envParam) => {
    return (window && window.oxygen_env) ? window.oxygen_env[envParam] : undefined;
};
function getInitialSession (appConfig) {
    let envParams = {};

    setEnvParameter(envParams,'NO_BACKEND_MODE'     , 'noBackendMode'       , false);
    setEnvParameter(envParams,'SOCKET_TIMER_DELAY'  , 'socketTimerDelay'    , 500);
    setEnvParameter(envParams,'DEV_MODE'            , 'devMode'             , false);
    setEnvParameter(envParams,'NO_FORM_VALIDATION'  , 'noFormValidation'    , false);
    setEnvParameter(envParams,'HISTORIC_AUDIT_ONLY' , 'historicAuditOnly'   , false);
    setEnvParameter(envParams,'DEV_PREVENT_RELOAD'  , 'devPreventReload'    , false);
    setEnvParameter(envParams,'BASE_URL'            , 'baseUrl'             , "");
    setEnvParameter(envParams,'LOGOUT_URL'          , 'logoutUrl'           , "http://www.nordicfc.se");
    setEnvParameter(envParams,'USE_HOME_CURRENCY'   , 'useHomeCurrency'     , false);
    setEnvParameter(envParams,'DEFAULT_HOME_CURRENCY', 'defaultHomeCurrency', 'SEK');
    setEnvParameter(envParams,'AVAILABLE_TIMEZONES' , 'availableTimeZones'  , [{id: "Europe/Stockholm"}, {id: "Europe/Helsinki"}, {id: "Europe/Copenhagen"}, {id: "America/Adak"}, {id: "Antarctica/DumontDUrville"}, {id: "America/Toronto"}]);
    setEnvParameter(envParams,'AVAILABLE_LANGUAGES' , 'availableLanguages'  , [{ name: 'English', code: 'en' }, { name: 'Svenska', code: 'sv' }]);
    setEnvParameter(envParams,'USER_MESSAGES_CONFIG', 'userMessages'        , { useGoToClient: false});
    setEnvParameter(envParams,'FIX_LOG_DEFAULT_FILTER', 'fixLogDefaultFilter', ["^((?!35=S).)*$|^.*35=S.*537=1.*$"]);
    setEnvParameter(envParams,'MARKET_SIM_URL'      , 'marketSimBaseUrl'    , "");
    setEnvParameter(envParams,'MARKET_SIM_CONFIG'   , 'marketSimConfig'     , {pokeMilliSeconds: 2000, openExchangeAccounts: [{name: "NFC", id: "d621f2b1f3b14c1bbf86f9ce73e10b08"}, {name: "SWB", id: "81eb9630a1a3478f8a0900ff78af804b"}, {name: "Test", id: "2359dab9728647809c33979e7bd9a780"}]});
    setEnvParameter(envParams,'GUI_STYLING'         , 'guiStyling'          , []);
    setEnvParameter(envParams,'USER_RIGHTS_CONFIG'  , 'userRightsConfig'    , {simpleAssignment: true});
    setEnvParameter(envParams,'REPORT_CONFIG'       , 'reportConfig'        , {defaultReportCurrency: 'SEK'});
    setEnvParameter(envParams,'BANK_HOLIDAY_CONFIG' , 'bankHolidayConfig'   , {
        minYear: 2010,
        maxYear: 2050,
        fileSpecification: {
            fileExtension: ".csv",
            separator: ';',
            commentLinePrefix: '#',
            encoding: 'UTF-8',
            levelDefinitions: [
                {
                    parentLevelHeadingKey: null,
                    dataProp: 'bankHolidays',
                    columnDefinitions: [
/*
                        {heading: 'Currency' , field: 'currencyId' , type: 'string', mandatory: true, lookUp: 'currencies'},
                        {heading: 'Date'       , field: 'date'       , type: 'date'  , mandatory: true, /!*errorFunctions: {incorrectDateFormat: (date) => (String((new Date(date))) === 'Invalid Date')}*!/},
                        {heading: 'Name'       , field: 'description', type: 'string', mandatory: true},
                        {heading: 'Comment'         , field: 'comment'    , type: 'string', mandatory: false},
*/
                        {heading: 'ISOCurrencyCode' , field: 'currencyId' , type: 'string', mandatory: true, lookUp: 'currencies', ignoreLineOnLookUpFailed: true},
                        {heading: 'EventYear'       , field: undefined    , type: 'string', mandatory: false},
                        {heading: 'EventDate'       , field: 'date'       , type: 'date'  , mandatory: true, /*errorFunctions: {incorrectDateFormat: (date) => (String((new Date(date))) === 'Invalid Date')}*/},
                        {heading: 'EventDayOfWeek'  , field: undefined    , type: 'string', mandatory: false},
                        {heading: 'EventName'       , field: 'description', type: 'string', mandatory: true},
                    ]
                }
            ]
        },
    });
    setEnvParameter(envParams,'AVAILABLE_LEGAL_PERSON_TYPES'  , 'availableLegalPersonTypes'  , [{id: "CORPORATE", default: true}]);
    setEnvParameter(envParams,'ERP_INTEGRATION_CONFIG'        , 'erpIntegrationConfig'       , {erpEnabled: false, closePopUpTimeout: 3000});

    console.log("Starting with common base env parameters :", envParams);
    console.log("Starting with app env parameters :", appConfig);
    return {...envParams, ...appConfig};
}

export const appEnvReducer = appConfig => createReducer(getInitialSession(appConfig), {
    [APPEND_TO_APP_ENV]     : setEnv,
    [SET_APP_ENV_PARAMETER] : setEnvParam,
});



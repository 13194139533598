import {Component} from "react";
import closeIcon from "gui-common/resources/closeIcon.png";
import React from "react";
import {
    clearUserMessageAnimation,
    setExitAnimation
} from "gui-common/userMessages/userMessagesReducer";
import {confirmUserMessageThunk} from "gui-common/userMessages/userMessageThunks"
import {animateAppContainer} from "gui-common/userMessages/userMessagesFunctions"
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {push} from "connected-react-router";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class UserMessageRow extends Component {

    state = {
        isConfirmed: false,
        hideTimerStarted: false,
        timer: undefined,
    };

    closeMessage() {
        if (this.state.isConfirmed) return;

        animateAppContainer(this.props.message.id, 'appContainerSlideUp');

               console.log("close message " + this.props.message.id);
        this.props.exitAnimation(this.props.message.id, false);

        this.setState({
            isConfirmed: true,
        });
    }

    componentWillUnmount () {
        // MT: Clear timer if unmounted before timer is expiring. Otherwise the timer will try to set state on an unmounted component.
        clearTimeout(this.state.timer);
    }

    componentDidMount() {
//        console.log("message mount " + this.props.message.id);

        animateAppContainer(this.props.message.id, 'appContainerSlideDown');

        if ((this.props.message.type === "info") && (this.props.message.requireConfirmation === false) && (!this.state.hideTimerStarted)) {
            this.setState({ hideTimerStarted: true });
            let timer = setTimeout(() => {
                this.closeMessage();
            }, 4000);
            this.setState({ timer: timer });
        }
    }
    animationEnd() {
        this.props.clearAnimation(this.props.message.id);
        let element = document.getElementById("appContentContainer");
        if (element) element.className = "content";
//        console.log("animation end " + this.props.message.id + " isConfirmed" + this.state.isConfirmed);

//        if (this.state.isConfirmed === true){
        if (this.props.message.nextAnimation === "userMessageExit") {
            this.props.confirm(this.props.message);
        }
    }

    goToMessageLog() {
        this.props.push('/userMessages');
    }

    render () {
//        console.log(this.props.message);
        moment.locale(this.props.currentLanguage);
        if (this.props.message.createdDateTime)   this.props.message.createdDateTime.locale(this.props.currentLanguage);
        if (this.props.message.confirmedDateTime) this.props.message.confirmedDateTime.locale(this.props.currentLanguage);

        let renderClassName = "userMessage ";
        if (this.props.message.type === "info")     renderClassName = renderClassName + "userInfoMessage ";
        if (this.props.message.type === "warning")  renderClassName = renderClassName + "userWarningMessage ";
        if (this.props.message.type === "error")    renderClassName = renderClassName + "userErrorMessage ";

        renderClassName = renderClassName + this.props.message.nextAnimation;

//        console.log("render message " + this.props.message.id + " with renderclass " + renderClassName);
        return (
            <div
                className={renderClassName}
                onAnimationEnd={() => this.animationEnd()}
                id={"userMessageRow-"+this.props.message.id}
                xp-test-id={'xpUserMessageRow'}
            >
                <span>
                    <span className='userMessageTopicDisplay'>
                        <XpTranslated trKey={'userTopicAdmin.topicToAdd.topics.'+this.props.message.topic} fallBackText={this.props.message.topic}/>
                    </span>
                    {this.props.message.createdDateTime.format('YYYY-MM-DD, HH:mm:ss') + " => "}
                    <XpTranslated trKey={this.props.message.textPath} trParams={this.props.message.parameters} fallBackText={this.props.message.plainText}/>
                </span>
                <img src={closeIcon} alt="Confirm" className="confirmMessageButton" onClick={() => this.closeMessage()}/>

                {(this.props.message.parameters?.clientId && this.props.userMessagesEnvConfig && this.props.userMessagesEnvConfig.useGoToClient && this.props.appConfig.userMessages?.gotoClientThunk) &&
                <WaitingXpoolButton
                    className       = "xPoolButtonInUserMessage"
                    labelKey        = 'userMessages.goToClientButton'
                    onClickCallback = {() => this.props.dispatch(this.props.appConfig?.userMessages?.gotoClientThunk({id: props.message.parameters.clientId}))}
                    toolTipKey      = 'userMessages.goToClientTooltip'
                    floatRight
                />}
                {(this.props.message.parameters?.linkToUmList) &&
                <WaitingXpoolButton
                    className       = "xPoolButtonInUserMessage"
                    labelKey        = 'userMessages.goToMessageLogButton'
                    onClickCallback = {() => this.goToMessageLog()}
                    toolTipKey      = 'userMessages.goToMessageLogTooltip'
                    floatRight
                />}
            </div>
        )
    }
}

const getMapStateToProps =  () => {
    return (state, props) => {
        return {
            userMessagesEnvConfig: selectAppEnvProperty(state, 'userMessages'),
            userMessages:       state.userMessagesState,
            translate:          selectTranslateFunction(state),
            currentLanguage:    selectActiveLanguage(state),
        };
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        confirm:        (message)     => dispatch(confirmUserMessageThunk(message)),
        clearAnimation: (messageId)   => dispatch(clearUserMessageAnimation(messageId)),
        exitAnimation:  (messageId, confirmedToApi)   => dispatch(setExitAnimation(messageId, confirmedToApi)),
        push:                 (loadPath)           => dispatch(push(loadPath)),
    };
};

export default connect(getMapStateToProps(), mapDispatchToProps)(UserMessageRow);

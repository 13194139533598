import React, {useEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {useDispatch, useSelector} from "react-redux";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import {loadAuditEntryFromApi, requestAuditEntriesFromApi} from "gui-common/audit/auditApi";
import {activeAuditRootModelsSelector} from "gui-common/audit/auditSelectors";
import {auditOrmToPrototypeMap, auditRootModelFromModelMap} from "gui-common/audit/auditConstants";
import AuditViewContent from "gui-common/audit/AuditViewContent";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {globalOrmConfig} from "gui-common/orm/ormModels";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getOrmItemSelectorGetter} from "gui-common/orm/ormSelectors";

const auditOnObjectEntriesListInstanceId = "auditOnObjectEntriesList";

function getRequestFilters(ormModel, modelObject) {
    if (!auditOrmToPrototypeMap[ormModel]) {
        return [];
    }
    let filterArray = [{
        modifiedPrototype: auditOrmToPrototypeMap[ormModel],
        modifiedEntityId: modelObject.id,
    }]
    if (!globalOrmConfig.nestedEntities[ormModel] || !globalOrmConfig.nestedEntities[ormModel].length) {
        return filterArray;
    }
    for (const nestedConfig of globalOrmConfig.nestedEntities[ormModel]) {
        if (!nestedConfig.useSubEntityProp) { // no prop for this, i.e. never managed separately, i.e. integrated part of the object.
            continue;
        }
        if (modelObject.ref[nestedConfig.useSubEntityProp] === true) { // Sub modelObject managed separately. Should not be included in this filter request
            continue;
        }
        if (!modelObject[nestedConfig.property]) {
            continue;
        }
        const nestedObjects = modelObject[nestedConfig.property].toModelArray();
        if (nestedObjects?.length) {
            filterArray = filterArray.concat(getRequestFilters(nestedConfig.model, nestedObjects[0]));
        }
    }
    return filterArray;
}

function ShowAuditHistoryModal (props) {
    const dispatch  = useDispatch();
    const auditRootModels   = useSelector(activeAuditRootModelsSelector);
    const auditedEntityOrmModelObject    = useSelectorInstance(getOrmItemSelectorGetter(props.ormModel), {selectId: props.auditedObject.id});
    const [auditEntries, setAuditEntries] = useState([]);
    const [reducedGrid, setReducedGrid] = useState(true);
    const [contentHeight, setContentHeight] = useState( undefined);
    const contentRef = useRef(null);

    const rootModel = useMemo(
        () => {
            const rootModelName = auditRootModelFromModelMap[props.ormModel];
            return auditRootModels.find(item => item.model === rootModelName);
        },
        []
    );

    useEffect(
        () => {
            if (!rootModel) {
                console.error("Could not find root model!", props);
                return;
            }
            dispatch(xpGridSetSelected(auditOnObjectEntriesListInstanceId, undefined));

            // If selector did not find auditedEntityOrmModelObject, it is probably disabled. Use auditedObject instead.
            const filterArray = getRequestFilters(props.ormModel, auditedEntityOrmModelObject ? auditedEntityOrmModelObject : props.auditedObject);
            if (!filterArray?.length) {
                return;
            }
            let tmpEntriesArray = [];

            Promise.all(filterArray.map(filter => {
                return dispatch(requestAuditEntriesFromApi(rootModel.id, filter, resp => {
                    tmpEntriesArray = tmpEntriesArray.concat(resp);
                }));
            })).then(result => {
                setAuditEntries(tmpEntriesArray.sort((first, last) => last.id - first.id));
            })
            if (filterArray.length > 1) setReducedGrid(false);
        },
        [],
    );

    useEffect(
        () => {
            if (!contentRef?.current) return;
            if (!contentHeight || (contentRef.current.scrollHeight > contentHeight)) setContentHeight(contentRef.current.scrollHeight);
        },
        [contentHeight, contentRef?.current?.scrollHeight],
    );

    const modalHeader = useMemo(
        () => {
            return (
                <span>
                    <XpTranslated trKey={'showAuditInfo.modalHeader'} />
                    <XpTranslated trKey={'general.modelNamesSmall.' + props.ormModel} />
                    {' ' + props.auditedObject.name}
                </span>
            );
        },
        []
    );

    function handleConfirm() {
        props.popModalWindow({ status: MODAL_TYPE_CONFIRM});
    }

    function fullEntryLoadRequest(auditEntry) {
        dispatch(loadAuditEntryFromApi(auditEntry, resp => {
            if (!auditEntries?.length) return;
            setAuditEntries(auditEntries.map(item => {
                if (item.id !== resp.id) return item;
                return {...item, ...resp};
            }))
        }));
    }

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalAudit"}};

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Confirmation modal"
                    onRequestClose={handleConfirm}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    <div xp-test-id={"xpModalShowAuditHistory" + props.ormModel}>
                        <h3 className ="xpoolModalHeader">{modalHeader}</h3>
                        <hr/>
                        <div ref={contentRef} style={{minHeight: contentHeight ? (contentHeight + 'px') : undefined}}>
                            <AuditViewContent
                                auditEntries         = {auditEntries}
                                requestFullEntryLoad = {(id) => fullEntryLoadRequest(id)}
                                listInstanceId       = {auditOnObjectEntriesListInstanceId}
                                contentInstanceId    = {'objectAudit'}
                                rootModel            = {rootModel}
                                hiddenState = {reducedGrid ? {
                                    id                  : true,
                                    auditDateTime       : false,
                                    operationTypeT      : false,
                                    userId              : true,
                                    userFullName        : false,
                                    apiVersion          : true,
                                } : {
                                    id                  : true,
                                    auditDateTime       : false,
                                    operationTypeT      : false,
                                    modelT              : false,
                                    modifiedObjectId    : true,
                                    modifiedObjectName  : false,
                                    userId              : true,
                                    userFullName        : false,
                                    apiVersion          : true,
                                }}
                                suppressObjectAudit={true}
                            />
                        </div>
                        <hr/>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonConfirm"}
                                labelKey        = {'general.buttons.close'}
                                onClickCallback = {handleConfirm}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
ShowAuditHistoryModal.propTypes = {
    popModalWindow  : PropTypes.func.isRequired,
    resultTypes     : PropTypes.object.isRequired,
    auditedObject   : PropTypes.object.isRequired,
    ormModel        : PropTypes.string.isRequired,
};

export default ShowAuditHistoryModal;


import React, {useEffect, useMemo} from 'react'
import PropTypes from 'prop-types';
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {onServiceActivationSwitchChange} from "features/legalEntityUnit/legalEntityUnitFunctions";
import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {useDispatch, useSelector} from "react-redux";
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {getDefaultItemId} from "gui-common/functions/functions";
import {useMarginGroupSelectList} from "features/marginGroup/marginGroupFunctions";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getShallowClientSelector} from "features/client/clientSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";

export function ListRateServiceSettings (props) {
    const formContext = useXpFormContext()
    const leuName = useXpFormField('name');
    const ormCurrencies   = useSelector(activeCurrenciesSelector);
    const decDenLangState = useSelector(selectDecDenLangState);
    const marginGroupsSelectList = useMarginGroupSelectList(item => item.priceMargin?.priceType === "BIDASK");

    const editClientApiChannel    = useXpFormField('xpAdminForm.clientSettings.Client.' + formContext.currentData?.client?.id + '.clientApiChannel');
    const newClientApiChannel     = useXpFormField('xpAdminForm.clientSettings.Client.new.clientApiChannel');
    const contextClientApiChannel = useXpFormField('clientApiChannel');
    const savedClientData = useSelectorInstance(getShallowClientSelector, {selectId: formContext.currentData?.client?.id});
    const noFormValidation = useAppEnvProperty('noFormValidation');

    const formDataTodayLimitOverride = useXpFormField('todayLimitOverride');
    const formDataPriceFeedOnly      = useXpFormField('priceFeedOnly');
    const formDataListRatesActive    = useXpFormField('enableListrates');
    const marginGroupAssigned        = useXpFormField('marginGroupAssigned');

    const dispatch = useDispatch();

    const clientApiChannel = useMemo(
        () => {
            if (formContext.formUseState === XP_FORM_VIEW) {
                return savedClientData?.clientApiChannel;
            }
            if (contextClientApiChannel) return contextClientApiChannel;
            if (editClientApiChannel) return editClientApiChannel;
            if (newClientApiChannel) return newClientApiChannel;
            return savedClientData?.clientApiChannel;
        },
        [editClientApiChannel, newClientApiChannel, savedClientData, contextClientApiChannel]
    );

    useEffect(
        () => {
            if (noFormValidation) {
                return;
            }
            if (clientApiChannel !== "INTERNAL") {
                dispatch(xpFormChangeField(formContext.formModel + '.exemptFromGlobalSuspend', false));
            }
        },
        [clientApiChannel, noFormValidation],
    );

    if (formDataPriceFeedOnly) return null;
    return (
        <div className={props.className}>
            <XpFormFieldGroup
                isExpanded={(formDataListRatesActive === true)}
                disableAnimations = {formContext?.formUseState === XP_FORM_VIEW}
                controlComponent={
                    <XpFormSwitchInput
                        field         = "enableListrates"
                        defaultValue  = {false}
                        onChangeThunk={onServiceActivationSwitchChange(leuName)}
                    />
                }
            >
                <XpFormSwitchInput
                    field         = "exemptFromGlobalSuspend"
                    defaultValue={false}
                    disabled={!noFormValidation && (clientApiChannel !== "INTERNAL")}
                />
                <XpFormSwitchInput
                    field         = "netDispatchTrades"
                    defaultValue  = {false}
                    dependentFields={{
                        sendToAccounting: 'sendToAccounting',
                    }}
                    errors={{
                        sendToAccountingIsActive:     (val, fields) => {
                            return fields.sendToAccounting && val;
                        }
                    }}
                />
                <XpFormSwitchInput
                    field         = "sendToAccounting"
                    defaultValue  = {false}
                />
                <XpFormSwitchInput
                    field         = "marginGroupAssigned"
                    defaultValue  = {false}
                />
                {marginGroupAssigned && <XpFormSelectorInput
                    isRequired inLineLayout noTemplate
                    field         = "marginGroupId"
                    selectList    = {marginGroupsSelectList}
                    defaultValue  = {getDefaultItemId(marginGroupsSelectList)}
                />}
                <XpFormSwitchInput
                    field         = "netTradingLimit"
                />
                <XpFormSelectorInput
                    isRequired inLineLayout alignRight noTemplate
                    field="tradingLimitCurrencyId"
                    selectList    = {ormCurrencies}
                    itemTextField = "currency"
                    inLineDivider={30}
                />
                <XpFormAmountInput
                    isRequired inLineLayout
                    field         = "tradingLimit"
                    errors={{
                        lowerThanZero:     (val) => /\d/.test(val) && (stringNumberToFloat(val, decDenLangState) < 0),
                    }}
                />
                {(formContext.auditMode && (formDataTodayLimitOverride > 0)) &&
                    <XpFormAmountInput
                        inLineLayout
                        field         = "todayLimitOverride"
                    />}

            </XpFormFieldGroup>

        </div>
    );
}
ListRateServiceSettings.propTypes = {
    className:        PropTypes.string,
};

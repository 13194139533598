import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {CONFIRMATION_DIALOG, INFORMATION_DIALOG} from "gui-common/modals/modalConstants";

export function checkAndConfirmDuplicate(checkArray, settingName, dispatch, user, saveShared) {
    return new Promise((resolve, reject) => {
        let returnObject = {duplicate: undefined, decision: "save"};
        returnObject.duplicate = checkArray.find(item => item.name === settingName);

        if (!returnObject.duplicate) {
            resolve(returnObject);
            return;
        }

        if (saveShared && !userCan('Update', 'SharedSetting', user)) {
            dispatch(pushModalWindow(INFORMATION_DIALOG,
                {
                    modalKey     : 'savedUserStates.duplicateNameInformation',
                    modalParams  : {name: settingName},
                }))
                .then((result) => {
                    returnObject.decision = "cancel";
                    resolve(returnObject);
                });
        }
        else {
            dispatch(pushModalWindow(CONFIRMATION_DIALOG,
                {
                    modalKey    : 'savedUserStates.duplicateNameConfirmation',
                    modalParams : {name: settingName},
                }))
                .then((result) => {
                    if (result.status === MODAL_TYPE_CONFIRM) returnObject.decision = "overwrite";
                    if (result.status === MODAL_TYPE_CANCEL)  returnObject.decision = "cancel";
                    resolve(returnObject);
                });
        }
    })
}

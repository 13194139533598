import {auditEntryTransformer} from "gui-common/audit/auditFunctions";
import {
    ormEntitiesGetFailed,
    ormEntitiesGetStarted,
    ormEntitiesReceived,
    ormEntityUpdated, ormEntityUpdateFailed, ormEntityUpdateStarted, ormEntitiesClearModel
} from "gui-common/orm/ormReducer";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {auditEntriesListInstanceId} from "gui-common/audit/AuditViewPage"
import {genericFetch} from "gui-common/api/apiCommon";
import {warnAndResolve} from "gui-common/functions/functions";
import {activeAuditActionTypesMapSelector} from "gui-common/audit/auditSelectors";
import {getAppEnvPropertySelector, selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {ormEntityClearLoadAll, ormEntityClearUpdate} from "gui-common/orm/ormLoadingReducer";
import cloneDeep from "lodash.clonedeep";
import {globalApiHandle} from "gui-common/api/apiConstants";



/* -----------------------------------------------------------------------------------------------------------------
* Functions to load a audit entriesfrom including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/

// If the loadDataCallback function is not defined, the data should be loaded into the ORM (base case for audit view)
export function requestAuditEntriesFromApi(rootModelId, filter, loadDataCallback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!filter) {
                warnAndResolve("Request audit entries called without filter", resolve);
                return;
            }
            if (!rootModelId) {
                warnAndResolve("Request audit entries called without rootModelId", resolve);
                return;
            }
            if (filter.modifiedEntityId && !filter.modifiedPrototype) {
                warnAndResolve("Request audit entries called with modifiedEntityId but no modifiedPrototype", resolve);
                return;
            }

            const filterStr = "?prototypeId=" + rootModelId +
                (filter.rootObjectId      ? "&entityId="          + filter.rootObjectId      : "") +
                (filter.modifiedPrototype ? "&modifiedPrototype=" + filter.modifiedPrototype : "") +
                (filter.modifiedEntityId  ? "&modifiedEntityId="  + filter.modifiedEntityId  : "") +
                (filter.userId            ? "&userId="            + filter.userId            : "") +
                (filter.fromDate          ? "&fromDate="          + filter.fromDate          : "") +
                (filter.toDate            ? "&toDate="            + filter.toDate            : "");

            let requestUrl = globalApiHandle.baseUrls.audit + filterStr + "&";

            let customText = "Request audit entries for " + filterStr;

            dispatch(ormEntitiesGetStarted('AuditEntry'));
            dispatch(genericFetch(
                requestUrl,
                customText,
                requestAuditEntriesResponseHandler(loadDataCallback),
                requestAuditEntriesFailHandler,
                requestAuditEntriesFailHandler,
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function auditItemsArrayTransformer(items, activeAuditActionTypesMap, auditConfig, getState) {
    let transformedItems = [];
    for (let item of items) {
        transformedItems.push(auditEntryTransformer(item, activeAuditActionTypesMap, auditConfig, getState));
    }
    return transformedItems;
}

const requestAuditEntriesResponseHandler = (loadDataCallback) => (resp) => {
    return (dispatch, getState) => {
        let transformedResponse = auditItemsArrayTransformer(resp, activeAuditActionTypesMapSelector(getState()), selectAppEnvProperty(getState(), 'auditConfig'), getState);
        if (loadDataCallback) {
            loadDataCallback(transformedResponse);
            dispatch(ormEntityClearLoadAll('AuditEntry')); // Must clear specifically since automatic functionality when using the ORM reducer is not activated.
            return;
        }
        dispatch(ormEntitiesClearModel('AuditEntry'));
        dispatch(ormEntitiesReceived('AuditEntry', transformedResponse));
        dispatch(xpGridSetSelected(auditEntriesListInstanceId, undefined));
    }
}

function requestAuditEntriesFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntitiesGetFailed('AuditEntry'));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to load a specific audit entry including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/

// If the loadDataCallback function is not defined, the data should be loaded into the ORM (base case for audit view)
export function loadAuditEntryFromApi(entry, loadDataCallback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!entry?.id) {
                warnAndResolve("Request audit entry called with no entryId", resolve);
                return;
            }
            let customText = "Request audit entry id " + entry.id;

            const currentApiVersion = getAppEnvPropertySelector()(getState(), 'currentApiVersion');
            const isCurrent = (entry.apiVersion === currentApiVersion)
            const historicAuditOnly = selectAppEnvProperty(getState(), 'historicAuditOnly');
            const useHistoric = !isCurrent || historicAuditOnly
            let baseUrl = useHistoric ? globalApiHandle.baseUrls.historicAudit : globalApiHandle.baseUrls.audit;
            let requestUrl = baseUrl + "/" + entry.id + "?";

            dispatch(ormEntityUpdateStarted('AuditEntry', {id: entry.id}));
            dispatch(genericFetch(
                requestUrl,
                customText,
                requestAuditEntryResponseHandler(entry.id, loadDataCallback, useHistoric),
                requestAuditEntryFailHandler(entry.id),
                requestAuditEntryFailHandler(entry.id),
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function requestAuditEntryResponseHandler(entryId, loadDataCallback, useHistoric) {
    return (resp) => {
        return (dispatch, getState) => {
            if (!resp) {
                console.log("API response for audit entry id " + entryId + " was empty");
                dispatch(ormEntityUpdateFailed('AuditEntry', {id: entryId}));
            }
            let transformedObject = {};
            if (useHistoric) {
                const parsedData = JSON.parse(resp);
                transformedObject = parsedData;
                transformedObject.rawAuditData = parsedData;
                console.log("Parsed historic audit entry: ", transformedObject);
                if (!transformedObject.id) {
                    transformedObject.id = entryId; // Fix for error in 2.4 audit files where there is no id on the records.
                }
            }
            else {
                const rawAuditData = cloneDeep(resp);
                transformedObject = auditEntryTransformer(resp, activeAuditActionTypesMapSelector(getState()), selectAppEnvProperty(getState(), 'auditConfig'), getState);
                transformedObject.rawAuditData = rawAuditData;
            }
            if (loadDataCallback) {
                loadDataCallback(transformedObject);
                dispatch(ormEntityClearUpdate('AuditEntry', {id: entryId})); // Must clear specifically since automatic functionality when using the ORM reducer is not activated.
                return;
            }
            dispatch(ormEntityUpdated('AuditEntry', transformedObject));
        }
    }
}

function requestAuditEntryFailHandler(entryId) {
    return (resp) => {
        return (dispatch, getState) => {
            console.log("Failed to get audit entry ", resp);
            dispatch(ormEntityUpdateFailed('AuditEntry', {id: entryId}));
        }
    }
}


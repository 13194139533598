import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

const commonProcessStatusConfig = {
    "se.nordicfc.common.integration.fix.common.heartbeat.FixProvider": {
        getStatusType: processArray => {
            if (processArray.some(process => {
                const sessionsArray = process.processStatus?.sessions;
                const atLeastOneSessionIsDown = sessionsArray.some(session => {
                    return session.status !== 'RUNNING'
                })
                return atLeastOneSessionIsDown;
            })) {
                return processStatusTypes.WARNING;
            }
            return processStatusTypes.RUNNING;
        },
        processTypeShortName: "FIX"
    }
}
const commonProcessStatusPackageConfig = {
    "se.nordicfc.common.integration.fix.common.heartbeat.model.FixSessions": {
        renderPackage: statusPackage => (<div>
            {statusPackage.sessions.map((session, index) => (<div key={index}>
                {session.sessionId}
                {" ==> " + session.status}
            </div>))}
        </div>)
    }
}
export const processStatusConfig = {};
export const processStatusPackageConfig = {};
export function setProcessStatusConfig(appConfig) {
    Object.assign(processStatusConfig       , commonProcessStatusConfig       , appConfig.processStatus?.processConfig);
    Object.assign(processStatusPackageConfig, commonProcessStatusPackageConfig, appConfig.processStatus?.packageConfig);
}

export const processStatusTypes = {
    'RUNNING'   : {
        name: 'RUNNING',
        renderClass: 'statusLightOk'
    },
    'STARTING'  : {
        name: 'STARTING',
        renderClass: 'statusLightStarting'
    },
    'WARNING'   : {
        name: 'WARNING',
        renderClass: 'statusLightWarning'
    },
    'ERROR' : {
        name: 'ERROR',
        renderClass: 'statusLightError'
    },
    'DOWN'  : {
        name: 'DOWN',
        renderClass: 'statusLightDown'
    }
}

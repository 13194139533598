import React from 'react'
import PropTypes from 'prop-types';
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {onServiceActivationSwitchChange} from "features/legalEntityUnit/legalEntityUnitFunctions";

export function ReferenceRateServiceActivation (props) {
    const leuName = useXpFormField('name');
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');

    if (formDataPriceFeedOnly) return null;
    return (
        <div className={props.className}>
            <XpFormSwitchInput
                field         = "enableIndicativeRates"
                defaultValue  = {true}
                onChangeThunk={onServiceActivationSwitchChange(leuName)}
            />
        </div>
    );
}
ReferenceRateServiceActivation.propTypes = {
    className:        PropTypes.string,
};

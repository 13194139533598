import {NavDropdown} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import PropTypes from "prop-types";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {useDispatch} from "react-redux";

function XpDropDownMenuItem(props) {
    const dispatch  = useDispatch();

    if (!props.displayCondition) {
        return null;
    }
    return (
        <NavDropdown.Item xp-test-id={props.testId} key={props.trKey} disabled={props.disabled} onClick={props.disabled ? null : (e) => {
            if (!props.useConfirmation) {
                props.confirmFunction()
            }
            else {
                dispatch(pushModalWindow(props.modalType ? props.modalType : CONFIRMATION_DIALOG,
                    {
                        modalKey: props.modalKey,
                        modalParams: props.modalParams,
                        ...props.modalProps
                    }))
                    .then((result) => {
                        if (result.status === MODAL_TYPE_CONFIRM) {
                            props.confirmFunction(result)
                        }
                        if (result.status === MODAL_TYPE_CANCEL) {
                        }
                    });
            }
        }}>
            <XpTranslated trKey={props.trKey} trParams={props.trParams}/>
        </NavDropdown.Item>
    )
}
XpDropDownMenuItem.propTypes = {
    displayCondition: PropTypes.bool,
    useConfirmation: PropTypes.bool,
    disabled: PropTypes.bool,
    testId: PropTypes.string,
    trKey: PropTypes.string.isRequired,

    modalType: PropTypes.string,
    modalProps: PropTypes.object,
    confirmFunction: PropTypes.func,
};
XpDropDownMenuItem.defaultProps = {
    displayCondition: true,
    useConfirmation: false,
};

export default XpDropDownMenuItem;

import PropTypes from "prop-types";
import React from "react";
import XpTooltip from "gui-common/components/XpTooltip";
import removeIcon from "gui-common/resources/removeIcon.png";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {useDispatch, useSelector} from "react-redux";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {sharedSettingsSelector, userSelector} from "gui-common/orm/ormSelectors";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {checkAndConfirmDuplicate} from "gui-common/userSettings/sharedSettings/savedUserStateFunctions";
import {postOrmItemToApi, putOrmItemToApi} from "gui-common/orm/ormApi";
import moment from "moment";
import {ormItemsLoading} from "gui-common/orm/ormLoadingSelectors";
import {isString} from "gui-common/functions/functions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {globalUserStatesConfig} from "gui-common/userSettings/userState/userStateConstants";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function SavedUserStatesList ({userStatesList, deleteUserState, shared, instanceId}) {

//    const [selectedId,   setSelectedId]   = useState("");
    const globalState                     = useSelector(state => state);
    const activeLanguage                  = useSelector(selectActiveLanguage);
    const user                            = useSelector(userSelector);
    const sharedUserStates                = useSelector(sharedSettingsSelector);
    const ormLoadingState                 = useSelector(state => ormItemsLoading(state, {ormModel: 'SharedSetting'}));

    const dispatch                        = useDispatch();

    const loadingState = shared ? ormLoadingState : undefined;

    function applySavedSettings(savedSettings) {
        if (!savedSettings.value || savedSettings.value.length === 0) return;

        let settingsArray = JSON.parse(savedSettings.value);
        settingsArray.forEach(setting => {
            const userStateStatic = globalUserStatesConfig[setting.name];
            if (!userStateStatic)                return;
            if (!userStateStatic.reloadFunction) return;
            let valueObject = {...JSON.parse(setting.value), userBlobId: globalState[setting.name].userBlobId}; // Must retain userBlobId and update in next check.
            console.log("Loading saved user setting " + setting.name + ".", valueObject);
            dispatch(userStateStatic.reloadFunction(valueObject));
        });
    }

    function shareSetting(setting) {
        checkAndConfirmDuplicate(sharedUserStates, setting.name, dispatch)
            .then(result => {
                if (!result.duplicate) {
                    dispatch(postOrmItemToApi(  'SharedSetting', setting))
                        .then(() => {
                            deleteUserState(setting)
                        })
                        .catch(res => {
                            console.log("Could not share setting. ", res);
                        })
                }
                else if (result.decision === "overwrite") {
//                    dispatch(deleteOrmItemToApi('SharedSetting', result.duplicate));
//                    dispatch(postOrmItemToApi(  'SharedSetting', setting));
                    dispatch(putOrmItemToApi(  'SharedSetting', {...setting, id: result.duplicate.id}))
                        .then(() => {
                            deleteUserState(setting)
                        })
                        .catch(res => {
                            console.log("Could not share setting. ", res);
                        })
                }
                else if (result.decision === "cancel") {
                }
            });
    }
    function deleteSetting (item) {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG,
            {
                modalKey    : 'savedUserStates.deleteSettingsConfirmation',
                modalParams : {name: item.name},
            }))
            .then((result) => {
                if (result.status === MODAL_TYPE_CONFIRM) deleteUserState(item);
                if (result.status === MODAL_TYPE_CANCEL) {}
            });
    }

    return (
        <div xp-test-id={'xpSavedUserStatesList-'+ instanceId}>
            <table style={{width: '100%', minWidth: '300px', tableLayout: 'fixed'}}>
                <tbody>
                <tr className="HeaderRow" key="header">
                    <th style={{width: '20px'}}    > </th>
                    <th style={{width: 'calc(35%-20px)'}}>
                        <XpTranslated trKey={shared ? 'savedUserStates.settingsList.sharedName' : 'savedUserStates.settingsList.localName'}/>
                    </th>
                    <th style={{width: '25%'}}    >
                        <XpTranslated trKey={'savedUserStates.settingsList.date'}/>
                    </th>
                    <th style={{width: '30%'}}    > </th>
                </tr>
                {(userStatesList && userStatesList.length > 0) ?
                    userStatesList.map(item => {
                        if (!item) return null;
                        let renderClass = "clickableRow";

                        let displayDate = "";
                        if (item.createdDateTime instanceof moment) {
                            item.createdDateTime.locale(activeLanguage);
                            displayDate = item.createdDateTime.format('l');
                        }
                        else if (isString(item.createdDateTime)) {
                            displayDate = moment(item.createdDateTime).format('l');
                        }

                        return (
                            <tr
                                key = {item.name}
                                className={renderClass}
                                //                                onClick={() => setSelectedId((item.name === selectedId) ? "" : item.name)}
                            >
                                <td style={{verticalAlign: 'middle'}}>
                                    {(!shared || userCan('Delete', 'SharedSetting', user)) &&
                                    <XpTooltip trKey='savedUserStates.settingsList.removeTooltip' trParams={{name: item.name}}>
                                        <img style={{height: '18px', margin: '0 0 2px 0', float: 'none' }} src={removeIcon} alt="Remove" className="closeDashboardComponentButton" onClick={() => deleteSetting(item)}/>
                                    </XpTooltip>}
                                </td>
                                <td style={{paddingLeft: '5px', verticalAlign: 'middle' }}><span className="textOverflow">{item.name}</span></td>
                                <td style={{paddingLeft: '5px', verticalAlign: 'middle' }}><span className="textOverflow">{displayDate}</span></td>
                                <td style={{verticalAlign: 'middle' }}>
                                    <WaitingXpoolButton
                                        className       = "xPoolButtonInTable"
                                        labelKey        = 'savedUserStates.settingsList.useButton'
                                        onClickCallback = {() => applySavedSettings(item)}
                                        toolTipKey      = 'savedUserStates.settingsList.useTooltip'
                                        waiting         = {(shared && loadingState && loadingState[item.id] && loadingState[item.id].loadingUpdate)}
                                        floatRight
                                        modalKey        = 'savedUserStates.useSettingsConfirmation'
                                        modalParams     = {{name: item.name}}
                                    />
                                    {(!shared && userCan('Create', 'SharedSetting', user)) &&
                                    <WaitingXpoolButton
                                        className       = "xPoolButtonInTable"
                                        labelKey        = 'savedUserStates.settingsList.shareButton'
                                        onClickCallback = {() => shareSetting(item)}
                                        toolTipKey      = 'savedUserStates.settingsList.shareTooltip'
                                        floatRight
                                        modalKey        = 'savedUserStates.shareSettingsModal'
                                        modalParams     = {{name: item.name}}
                                    />}
                                </td>
                            </tr>
                        )
                    })
                    :
                    <tr className="">
                        <td > </td>
                        <td style={{paddingLeft: '5px'}} colSpan={3} >
                            <div>
                                <XpTranslated trKey={shared ? 'savedUserStates.sharedSettings.noItemsMessage' : 'savedUserStates.savedSettings.noItemsMessage'}/>
                            </div>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}
SavedUserStatesList.propTypes = {
    userStatesList:  PropTypes.array.isRequired,
    deleteUserState: PropTypes.func,
    shared:          PropTypes.bool.isRequired
};

export default SavedUserStatesList




import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectIsDevMode, useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import {createMomentObjectsForProperties, createMomentObjectsForProperties2} from "gui-common/functions/functions";
import {translateDateFieldsRelativeNow} from "gui-common/xpGrid/xpGridFunctions";
import {NavDropdown} from "react-bootstrap";
import {setStyling} from "gui-common/app/commonAppFunctions";
import {addUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {getOrmDevMenuItems} from "gui-common/orm/ormFunctions";
import {setAppEnvParameter} from "gui-common/app/appEnvReducer";
import {LinkContainer} from "react-router-bootstrap";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";

function DevMenu (props) {
    const devMode                = useSelector(selectIsDevMode);
    const appReady               = useSelector(selectAppReadyState);
    const styleArray             = useAppEnvProperty( 'guiStyling');
    const noFormValidation       = useAppEnvProperty( 'noFormValidation');
    const oxygenAppContext       = useOxygenAppContext();

    const dispatch  = useDispatch();

    function baseCase(iterations){

        console.log("Starting test");

        const testObject = {time1: "2020-08-16T11:54:14.168Z"};
        const ret = createMomentObjectsForProperties(testObject, ['time1']);
        for (let i=1; i<=10000; i++) {
            translateDateFieldsRelativeNow(ret, 'en', ['time1']);
        }
        console.log("Test completed");
    }
    function alternative(iterations){
        console.log("Starting test2");
        for (let i=1; i<=iterations; i++) {
            // console.log("Test ", i);
            const testObject = {time1: "2020-08-16T11:54:14.168Z"};
            createMomentObjectsForProperties2(testObject, ['time1']); // 460 ms.
            // translateDateFieldsRelativeNow(testObject, 'en', ['time1', 'time2']);
            // console.log("Test2 completed", ret);
        }
        console.log("Test2 completed");
    }

    const iterations = 100000;

    if (!devMode || !appReady) return null;

    return (
        <NavDropdown title="Dev" id="dev-menu" alignRight>
            {styleArray.map(styling => <NavDropdown.Item key ={styling.name}    onClick={() => dispatch(setStyling(styling))}>{styling.name}</NavDropdown.Item>)}
            <NavDropdown.Divider/>
            <NavDropdown.Item key = 'um1' onClick={() => dispatch(addUserMessageThunk("error"  , "userMessages.error.apiFetchFail400", {fetchType: "FISH", errorCode: "brax", message: "torsk", httpCode: 430} , false))}>UM Error</NavDropdown.Item>
            <NavDropdown.Item key = 'um2' onClick={() => dispatch(addUserMessageThunk("info"   , "userMessages.info.legalEntityAdded", {name: "Ludde", id: 666}                         , false))}>UM Info</NavDropdown.Item>
            <NavDropdown.Item key = 'um4' onClick={() => dispatch(addUserMessageThunk("warning", "userMessages.info.legalEntityAdded", {name: "Internal", id: 666}, true                , false))}>UM Internal</NavDropdown.Item>
            <NavDropdown.Divider/>
            <NavDropdown.Item key = 'genTest1'   onClick={() => baseCase(iterations)}>Base test</NavDropdown.Item>
            <NavDropdown.Item key = 'genTest2'   onClick={() => alternative(iterations)}>Alt test</NavDropdown.Item>
            {/*
                                {this.renderPerformanceTest("A", this.perfTestA)}
                                {this.renderPerformanceTest("B", this.perfTestB)}
                                {this.renderPerformanceTest("C", this.perfTestC)}
*/}
            {/*<LinkContainer to='/adminUserRightsDev'><MenuItem>{translate('topMenu.userRights')+"Dev"}</MenuItem></LinkContainer>*/}
            <NavDropdown.Divider/>
            {getOrmDevMenuItems(dispatch)}
            <NavDropdown.Divider/>
            <NavDropdown.Item
                key = 'noFormValidation'
                onClick={() => dispatch(setAppEnvParameter('noFormValidation', !noFormValidation))}
            >
                {noFormValidation ? "Turn ON form validation" : "Turn OFF form validation"}
            </NavDropdown.Item>
            <NavDropdown.Divider/>
            {oxygenAppContext.appConfig.mainMenu.devItems}
            <NavDropdown.Divider/>
            <LinkContainer to='/testPage'><NavDropdown.Item>TestPage</NavDropdown.Item></LinkContainer>
        </NavDropdown>
    )
}
export default DevMenu




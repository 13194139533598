import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {dbStateSelector} from "../orm/dbStateSelector";
import {selectTranslateFunction} from "../appLocale/xpTranslated/xpTranslatedSelectors";
import {createSelector} from "reselect";
import {orm} from "gui-common/orm/orm";

export const fixLogListDataSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectTranslateFunction(state),
    (session, translate) => {
        return session.FixMessage.all().orderBy('logTime', 'desc').toRefArray();
    }
);

export const getFilteredFixLogListDataSelector = () => createSelector(
    [
        fixLogListDataSelector,
        (state, props) => props.regexpSearchString,
    ],
    (fixLog, regexpSearchString) => {
        return fixLog.filter(item => item.message.search(regexpSearchString) !== -1);
    }
);
export const fixLogContainsMessagesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        return session.FixMessage.count() > 0;
    }
);

import {
    createStandardAuditFields2,
    xpColCreate, xpColHeadCreate
} from "gui-common/xpGrid/xpGridFunctions";
import {allowAll, domainRightAllowed, instanceRightAllowed} from "gui-common/userRights/userRightsFunctions";
import {agreementStates, agreementTypes} from "features/agreement/agreementConstants";
import {useStore} from "react-redux";
import {getOrmItemSelectorGetter} from "gui-common/orm/ormSelectors";
import {globalUserRightsConfig} from "gui-common/userRights/userRightsConstants";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG, CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {getActiveListRatesWithOwnerProcessDown, getAffectedListRates} from "features/listRate/listRateFunctions";
import {putAgreementActionToApi} from "features/agreement/agreementApi";
import {entityIsActive} from "gui-common/audit/auditFunctions";

export function canAgreementBeDisabled(agreement) {

    let returnObject = {
        canBeDisabled: true,
        modalKey: 'agreementForm.modalConfirmDisableListRateAgreement',
        modalParams: {name: agreement.name}
    };

    // Market order agreements can always be disabled.
    if (['MarketOrderAgreement', 'MarketOrderAllAgreement', 'ReferenceRateAgreement', 'PriceFeedAgreement'].indexOf(agreement.type) !== -1) {
        returnObject.modalKey = 'agreementForm.modalConfirmDisableMarketOrderAgreement';
        return returnObject;
    }
    // Only allow to disable of a list rate agreement when in state suspended.
    if (agreement.status !== 'Suspended') {
        returnObject.modalKey = 'agreementForm.modalConfirmListRateAgreementActive';
        returnObject.canBeDisabled = false;
        return returnObject;
    }
    return returnObject;
}


export function getAgreementsColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState,'name'                 , 200, 100, 'agreementsList.name', {cellRenderer: 'agGroupCellRenderer', lockVisible: true, lockPosition: true});
    xpColCreate(translate, subArray, hiddenState,'id'                   , 100, 50 , 'agreementsList.id', {cellStyle: {textAlign: "right"}, sort: 'desc'});
    xpColCreate(translate, subArray, hiddenState,'currencyPairStr'      , 200, 100, 'agreementsList.currencyPairStr');
    xpColCreate(translate, subArray, hiddenState,'typeT'                , 100, 50, 'agreementsList.type', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: agreementTypes.map(item => 'agreementForm.type.values.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState,'clientApiChannelT'    , 100, 50, 'clientForm.clientApiChannel.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState,'tenor'                , 100, 50 , 'agreementsList.tenor');
    xpColCreate(translate, subArray, hiddenState,'exemptFromGlobalSuspendT', 50, 50, 'legalEntityUnitForm.exemptFromGlobalSuspend.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState,'todayCutOffTime'      , 100, 50 , 'agreementsList.todayCutOffTime');
    xpColCreate(translate, subArray, hiddenState,'listRatePeriodT'      , 100, 50 , 'agreementsList.listRatePeriod');
    xpColCreate(translate, subArray, hiddenState,'dispatchTradePeriodT' , 100, 50 , 'agreementsList.dispatchTradePeriod');
    xpColCreate(translate, subArray, hiddenState,'dispatchLevel'        , 200, 100, 'agreementsList.dispatchLevel', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'gracePeriodT'         , 200, 100, 'agreementsList.gracePeriod', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'buySellType'          , 100, 50 , 'agreementsList.buySellType');
    xpColCreate(translate, subArray, hiddenState,'meansOfPaymentT'      , 100, 50 , 'agreementsList.meansOfPayment');
    xpColCreate(translate, subArray, hiddenState,'openTime'             , 150, 80 , 'agreementsList.openTime');
    xpColCreate(translate, subArray, hiddenState,'closeTime'            , 150, 80 , 'agreementsList.closeTime');
    xpColCreate(translate, subArray, hiddenState,'comment'              , 300, 100, 'agreementsList.comment', {tooltipField: 'comment'});
    xpColCreate(translate, subArray, hiddenState,'guiStatusT'           , 300, 100, 'agreementsList.status', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: agreementStates.map(item => 'agreementsList.statusTypes.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColHeadCreate(translate, outArray, subArray,  'agreementsList.agreementInfo');

    xpColCreate(translate, subArray, hiddenState,'suspendedByUser'      , 300, 100, 'agreementsList.suspendedByUserId', {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState,'suspendedDateTime'    , 300, 100, 'agreementsList.suspendedDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState,'suspendedComment'     , 300, 100, 'agreementsList.suspendedComment', {tooltipField: 'suspendedComment'});
    xpColHeadCreate(translate, outArray, subArray,  'agreementsList.suspendInfo');

    xpColCreate(translate, subArray, hiddenState,'resumedByUser'        , 300, 100, 'agreementsList.resumedByUserId', {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState,'resumedDateTime'      , 300, 100, 'agreementsList.resumedDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState,'resumedComment'       , 300, 100, 'agreementsList.resumedComment', {tooltipField: 'resumedComment'});
    xpColHeadCreate(translate, outArray, subArray,  'agreementsList.resumeInfo');

    xpColCreate(translate, subArray, hiddenState,'clientId'             , 100, 50 , 'agreementsList.clientId');
    xpColCreate(translate, subArray, hiddenState,'clientName'           , 300, 100, 'agreementsList.clientName');
    xpColCreate(translate, subArray, hiddenState,'legalEntityId'        , 100, 50 , 'agreementsList.legalEntityId');
    xpColCreate(translate, subArray, hiddenState,'legalEntityName'      , 300, 100, 'agreementsList.legalEntityName');
    xpColCreate(translate, subArray, hiddenState,'legalEntityUnitId'    , 100, 50 , 'agreementsList.legalEntityUnitId');
    xpColCreate(translate, subArray, hiddenState,'legalEntityUnitName'  , 300, 100, 'agreementsList.legalEntityUnitName');
    xpColHeadCreate(translate, outArray, subArray, 'agreementsList.partyInfo');

    xpColCreate(translate, subArray, hiddenState,'buyAmount'           , 100, 50 , 'listRatesList.buyAmount'       , {cellRenderer: 'xpGridNumberRenderer'  , cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'sellAmount'          , 100, 50 , 'listRatesList.sellAmount'      , {cellRenderer: 'xpGridNumberRenderer'  , cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'timeExpire'          , 200, 100, 'listRatesList.timeExpire'      , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState,'nextDispatchTime'    , 200, 100, 'listRatesList.nextDispatchTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColHeadCreate(translate, outArray, subArray, 'agreementsList.activeListRate');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}

export function userCanSuspendAgreement(agreement) {
    if (allowAll) return true;
    if (!agreement) return false;
    return instanceRightAllowed(agreement,'Suspend');
}
export function userCanSuspendAgreements(parentEntity) {
    if (allowAll) return true;
    if (!parentEntity) return false;
    return domainRightAllowed('Agreement'    , parentEntity, 'Suspend');
}
export function userCanResumeAgreement(agreement) {
    if (allowAll) return true;
    if (!agreement) return false;
    return instanceRightAllowed(agreement,'Resume');
}
export function userCanResumeAgreements(parentEntity) {
    if (allowAll) return true;
    if (!parentEntity) return false;
    return domainRightAllowed('Agreement'    , parentEntity, 'Resume');
}

export function getTenorsToMap(ormTenors, tenorsInAgreement) {
    let retObject = {};

    ormTenors.forEach(tenor => {
        retObject[tenor.name] = (tenorsInAgreement && (tenorsInAgreement[tenor.name] === true));
    })
    if (!tenorsInAgreement) return retObject

    Object.keys(tenorsInAgreement).forEach(key => {
        if (retObject[key] === undefined) retObject[key] = (tenorsInAgreement[key] === true);
    });

    return retObject;
}

const prototypeToTypeMap = {
    ListRateAgreementEntity      : "ListRateAgreement",
    MarketOrderAgreementEntity   : "MarketOrderAgreement",
    MarketOrderAllAgreementEntity: "MarketOrderAllAgreement",
    ReferenceRateAgreementEntity : "ReferenceRateAgreement",
    PriceFeedAgreementEntity     : "PriceFeedAgreement",
}

export function getAuthorisedAgreementTypes(executionRights, right) {
    if (allowAll) return globalUserRightsConfig.modelToPrototypeMap.Agreement.map(prototype => prototypeToTypeMap[prototype]);

    if (!executionRights || !executionRights.domainRights) return undefined;

    let authorizedPrototypes = [];
    globalUserRightsConfig.modelToPrototypeMap.Agreement.forEach(prototype => {
        const prototypeDomainRights = executionRights.domainRights[prototype];
        if (!prototypeDomainRights) return false;
        if (prototypeDomainRights.entityPrototypeRights[right] === true) authorizedPrototypes.push(prototypeToTypeMap[prototype]);
    })
    return authorizedPrototypes;
}

export function useAgreementSelectorFunction() {
    const reduxStore = useStore();
    const agreementSelector = getOrmItemSelectorGetter('Agreement')();

    return (selectId) => {
        const reduxState = reduxStore.getState();
        const listRateAgreementModel = agreementSelector(reduxState, {selectId: selectId});

        if (!listRateAgreementModel) {
            return undefined
        }
        return listRateAgreementModel.ref;
    }
}



export function noListRateProcessRunning(processStatus) {
    return !processStatus || !Object.keys(processStatus).length || !Object.values(processStatus).filter(item => item.active).length;
}

export function createListRateAgreementMenuFunction(agreements, listRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, translateRef, dispatch) {
    const affectedAgreements = getAffectedAgreements(agreements, action);
    return {
        name: translateRef.current(trKeyBase + "." + action + ".menuItem", {count: affectedAgreements.length, name: parentName}),
        action: () => setTimeout(() => launchListRateAgreementAction(agreements, listRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, dispatch), 50),
        context: {dispatch: dispatch},
        disabled: affectedAgreements.length === 0,
    }
}


export function launchListRateAgreementAction(agreements, listRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, dispatch, highestAlert) {

    const affectedAgreements = getAffectedAgreements(agreements, action);
    const affectedListRates  = action === 'suspend' ? getAffectedListRates(listRates,  'expire') : [];
    const listRatesWithProcessDown      = getActiveListRatesWithOwnerProcessDown(listRates, listRateProcessStatusRef.current);
    const listRatesWithProcessDownCount = listRatesWithProcessDown?.length;

    const noListRateProcessActive        = noListRateProcessRunning(listRateProcessStatusRef.current);

    function launchModal() {
        dispatch(pushModalWindow(CONFIRMATION_WITH_COMMENT_DIALOG, {modalKey: trKeyBase + "." + action, modalParams: {count: affectedAgreements.length, openListRates: affectedListRates.length, name: parentName}, commentIsRequired: commentRequiredMap[action], bodyClassName: highestAlert ? "modalBodyHighestAlert" : ""}))
            .then((result) => {
                if (result?.status === MODAL_TYPE_CONFIRM) {
                    dispatch(putAgreementActionToApi(action, filter, result.comment));
                }
            })
    }

    if (noListRateProcessActive) {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "listRates.confirmNoListRateProcessRunningModal", modalParams: {}}))
            .then((result) => {
                if (result?.status === MODAL_TYPE_CONFIRM) {
                    launchModal();
                }
            })
        return;
    }

    if (listRatesWithProcessDown?.length !== 0) {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "listRates.confirmUnmanagedListRatesModal", modalParams: {count: listRatesWithProcessDownCount}}))
            .then((result) => {
                if (result?.status === MODAL_TYPE_CONFIRM) {
                    launchModal();
                }
            })
        return;
    }
    launchModal();
}


export function getAffectedAgreements(agreements, action) {
    if (!agreements || !agreements.length) return [];

    let checkStatus;
    if (action === 'suspend') {
        checkStatus = 'Active';
    }
    else if (action === 'resume') {
        checkStatus = 'Suspended';
    }
    else {
        return [];
    }
    return agreements.filter(item => entityIsActive(item) && item.status === checkStatus && item.type === 'ListRateAgreement');
}
function getCount(items, status) {
    if (!items || !items.length) return 0;
    return items.filter(item => item.status === status).length;
}

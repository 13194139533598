import {xpIsRequired} from "gui-common/functions/functions";
import {globalOrmConfig} from "gui-common/orm/ormModels";
import {entityIsDisabled} from "gui-common/audit/auditFunctions";
import {globalUserRightsConfig} from "gui-common/userRights/userRightsConstants";

export const allowAll = (window.oxygen_env && (window.oxygen_env.ALLOW_ALL !== undefined)) ? window.oxygen_env.ALLOW_ALL : false;

//************************************************************************************************************
//************** INSTANCE RIGHTS FUNCTIONS *********************************************************************
//************************************************************************************************************

export const instanceRightAllowed = (instance, right) => {
    if (!instance.executionRights || !instance.executionRights.instanceRights) return false;
    return (instance && (instance.executionRights.instanceRights[right] === true));
};
function nestedInstanceRightAllowed(instance, thisModelToCheck, rightToCheck, nestedPropToCheck) {
    if (!instanceRightAllowed(instance, rightToCheck)) return false;
    if (!nestedPropToCheck) return true;
    for (let nestedModel of globalOrmConfig.nestedEntities[thisModelToCheck]) {
        if (!nestedModel[nestedPropToCheck]) continue;
        if (!instance[nestedModel.property]) continue;
        for (let childInstance of instance[nestedModel.property]) {
            if (entityIsDisabled(childInstance)) continue;
            if (!nestedInstanceRightAllowed(childInstance, nestedModel.model, rightToCheck, nestedPropToCheck)) return false;
        }
    }
    return true;
}
export function userCanDelete(objectInstance, ormModel) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    if (ormModel) return nestedInstanceRightAllowed(objectInstance, ormModel, 'Delete', 'nestedRightRequiredForAdmin');
    return instanceRightAllowed(objectInstance,'Delete');
}
export function userCanUpdate(objectInstance) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,'Update');
}
export function userCanRunPool(client) {
    if (allowAll) return true;
    if (!client) return false;
    return instanceRightAllowed(client,'RunCashpoolExecution');
}
export function userCanRefreshBalances(client) {
    if (allowAll) return true;
    if (!client) return false;
    return instanceRightAllowed(client,'RunCashpoolCollectBalance');
}

// --- field specific rights on Client, Legal entity, Legal Entity Unit and BankAccount ----
export function userCanEditBase(objectInstance) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,'EditBase');
}
export function userCanEditRuntimes(objectInstance) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,'EditRuntimes');
}
export function userCanEditMinMax(objectInstance) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,'EditMinMax');
}
export function userCanEditHoldOnRate(objectInstance) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,'EditHoldOnRate');
}
export function userCanEditPoolParams(objectInstance) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,'EditPoolParams');
}
export function userCanRegisterBalance(objectInstance) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,'BalanceCreate');
}
export function userCanDisableBalance(objectInstance) {
    if (allowAll) return true;
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,'BalanceDelete');
}



//************************************************************************************************************
//************** DOMAIN RIGHTS FUNCTIONS *********************************************************************
//************************************************************************************************************

export const domainRightAllowed = (model, instance, right) => {
    if (allowAll) return true;
    if (!instance.executionRights || !instance.executionRights.domainRights) return false;
    const prototypes = globalUserRightsConfig.modelToPrototypeMap[model];
    for (let prototype of prototypes) {
        const prototypeDomainRights = instance.executionRights.domainRights[prototype];
        if (!prototypeDomainRights) return false;
        if (prototypeDomainRights.entityPrototypeRights[right] === true) return true;
    }
    return false;
};

export function userCanReadDomain(modelToRead, parent) {
    if (allowAll) return true;
    if (!modelToRead) {
        console.error("No modelToRead in userCanReadDomain");
        return false;
    }
    if (typeof parent === 'object') {
        return domainRightAllowed(modelToRead, parent, 'Read');
    }
    else {
        console.error("Incorrect type for parent in userCanCreate");
        return false;
    }
}

// This will only work for Create since all other rights would require to parse instanceRights structure in actual data
function nestedDomainRightsAllowed(instance, thisModelToCheck, rightToCheck, nestedPropToCheck) {
    if (!domainRightAllowed(thisModelToCheck, instance, rightToCheck)) return false;
    if (!nestedPropToCheck) return true;
    for (let nestedModel of globalOrmConfig.nestedEntities[thisModelToCheck]) {
        if (!nestedModel[nestedPropToCheck]) continue;
        if (!nestedDomainRightsAllowed(instance, nestedModel.model, rightToCheck, nestedPropToCheck)) return false;
    }
    return true;
}

// Used to check for what op units the user can create full client structure including bank accounts.
export function userCanCreate(modelToCreate, parent, nestedPropToCheck) {
    if (allowAll) return true;
    if (!modelToCreate) {
        console.error("No modelToCreate in userCanCreate");
        return false;
    }

    let parentArray;
    if      (Array.isArray(parent))      parentArray = parent
    else if (typeof parent === 'object') parentArray = [parent]
    else {
        console.error("Incorrect type for parent in userCanCreate");
        return false;
    }
    for (let parent of parentArray) if (nestedDomainRightsAllowed(parent, modelToCreate, 'Create', nestedPropToCheck)) return true;
    return false;
}

//************************************************************************************************************
//************** SYSTEM RIGHTS FUNCTIONS *********************************************************************
//************************************************************************************************************
const systemRightAllowed = (user, systemFunction, right) => {
    if (!user || !user.systemRights || !user.systemRights.systemRights) return false;
    const functionRights = user.systemRights.systemRights[systemFunction];
    if (!functionRights) return false;
    return (functionRights.entityPrototypeRights[right] === true);
};

export function userCan(right, systemFunction, user) {
    if (allowAll) return true;
    return systemRightAllowed(user, systemFunction, right)
}

//************************************************************************************************************
//************** Support functions to get info on user rights configuration **********************************
//************************************************************************************************************
function getEntityDataRight(user, entity, prototype) {
    if (!user.dataRightsCollections || user.dataRightsCollections.length) return undefined;

    for (let dataRightsCollection of user.dataRightsCollections) {
        if (!dataRightsCollection.assigned) continue;
        for (let entityDataRight of dataRightsCollection.assigned) {
            if (entityDataRight.entity.id !== entity.id) continue;
            if (entityDataRight.entityPrototypeName !== prototype) continue;
            return entityDataRight;
        }
    }
}
function userRightRestricted(entityDataRight, right, user) {
    if (!right || !entityDataRight) return false;

    if (entityDataRight.instanceRights && (entityDataRight.instanceRights[right] === false)) return true;

    if (entityDataRight.domainRights) {
        for (let domain in entityDataRight.domainRights) {
            const domainRights = entityDataRight.domainRights[domain];
            if (domainRights && domainRights.entityPrototypeRights && (domainRights.entityPrototypeRights[right] === false)) return true;
        }
    }
    if (!entityDataRight.childDataRightsCollectionId) return false;

    const childDataRightsCollection = user.dataRightsCollections.find(item => item.id === entityDataRight.childDataRightsCollectionId);
    if (!childDataRightsCollection || !childDataRightsCollection.assigned || !childDataRightsCollection.assigned.length) return false;

    for (let childEntityDataRight of childDataRightsCollection.assigned) {
        if (userRightRestricted(childEntityDataRight, right, user)) return true;
    }
    return false;
}
export function userRightRestrictedBelowEntity(
    user       = xpIsRequired('user'  ),
    entity     = xpIsRequired('entity'),
    model      = xpIsRequired('model' ),
    right      = xpIsRequired('right' ),
) {
    if (allowAll) return false;
    if (!user ||!entity || !model || !right) {
        console.error("Incorrect parameters to function userRightRestrictedBelowEntity", user, entity, model, right);
        return true;
    }
    const entityDataRight = getEntityDataRight(user, entity, globalUserRightsConfig.modelToPrototypeMap[model]);
    if (!entityDataRight) return false;

    return userRightRestricted(entityDataRight, right, user);
}

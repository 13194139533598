import React, {useCallback, useMemo, useRef} from 'react';
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {useDispatch, useSelector} from "react-redux";
import {bankHolidaysSelector} from "gui-common/bankHolidays/bankHolidaysSelectors";
import {deleteHolidayAfterConfirm, prepareBankHolidaysColumns} from "gui-common/bankHolidays/bankHolidaysFunctions";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {userSelector} from "gui-common/orm/ormSelectors";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

export const bankHolidaysHiddenState = {
    id                  : true,
    name                : true,
    currencyName        : false,
    date                : false,
    description         : false,
    comment             : false,
    createdDateTime     : false,
    createdByUser       : false,
    changedDateTime     : false,
    changedByUser       : false,
    disabledDateTime    : true,
    disabledByUser      : true,
};


function BankHolidaysDataList (props) {

    const translate     = useXpTranslateFunction();
    const user          = useSelector(userSelector);
    const dispatch      = useDispatch();
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;


    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridRowDoubleClicked    : useCallback(props.auditMode ? () => {} : (params) => props.setEditNew(params.node.data), []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!props.itemSelectedCallback) return;
                props.itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [props.itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            props.auditMode ? () => {} : (params) => {
                if (!params || !params.node || !params.node.data) return [];
                let menuItems = [];

                if (userCan('Update', 'BankHolidays', user)) menuItems.push({
                    name: translateRef.current("bankHolidays.list.gridContextMenuItems.update.menuItem"),
                    action: () => props.setEditNew(params.node.data),
                    context: {params: params, dispatch: dispatch},
                    disabled: false,
                });

                if (userCan('Create', 'BankHolidays', user)) menuItems.push({
                    name: translateRef.current("bankHolidays.list.gridContextMenuItems.create.menuItem"),
                    action: () => props.setEditNew(),
                    context: {params: params, dispatch: dispatch},
                    disabled: false,
                });

                if (userCan('Delete', 'BankHolidays', user)) menuItems.push({
                    name: translateRef.current("bankHolidays.list.gridContextMenuItems.delete.menuItem"),
                    action: () => dispatch(deleteHolidayAfterConfirm(params.node.data)),
                    context: {params: params, dispatch: dispatch},
                    disabled: false,
                });

                return menuItems;
            }, [translate]),
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('bankHolidays.noBankHolidayDataToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? true : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                rowDataSelector         : bankHolidaysSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                // getRowDataSelector      : ,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'BankHoliday',
                rowClassRules           : {},
            };
        },
        [translate, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareBankHolidaysColumns(translate, props.bankHolidaysHiddenState ? props.bankHolidaysHiddenState : bankHolidaysHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={'bankHolidaysDataList'}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
            >
            </XpGrid>
        </div>
    );
}
export default BankHolidaysDataList;

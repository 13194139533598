/* -----------------------------------------------------------------------------------------------------------------
* Socket message dictionaries for models and message types.
* -----------------------------------------------------------------------------------------------------------------*/
import {addUserMessage, updateUserMessage} from "gui-common/userMessages/userMessagesReducer";
import {executionRightsUpdated} from "gui-common/functions/thunks";
import {ormFunctionMap, ormFunctionMapDeleted} from "gui-common/api/ormSocketMethodMaps";
import {handleSystemTextResponseThunk} from "gui-common/appLocale/xpTranslated/systemTextApi";
import {gotFxRejectConfigurationFromSocket} from "gui-common/fxRejectConfig/fxRejectConfigApi";
import {clearAndReloadBankHolidays} from "gui-common/bankHolidays/bankHolidaysFunctions";
import {fixLogContainsMessagesSelector} from "gui-common/fixLog/fxLogSelectors";
import {gotCurrencyPairSettingsFromSocket} from "gui-common/currencyPairConfig/currencyPairConfigFunctions";


const systemTextMap = {
    'se.nordicfc.common.message.functions.Create'                    : {batchPossible: false, ormEventType: 'create', functionToDispatch: (model, payload) => handleSystemTextResponseThunk([payload])},
};

const bankHolidayFunctionMap = {
    'se.nordicfc.common.message.functions.Update'                    : {batchPossible: false, ormEventType: 'update', functionToDispatch: (model, payload) => clearAndReloadBankHolidays()},
};
const umFunctionMap = {
    'se.nordicfc.common.message.functions.Create'                    : {batchPossible: false, ormEventType: 'create', functionToDispatch: (model, payload) => addUserMessage(model, payload)},
    'se.nordicfc.common.message.functions.Update'                    : {batchPossible: false, ormEventType: 'update', functionToDispatch: (model, payload) => updateUserMessage(model, payload)},
};
const userFunctionMap = {
    ...ormFunctionMap,
    'se.nordicfc.common.message.functions.ReAuthenticateUser'        : {batchPossible: false, ormEventType: 'update', functionToDispatch: (model, payload) => executionRightsUpdated(model, payload)},
};
const fxRejectConfigFunctionMap = {
    'se.nordicfc.common.message.functions.Create'                    : {batchPossible: false, ormEventType: 'create', functionToDispatch: (model, payload) => gotFxRejectConfigurationFromSocket(payload)},
};
const currencyPairSettingsFunctionMap = {
    'se.nordicfc.common.message.functions.Update'                    : {batchPossible: false, ormEventType: 'create', functionToDispatch: (model, payload) => gotCurrencyPairSettingsFromSocket(payload)},
};

export const commonWebSocketEvents = {
    'USER_MESSAGE'              : 'se.nordicfc.common.usermessage.repository.model.UserMessageTicket',
    'USER'                      : 'se.nordicfc.common.model.User',
    'SYSTEM_EVENT'              : 'se.nordicfc.common.model.SystemEvent',
    'SYSTEM_TEXT'               : 'se.nordicfc.common.model.SystemText',
    'FX_REJECT_CONFIGURATION'   : 'se.nordicfc.common.fxrejectconfiguration.model.FxRejectConfigurationGroup',
    'SHARED_SETTING'            : 'se.nordicfc.xpoolapi.model.XpoolApiSharedSettings',
    'FX_PRICE'                  : 'se.nordicfc.common.model.FxPrice',
    'FIX_MESSAGE'               : 'se.nordicfc.common.datatypes.model.NfcFixLog',
    'NO_RUN_DATE'               : 'se.nordicfc.xpoolapi.model.XpoolApiNoRunDate',
    'CURRENCY_PAIR_SETTING'     : 'se.nordicfc.common.currencypairsettings.model.CurrencyPairSetting',
    'DATA_RIGHTS_COLLECTION'    : 'se.nordicfc.common.security.authorisation.model.shallow.ShallowDataRightsCollection',
    'SYSTEM_RIGHT'              : 'se.nordicfc.common.authorisation.model.shallow.ShallowSystemRights',
    'BANK_HOLIDAYS_UPDATED'     : 'se.nordicfc.xpool.common.model.function.BankHolidayUpdated',
    'PROCESS_STATUS'            : 'se.nordicfc.common.application.monitor.heartbeat.model.ApiProcessStatus',
}

export const commonProcessWebSocketEventMap = {
    [commonWebSocketEvents.USER_MESSAGE]              : { batchPossible: false, model: 'UserMessage'          , functionMap: umFunctionMap            , logMessage: false },
    [commonWebSocketEvents.USER]                      : { batchPossible: false, model: 'User'                 , functionMap: userFunctionMap          , logMessage: false },
    [commonWebSocketEvents.SYSTEM_EVENT]              : { batchPossible: false, model: 'SystemEvent'          , functionMap: ormFunctionMap           , logMessage: false },
    [commonWebSocketEvents.SYSTEM_TEXT]               : { batchPossible: false, model: 'SystemText'           , functionMap: systemTextMap            , logMessage: false },
    [commonWebSocketEvents.FX_REJECT_CONFIGURATION]   : { batchPossible: false, model: 'FxRejectConfiguration', functionMap: fxRejectConfigFunctionMap, logMessage: false },
    [commonWebSocketEvents.SHARED_SETTING]            : { batchPossible: true , model: 'SharedSetting'        , functionMap: ormFunctionMapDeleted    , logMessage: false },
    [commonWebSocketEvents.FX_PRICE]                  : { batchPossible: true , model: 'FxPrice'              , functionMap: ormFunctionMapDeleted    , logMessage: false },
    [commonWebSocketEvents.FIX_MESSAGE]               : { batchPossible: true , model: 'FixMessage'           , functionMap: ormFunctionMap           , logMessage: false },
    [commonWebSocketEvents.NO_RUN_DATE]               : { batchPossible: false, model: 'NoRunDate'            , functionMap: ormFunctionMapDeleted    , logMessage: false },
    // [commonWebSocketEvents.CURRENCY_PAIR_SETTING]     : { batchPossible: false, model: 'CurrencyPairConfig'   , functionMap: ormFunctionMap           , logMessage: false },
    [commonWebSocketEvents.CURRENCY_PAIR_SETTING]     : { batchPossible: false, model: 'CurrencyPairConfig'   , functionMap: currencyPairSettingsFunctionMap, logMessage: false },
    [commonWebSocketEvents.DATA_RIGHTS_COLLECTION]    : { batchPossible: false, model: 'DataRightsCollection' , functionMap: ormFunctionMapDeleted    , logMessage: false },
    [commonWebSocketEvents.SYSTEM_RIGHT]              : { batchPossible: false, model: 'UserSystemRight'      , functionMap: ormFunctionMapDeleted    , logMessage: false },
    [commonWebSocketEvents.BANK_HOLIDAYS_UPDATED]     : { batchPossible: false, model: undefined              , functionMap: bankHolidayFunctionMap   , logMessage: false },
    [commonWebSocketEvents.PROCESS_STATUS]            : { batchPossible: true , model: 'NFCProcessStatus'     , functionMap: ormFunctionMap           , logMessage: false },
};

export const commonFilterWebSocketEventMap = {
    'se.nordicfc.common.datatypes.model.NfcFixLog': (message, state, dispatch) => {
        // Filter ledger bankAccount events for ledger accounts that are not active.
        return !fixLogContainsMessagesSelector(state);
    },
}

import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {deleteOrmItemToApi} from "gui-common/orm/ormApi";
import {removeSavedUserState} from "gui-common/userSettings/sharedSettings/savedUserStatesReducer";
import {ormModelLoadingNew} from "gui-common/orm/ormLoadingSelectors";
import SaveUserStateButton from "gui-common/userSettings/sharedSettings/SaveUserStateButton"
import {sharedSettingsSelector, userSelector} from "gui-common/orm/ormSelectors";
import SavedUserStatesList from "gui-common/userSettings/sharedSettings/SavedUserStatesList";
import {deleteUserSetting} from "gui-common/userSettings/userState/userSettingsApi";
import {resetUserStateState} from "gui-common/userSettings/userState/userStateReducer";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {globalUserStatesConfig} from "gui-common/userSettings/userState/userStateConstants";


function AdminUserStates () {
    const currentState      = useSelector(state => state);
    const sharedUserStates  = useSelector(sharedSettingsSelector);
    const savedUserStates   = useSelector(state => state.savedUserStatesState.savedSettings);
    const sharedListLoading = useSelector(state => ormModelLoadingNew(state, {ormModel: 'SharedSetting'}));
    const user              = useSelector(userSelector);

    const dispatch          = useDispatch();

    function clearSettings() {
        for (let userStateId in globalUserStatesConfig) {
            if (userStateId === 'savedUserStatesState') continue;
            if (userStateId === 'userPreferencesState') continue;
            dispatch(deleteUserSetting(userStateId, currentState[userStateId]));
            dispatch(globalUserStatesConfig[userStateId].resetFunction());
        }
        dispatch(resetUserStateState());
    }

    const userCanReadSharedSettings = userCan('Read', 'SharedSetting', user);
    return (
        <div xp-test-id={"xpAdminUserStates"} >
            <h3 className="userPrefsCardHeader">
                <XpTranslated trKey={'savedUserStates.header'}/>
            </h3>
            {userCanReadSharedSettings &&
            <SavedUserStatesList
                headerKey       = 'savedUserStates.sharedSettings.header'
                userStatesList  = {sharedUserStates}
                deleteUserState = {(item) => dispatch(deleteOrmItemToApi('SharedSetting', item))}
                loading         = {sharedListLoading}
                shared          = {true}
                instanceId      = 'shared'
            />}
            <SavedUserStatesList
                headerKey       = 'savedUserStates.savedSettings.header'
                userStatesList  = {savedUserStates}
                deleteUserState = {(item) => dispatch(removeSavedUserState(item))}
                shared          = {false}
                instanceId      = 'local'
            />
            <SaveUserStateButton
                labelKey       = 'savedUserStates.saveSettingsButton'
                toolTipKey     = 'savedUserStates.saveSettingsButtonTooltip'
            />
            <WaitingXpoolButton
                labelKey        = 'savedUserStates.clearSettingsButton'
                modalKey        = 'savedUserStates.clearSettingsConfirmation'
                onClickCallback = {() => clearSettings()}
                toolTipKey      = 'savedUserStates.clearSettingsTooltip'
                floatRight
            />
        </div>
    )
}
AdminUserStates.propTypes = {
};
export default AdminUserStates

import {useDispatch, useSelector} from "react-redux";
import {NavDropdown} from "react-bootstrap";
import {confirmAllUserMessages} from "gui-common/userMessages/userMessageThunks";
import {openUserMessagesSelector} from "gui-common/userMessages/userMessagesSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpTooltip from "gui-common/components/XpTooltip";

function ConfirmAllUserMessagesMenuItem(props) {
    const openUserMessages = useSelector(openUserMessagesSelector);
    const dispatch  = useDispatch();
    const confirmAllUserMessagesDisabled = openUserMessages ? (openUserMessages.length) === 0 : true;

    return (
        <XpTooltip trKey={confirmAllUserMessagesDisabled ? 'topMenu.confirmAllUserMessages.tooltipDisabled' : 'topMenu.confirmAllUserMessages.tooltipActive'}>
            <NavDropdown.Item
                key = 'confirmAllUserMessages'
                onClick={confirmAllUserMessagesDisabled ? undefined : () => dispatch(confirmAllUserMessages())}
                disabled={confirmAllUserMessagesDisabled}
            >
                <XpTranslated trKey={'topMenu.confirmAllUserMessages.menuItem'}/>
            </NavDropdown.Item>
        </XpTooltip>
    )
}
export default ConfirmAllUserMessagesMenuItem

import cloneDeep from "lodash.clonedeep";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushModalWindow} from "redux-promising-modals";
import InstanceUserRights from "gui-common/userRights/InstanceUserRights";
import React from "react";
import {CONFIRMATION_DIALOG, GET_TEST_PARAMETERS_DIALOG, INFORMATION_DIALOG} from "gui-common/modals/modalConstants";

function newParentPath(id, idIndex, outArray) {
    for (let item of outArray) {
        if ((id === item[idIndex]) && (item.hierarchy)) return item.hierarchy.slice(0);
    }
    return [];
}

export function newStructureGridData(objectArray, parentId, parentIdIndex, idIndex, nameIndex) {
    for (let item of objectArray) {
        if(item[parentIdIndex] === parentId){
            if(parentId !== null) item.hierarchy = newParentPath(parentId, idIndex, objectArray);
            else                  item.hierarchy = [];
            item.hierarchy.push(item[nameIndex] + "-" + item.id);
            newStructureGridData(objectArray, item[idIndex], parentIdIndex, idIndex, nameIndex);
        }
    }
}

export function topStructureGridData(objectArray, parentId, parentIdIndex, idIndex, nameIndex) {
    let outArray = cloneDeep(objectArray);
    newStructureGridData(outArray, parentId, parentIdIndex, idIndex, nameIndex);
    return outArray.filter(item => (item.hierarchy !== undefined)); // remove items not found by parser.
}

function getFirstNode(entity, nameProp) {
    return [entity[nameProp] + "-" + entity.id];
}

function createHierarchyArray(entity, entityArray, parentIdProp, nameProp) {
    const entityParentId = entity[parentIdProp]
    if (entityParentId === null) return getFirstNode(entity, nameProp);

    const parentEntity = entityArray.find(item => item.id === entityParentId);
    if (!parentEntity) return getFirstNode(entity, nameProp); // The parent entity is not within the array, treat as a root entity

    // return getFirstNode(entity, nameProp).concat(createHierarchyArray(parentEntity, entityArray, parentIdProp, nameProp))
    return createHierarchyArray(parentEntity, entityArray, parentIdProp, nameProp).concat(getFirstNode(entity, nameProp));
}

export function enrichWithTreeData(entityArray, parentIdProp, nameProp) {
    for (let entity of entityArray) {
        entity.hierarchy = createHierarchyArray(entity, entityArray, parentIdProp, nameProp);
    }
}

export function formatDateField(field, currentLanguage, format) {
    if (field._isAMomentObject === true) {
        let momentObject = field;
        momentObject.locale(currentLanguage);
        return momentObject.format(format);
    }
    return "";
}
export function translateDateFields(item, format, lang) {
    let objKey;
    for (objKey in item) {
        if ((item[objKey] === null) || (item[objKey] === undefined)) continue;
        if (item[objKey].hasOwnProperty('_isAMomentObject')) item[objKey+"T"] = formatDateField(item[objKey], lang, format);
    }
}

export function formatDateFieldRelativeNow(field, currentLanguage) {
    let momentObject = field;
    momentObject.locale(currentLanguage);
    return momentObject.calendar();
}
export function isMomentObject(item) {
    return (item && (item._isAMomentObject === true));
}
export function translateDateFieldsRelativeNow(item, lang, fields) {
    if (!item) return "";
    let fieldsToIterate = item;
    if (fields && Array.isArray(fields)) {
        fieldsToIterate = {};
        for (let key of fields) fieldsToIterate[key] = "";
    }
    for (let objKey in fieldsToIterate) {
        if (!isMomentObject(item[objKey])) continue;
        item[objKey+"T"] = formatDateFieldRelativeNow(item[objKey], lang);
    }
}

export function createIsWaitingData (item, field, isWaiting) {
    if (isWaiting) item[field] = " " + item[field];
    item[field+"RenderData"] = {isWaiting: isWaiting};
}
export function createTranslatedFieldData (item, field, translate, trKey, trParams, isWaiting) {
    item[field+'T'] = (isWaiting ? " " : "") + (trKey ? translate(trKey, trParams) : item[field]);
    // item[field+'T'] = (isWaiting ? " " : "") + item[field];
    item[field+"TRenderData"] = {trKey: trKey, trParams: trParams, isWaiting: isWaiting};
}
/*export function createFormFieldData(item, field, savedValue) {
    item[field+"RenderData"] = {
        savedValue: savedValue,
        // eslint-disable-next-line
        isChanged : item[field] != savedValue,
    };
}*/
export function createNumberFieldData (item, field, formatter, isWaiting) {
    item[field+'T'] = (isWaiting ? " " : "") + formatter(item[field]);
    item[field+"TRenderData"] = {isWaiting: isWaiting};
}
export function translateBoolFields(item, translate, keyPath, trParams) {
    let objKey;
    const useKeyPath = (keyPath !== undefined) ? keyPath : "general.boolText";
    for (objKey in item) {
        if ((item[objKey] === null) || (item[objKey] === undefined)) continue;
        if (typeof item[objKey] === 'boolean') {
            const trKey = item[objKey] ? useKeyPath+'.true' : useKeyPath+'.false';
            createTranslatedFieldData(item, objKey, translate, trKey, trParams);
        }
    }
}

export const xpColCreate = (translate, subArray, hiddenState, colName, width, minWidth, trKey, extraProps, headerProps) => {
    const colId = (extraProps && extraProps.colId) ? extraProps.colId : colName;
    const hideColumn = (typeof hiddenState === 'object') ? hiddenState[colId] : hiddenState;
    if (hideColumn !== undefined) subArray.push({
        colId: colId,
        field: colName,
        hide: hideColumn,
        width: width,
        minWidth: minWidth,
        headerName: (trKey !== null) ? translate(trKey) : colName,
        headerComponentParams: {trKey: trKey, ...headerProps},
        ...extraProps
    })
};
export const xpColShortDateColCreate = (subArray, width, colName, format, extraProps) => {
    const colId = (extraProps && extraProps.colId) ? extraProps.colId : colName;
    subArray.push({
        colId: colId,
        field: colName,
        width: width,
        minWidth: width,
        headerName: colName,
        headerComponent: 'xpGridShortDateHeader',
        cellRenderer: 'xpGridNumberRenderer',
        sortable  : false,
        filter    : false,
        cellRendererParams: {xpDateTimeFormat: format ?  format : 'MMM-DD'},
        ...extraProps
    })
};
export const xpColHeadCreate = (translate, outArray, subArray, trKey, trParams, className, extraProps, headerProps) => {
    if (subArray.length <= 0) return;
    outArray.push({
        headerName: translate(trKey),
        headerGroupComponentParams: {trKey: trKey, trParams: trParams, xpClassName: className, ...headerProps},
        children: [...subArray],
        ...extraProps
    });
    subArray.length = 0;
};

export function createStandardAuditFields2 (hiddenState, translate) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'createdDateTime', 120, 50, 'general.createdDateTimeInGrid', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'lll'}});
    xpColCreate(translate, subArray, hiddenState, 'createdByUser',   120, 50, 'general.createdByUserInGrid', {cellRenderer: 'xpGridUserRenderer'});
    xpColHeadCreate(translate, outArray, subArray, 'general.createdDateTimeInGridGroup');

    xpColCreate(translate, subArray, hiddenState, 'changedDateTime', 120, 50, 'general.changedDateTimeInGrid', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'lll'}});
    xpColCreate(translate, subArray, hiddenState, 'changedByUser',   120, 50, 'general.changedByUserInGrid', {cellRenderer: 'xpGridUserRenderer'});
    xpColHeadCreate(translate, outArray, subArray, 'general.changedDateTimeInGridGroup');

    return outArray;
}
export function createFlatAuditFields(hiddenState, translate) {
    let outArray = [];

    xpColCreate(translate, outArray, hiddenState, 'createdDateTime', 120, 50, 'general.createdDateTimeInFlatGrid', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'lll'}});
    xpColCreate(translate, outArray, hiddenState, 'createdByUser',   120, 50, 'general.createdByUserInFlatGrid', {cellRenderer: 'xpGridUserRenderer'});

    xpColCreate(translate, outArray, hiddenState, 'changedDateTime', 120, 50, 'general.changedDateTimeInFlatGrid', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'lll'}});
    xpColCreate(translate, outArray, hiddenState, 'changedByUser',   120, 50, 'general.changedByUserInFlatGrid', {cellRenderer: 'xpGridUserRenderer'});
    return outArray;
}

export function createMenuItem(trKey, trParams, translate, dispatch, confirmFunction, modalType, modalProps, modalParams, disableMenuItem) {
    return {
        name: translate(trKey+".menuItem", trParams),
        action: function () {
            setTimeout(() =>
            this.context.dispatch(pushModalWindow(modalType ? modalType : CONFIRMATION_DIALOG, {modalKey: trKey, modalParams: modalParams, ...modalProps}))
                .then((result) => {
                    if ((result?.status === MODAL_TYPE_CONFIRM) && confirmFunction) {confirmFunction(result)}
                    if (result?.status === MODAL_TYPE_CANCEL) {}
                }), 50);
        },
        context: {dispatch: dispatch},
        disabled: disableMenuItem,
    }
}

function compare( a, b ) {
    if ( a.key < b.key ) return -1;
    if ( a.key > b.key ) return 1;
    return 0;
}
export function createSetOrmPropertyMenuItem(ormModel, item, dispatch) {
    return {
        name: 'DEV/TEST: View object data',
        action: function () {

            let parameters = [];
            for (let key in this.context.item) {
                if (['number', 'string', 'boolean'].indexOf(typeof this.context.item[key]) === -1) continue;
                parameters.push({key: key, value: this.context.item[key]});
            }
            parameters = parameters.sort(compare);

/*
            for (item of messageObjects) {
                message += item.key + ': ' + item.value + '\n';
            }
*/

            // Timeout required to get focus to land in dialogue. Propable competition between context menu item close from grid.
            setTimeout(() => {
                this.context.dispatch(pushModalWindow(GET_TEST_PARAMETERS_DIALOG,
                        {
                            headerText: 'View object data',
                            parameters: parameters,
                            ormItem: this.context.item,
                            label1: "property to set",
                            label2: "Numeric value",
                            label3: "String value",
                            ormModel: ormModel,
                        }
                    ))
                        .then((result) => {
                            if (!result) {
                                return;
                            }
                            if (result.status === MODAL_TYPE_CANCEL) {
                                return;
                            }
                            let itemData = {};
                            itemData.id = Number(this.context.item.id);
                            if (result.p2) itemData[result.p1] = Number(result.p2);
                            if (result.p3) itemData[result.p1] = result.p3;
                            dispatch({type: 'ORM_ENTITY_UPDATE', payload: {itemType: ormModel, item: itemData}})
                        })
                }, 50)
        },
        context: {item: item, dispatch: dispatch},
        disabled: false,
    }

}

export function createViewExecutionRightsModel(item, dispatch) {
    return {
        name: 'View execution rights',
        action: function () {

            this.context.dispatch(pushModalWindow(INFORMATION_DIALOG,
                {
                    headerText: 'Execution rights for ' + item.name,
                    messageText: item.executionRights ? <InstanceUserRights activeEntityDataRight={{...item.executionRights, entity: item}} entityDataRight={{...item.executionRights, entity: item}} dataRightsCollection={null} readOnly={true}/> : "No execution rights defined",
                    className: " xpoolModalViewExecutionRights"
                }
            ))
                .then((result) => {
                });
        },
        context: {item: item, dispatch: dispatch},
        disabled: (!item || !item.executionRights),
    }
}

export function calculateNumberOfHeaderRows(colDef, currentLevel = 1) {
    if (!colDef || !Array.isArray(colDef)) return currentLevel;
    let maxLevels = currentLevel;
    for (let item of colDef) {
        if (!item.children || !Array.isArray(item.children)) continue;
        const levels = calculateNumberOfHeaderRows(item.children, currentLevel + 1);
        if (levels > maxLevels) maxLevels = levels;
    }
    return maxLevels;
}

export const xpGridRowHeight = 28;
export const xpGridEmptyHeight = 50;
export const xpGridHeaderHeight = 32;

export function calculateGridHeaderHeight(colDef) {
    return calculateNumberOfHeaderRows(colDef) * xpGridHeaderHeight + 5; // add 5 for a bit margin
}

export function getFormInGridErrorFunctions(errors, data) {
    const errorFunctions = {};
    for (let key in errors) {
        errorFunctions[key] = data ? (val) => errors[key](val, cloneDeep(data)) : (val) => false;
    }
    return errorFunctions;
}


export function calculateNewGridHeight(colDef, rowData, maxRowsToDisplay) {
    let newGridHeight = calculateGridHeaderHeight(colDef);
    if (!rowData || (rowData.length === 0)) return newGridHeight + xpGridEmptyHeight;
    let rowsCounter = maxRowsToDisplay;
    for (let row of rowData) {
        if ((rowsCounter > 0) || row.detail) newGridHeight += (row.rowHeight ? row.rowHeight : xpGridRowHeight);
        if (!row.detail) rowsCounter--;
    }
    return newGridHeight
}

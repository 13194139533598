import React from 'react'
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import {useSelector} from "react-redux";
import 'moment/locale/fr';
import 'moment/locale/sv';
import PropTypes from "prop-types";
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import XpUserIcon from "gui-common/menuComponents/XpUserIcon";
import XpLanguageSelector from "gui-common/menuComponents/XpLanguageSelector";
import XpNumberFormatMenu from "gui-common/menuComponents/XpNumberFormatMenu";
import {XpHomeCurrencySelector} from "gui-common/menuComponents/XpHomeCurrencySelector";
import DevMenu from "gui-common/app/DevMenu";
import XpMenuTitle from "gui-common/menuComponents/XpMenuTitle";
import CreateUserMessageMenuItem from "gui-common/userMessages/CreateUserMessageMenuItem";
import {LogoutMenuItem} from "gui-common/api/LogoutMenuItem";
import ConfirmAllUserMessagesMenuItem from "gui-common/userMessages/ConfirmAllUserMessagesMenuItem";
import XpDropDownMenuLinkItem from "gui-common/menuComponents/XpDropDownMenuLinkItem";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";

export default function MainMenu(props) {
    const appReady = useSelector(selectAppReadyState);
    const oxygenAppContext = useOxygenAppContext();

    return (
        <div xp-test-id='xpMainMenuContainer'>
            <Navbar bg="dark" variant="dark" collapseOnSelect fixed='top' expand="md">
                <XpMenuTitle/>
                <Navbar.Toggle/>

                <Navbar.Collapse>
                    {appReady &&
                        <Nav className="mr-auto">
                            {oxygenAppContext.appConfig.mainMenu.leftMenuItems}
                        </Nav>
                    }
                    <Nav>
                        {/* Client selection dropdown *************************************************************************/}
                        {appReady && oxygenAppContext.appConfig.mainMenu.rightMenuItems}

                        {/* System and client settings ***************************************************************/}
                        {appReady && <oxygenAppContext.appConfig.mainMenu.SettingsMenu/>}

                        {/* Dev menu with menu items ot showed only in dev mode ****************************************************************/}
                        <DevMenu/>

                        {/* User related settings and test menu items  *********************************************************/}
                        {appReady &&
                        <NavDropdown
                            title={<XpUserIcon/>}
                            id="user-nav-dropdown"
                            alignRight
                        >
                            <XpDropDownMenuLinkItem targetPath={'/userSettings'} trKey={'topMenu.userSettings'} />
                            <XpDropDownMenuLinkItem targetPath={'/myUserRights'} trKey={'topMenu.myUserRights'} />
                            <NavDropdown.Divider/>
                            <XpDropDownMenuLinkItem targetPath={'/userMessages'} trKey={'topMenu.userMessages'} />
                            <ConfirmAllUserMessagesMenuItem/>
                            <CreateUserMessageMenuItem/>
                            <NavDropdown.Divider/>
                            <LogoutMenuItem/>
                        </NavDropdown>}
                        {/* Decimals and denominator menu *********************************************************************/}
                        <XpNumberFormatMenu/>
                        {/* Home currency selector ********************************************************************/}
                        <XpHomeCurrencySelector/>
                        {/* Language menu and refresh button ********************************************************************/}
                        <XpLanguageSelector/>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}
MainMenu.propTypes = {
};

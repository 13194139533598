import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {prepareFxRejectConfigGridColumns, formatFxRejectConfigGridData} from "./fxRejectConfigFunctions"
import {userSelector} from "gui-common/orm/ormSelectors";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useStateRef} from "gui-common/functions/hooks";
import {selectActiveLanguage, useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function addConfigurableField(field, config) {
    if (!config.fieldsConfig[field]) return false;
    return (config.fieldsConfig[field].use);
}

function FxRejectConfigList (props) {

    const translate       = useXpTranslateFunction();
    const currentLanguage = useSelector(selectActiveLanguage);
    const thisUser        = useSelector(userSelector);
    const fxRejectEnvConfig = useSelector(state => selectAppEnvProperty(state, 'fxRejectConfig'));
    const dispatch        = useDispatch();

    const [itemEditingRef        , setItemEditing         ] = useStateRef(undefined);
    const [cellValueBeforeEditRef, setCellValueBeforeEdit ] = useStateRef(undefined);
    const [rowIndexBeforeDragRef , setRowIndexBeforeDrag  ] = useStateRef(undefined);

    const hiddenStateToUse = useMemo(
        () => {
            if (props.hiddenState) return props.hiddenState;
            let tmp = {
                matchOrderIndex     : false,
                rejectCode          : false,
                matchText           : false,
                autoGeneratedT      : false,
                createdDateTime     : false,
                createdByUser       : true,
                changedDateTime     : false,
                changedByUser       : true,
            };
            if (addConfigurableField('retry'    , fxRejectEnvConfig)) tmp.retryExecution    = false;
            if (addConfigurableField('errorText', fxRejectEnvConfig)) {
                tmp.errorResponseText  = false;
                tmp.originalReasonText = true;
            }
            return tmp;
        },
        [props.hiddenState, fxRejectEnvConfig]
    );
    const gridData = useMemo(
        () => {
            if (!props.inputData) return [];
            return formatFxRejectConfigGridData(props.inputData, currentLanguage, translate);
        },
        [currentLanguage, translate, props.inputData]
    );
    const userCanUpdate = useMemo(() => { return userCan('Update', 'FxRejectConfiguration', thisUser);}, [thisUser]);


    const gridColumns = useMemo(
        () => {
            return prepareFxRejectConfigGridColumns(translate, hiddenStateToUse, userCanUpdate);
        },
        [translate]
    );

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((data  ) => {
            if (itemEditingRef.current && !data) dispatch(xpGridSetSelected(props.instanceId, itemEditingRef.current?.id));
        }, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                let menuItems = [];
                let menuItem = {};

                if (params.node && params.column.colDef.editable && userCanUpdate) {
                    menuItem = {
                        name: translate('fxRejectConfig.editButton'),
                        action: function () {
                            let api = this.context.api;
                            api.startEditingCell({
                                rowIndex: this.context.node.rowIndex,
                                colKey: this.context.column.colId,
                            });
                        },
                        context: params,
                        disabled: params.node.data.isDeleted,
                    };
                    menuItems.push(menuItem);
                }

                menuItem = {
                    name: translate('fxRejectConfig.removeButton'),
                    action: function () {
                        props.removeConfig(this.context.node.data)
                    },
                    context: params,
                    disabled: false,
                };
                if (params.node && userCanUpdate && !params.node.data.isDeleted) menuItems.push(menuItem);

                menuItem = {
                    name: translate('fxRejectConfig.restoreButton'),
                    action: function () {
                        props.restoreConfig(this.context.node.data)
                    },
                    context: params,
                    disabled: false,
                };
                if (params.node && userCanUpdate && params.node.data.isDeleted) menuItems.push(menuItem);

                menuItem = {
                    name: translate('fxRejectConfig.addButton'),
                    action: function () {
                        props.addConfig(this.context.node.data.matchOrderIndex)
                    },
                    context: params,
                    disabled: false,
                };
                if (params.node && userCanUpdate) menuItems.push(menuItem);

                return menuItems;
            }, [translate]),

        onRowDragEnter          : useCallback((params) => {
            if (rowIndexBeforeDragRef.current === undefined) setRowIndexBeforeDrag(params.overIndex);
        }, []),
        onRowDragLeave          : useCallback((params) => {
            props.moveNodeToNewIndex(params.node.data, rowIndexBeforeDragRef.current);
        }, [rowIndexBeforeDragRef.current]),
        onRowDragEnd            : useCallback((params) => {
            const nexIndex = (params.overIndex !== -1) ? params.overIndex : rowIndexBeforeDragRef.current;
            setRowIndexBeforeDrag(undefined);
            if (params.overIndex !== rowIndexBeforeDragRef.current) {
                props.moveNodeToNewIndex(params.node.data, nexIndex);
                // saveModifiedGridToReducer(params);
            }
        }, []),
        onCellEditingStarted    : useCallback((params) => {
            if (params.node.data.isDeleted) {
                params.api.stopEditing();
                return;
            }
            setCellValueBeforeEdit(params.value);
            setItemEditing(params.data);
            props.setEditingCallback(true);
        }, []),
        onCellEditingStopped    : useCallback((params) => {
            setCellValueBeforeEdit(undefined);
            setItemEditing(undefined);
            // eslint-disable-next-line
            // if (params.value != cellValueBeforeEditRef.current) saveModifiedGridToReducer(params);
            props.setEditingCallback(false);
            // eslint-disable-next-line
        }, []),
    }

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('fxRejectConfig.list.noFxRejectConfigsAvailable'),
                fillAvailableSpace      : false,
                groupDefaultExpanded    : 0,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'FxRejectConfiguration',
                rowClassRules           : {
                    'xp-grid-removed-row': params => {
                        return (params.data.isDeleted === true)
                    },
                    'xp-grid-added-row': params => {
                        return (params.data.isNew === true)
                    },
                },
                onGridReadyCallback          : props.onGridReadyCallback,
                suppressDragLeaveHidesColumns: true,
                suppressMoveWhenRowDragging  : true,
                stopEditingWhenGridLosesFocus: true,
                rowDragManaged               : true,
                suppressRowDrag              : !userCanUpdate,
                animateRows                  : true,
                addExtraHeight               : 45,
                suppressRowClickSelection    : true,
            };
        },
        [translate, props.rowDataSelectorProps]
    );


    return (
        <XpGrid
            gridId={props.instanceId}
            {...gridCallbacks}
            {...gridOptions}
            columnDefs={gridColumns}
            rowData={gridData}
            callingContext={this}
            setHeightCallback={props.setHeightCallback}
        />
    )
}
FxRejectConfigList.propTypes = {
};
export default FxRejectConfigList

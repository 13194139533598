import React from "react";
import {useSelector} from "react-redux";
import {selectIsDevMode, useAppEnvProperty} from "gui-common/app/appEnvSelectors";

function DevInfo (props) {

    const devMode = useSelector(selectIsDevMode);
    const baseUrl = useAppEnvProperty(props.urlProp ? props.urlProp : 'baseUrl')

    if (!devMode) return null;

    return (
        <div style={{position: 'fixed', left: '1px', bottom: '-2px', fontSize: '12px'}}>
            {baseUrl}
        </div>
    )
}

export default DevInfo




import React from 'react'
import DashboardIcon from "gui-common/dashboard/DashboardIcon";
import {userSelector} from "gui-common/orm/ormSelectors";
import {dashboardConstants} from "gui-common/dashboard/dashboardConfig";
import {useSelector} from "react-redux";


function DashboardPicker(props) {
    const user = useSelector(userSelector);
    const dashboardState = useSelector(state => state.dashboardState)

    function iconDiv(iconName, componentName, keyName) {
        if (dashboardConstants.widgets[componentName].authorizedFunction && !dashboardConstants.widgets[componentName].authorizedFunction(user)) return null;
        return (
             <DashboardIcon key={keyName} iconName={iconName} componentName={componentName} myKey={keyName}/>
        )
    }

    return (
        <div className="dashboardPicker">
                {dashboardState.pickerIcons.map((item) => iconDiv(item.icon, item.componentName, item.keyName))}
        </div>
    );
}
export default DashboardPicker;

import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {prepareDispatchOrdersColumns, useListRateSelectorFunction} from "./dispatchTradeFunctions";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {MANUAL_DISPATCH_CONFIRM_DIALOG} from "appConfig/appModals";
import {stateRequestedOnId} from "gui-common/requestEntityState/requestEntityStateFunctions";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {confirmDispatchTradeToApi} from "features/dispatchTrade/dispatchTradeApi";
import {userCanConfirmDispatchTrade} from "features/dispatchTrade/dispatchTradeFunctions";
import {ListRateOrdersList} from "features/listRateOrder/ListRateOrdersList";
import {getDispatchTradesListDataSelector} from "features/dispatchTrade/dispatchTradeSelectors";
import {useAgreementSelectorFunction} from "features/agreement/agreementFunctions";
import {useSelectorRef} from "gui-common/functions/hooks";
import {listRateProcessStatusSelector} from "features/listRate/listRateSelectors";
import {getActiveListRatesWithOwnerProcessDown} from "features/listRate/listRateFunctions";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {getListRateOrdersForDispatchTradeSelector} from "features/listRateOrder/listRateOrderSelectors";

const dispatchTradesHiddenState = {
    counterparty           : false,
    legalEntityUnitName    : true,
    legalEntityUnitId      : true,
    id                     : false,
    displaySideT           : false,
    rate                   : false,
    amount                 : false,
    numberOfOrders         : true,
    amountCurrencyT        : false,
    listRateId             : false,
    currencyPairStr        : false,
    tenor                  : false,
    settlementDate         : false,
    clientSpotRate         : true,
    refSpotRate            : true,
    ibSpotRate             : true,
    spotMargin             : true,
    riskPremium            : true,
    clientFwdPoints        : true,
    refFwdPoints           : true,
    ibFwdPoints            : true,
    fwdPointsMargin        : true,
    fwdPointsDiff          : true,
    refSpotRateRPpts       : true,
    statusT                : false,
    tradeReportStateT      : true,
    bookingReference       : true,
    bookingReferenceOrigin : true,
    bookedByUser           : true,
    bookingComment         : true,
    tradeTime              : false,
};

function disableManualConfirmMenuItem(itemData, requestState) {
    if (itemData.status !== 'Timeout') return true;
    return stateRequestedOnId(requestState, itemData.id);
}

DispatchTradeList.detailIcon = 'save_alt';
DispatchTradeList.detailIconToolTipKey = 'dispatchTradesList.detailIconToolTipKey';
export function DispatchTradeList ({instanceId, setHeightCallback, fillAvailableSpace, suppressRowClickSelection, gridId, rowDataSelectorProps, getRowDataSelector}) {

    const requestingStateOnModelSelector = useMemo(()=>{
        return getRequestingStateOnModelSelector();
    }, [])

    const dispatchTradeRequestState         = useSelector(state => requestingStateOnModelSelector(state, {model: 'DispatchTrade'}))
    const dispatchTradeRequestStateRef = useRef(dispatchTradeRequestState); // Needed to transport updated hook to callback scope.
    dispatchTradeRequestStateRef.current = dispatchTradeRequestState;

    const dispatch        = useDispatch();

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const listRateProcessStatusRef = useSelectorRef(listRateProcessStatusSelector);

    const agreementSelectorFunction = useAgreementSelectorFunction()
    const listRateSelectorFunction = useListRateSelectorFunction()

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((data)   => {}, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                let menuItems = [];

                const listRateAgreement = agreementSelectorFunction(params.node.data.agreementId);
                if (!listRateAgreement) {
                    console.warn("Could not find list rate agreement in DispatchTradeList", params.node.data);
                    return []
                }

                const listRate = listRateSelectorFunction(params.node.data.listRateId);
                if (!listRate) {
                    console.warn("Could not find list rate in DispatchTradeList", params.node.data);
                    return []
                }

                const listRatesWithProcessDown = getActiveListRatesWithOwnerProcessDown([listRate], listRateProcessStatusRef.current);

                const userCanConfirm = userCanConfirmDispatchTrade(listRateAgreement);
                if (userCanConfirm) menuItems.push({
                    name: translateRef.current("dispatchTradesList.gridContextMenuItems.confirm.menuItem"),
                    action: () => setTimeout(() => {
                        function launchModal(context) {
                            dispatch(pushModalWindow(MANUAL_DISPATCH_CONFIRM_DIALOG, {dispatchTrade: params.node.data}))
                                .then(({status, bookingReference, comment}) => {
                                    if (status === MODAL_TYPE_CONFIRM) {
                                        dispatch(confirmDispatchTradeToApi(params.node.data, bookingReference, comment));
                                    }
                                })
                        }
                        if (listRatesWithProcessDown?.length === 0) {
                            launchModal();
                        }
                        else {
                            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "listRates.confirmUnmanagedListRateModal"}))
                                .then((result) => {
                                    if (result?.status === MODAL_TYPE_CONFIRM) {
                                        launchModal();
                                    }
                                })
                        }
                    }, 50),
                    disabled: disableManualConfirmMenuItem(params.node.data, dispatchTradeRequestStateRef.current),
                });

                return menuItems;
            }, [translate]),
        rowClassRules: {
            'xp-grid-inactive-row': function(params) {
                return  (['Active', 'TimeToDispatch', 'SentToBook', 'Timeout'].indexOf(params.data.status) === -1);
            },
        },
    };

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : instanceId,
                overlayNoRowsTemplate   : translate('dispatchTradesList.noOrdersToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                // rowDataSelector         : selectDispatchTradesListData,
                getRowDataSelector      : getRowDataSelector ? getRowDataSelector : getDispatchTradesListDataSelector,
                rowDataSelectorProps    : rowDataSelectorProps,
                treeData                : false,
                masterDetail            : true,
                ormModel                : 'DispatchTrade',
                xpDetailRendererProps   : {
                    availableComponents: [
                        {
                            detailType: 'listRateOrders',
                            componentToRender: ListRateOrdersList,
                            propsToComponent: {
/*
                                rowDataFilterFunction: (item, parentItem) => {
                                    if (parentItem.id === item.dispatchTradeId) return true;
                                    return false;
                                },
*/
                                getRowDataSelector: getListRateOrdersForDispatchTradeSelector,
                                getRowDataSelectorProps: parentItemData => ({dispatchTradeId: parentItemData.id}),
                            },
                        }
                    ],
                    parentInstanceId: instanceId,
                },
            };
        },
        [translate, rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareDispatchOrdersColumns(translate, dispatchTradesHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={gridId ? gridId : "dispatchOrdersGrid-" + instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={setHeightCallback}
                suppressRowClickSelection={suppressRowClickSelection} // This must be a direct propagation of prop since the memoized grid options will no be updated by this prop.
            >
            </XpGrid>
        </div>
    );
}

import {orm} from "gui-common/orm/orm";
import {attr, fk, Model} from "redux-orm";

const commonNestedOrmEntities = {
    User: [
        {
            property : "systemRights",
            model    : "UserSystemRight"
        },
        {
            property : "dataRightsCollection",
            model    : "DataRightsCollection"
        },
    ],

    DataRightsCollection: [
        {
            property : "assigned",
            model    : "EntityDataRight"
        }
    ],
    EntityDataRight: [
        {
            property : "childDataRightsCollection",
            model    : "DataRightsCollection"
        }
    ],
    LedgerAccount: [
        {
            property : "balances",
            model    : "LedgerAccountBalance"
        }
    ],
    RootBankHoliday: [
        {
            property : "bankHolidays",
            model    : "BankHoliday"
        }

    ],
};
const commonOrmParentProperties = {
    OperatingUnit   : undefined,
    Client          : {property: 'operatingUnitId', ormModel: 'OperatingUnit'},
    LegalEntity     : {property: 'clientId'       , ormModel: 'Client'},
    LegalEntityUnit : {property: 'legalEntityId'  , ormModel: 'LegalEntity'},
};

const commonOrmCompareFunctions = {}

class Currency extends Model {}
Currency.modelName = 'Currency';
Currency.fields = {
    id:         attr(),
    currency:   attr(),
    enabled:    attr()
};

class Country extends Model {}
Country.modelName = 'Country';
Country.fields = {
    id:         attr(),
    country:    attr(),
    enabled:    attr()
};

class CurrencyPair extends Model {}
CurrencyPair.modelName = 'CurrencyPair';
CurrencyPair.fields = {
    id:                 attr(),
    baseCurrencyId:     fk('Currency', 'currencyPairsBaseCurrency'),
    quoteCurrencyId:    fk('Currency', 'currencyPairsQuoteCurrency'),
    key:                attr(), // Currency pair as a string
    rate:               attr(),
    enabled:            attr()
};

class CurrencyPairConfig extends Model {}
CurrencyPairConfig.modelName = 'CurrencyPairConfig';
CurrencyPairConfig.fields = {
    id:                 attr(),
    currencyPairId:     fk('CurrencyPair', 'currencyPairConfig'),
    configType:         attr(),
    configObject:       attr(),
    changedDateTime:    attr(),
    changedByUser:      attr(),
};

class FxPrice extends Model {}
FxPrice.modelName = 'FxPrice';
FxPrice.fields = {
    id:                 attr(),
    fromCurrencyId:     attr(),
    toCurrencyId:       attr(),
    currencyPairId:     attr(),
    indicator:          attr(),
    rate:               attr(),
    time:               attr(),
};
class FixMessage extends Model {}
FixMessage.modelName = 'FixMessage';
FixMessage.fields = {
    id:                 attr(),
};

class FxRejectConfiguration extends Model {}
FxRejectConfiguration.modelName = 'FxRejectConfiguration';
FxRejectConfiguration.fields = {
    id:                 attr(),
    rejectType:         attr(),
    matchOrderIndex:    attr(),
    rejectCode:         attr(),
    matchText:          attr(),
    autoGenerated:      attr(),
    retryExecution:     attr(),
    originalReasonText: attr(),
    errorResponseText:  attr(),
    changedDateTime:    attr(),
    changedByUser:      attr(),
};

class Provider extends Model {}
Provider.modelName = 'Provider';
Provider.fields = {
    id:            attr(),
    providerType:  attr(), // FX, SYSTEM
    name:          attr(),
    description:   attr(),
    countryId:     attr(),
    enabled:       attr()
};

class NFCProcessStatus extends Model {}
NFCProcessStatus.modelName = 'NFCProcessStatus';
NFCProcessStatus.fields = {
    id:                 attr(),
    active:             attr(),
    processPrototype:   attr(),
    processPrototypeId: attr(),
    processStatus:      attr(),
    enabled:            attr()
};

class ErpSystem extends Model {}
ErpSystem.modelName = 'ErpSystem';
ErpSystem.fields = {
    id:            attr(),
    name:          attr(),
    description:   attr(),
    erpPrototype:  attr(),
    erpSpecifics:  attr(),
};

class ErpIntegration extends Model {}
ErpIntegration.modelName = 'ErpIntegration';
ErpIntegration.fields = {
    id:            attr(),
    erpEnabled:    attr(),
    erpSystem:     attr(),
    status:        attr(),
    reason:        attr(),
    connectionSessionId: attr(),
};

class OperatingUnit extends Model {}
OperatingUnit.modelName = 'OperatingUnit';
OperatingUnit.fields = {
    id:            attr(),
    name:          attr(),
    email:         attr(),
    phoneNumber:   attr(),
    enabled:       attr()
};

class SystemEvent extends Model {}
SystemEvent.modelName = 'SystemEvent';
SystemEvent.fields = {
    id                    : attr(),
    systemEventType       : attr(), // PROVIDER, COMMON
    systemEventStatusType : attr(), // WARNING, ERROR, DOWN, RUNNING, STARTING
    componentInformation  : attr(),
    providerId            : fk('Provider', 'events'),
    time                  : attr(),
    description           : attr(),
};

class User extends Model {}
User.modelName = 'User';
User.fields = {
    id                  : attr(),
    firstName           : attr(),
    lastName            : attr(),
    socialSecurityNumber: attr(),
    countryCode         : attr(),
    topics              : attr(),
    state               : attr(),
    profiles            : attr(),
    profilesStr         : attr(),
};

class AuditEntry extends Model {}
AuditEntry.modelName = 'AuditEntry';
AuditEntry.fields = {
    id             : attr(),
    auditDateTime  : attr(),
    model          : attr(),
    rootModel      : attr(),
    prototype      : attr(),
    rootPrototype  : attr(),
    operationType  : attr(),
    apiVersion     : attr(),
    client         : attr(),
    user           : attr(),
    modifiedObject : attr(),
};
class AuditRootModel extends Model {}
AuditRootModel.modelName = 'AuditRootModel';
AuditRootModel.fields = {
    id             : attr(),
    model          : attr(),
    prototype      : attr(),
    apiVersion     : attr(),
    enabled        : attr(),
    default        : attr(),
    rootObjects    : attr(),
};
class AuditUserActionType extends Model {}
AuditUserActionType.modelName = 'AuditUserActionType';
AuditUserActionType.fields = {
    id             : attr(),
    model          : attr(),
    prototype      : attr(),
    apiVersion     : attr(),
    enabled        : attr(),
    default        : attr(),
};

class Topic extends Model {}
Topic.modelName = 'Topic';
Topic.fields = {
    topic           : attr(),
};

class NoRunDate extends Model {}
NoRunDate.modelName = 'NoRunDate';
NoRunDate.fields = {
    date           : attr(),
};

class SharedSetting extends Model {}
SharedSetting.modelName = 'SharedSetting';
SharedSetting.fields = {
    id              : attr(),
    name            : attr(),
    state           : attr(),
    createdByUser   : fk('User', 'createdBlobs'),
    changedDateTime : attr(),
};

class Tenor extends Model {}
Tenor.modelName = 'Tenor';
Tenor.fields = {
    id      : attr(),
    tenor   : attr(),
    enabled : attr(),
};

class DataRightsCollection extends Model {}
DataRightsCollection.modelName = 'DataRightsCollection';
DataRightsCollection.fields = {
    id                              : attr(),
    user                            : attr(),
    userId                          : fk('User', 'dataRightsCollections'),
    state                           : attr(),
    parentEntityDataRightId         : attr(),
    assignable                      : attr(), // assigned will be created through the fk in EntityDataRights.
    basedOnDataRightsCollectionId   : attr(),
    changedByUser                   : attr(),
    changedDateTime                 : attr(),
    disabledByUser                  : attr(),
    disabledDateTime                : attr(),
};
class EntityDataRight extends Model {}
EntityDataRight.modelName = 'EntityDataRight';
EntityDataRight.fields = {
    id                          : attr(),
    parentDataRightsCollectionId: fk('DataRightsCollection', 'assigned'),
    entityPrototypeName         : attr(),
    entity                      : attr(),
    instanceRights              : attr(),
    domainRights                : attr(),
    childDataRightsCollection   : attr(),
    changedByUser               : attr(),
    changedDateTime             : attr(),
    disabledByUser              : attr(),
    disabledDateTime            : attr(),
};
class UserSystemRight extends Model {}
UserSystemRight.modelName = 'UserSystemRight';
UserSystemRight.fields = {
    id                          : attr(),
    user                        : attr(),
    userId                      : fk('User', 'systemRights'),
    systemRights                : attr(),
    changedByUser               : attr(),
    changedDateTime             : attr(),
};

class BankHoliday extends Model {}
BankHoliday.modelName = 'BankHoliday';
BankHoliday.fields = {
    id                  : attr(),
    currency            : attr(),
    description         : attr(),
    comment             : attr(),
    timezone            : attr(),
    startDateTime       : attr(),
    endDateTime         : attr(),
    name                : attr(),
    date                : attr(),
    createdDateTime     : attr(),
    createdByUser       : attr(),
    changedDateTime     : attr(),
    changedByUser       : attr(),
    disabledDateTime    : attr(),
    disabledByUser      : attr(),
};

class RootBankHoliday extends Model {}
RootBankHoliday.modelName = 'RootBankHoliday';
RootBankHoliday.fields = {
    id                  : attr(),
    bankHolidays        : attr(),
};

class LedgerAccount extends Model {}
LedgerAccount.modelName = 'LedgerAccount';
LedgerAccount.fields = {
    id      : attr(),
    ledgerAccountId: attr(),
    type    : attr(),
    balance : attr(),
};

class LedgerAccountBalance extends Model {}
LedgerAccountBalance.modelName = 'LedgerAccountBalance';
LedgerAccountBalance.fields = {
    id:              attr(),
    balanceId:       attr(),
    ledgerAccountId: fk('LedgerAccount', 'balances'),
    balance:         attr(),
    balanceTime:     attr(),
    valueDate:       attr(),
    comment:         attr(),
    createdDateTime: attr(),
    createdByUser:   attr(),
    changedDateTime: attr(),
    changedByUser:   attr(),
    disabledDateTime:attr(),
    disabledByUser:  attr(),
};

function addIfUsed(target, isUsed, addArray) {
    if (!isUsed) {
        return;
    }
    target.push(...addArray);
}
function getCommonOrmModels(appConfig) {
    const returnArray = [Country, Currency, CurrencyPair, Tenor, Provider, NFCProcessStatus, OperatingUnit, SystemEvent, User, SharedSetting];

    addIfUsed(returnArray, appConfig.useFx                      , [FxPrice, FixMessage]);
    addIfUsed(returnArray, appConfig.erp                        , [ErpSystem, ErpIntegration]);
    addIfUsed(returnArray, appConfig.userRights                 , [EntityDataRight, DataRightsCollection, UserSystemRight]);
    addIfUsed(returnArray, appConfig.audit                      , [AuditEntry, AuditRootModel, AuditUserActionType]);
    addIfUsed(returnArray, appConfig.userMessages               , [Topic]);
    addIfUsed(returnArray, appConfig.useNoRunDates              , [NoRunDate]);
    addIfUsed(returnArray, appConfig.useBankHolidays            , [BankHoliday, RootBankHoliday]);
    addIfUsed(returnArray, appConfig.useLedger                  , [LedgerAccount, LedgerAccountBalance]);
    addIfUsed(returnArray, appConfig.useCurrencyPairSettings    , [CurrencyPairConfig]);
    addIfUsed(returnArray, appConfig.useFxRejectConfig          , [FxRejectConfiguration]);
    return returnArray;
}

export function registerOrmModels(appConfig) {
    orm.register(...getCommonOrmModels(appConfig), ...appConfig.orm.models)
}
export const globalOrmConfig = {
    nestedEntities: commonNestedOrmEntities,
    parentProperties: commonOrmParentProperties,
    compareFunctions: commonOrmCompareFunctions,
    onUpdateThunks: {},
};

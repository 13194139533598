import moment from "moment";
import {fetchUserSettings} from "gui-common/userSettings/userState/userSettingsApi";
import {getOrmItemFromApi, getOrmItemsFromApi} from "gui-common/orm/ormApi";
import {getUserMessagesFromApi} from "gui-common/userMessages/userMessagesApi";
import {getSystemTextsFromApi} from "gui-common/appLocale/xpTranslated/systemTextApi";
import {loadMatrixInSequence} from "gui-common/api/apiCommon";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {getUserDataRightsFromApi, getUserSystemRightFromApi} from "gui-common/userRights/adminUserRightsApi";
import {getFxRejectConfigFromApi} from "gui-common/fxRejectConfig/fxRejectConfigApi";
import {refixIncomingTransformersMap, refixOutgoingTransformersMap} from "appConfig/api/apiTransformers";
import {refixFilterWebSocketEventMap, refixProcessWebSocketEventMap} from "appConfig/api/webSocketConfig";
import {selectLoggedInUserId} from "gui-common/api/apiSelectors";

const baseUrl = "/refix-api/v1/";
const refixBaseUrls = {
    OperatingUnit           : baseUrl + "operating-units",
    Client                  : baseUrl + "clients",
    LegalEntity             : baseUrl + "legal-entities",
    LegalEntityUnit         : baseUrl + "legal-entity-units",
    Agreement               : baseUrl + "agreements",
    ListRate                : baseUrl + "list-rates",
    DispatchTrade           : baseUrl + "dispatch-trades",
    ListRatePeriod          : baseUrl + "list-rate-periods",
    DispatchPeriod          : baseUrl + "dispatch-periods",
    ListRateOrder           : baseUrl + "orphan-list-rate-orders",
    MarketOrder             : baseUrl + "orphan-market-orders",
    MarginGroup             : baseUrl + "price-margin-groups",
    PriceTolerances         : baseUrl + "price-tolerances",
    PriceFeed               : baseUrl + "price-feeds",

    // Not ORM object related
    sandbox                 : baseUrl + "sandbox",
};

export const refixApiConfig = {
    baseUrl: baseUrl,
    incomingTransformersMap: refixIncomingTransformersMap,
    outgoingTransformersMap: refixOutgoingTransformersMap,
    baseUrls: refixBaseUrls,
    filterEventMap: refixFilterWebSocketEventMap,
    processEventMap: refixProcessWebSocketEventMap,
    initialDataLoadThunk: refixLoadApplicationDataThunk,
    onDataLoadCompletedThunk: undefined,
}
const getDataLoadFunctionMatrix = (getState) =>  {

    if (selectAppEnvProperty(getState(), 'noBackendMode')) return [];

    let fromDateStr = moment().subtract(1, 'days').format('YYYY-MM-DD');

    return [
        [
            {function: getOrmItemsFromApi, parameters: ['AuditRootModel']},
            {function: getOrmItemsFromApi, parameters: ['Currency'    ]},
            {function: getOrmItemsFromApi, parameters: ['CurrencyPair']},
            {function: getOrmItemsFromApi, parameters: ['CurrencyPairConfig']},
            {function: getOrmItemsFromApi, parameters: ['Country'    ]},
            {function: getOrmItemsFromApi, parameters: ['Tenor'    ]},
            {function: getOrmItemsFromApi, parameters: ['Provider'    ]},
            {function: getOrmItemsFromApi, parameters: ['NFCProcessStatus']},
            {function: getOrmItemsFromApi, parameters: ['SystemEvent']},
            {function: getOrmItemsFromApi, parameters: ['User']},
            {function: getOrmItemsFromApi, parameters: ['OperatingUnit']},
            {function: getOrmItemsFromApi, parameters: ['Topic']},
            {function: getOrmItemsFromApi, parameters: ['ListRatePeriod']},
            {function: getOrmItemsFromApi, parameters: ['DispatchPeriod']},
            {function: getOrmItemsFromApi, parameters: ['PriceFeed']},
            {function: getOrmItemsFromApi, parameters: ['MarginGroup']},
            {function: getOrmItemsFromApi, parameters: ['PriceTolerances']},
        ], [
            {function: getOrmItemsFromApi, parameters: ['Client']}, // Including all nested data
            {function: getOrmItemsFromApi, parameters: ['FxPrice']},
            {function: getOrmItemFromApi , parameters: ['User', selectLoggedInUserId(getState())]},
            {function: getSystemTextsFromApi, parameters: []},
            {function: getUserMessagesFromApi, parameters: [fromDateStr]},
            {function: getOrmItemsFromApi, parameters: ['SharedSetting']},
            {function: getOrmItemsFromApi, parameters: ['ListRateOrder']},
            {function: getOrmItemsFromApi, parameters: ['MarketOrder']},
            {function: getFxRejectConfigFromApi, parameters: ['Quote']},
            {function: getFxRejectConfigFromApi, parameters: ['Order']},
            {function: getUserDataRightsFromApi, parameters: []},
            {function: getUserSystemRightFromApi, parameters: []},
        ]
    ];
};

function refixLoadApplicationDataThunk() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(fetchUserSettings())
                .then(result => {
                    loadMatrixInSequence(getDataLoadFunctionMatrix(getState), dispatch)
/*
                        .then(result => {
                            let data = require('./restReturnClientData');
                            // addMockedData('Tenor'           , data.tenors           , dispatch, getState);
                            if (selectAppEnvProperty(getState(), 'noBackendMode')) {
                                addMockedData('CurrencyPair', data.currencyPairs, dispatch);
                                addMockedData('OperatingUnit', data.operatingUnits, dispatch);
                                addMockedData('Tenor', data.tenors, dispatch);
                                addMockedData('ListRatePeriod', data.periods, dispatch);
                                addMockedData('DispatchPeriod', data.dispatchPeriods, dispatch);
                                addMockedData('Client', data.clients, dispatch);
                                addMockedData('FxPrice', data.fxPrices, dispatch);
                                addMockedData('ListRateOrder', data.listRateOrders, dispatch);
                                addMockedData('MarketOrder', data.marketOrders, dispatch);
                            }
                            // addMockedData('AuditRootModel'      , data.auditRootModels   , dispatch);
                            if (selectIsDevMode(getState())) {
                                // addMockedData('CurrencyPairConfig'  , data.currencyPairConfig, dispatch);
                                // addMockedData('AuditEntry'          , data.auditRecords      , dispatch);
                            }
                        })
*/
                        .then(result => {resolve(result)})
                        .catch(err => {reject(err)})
                })
        })
    }
}

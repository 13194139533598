import {useWebSockets} from "gui-common/api/webSocketHooks";
import {useDispatch, useSelector, useStore} from "react-redux";
import {useEffect, useMemo, useRef} from "react";
import {push} from "connected-react-router";
import {selectUserLoggedInState, selectWebSocketsStatus} from "gui-common/api/apiSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {
    apiSetAppReadyState,
    apiSetLoginMessage,
    apiSetUserLoginSuccess, apiSetWebSocketsState
} from "gui-common/api/apiReducers";
import {ormClearData} from "gui-common/orm/ormReducer";
import {entityStateRequestClearAll} from "gui-common/requestEntityState/requestEntityStateReducer";
import {clearModalWindows} from "redux-promising-modals";
import {addUserMessageThunk, issueUnreadUserMessagesAtStartup} from "gui-common/userMessages/userMessageThunks";
import {getFirstAwtBatchFromApi} from "gui-common/api/apiFunctions";
import {commonIncomingTransformersMap, commonOutgoingTransformersMap} from "gui-common/api/commonTransformers";
import {getCommonBaseUrls, globalApiHandle} from "gui-common/api/apiConstants";
import {useStateRef} from "gui-common/functions/hooks";
import {commonFilterWebSocketEventMap, commonProcessWebSocketEventMap} from "gui-common/api/webSocketConfig";

export function useApi(appConfig, initialLoginKeys) {
    const dispatch = useDispatch();
    const webSocketsHandle = useWebSockets(appConfig, {onAllOpen: onAllOpenCb})
    const webSocketsHandleRef = useRef(webSocketsHandle);
    webSocketsHandleRef.current = webSocketsHandle;
    const userLoggedIn = useSelector(selectUserLoggedInState);
    const webSocketsStatus = useSelector(selectWebSocketsStatus);
    const store = useStore();

    const [gotoPathAfterReloadRef, setGotoPathAfterReload] = useStateRef(useAppEnvProperty('appStartPath'));

    useMemo(
        () => {
            globalApiHandle.incomingTransformersMap = {...commonIncomingTransformersMap , ...appConfig.api.incomingTransformersMap};
            globalApiHandle.outgoingTransformersMap = {...commonOutgoingTransformersMap , ...appConfig.api.outgoingTransformersMap};
            globalApiHandle.filterEventMap          = {...commonFilterWebSocketEventMap , ...appConfig.api.filterEventMap};
            globalApiHandle.processEventMap         = {...commonProcessWebSocketEventMap, ...appConfig.api.processEventMap};
            globalApiHandle.baseUrls                = {...getCommonBaseUrls(appConfig)  , ...appConfig.api.baseUrls};
        },
        []
    );

    // This effect initiates the api login and data load at application start-up
    useEffect(
        () => {
            if ((process.env.NODE_ENV !== "production") && (module.hot) && userLoggedIn) {
                return;
            } // No need to reload data when hot reloading.
            dispatch(push("/loadingData"));
            dispatch(getFirstAwtBatchFromApi(initialLoginKeys))
                .then(result => {
                    webSocketsHandleRef.current.openWebSocketsFromClosed();
                })
                .catch(err => {
                    console.error("Could not get first batch of AWT tokens at sign-in: " + err);
                    dispatch(loadProcessFailed());
                });
        }, [],
    );

    useEffect(
        () => {
            if (webSocketsStatus !== webSocketsHandle.status) {
                dispatch(apiSetWebSocketsState(webSocketsHandle.status))
            }
        },
        [webSocketsHandle.status],
    );


    // When all web sockets are open, the data load from the API is started.
    function onAllOpenCb() {
/*
        if (appReady.current) { // If data load is initiated by user when app is ready, lock application and show loading screen
            dispatch(setAppReadyState(false));
            dispatch(push("/loadingData"));
        }
*/
        dispatch(push("/loadingData"));
        dispatch(apiSetLoginMessage("api.loadingMessage.startDataLoad"));

        console.log("**************************************************");
        console.log("********** Starting data load process ************");
        console.log("**************************************************");

        // Start clearing ORM data
        dispatch(ormClearData());
        // Clear waiting state reducer
        dispatch(entityStateRequestClearAll());
        // Clear modal reducer
        dispatch(clearModalWindows());

        if (typeof appConfig.api.initialDataLoadThunk !== 'function') {
            console.error("No loadApplicationDataThunk defined in loadDataFromApi");
            dispatch(loadProcessFailed());
            return;
        }
        dispatch(appConfig.api.initialDataLoadThunk())
            .then(result => {
                console.log("********** Data load completed ************");
                const userLoggedIn = selectUserLoggedInState(store.getState());

                dispatch(apiSetLoginMessage("api.loadingMessage.dataLoadComplete"));

                if (!userLoggedIn) {
                    console.log("Login process completed");
                    dispatch(issueUnreadUserMessagesAtStartup());
                }
                else {
                    dispatch(addUserMessageThunk("warning", "userMessages.info.webSocketRunning"));
                }

                dispatch(apiSetUserLoginSuccess(initialLoginKeys.referenceId));
                dispatch(push(gotoPathAfterReloadRef.current));

                if (typeof appConfig.api.onDataLoadCompletedThunk === 'function') {
                    dispatch(appConfig.api.onDataLoadCompletedThunk());
                }
                webSocketsHandleRef.current.startProcessingMessages();
            })
            .catch(err => {
                console.error("Data load process failed: " + err);
                dispatch(loadProcessFailed());
            });
    }

    function resetAndReloadApi(gotoPathAfterReload) {
        if (gotoPathAfterReload) {
            setGotoPathAfterReload(gotoPathAfterReload);
        }
        dispatch(push("/loadingData"));
        webSocketsHandleRef.current.resetAndOpenWebSockets();
    }

    function loadProcessFailed() {
        return (dispatch, getState) => {
            webSocketsHandleRef.current.closeWebSockets();
            dispatch(apiSetAppReadyState(false));
            dispatch(push('/failedDataLoad'));
        }
    }
    // console.log("Api hook return", webSocketsHandle.status);
    return {
        status: webSocketsHandle.status,
        reload: resetAndReloadApi,
    };
}


import {useCallback, useMemo, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import {globalApiHandle, webSocketStatus} from "gui-common/api/apiConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {addUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {push} from "connected-react-router";
import ReconnectingWebSocket from "reconnecting-websocket";
import {selectEventServiceState} from "gui-common/eventService/eventServiceSelectors";
import {ormEntitiesBatchAction} from "gui-common/orm/ormReducer";
import {apiPushAwt, apiSetAppReadyState, apiSetLoginMessage} from "gui-common/api/apiReducers";
import {apiGetAwtToken} from "gui-common/api/apiFunctions";
import {useStateRef} from "gui-common/functions/hooks";



function useWebSocketHostsFromEnv() {
    const hostsArray = useAppEnvProperty('webSocketHosts');
    const singleHost = useAppEnvProperty('webSocketHost');
    return useMemo(
        () => {
            if (hostsArray?.length) {
                return hostsArray;
            }
            return [{id: "SINGLE", url: singleHost}];
        },
        []
    );

}

let messageReceivedSequenceNumber = 0;
export function useWebSockets(appConfig, callbacks) {

    const dispatch = useDispatch();
    const store = useStore();
    const webSocketHostsEnvConfig = useWebSocketHostsFromEnv();

    const [webSocketHosts                , setWebSocketHosts            ] = useState({});
    const [aggregatedWebSocketsStatusRef , setAggregatedWebSocketsStatus] = useStateRef(webSocketStatus.DOWN);
    const [emptyQueuesIntervalHandleRef  , setEmptyQueuesIntervalHandle ] = useStateRef(undefined);
    const [reloadRequestedRef            , setReloadRequested           ] = useStateRef(false);
    const [allDownHandledRef             , setAllDownHandled            ] = useStateRef(false);

    const [webSocketQueues               , setWebSocketQueues           ] = useState({batchQ:  [], singleQ: []});

    const emptyQueuesIntervalFromEnv = useAppEnvProperty('socketTimerDelay');
    const startProcessingMessages = useCallback((intervalMs) => {
        emptySocketQ(webSocketQueues, store, dispatch);
        const interval = intervalMs ? intervalMs : emptyQueuesIntervalFromEnv ? emptyQueuesIntervalFromEnv : 500;
        console.log("Starting socket queue timer at " + interval + "ms interval");

        const handle = setInterval(() => {
            emptySocketQ(webSocketQueues, store, dispatch)
        }, interval);

        setEmptyQueuesIntervalHandle(handle);
        setAggregatedWebSocketsStatus(webSocketStatus.RUNNING);
    }, [])

    function emptySocketQueueAndClearInterval() {
        if (emptyQueuesIntervalHandleRef.current) {
            clearInterval(emptyQueuesIntervalHandleRef.current);
        }
        emptySocketQ(webSocketQueues, store, dispatch)
        setWebSocketQueues({batchQ:  [], singleQ: []});
    }

    const openWebSocketsFromClosed = useCallback(() => {
        setWebSocketHosts({});
        emptySocketQueueAndClearInterval();

        dispatch(apiSetLoginMessage("api.loadingMessage.startingWebSocket"));
        setAggregatedWebSocketsStatus(webSocketStatus.STARTING);

        webSocketHostsEnvConfig.forEach(hostConfig => {
            initSocket(hostConfig);
        })
    }, []);

    const resetAndOpenWebSockets = useCallback(() => {
        dispatch(apiSetAppReadyState(false));
        setAggregatedWebSocketsStatus(webSocketStatus.CLOSING);
        dispatch(apiSetLoginMessage("api.loadingMessage.closingWebSocket"));
        setReloadRequested(true);
        closeWebSockets();
    }, []);

    const closeWebSockets = useCallback(() => {
        console.log("Closing all web sockets");
        dispatch(apiSetAppReadyState(false));
        setAggregatedWebSocketsStatus(webSocketStatus.DOWN);
        for (const hostId in webSocketHosts) {
            webSocketHosts[hostId].handle.close();
        }
    }, []);

    function setSocketStatusAndAggregatedStatus(webSocketId, status) {
        if (!webSocketHosts[webSocketId]) {
            return undefined;
        }
        webSocketHosts[webSocketId].status = status;

        const newAggregatedStatus = getAggregatedStatus(webSocketHosts);
        if (newAggregatedStatus !== aggregatedWebSocketsStatusRef.current) {
            console.log("Setting aggregated status ", aggregatedWebSocketsStatusRef.current, newAggregatedStatus, webSocketId, status);
            setAggregatedWebSocketsStatus(newAggregatedStatus)
        }
        return newAggregatedStatus;
    }

    function wsOpenCallback (msg, webSocketId) {
        console.log("**************** Socket open: ", webSocketId);
        const aggregatedStatus = setSocketStatusAndAggregatedStatus(webSocketId, webSocketStatus.OPEN);
        if (typeof callbacks.onOpen === 'function') {
            callbacks.onOpen(webSocketId, msg);
        }
        if (aggregatedStatus === webSocketStatus.OPEN) {
            // All sockets are up, start data load!
            console.log("**************** All socket connections are open!");
            setAllDownHandled(false);
            if (typeof callbacks.onAllOpen === 'function') {
                callbacks.onAllOpen();
            }
        }
    }

    function handleUnexpectedDown(userMessageKey, messageParams) {
        setAggregatedWebSocketsStatus((webSocketStatus.DOWN));
        dispatch(push("/connectionDown"));
        // closeWebSockets();
        dispatch(addUserMessageThunk("error", userMessageKey, messageParams, true));
        // openWebSocketsFromClosed();
    }

    function wsClosedCallback (msg, webSocketId) {
        console.log("**************** Socket closed: " + webSocketId + ". Msg: ", msg);
        if (typeof callbacks.onClose === 'function') {
            callbacks.onClose(webSocketId, msg);
        }
        if (![webSocketStatus.DOWN, webSocketStatus.CLOSING].includes(aggregatedWebSocketsStatusRef.current)) {
            handleUnexpectedDown("userMessages.error.webSocketClosed");
            return;
        }
        setSocketStatusAndAggregatedStatus(webSocketId, webSocketStatus.DOWN);
        if (allStatusMatch(webSocketHosts, webSocketStatus.DOWN)) {
            if (allDownHandledRef.current) {
                return;
            }
            // All sockets are down, restart if requested!
            console.log("**************** All socket connections are down!");
            if (typeof callbacks.onAllClosed === 'function') {
                callbacks.onAllClosed();
            }
            if (reloadRequestedRef.current) {
                openWebSocketsFromClosed();
            }
        }
    }
    function wsErrorCallback (msg, webSocketId) {
        console.log("**************** Socket error: " + webSocketId + ". Msg: ", msg);
        if (typeof callbacks.onError === 'function') {
            callbacks.onError(webSocketId, msg);
        }
        if ([webSocketStatus.STARTING, webSocketStatus.OPEN, webSocketStatus.RUNNING].includes(aggregatedWebSocketsStatusRef.current)) {
            handleUnexpectedDown("userMessages.error.errorFromWebSocket", {errorMessage: msg.type});
        }
        setSocketStatusAndAggregatedStatus(webSocketId, webSocketStatus.DOWN);
    }
    function wsMessageCallback (msg, webSocketId) {
        // console.log("Socket message: ", msg);
        if (!msg.data) {
            console.log("Socket message received from " + webSocketId + " without data: ", msg);
            return;
        }
        let parsedObjectArray;
        try {
            parsedObjectArray = JSON.parse(msg.data);
        }
        catch (error) {
            console.error("Could not parse socket message from " + webSocketId + ": ", error, msg.data);
            return;
        }
        for (let parsedObject of parsedObjectArray) {
            parsedObject.webSocketId = webSocketId;
            if (!parsedObject.timestamp) {
                console.warn("No timestamp in socket message from " + webSocketId + " :", parsedObject);
            }
            else {
                if (parsedObject.timestamp < webSocketHosts[webSocketId].lastReceivedSocketTimestamp) {
                    console.warn("Received timestamp in socket message from " + webSocketId + " earlier than lastReceivedSocketTimestamp", parsedObject, webSocketHosts[webSocketId].lastReceivedSocketTimestamp);
                }
                webSocketHosts[webSocketId].lastReceivedSocketTimestamp = parsedObject.timestamp;
            }

            // If AWT message, handle before main processing code and return. This will not affect ORM or other parts of the state.
            if ((parsedObject.payloadType === 'se.nordicfc.common.authentication.tokens.AWTToken') && (parsedObject.functionType === 'se.nordicfc.common.message.functions.Create')) {
                dispatch(apiPushAwt([parsedObject.payload]));
                continue;
            }

            if (filterSocketMessage(parsedObject, globalApiHandle.filterEventMap, store, dispatch)) {
                continue;
            }

            // Event service hookup. See eventServiceReducer for how it works.
            const eventServiceState = selectEventServiceState(store.getState());
            if (eventServiceState[parsedObject.payloadType]) {
                for (const callbackRef of eventServiceState[parsedObject.payloadType]) {
                    if (callbackRef.current) {
                        callbackRef.current(parsedObject);
                    }
                }
            }


            // *********************************************************************************************************************************************************************
            // NOTE!!! Only message types with objects that are processed in the ORM may be batched. E.g. User Messages cannot be batched since they are not processed in the ORM.
            // *********************************************************************************************************************************************************************
            /*
                        const batchMessage = () => {
                            const prototypeConfig = socketPayloadPrototypeMap[parsedObject.prototypeName];
                            if (!prototypeConfig || !prototypeConfig.batch) return false;
                            if (prototypeConfig.batchType && !prototypeConfig.batchType[parsedObject.type]) return false;
                            return true;
                        };
            */
            const payloadConfig = globalApiHandle.processEventMap[parsedObject.payloadType];
            if (!payloadConfig) {
                console.warn("Socket message payloadType from " + webSocketId + " is not supported: ", parsedObject.payloadType, parsedObject);
                continue;
            }
            const functionConfig = payloadConfig.functionMap[parsedObject.functionType];
            if (!functionConfig) {
                console.warn("Socket message functionType from " + webSocketId + " is not supported: ", parsedObject.functionType, parsedObject.payloadType, parsedObject);
                continue;
            }
            const enrichObject = (obj) => ({
                ...obj,
                functionToDispatch: functionConfig.functionToDispatch,
                model:              payloadConfig.model,
                logMessage:         payloadConfig.logMessage,
                ormEventType:       functionConfig.ormEventType,
            })
            // Add specific batchable models and event types to batchQ
            if (payloadConfig.batchPossible && functionConfig.batchPossible) webSocketQueues.batchQ.push( enrichObject(parsedObject));
            else                                                             webSocketQueues.singleQ.push(enrichObject(parsedObject));
        }
    }

    function initSocket(hostEnvConfig) {
        const baseUrl = hostEnvConfig.url ? hostEnvConfig.url : ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host;
        const getWebSocketUrl = () => {
            const token = apiGetAwtToken(dispatch, store.getState());
            if (!token) {
                console.error("No web session token available when opening websocket! Logoff!");
                dispatch(push('/authenticationFailed'));
                return "";
            }
            return baseUrl + "/ws/register?referenceId=" + token.referenceId + "&awt=" + token.awt + '&sessionId=' + token.sessionId;
        }

        console.log("Opening web socket connection " + hostEnvConfig.id + " on url: ", baseUrl);

        const webSocketHandle = new ReconnectingWebSocket(getWebSocketUrl, [], {debug: false, automaticOpen: true, minReconnectionDelay: 500});

        webSocketHandle.addEventListener('open',    (msg)       => wsOpenCallback(msg,hostEnvConfig.id));
        webSocketHandle.addEventListener('message', (msg) => wsMessageCallback(msg, hostEnvConfig.id));
        webSocketHandle.addEventListener('close',   (msg)   => wsClosedCallback(msg, hostEnvConfig.id));
        webSocketHandle.addEventListener('error',   (msg)   => wsErrorCallback(msg, hostEnvConfig.id));

        webSocketHosts[hostEnvConfig.id] = {
            envConfig: hostEnvConfig,
            handle: webSocketHandle,
            status: webSocketStatus.STARTING,
            lastReceivedSocketTimestamp: 0,
        }
    }

    // console.log("Sockets hook return", aggregatedWebSocketsStatusRef.current);
    return {
        status: aggregatedWebSocketsStatusRef.current,
        startProcessingMessages: startProcessingMessages,
        resetAndOpenWebSockets: resetAndOpenWebSockets,
        openWebSocketsFromClosed: openWebSocketsFromClosed,
        closeWebSockets: closeWebSockets,
    };
}


function getAggregatedStatus(hosts) {
    if (anyStatusMatch(hosts, webSocketStatus.DOWN)) {
        return webSocketStatus.DOWN;
    }
    if (anyStatusMatch(hosts, webSocketStatus.STARTING)) {
        return webSocketStatus.STARTING;
    }
    if (allStatusMatch(hosts, webSocketStatus.OPEN)) {
        return webSocketStatus.OPEN;
    }
    return undefined;
}
function anyStatusMatch(map, status) {
    for (const key in map) {
        if (map[key].status === status) {
            return true;
        }
    }
    return false;
}
function allStatusMatch(map, status) {
    for (const key in map) {
        if (map[key].status !== status) {
            return false;
        }
    }
    return true;
}
function filterSocketMessage(parsedObject, filterEventMap, store, dispatch) {
    let excludeFunction = filterEventMap[parsedObject.payloadType];
    if (!excludeFunction) {
        return false;
    }
    return excludeFunction(parsedObject, store.getState(), dispatch)
}


/* -----------------------------------------------------------------------------------------------------------------
* Socket message processing functions.
* -----------------------------------------------------------------------------------------------------------------*/
function processSocketMessage(message, dispatch, dispatchMessage) {

    let transformedPayload;
    if (message.model) {
        const transformer   = globalApiHandle.incomingTransformersMap[message.model];
        if (!transformer)                {console.error("No transformer for socket message model : ", message)           ; return undefined;}
        if (!message.functionToDispatch) {console.error("No call function for socket message : ", message)               ; return undefined;}

        try {
            transformedPayload = transformer(message.payload); // no params provided means that removeExecutionRights is set to false in nestled transformers under main object. Set like this now to check BE fix. Remove permanently later.
        }
        catch (error)                   {console.error("Could not transform socket message: ", message, error)          ; return undefined;}
        if (!transformedPayload)        {console.error("Transformed payload is undefined in socket message: ", message) ; return undefined;}
    }
    else {
        transformedPayload = message.payload;
    }

    // All is fine, log message to process.
    if (message.logMessage) console.log("Parsed socket message ", message.payloadType, " : ", message);

    // Dispatch function if not batch process.
    if (dispatchMessage) dispatch(message.functionToDispatch(message.model, transformedPayload));

    return {model: message.model, itemData: transformedPayload, ormEventType: message.ormEventType};
}

/* -----------------------------------------------------------------------------------------------------------------
* Socket message queue handling.
* -----------------------------------------------------------------------------------------------------------------*/

const getLogString = (queue) => {
    let logStr = "";
    try {
        for (let msg of queue) logStr = logStr + "<" + msg.functionType.slice(msg.functionType.lastIndexOf('.')+1) + msg.payloadType.slice(msg.payloadType.lastIndexOf('.')) + "(" + msg.payload.id + ")> \n";
    }
    catch (error) {
        console.log("Error in getLogString: ", error);
        return "<could not resolve log string>";
    }
    return logStr;
};

function emptyQ1untilAfterQ2(q1, q2, logString) {
    if (q1.length === 0) return [[],[]];

    let itemsToPop = [];
    let remainingQ = [];
    const firstSequenceNumberInQ2 = q2?.length ? q2[0].receivedSequenceNumber : undefined;
    for (const [index, item] of q1.entries()) {
        if (firstSequenceNumberInQ2 && (firstSequenceNumberInQ2 < item.receivedSequenceNumber)) {
            remainingQ = q1.slice(index); // Wait with processing of this message and the rest of the q until the other q has been emptied.
            break;
        }
        itemsToPop.push(item);
    }
    if (itemsToPop.length) console.log(logString + getLogString(itemsToPop), [...itemsToPop]);
    return [itemsToPop, remainingQ];
}

function emptySocketQ(webSocketQ, store, dispatch) {

    const totalNumberOfMessages = webSocketQ.batchQ.length + webSocketQ.singleQ.length;
    let processedNumberOfMessages = 0;
    let singleQprefix = " ";
    let batchQprefix  = " ";

    while (webSocketQ.batchQ.length || webSocketQ.singleQ.length) {

        if (webSocketQ.singleQ.length) {
            const [singleQItemsToProcess, singleQRemainingItems] = emptyQ1untilAfterQ2(webSocketQ.singleQ, webSocketQ.batchQ, singleQprefix + "Empty socket singleQ:\n");
            for (let item of singleQItemsToProcess) {
                processSocketMessage(item, dispatch, true);
                processedNumberOfMessages++;
            }
            webSocketQ.singleQ = singleQRemainingItems;
        }

        if (webSocketQ.batchQ.length) {
            let batchItemsToDispatch = [];

            const [batchQItemsToProcess, batchQRemainingItems] = emptyQ1untilAfterQ2(webSocketQ.batchQ, webSocketQ.singleQ, batchQprefix + "Empty socket batchQ:\n");
            for (let item of batchQItemsToProcess) {
                const messageObject = processSocketMessage(item, dispatch, false);
                if (!messageObject) continue;
                if (!messageObject.model) {
                    console.warn("No model in batched socket item, skipping!", messageObject);
                    continue;
                }
                batchItemsToDispatch.push(messageObject);
                processedNumberOfMessages++;
            }
            if (batchItemsToDispatch.length > 0) dispatch(ormEntitiesBatchAction(batchItemsToDispatch));
            webSocketQ.batchQ = batchQRemainingItems;
        }
        singleQprefix = "+";
        batchQprefix  = "+";
    }
    if (processedNumberOfMessages !== totalNumberOfMessages) console.error("processedNumberOfMessages not equal to totalNumberOfMessages! Messages lost.", processedNumberOfMessages, totalNumberOfMessages);
}

import {clientsSelector} from "features/client/clientSelectors";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {
    getClientAdminFormConfig,
    getMarginGroupAdminFormConfig,
    getPriceToleranceAdminFormConfig
} from "appConfig/xpAdminFormConfig";
import {marginGroupsSelector} from "features/marginGroup/marginGroupSelectors";
import {priceTolerancesSelector} from "features/priceTolerances/priceToleranceSelectors";
import {getHistoricAuditConfig} from "gui-common/audit/auditFunctions";


const commonHistoricAuditData = {
    excludeField: {
        API_2_0: ['createdDateTime', 'createdByUser', 'changedDateTime', 'changedByUser', 'disabledDateTime', 'disabledByUser', 'executionRight', 'executionRights', 'userCanSetPoolingParameters'],
        API_3_2: ['createdDateTime', 'createdByUser', 'changedDateTime', 'changedByUser', 'disabledDateTime', 'disabledByUser', 'executionRight', 'executionRights', 'userCanSetPoolingParameters'],
    },
    skipParentLevels: {
        API_2_0: [],
        API_3_2: [],
    }
}
function localGetHistoricAuditConfig(data) {
    return getHistoricAuditConfig(data, commonHistoricAuditData);
}
export const refixAuditModelConfig = {
    Client: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : true,
            selector      : clientsSelector,
            selectorProps : {},
        },
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0'],
                excludeFields:    ['legalEntities'],
                skipParentLevels: [],
            },
            {
                apiVersions: ['API_3_2'],
                excludeFields:    ['legalEntities'],
                skipParentLevels: [],
            }
        ]),
    },
    LegalEntity: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0'],
                excludeFields:    ['legalEntityUnits', 'subsidiaries'],
                skipParentLevels: ['legalEntities'],
            },
            {
                apiVersions: ['API_3_2'],
                excludeFields:    ['legalEntityUnits', 'subsidiaries'],
                skipParentLevels: ['legalEntities'],
            }
        ]),
    },
    LegalEntityUnit: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0', 'API_3_2'],
                excludeFields:    ['agreements', 'todayLimitOverrideByUser'],
                skipParentLevels: ['legalEntities', 'legalEntityUnits'],
            }
        ]),
    },
    Agreement: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0', 'API_3_2'],
                excludeFields:    [],
                skipParentLevels: ['legalEntities', 'legalEntityUnits', 'agreements'],
            }
        ]),
    },
    MarginGroup: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'marginGroupAudit', getXpAdminFormConfig: getMarginGroupAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : true,
            selector      : marginGroupsSelector,
            selectorProps : undefined,
        },
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0', 'API_3_2'],
                excludeFields:    ['id'],
                skipParentLevels: [],
            }
        ]),
    },
    PriceTolerances: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'priceToleranceAudit', getXpAdminFormConfig: getPriceToleranceAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : true,
            selector      : priceTolerancesSelector,
            selectorProps : undefined,
        },
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0', 'API_3_2'],
                excludeFields:    [],
                skipParentLevels: [],
            }
        ]),
    },
}

export const refixAuditPrototypeMap = {
    'se.nordicfc.refix.common.model.audit.Client'                           : { model: 'Client' },
    'se.nordicfc.refix.common.model.audit.LegalEntity'                      : { model: 'LegalEntity' },
    'se.nordicfc.refix.common.model.audit.LegalEntityUnit'                  : { model: 'LegalEntityUnit' },
    'se.nordicfc.refix.common.model.audit.Agreement'                        : { model: 'Agreement' },
    'se.nordicfc.common.pricemargin.model.audit.PriceMarginGroup'           : { model: 'MarginGroup' },
    'se.nordicfc.refix.common.model.audit.PriceToleranceConfigurationAudit' : { model: 'PriceTolerances' },
};

export const refixAuditModelsFromRootModel = {
    "Client": [
        "Client",
        "LegalEntity",
        "LegalEntityUnit",
        "Agreement"
    ],
    "BankHoliday": [
        "BankHoliday",
    ],
    "MarginGroup": [
        "MarginGroup",
    ],
    "PriceTolerances": [
        "PriceTolerances",
    ],
}

import {ManualDispatchModal} from "features/dispatchTrade/ManualDispatchModal";
import {TradingLimitOverrideModal} from "features/legalEntityUnit/TradingLimitOverrideModal";
import {SandboxAppIdActivationModal} from "features/client/SandboxAppIdActivationModal";


export const MANUAL_DISPATCH_CONFIRM_DIALOG  = 'MANUAL_DISPATCH_CONFIRM_DIALOG';
export const TRADING_LIMIT_OVERRIDE_DIALOG   = 'TRADING_LIMIT_OVERRIDE_DIALOG';
export const SANDBOX_APP_ID_ACTIVATE_DIALOG  = 'SANDBOX_APP_ID_ACTIVATE_DIALOG';

export const refixModals = [
    [MANUAL_DISPATCH_CONFIRM_DIALOG, ManualDispatchModal],
    [TRADING_LIMIT_OVERRIDE_DIALOG , TradingLimitOverrideModal],
    [SANDBOX_APP_ID_ACTIVATE_DIALOG, SandboxAppIdActivationModal],
];

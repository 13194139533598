import React, { Component } from 'react'
import {connect} from "react-redux";
import {noRunDateSelector, userSelector} from "gui-common/orm/ormSelectors"
import {ormItemsLoading} from "gui-common/orm/ormLoadingSelectors"
import spinnerGif from "gui-common/resources/spinner.gif";
import {withRouter} from "react-router-dom";
import {deleteOrmItemToApi, postOrmItemToApi} from "gui-common/orm/ormApi"
import DatePicker from 'react-datepicker';
import XpTooltip from "gui-common/components/XpTooltip";
import removeIcon from "gui-common/resources/removeIcon.png";
import "gui-common/noRunDate/noRunDate.css"
import {userCan} from "gui-common/userRights/userRightsFunctions";
import moment from "moment";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {dateToYMD} from "gui-common/functions/functions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


class AdminNoRunDate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateToAdd: undefined,
        };
    }

    getExcludedDates() {
        let excludedDates = [];
        this.props.noRunDates.forEach(date => {
            excludedDates.push(new Date(date.date))
        });
        return excludedDates;
    }

    deleteDate(date) {
        if (!date || !date.id) return;
        this.props.pushModalWindow(CONFIRMATION_DIALOG,
            {
                modalKey    : 'noRunDate.deleteDateConfirmationModal',
                modalParams : {date: date.date},
            })
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    this.props.deleteOrmItemToApi("NoRunDate", date);
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    addDate(date) {
        if (!date) return;
        const dateStr = dateToYMD(date);
        this.props.postOrmItemToApi("NoRunDate", {date: dateStr});
        this.setState({dateToAdd: undefined})
    }

    render() {
        if (!this.props.noRunDates)   return null;

        const waiting = (this.props.loadingState && (this.props.loadingState.loadingAll || this.props.loadingState.loadingCreate));

        return (
            <div xp-test-id='xpAdminNoRunDate'>
                <h2><XpTranslated trKey={'noRunDate.header'}/></h2>
                <hr/>
                <div className={"formCard"} xp-test-id={"noRunDateAdmin"} style={{maxWidth: '1000px'}}>
                    <div className={"adminFormContainer"} >

                        {userCan('Create', 'NoRunDate', this.props.user) &&
                        <div className={"admin2Column"}>
                            <div className="noRunDateSelect">
                                <label><XpTranslated trKey={'noRunDate.addDateLabel'}/></label>
                                <DatePicker
                                    selected={this.state.dateToAdd}
                                    onChange={(date) => {this.setState({dateToAdd: date})}}
                                    dateFormat="yyyy-MM-dd"
                                    minDate={new Date()}
                                    excludeDates={this.getExcludedDates()}
                                    placeholderText={this.props.translate('noRunDate.addDateTemplate')}
                                />
                            </div>
                            <WaitingXpoolButton
                                labelKey        = "noRunDate.addButton"
                                onClickCallback = {() => this.addDate(this.state.dateToAdd)}
                                disabled        = {((this.state.dateToAdd === undefined) && !this.props.loadingState.loadingCreate)}
                                waiting         = {waiting}
                                xp-test-id="noRunDateAdminAddButton"
                            />
                        </div>}

                        <div className={"admin2Column"}>
                            <label className="noRunDateTableLabel"><XpTranslated trKey={'noRunDate.dateListHeader'}/></label>
                            {waiting
                                ?
                                <div xp-test-id="userTopicAdminSpinner">
                                    <div>
                                        <p className="valueText"><XpTranslated trKey={'noRunDate.loading'}/></p>
                                        <img className="spinner" src={spinnerGif} alt="waiting" />
                                    </div>
                                </div>
                                :
                                <div>
                                    <table style={{marginBottom: '5px'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{width: '20px', border: 'none'}}                         > </td>
                                            <td style={{width: '100px', border: 'none'}}                         > </td>
                                        </tr>
                                        {this.props.noRunDates.map((date, index) => {
                                            const itemInThePast = moment(date.date).isBefore(moment(), 'day');
                                            return (
                                            <tr key = {index}>
                                                <td style={{border: 'none', paddingBottom: '2px'}}>
                                                    {userCan('Delete', 'NoRunDate', this.props.user) &&
                                                    <div className="tableField">
                                                        {(this.props.loadingState && this.props.loadingState[date.id] && this.props.loadingState[date.id].loadingUpdate) ?
                                                            <img className="smallTableSpinner" src={spinnerGif} alt="waiting"/> :
                                                            itemInThePast ?
                                                                    <XpTooltip trKey='noRunDate.removeDateTooltipInThePast' trParams={{date: date.date}}>
                                                                        <img style={{height: '18px', marginTop: "2px"}} src={removeIcon} alt="Remove" className="closeDashboardComponentButton closeDashboardComponentButtonInactive"/>
                                                                    </XpTooltip>
                                                                    :
                                                                    <XpTooltip trKey='noRunDate.removeDateTooltip' trParams={{date: date.date}}>
                                                                        <img style={{height: '18px', marginTop: "2px" }} src={removeIcon} alt="Remove" className="closeDashboardComponentButton" onClick={() => this.deleteDate(date)}/>
                                                                    </XpTooltip>
                                                        }
                                                    </div>}
                                                </td>
                                                <td style={{paddingLeft: '5px', border: 'none'}}><div>{date.date}</div></td>
                                            </tr>
                                        )})}
                                        </tbody>
                                    </table>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        translate       : selectTranslateFunction(state),
        noRunDates      : noRunDateSelector(state),
        loadingState    : ormItemsLoading(state, {ormModel: 'NoRunDate'}),
        user            : userSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        postOrmItemToApi:    (ormModel, item) => dispatch(postOrmItemToApi(ormModel, item)),
        deleteOrmItemToApi:  (ormModel, item) => dispatch(deleteOrmItemToApi(ormModel, item)),
        pushModalWindow:      (type, params)        => dispatch(pushModalWindow(type, params)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminNoRunDate));



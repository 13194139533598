import {setEnvParameter} from "gui-common/app/appEnvReducer";
import {
    timeEarlierOrSameThanParameter,
    timeLaterThanParameter,
} from "gui-common/xpForm/formComponentsValidators";

export function getRefixEnvParams () {
    let retParams = {};

    setEnvParameter(retParams,'APP_START_PATH'               , 'appStartPath'              ,  '/dashboard');
    setEnvParameter(retParams,'WEB_SOCKET_HOSTS'             , 'webSocketHosts'            ,  []);
    setEnvParameter(retParams,'WEB_SOCKET_HOST'              , 'webSocketHost'             ,  "");
    setEnvParameter(retParams,'CURRENT_API_VERSION'          , 'currentApiVersion'         ,  "API_3_2");
    setEnvParameter(retParams,'TENORS_SORT_ORDER'   , 'tenorsSortOrder'  ,
        ["TD", "TM", "ON", "SP", "SN", "1W", "2W", "3W", "1M", "2M", "3M", "4M", "5M", "6M", "7M", "8M", "9M", "1Y"]
    );
    setEnvParameter(retParams,'AGREEMENT_FORM_CONFIG'   , 'agreementFormConfig'  , {
        fieldsConfig: {
            type: {typesList: [{id: 'ListRateAgreement'}, {id: 'MarketOrderAgreement'}, {id: 'ReferenceRateAgreement'}, {id: 'PriceFeedAgreement'}]},
            gracePeriod: {maxSeconds: 60},
            todayCutOffTime: {default: '17:00'}
        },
    });
    setEnvParameter(retParams,'CLIENT_FORM_CONFIG', 'clientFormConfig', {
        fieldsConfig: {
            timeZone : {hide: true},
        }
    });
    setEnvParameter(retParams,'LEGAL_ENTITY_FORM_CONFIG', 'legalEntityFormConfig', {
        fieldsConfig: {
            legalPersonType : {hide: true},
            lei             : {hide: true}
        }
    });
    setEnvParameter(retParams,'LEGAL_ENTITY_UNIT_FORM_CONFIG', 'legalEntityUnitFormConfig', {
        fieldsConfig: {
            restTimeZone : {hide: false},
            appId        : {hide: false},
        }
    });
    setEnvParameter(retParams,'FX_REJECT_CONFIG', 'fxRejectConfig', {
        fieldsConfig: {
            retry     : {use: false},
            errorText : {use: true},
        }
    });
    setEnvParameter(retParams,'MANDATORY_LISTRATE_ACTION_COMMENT', 'mandatoryListRateActionComment', {
        all: {
            suspend : true,
            resume  : true,
            expire  : true,
            dispatch: true,
        },
        currency: {
            suspend : true,
            resume  : true,
            expire  : true,
            dispatch: true,
        },
        businessUnit: {
            suspend : true,
            resume  : true,
            expire  : true,
            dispatch: true,
        },
        agreement: {
            suspend : true,
            resume  : true,
            expire  : true,
            dispatch: true,
        },
        dispatchTrade: {
            dispatch: true,
        },
        listRate: {
            suspend : true,
            expire  : true,
            dispatch: true,
        },
    });
    setEnvParameter(retParams,'MANUAL_DISPATCH_TRADE_FORM', 'confirmDispatchTradeForm', {
        commentIsRequired: true,
    });
    setEnvParameter(retParams,'TRADING_LIMIT_OVERRIDE_FORM', 'tradingLimitOverrideForm', {
        commentIsRequired: true,
    });
    setEnvParameter(retParams,'REPORT_SET_CONFIG', 'reportSetConfig', {
        ListRateOrders     : {hide: false, useMockData: true, reportPrefix: 'reFix: '},
        MarketOrders       : {hide: false, useMockData: true, reportPrefix: 'reFix: '},
        SentDispatchTrades : {hide: false, useMockData: true, reportPrefix: 'reFix: '},
        IssuedListRates    : {hide: false, useMockData: true, reportPrefix: 'reFix: '},
    });
    setEnvParameter(retParams,'USE_HOME_CURRENCY'   , 'useHomeCurrency'     , true);
    setEnvParameter(retParams,'IS_SANDBOX'   , 'isSandbox'     , false);

    setEnvParameter(retParams,'CLIENT_WIZARD_FORM', 'clientWizardForm', {
        fileReaderConfig: {allowMultipleClientEntries: false }
    });

    setEnvParameter(retParams,'CURRENCY_PAIRS_CONFIG'   , 'currencyPairsConfig'     , {});

    setEnvParameter(retParams,'XP_GRID_CONFIG'      , 'xpGridConfig'        , {
        defaultMaxRows: 10,
        maxRowsOnOrmModel: {
            'Client'            : 10,
            'LegalEntity'       : 5,
            'LegalEntityUnit'   : 5,
            'Agreement'         : 5,
            'ListRate'          : 10,
            'DispatchTrade'     : 20,
            'ListRateOrder'     : 20,
            'MarketOrder'       : 20,
        }
    });
    setEnvParameter(retParams,'AUDIT_CONFIG'                  , 'auditConfig'                  , {activateObjectAuditLog: true, restrictFullAuditSearch: false});
    setEnvParameter(retParams,'ONBOARDING_WIZARD_FILE_CONFIG' , 'onboardingWizardFileConfig'   , {
        fileSpecification: {
            fileExtension: ".csv",
            separator: ';',
            commentLinePrefix: '#',
            encoding: 'ISO-8859-1',
            levelDefinitions: [
                {
                    dataProp: 'clients',
                    columnDefinitions: [
                        {heading: 'Client Name'          , field: 'name'                  , type: 'string' , mandatory: true , minLength: 2, maxLength: 50},
                        {heading: 'Client Description'   , field: 'description'           , type: 'string' , mandatory: false, maxLength: 100},
                        {heading: 'Country Code'         , field: 'countryId'             , type: 'string' , mandatory: true , lookUpToId: 'countries'},
                        {heading: 'Client ID'            , field: 'clientRefId'           , type: 'string' , mandatory: true , exactLength: 12},
                        {heading: 'Operating Unit'       , field: 'operatingUnitId'       , type: 'string' , mandatory: true , lookUpToId: 'operatingUnits'},
                        {heading: 'Fx Short Name'        , field: 'fxShortName'           , type: 'string' , mandatory: true , minLength: 2, maxLength: 20},
                        {heading: 'FX Provider'          , field: 'fxProviderId'          , type: 'string' , mandatory: true , lookUpToId: 'fxProviders'},
                        {heading: 'REST output time zone', field: 'restTimeZone'          , type: 'string' , mandatory: true , lookUpToId: 'timeZones'},
                        {heading: 'Open banking app-id'  , field: 'appId'                 , type: 'string' , mandatory: true , exactLength: 34},
                        {heading: 'Limit Currency'       , field: 'tradingLimitCurrencyId', type: 'string' , mandatory: true , lookUpToId: 'currencies'},
                        {heading: 'Daily trading limit'  , field: 'tradingLimit'          , type: 'number' , mandatory: true},
                        {heading: 'Net trading limit'    , field: 'netTradingLimit'       , type: 'boolean', mandatory: true},
                        {heading: 'API channel'          , field: 'clientApiChannel'      , type: 'string' , mandatory: true, lookUpToId: 'clientApiChannels'},
                        {heading: 'Exempt from global suspend', field: 'exemptFromGlobalSuspend', type: 'boolean', mandatory: true},
                        {heading: 'Net Dispatch Trades'  , field: 'netDispatchTrades'     , type: 'boolean', mandatory: true},
                        {heading: 'Send To Accounting'   , field: 'sendToAccounting'      , type: 'boolean', mandatory: true},
                    ],
                },
                {
                    dataProp: 'agreements',
                    columnDefinitions: [
                        {heading: 'Agreement type'  , field: 'type'                 , type: 'string'  , mandatory: true, lookUpToId: 'agreementTypes'},
                        {heading: 'Currency Pair'   , field: 'currencyPairId'       , type: 'string'  , mandatory: true, lookUpToId: 'currencyPairs',
                            errorFunctions: {
                                agreementTypeNotAvailable: (agreement, validationParams) => {
                                    const thisCurrencyPair = validationParams.ormCurrencyPairs.find(item => item.id === agreement.currencyPairId);
                                    if (thisCurrencyPair && thisCurrencyPair.validAgreementTypes.includes(agreement.type)) return false;
                                    return {
                                        currencyPairName: thisCurrencyPair?.currencyPairName,
                                        agreementType: agreement.type,
                                    };
                                }
                            }
                        },
                        {heading: 'Tenors'          , field: 'tenorsSource'         , type: 'string'  , mandatory: false},
                        {heading: 'Tenor'           , field: 'tenor'                , type: 'string'  , mandatory: false, lookUpToId: 'listRateTenors'},
                        {heading: 'Period'          , field: 'listRatePeriodId'     , type: 'string'  , mandatory: false, lookUpToId: 'listRatePeriods'},
                        {heading: 'Dispatch Level'  , field: 'dispatchLevel'        , type: 'number'  , mandatory: false},
                        {heading: 'Dispatch Period' , field: 'dispatchTradePeriodId', type: 'string'  , mandatory: false, lookUpToId: 'dispatchPeriods'},
                        {heading: 'Price Type'      , field: 'buySellType'          , type: 'string'  , mandatory: false, lookUpToId: 'buySellTypes'},
                        {heading: 'Grace Period'    , field: 'gracePeriod'          , type: 'number'  , mandatory: false,
                            errorFunctions: {
                                gracePeriodAboveMax: (agreement, validationParams) => {
                                    if (!validationParams?.gracePeriodMaxSeconds) return false;
                                    if (agreement.type !== ' ListRateAgreement') return false;
                                    if (agreement.gracePeriod <= validationParams.gracePeriodMaxSeconds) return false;
                                    return {
                                        gracePeriod: agreement.gracePeriod,
                                        gracePeriodMaxSeconds: validationParams.gracePeriodMaxSeconds,
                                    };
                                }
                            }
                        },
                        {heading: 'Opening Time'    , field: 'openTime' , type: 'time', mandatory: false,
                            errorFunctions: {
                                openLaterThanClose    : (agreement, validationParams) => {
                                    if ((agreement.type === ' ListRateAgreement') && (agreement.listRatePeriodId === validationParams.weekendPeriodId)) return false;
                                    if (!timeLaterThanParameter(  agreement.openTime, agreement.closeTime)) return false;
                                    return {
                                        openTime: agreement.openTime,
                                        closeTime: agreement.closeTime,
                                    }
                                },
                                openEarlierThanClose    : (agreement, validationParams) => {
                                    if (agreement.type !== ' ListRateAgreement') return false;
                                    if (agreement.listRatePeriodId !== validationParams.weekendPeriodId) return false;
                                    if (!timeEarlierOrSameThanParameter(  agreement.openTime, agreement.closeTime)) return false;
                                    return {
                                        openTime: agreement.openTime,
                                        closeTime: agreement.closeTime,
                                    }
                                },
                                oneTimeFieldMissing    : (agreement, validationParams) => {
                                    if (agreement.openTime  && agreement.closeTime) return false;
                                    if (!agreement.openTime && !agreement.closeTime) return false;
                                    return {
                                        openTime: agreement.openTime,
                                        closeTime: agreement.closeTime,
                                    }
                                },
                            }
                        },
                        {heading: 'Closing Time'    , field: 'closeTime', type: 'time', mandatory: false},
                        {heading: 'ASAP cutoff Time', field: 'todayCutOffTime', type: 'time', mandatory: false},
                    ],
                    enrichItemFn: agreement => {
                        if (agreement.tenorsSource) {
                            agreement.tenors = {};
                            agreement.tenorsSource.split(',').forEach(tenor => agreement.tenors[tenor] = true);
                        }
                        agreement.currencyPairName = agreement.currencyPairId_source;
                    },
                }
            ],
            dataTree: [{
                dataProp: 'clients',
                property: 'clients',
                errorFunctions: {
/*
                    noBaseCurrencyAccount: client => {
                        const accountCurrencies = client.currencies.map(item => item.currencyId);
                        if (!accountCurrencies.includes(client.topCurrencyId)) {
                            return {topCurrencyId: client.topCurrencyId_source}
                        }
                        return false;
                    }
*/
                },
                enrichItemFn: client => {
                    if (!client.agreements?.length) return;
                    client.currencyPairs = [];
                    client.listRateAgreements = [];
                    client.marketOrderAgreements = [];
                    for (const agreement of client.agreements) {
                        let thisCurrencyPair = client.currencyPairs.find(item => item.currencyPairId === agreement.currencyPairId);
                        if (!thisCurrencyPair) {
                            thisCurrencyPair = {
                                currencyPairId: agreement.currencyPairId,
                                currencyPairName: agreement.currencyPairId_source,
                                useListRateAgreements: false,
                                useMarketOrderAgreements: false,
                            }
                            client.currencyPairs.push(thisCurrencyPair);
                        }
                        if (agreement.type === 'ListRateAgreement') {
                            thisCurrencyPair.useListRateAgreements = true;
                            client.useListRateAgreements = true;
                            client.listRateAgreements.push(agreement);
                        }
                        if (agreement.type === 'MarketOrderAgreement') {
                            thisCurrencyPair.useMarketOrderAgreements = true;
                            client.useMarketOrderAgreements = true;
                            client.marketOrderAgreements.push(agreement);
                        }
                    }
                },
                children: [
                    {
                        dataProp: 'agreements',
                        property: 'agreements',
                        children: null,
                    }]
            }],
        },
    });
    setEnvParameter(retParams,'RUN_CONFIGURATION_CONFIG'     , 'runConfigurationConfig'    , {
        marginGroupOverride: {
        },
    });
    setEnvParameter(retParams,'AVAILABLE_CLIENT_API_CHANNELS'     , 'availableClientApiChannels'    , [{id: "External", default: true}, {id: "Internal"}]);

    return retParams;
}

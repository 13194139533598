import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";

export function prepareCurrencyExposureColumns(translate, hiddenState) {
    let outArray = [];

    xpColCreate(translate, outArray, hiddenState, 'currency'      , 50, 50, 'currencyExposureList.currency', {cellRenderer: 'agGroupCellRenderer', lockVisible: true, lockPosition: true});
    xpColCreate(translate, outArray, hiddenState, 'BS'       , 50, 50, 'currencyExposureList.B/S');
    xpColCreate(translate, outArray, hiddenState, 'amountT'      , 50, 50, 'currencyExposureList.exposureAmount');
    xpColCreate(translate, outArray, hiddenState, 'exposureBaseCurrencyT'  , 50, 50, 'currencyExposureList.exposureBaseCurrency');
    xpColCreate(translate, outArray, hiddenState, 'exposureAvgRateT' , 50, 50, 'currencyExposureList.exposureAvgRate');
    xpColCreate(translate, outArray, hiddenState, 'marketRateT' , 50, 50, 'currencyExposureList.marketRate');
    //xpColCreate(translate, outArray, hiddenState, 'PNLT' , 50, 50, 'currencyExposureList.PNL');

    return outArray;
}

export function roundRate(rate) {
    return (Math.round(rate * 10000)) / 10000;
}

import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {formatClientsGridData, prepareClientsGridColumns} from "features/client/clientFunctions";
import {shallowActiveClientsSelector} from "features/client/clientSelectors";
import XpAdminFormEntityGridTemplate from "gui-common/xpAdminForm/XpAdminFormEntityGridTemplate";

export default function ClientsList (props) {

    const inputData = (props.inputData) ? props.inputData : useSelector(shallowActiveClientsSelector);
    const currentLanguage = useSelector(selectActiveLanguage);
    const translate = useSelector(selectTranslateFunction);

    const columnDefs = useMemo(
        () => {
            const hiddenState = props.hiddenState ? props.hiddenState : {
                name:               false,
                id:                 true,
                description:        false,
                clientApiChannelT:  false,
                accountManager:     true,
                operatingUnitT:     true,
                createdDateTime:    false,
                createdByUser:      true,
                changedDateTime:    false,
                changedByUser:      true,
            };
            return prepareClientsGridColumns(translate, hiddenState);
        },
        [translate, props.hiddenState]
    );
    const rowData = useMemo(
        () => {
            return formatClientsGridData(inputData, currentLanguage, translate);
        },
        [inputData, currentLanguage, translate]
    );

    return (
        <XpAdminFormEntityGridTemplate
            columnDefs={columnDefs}
            rowData={rowData}
            trKeyBase='clientsList'
            ormModel='Client'
            {...props}
        />
    )
}

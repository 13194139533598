import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {
    userRightsAdminSetInstanceRight,
    userRightsAdminSetDomainRight
} from "gui-common/userRights/adminUserRightsReducer"

import DomainRights from "gui-common/userRights/DomainRights";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {extractDomainRights} from "gui-common/userRights/adminUserRightsFunctions";
import {RemoveEntityDataRightButton} from "gui-common/userRights/InstanceRights";
import AdminDataRightsCollection from "gui-common/userRights/AdminDataRightsCollection";
import {getModifiedEntityDataRightSelector} from "gui-common/userRights/adminUserRightsSelectors";

function InstanceUserRights(props) {
    const dispatch  = useDispatch();

    const entityDataRight = props.currentData ? props.currentData : props.entityDataRight;

    const modifiedEntityDataRightSelector = useMemo(() => getModifiedEntityDataRightSelector(), []);
    const modifiedEntityDataRight  = useSelector(state => modifiedEntityDataRightSelector(state, {selectId: entityDataRight.id}));

    const domainRights = useMemo(() => {
        if (!entityDataRight) return [];
        return extractDomainRights(entityDataRight.domainRights, props.readOnly, props.activeEntityDataRight?.domainRights);
    }, [entityDataRight, props.activeEntityDataRight]);

    if (!entityDataRight) return null;

    const currentEntityDataRight = (modifiedEntityDataRight && !props.auditMode) ? modifiedEntityDataRight : entityDataRight;
    const activeEntityDataRight  = props.beforeChangeData ? props.beforeChangeData : props.activeEntityDataRight;

    let renderClass = "instanceUserRightsCard";
    if (props.isAdded)   renderClass += " instanceUserRightsCardAdded";
    if (props.isRemoved) renderClass += " instanceUserRightsCardRemoved";

    function getInstanceRightChanged (right) {
        if (!activeEntityDataRight) return false;
        return (currentEntityDataRight.instanceRights[right] !== activeEntityDataRight.instanceRights[right]);
    }

    return (
        <div>
            <XpAccordion
                headerKey='adminUserRights.instanceUserRightsHeader'
                headerParams={{instanceName: entityDataRight.entity.name}}
                instanceId={"adminUserRightsInstance-" + entityDataRight.id}
                forceExpanded={props.readOnly ? true : undefined}
                bypass={props.readOnly}
            >
                <div className={renderClass} >
                    {props.readOnly &&
                    <h4>
                        <XpTranslated trKey='adminUserRights.instanceUserRightsHeader' trParams={{instanceName: entityDataRight.entity.name}}/>
                    </h4>}
                    <div>
                        <div className="instanceUserRightsBlock">
                            {(!props.readOnly && !props.isRemoved) &&
                            <div style={{display: 'inline-block', margin: '0px 5px 5px -2px'}}>
                                <RemoveEntityDataRightButton
                                    dataRightsCollection = {props.dataRightsCollection}
                                    entityDataRight      = {currentEntityDataRight}
                                    displayLabel = {true}
                                />
                            </div>}

                            {Object.keys(currentEntityDataRight.instanceRights).map(right => {
                                const handleChange = (event) => {
                                    dispatch(userRightsAdminSetInstanceRight(props.dataRightsCollection.user, currentEntityDataRight, right, event.target.checked));
                                };
                                const isAllowed              = (currentEntityDataRight.instanceRights[right] === true);
                                const instanceRightChanged   = getInstanceRightChanged(right);

                                if (props.readOnly && !isAllowed && !instanceRightChanged) return null;

                                let cellClassName = "";
                                if (instanceRightChanged)   cellClassName = "changedCell";

                                return (
                                    <div key = {right} className={cellClassName}>
                                        <div className="checkboxTdColumn" style={{display: "inline-block"}}>
                                            <input
                                                type="checkbox"
                                                checked={isAllowed}
                                                onChange={handleChange}
                                                disabled={props.readOnly || props.isRemoved}
                                            />
                                        </div>
                                        <label className="labelCheckbox">{right}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{display: "inline-block", verticalAlign: "top"}}>
                            <DomainRights
                                instanceDomainRights = {currentEntityDataRight.domainRights}
                                instanceDomainRightsBeforeChange = {activeEntityDataRight ? activeEntityDataRight.domainRights : undefined}
                                setDomainRight       = {(domain, userRight, isAllowed) => dispatch(userRightsAdminSetDomainRight(props.dataRightsCollection.user, currentEntityDataRight, domain, userRight, isAllowed))}
                                domainRights         = {domainRights}
                                readOnly             = {props.readOnly || props.isRemoved}
                            />
                        </div>
                    </div>
                    {(entityDataRight.childDataRightsCollectionId || entityDataRight.childDataRightsCollection) &&
                    <div style={{}}>
                        <AdminDataRightsCollection
                            dataRightsCollectionId       = {entityDataRight.childDataRightsCollectionId}
                            activeDataRightsCollectionId = {activeEntityDataRight ? activeEntityDataRight.childDataRightsCollectionId : undefined}

                            // Only relevant for audit when the full structure is parsed.
                            currentData         = {props.auditMode ? entityDataRight.childDataRightsCollection       : undefined}
                            beforeChangeData    = {props.auditMode ? activeEntityDataRight?.childDataRightsCollection : undefined}

                            parentInstance      = {entityDataRight}
                            readOnly            = {props.readOnly}
                            auditMode           = {props.auditMode}
                            isRemoved           = {props.isRemoved}
                        />
                    </div>}
                </div>
            </XpAccordion>
        </div>
    );
}

InstanceUserRights.propTypes = {
    entityDataRight       : PropTypes.object.isRequired,
    dataRightsCollection  : PropTypes.object.isRequired,
    readOnly              : PropTypes.bool,
};

export default InstanceUserRights;

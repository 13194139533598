import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import 'gui-common/userRights/adminUserRights.css'
import {getUserSelector} from "gui-common/orm/ormSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getTopDataRightsCollectionsSelector} from "gui-common/userRights/adminUserRightsSelectors";
import AdminDataRightsCollection from "gui-common/userRights/AdminDataRightsCollection";
import AdminSystemRights from "gui-common/userRights/AdminSystemRights";
import {renderUserProfiles} from "gui-common/user/userFunctions";

export function RenderUserProfiles(props) {
    if (!props.user) return null;
    return (
        <h4>
            <XpTranslated trKey='userPreferencesForm.profiles'    />
            {renderUserProfiles(props.user)}
        </h4>
    )
}

function ViewMyUserRights(props) {
    const userSelector = useMemo(() => getUserSelector()    , []);
    const user         = useSelector(userSelector);

    const dataRightsCollectionsSelector = useMemo(() => getTopDataRightsCollectionsSelector()    , []);
    const dataRightsCollections = useSelector(state => dataRightsCollectionsSelector(state, {user: user}));

    const dataRightsCollectionId = useMemo(() => {
        const dataRightsCollection = dataRightsCollections.find(item => item.state === 'ACTIVE');
        return dataRightsCollection ? dataRightsCollection.id : undefined;
    }, [dataRightsCollections]);

    return (
        <div>
            <h2><XpTranslated trKey={'adminUserRights.myRightsHeader'}/></h2>
            <p className="centeredText"><XpTranslated trKey={'adminUserRights.myRightsMessage'}/></p>
            <hr/>
            <div>
                <RenderUserProfiles user={user}/>
                <AdminSystemRights
                    user                   = {user}
                    readOnly               = {true}
                />
                <AdminDataRightsCollection
                    dataRightsCollectionId       = {dataRightsCollectionId}
                    activeDataRightsCollectionId = {dataRightsCollectionId}
                    readOnly               = {true}
                />
            </div>
        </div>
    );
}

export default withRouter(ViewMyUserRights);

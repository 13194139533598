import {useMemo} from "react";
import {
    globalOrmConfig
} from "gui-common/orm/ormModels";

export function useOrm(appOrmConfig) {
    useMemo(
        () => {
            if (appOrmConfig.nestedEntities) {
                globalOrmConfig.nestedEntities   = {...globalOrmConfig.nestedEntities  , ...appOrmConfig.nestedEntities};
            }
            if (appOrmConfig.parentProperties) {
                globalOrmConfig.parentProperties   = {...globalOrmConfig.parentProperties  , ...appOrmConfig.parentProperties};
            }
            if (appOrmConfig.compareFunction) {
                globalOrmConfig.compareFunctions   = {...globalOrmConfig.compareFunctions  , ...appOrmConfig.compareFunction};
            }
            if (appOrmConfig.onUpdateThunks) {
                globalOrmConfig.onUpdateThunks   = {...globalOrmConfig.onUpdateThunks  , ...appOrmConfig.onUpdateThunks};
            }
        },
        [],
    );
}

import React, { Component } from 'react'
import {connect} from "react-redux";
import 'gui-common/processStatus/processStatus.css';
import ProcessStatusPanel from "gui-common/processStatus/ProcessStatusPanel";
import {processPrototypeStatusMapSelector} from "gui-common/processStatus/processStatusSelectors";

class ProcessStatusWidget extends Component {


    render() {
        if (!Object.keys(this.props.processStatusList).length) {
            return null;
        }

        return (
            <div className="integrationStatusContainer" ref='integrationStatusContainer'>
                {Object.keys(this.props.processStatusList).map(prototype =>
                    <ProcessStatusPanel
                        processPrototype = {prototype}
                        processStatusMap = {this.props.processStatusList[prototype]}
                        key={prototype}
                        instanceId={this.props.instanceId + "-" + prototype}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        processStatusList: processPrototypeStatusMapSelector(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessStatusWidget);



import {processStatusTypes} from "gui-common/processStatus/processStatusConstants";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

export const refixProcessStatusConfig = {
    "se.nordicfc.swedbank.refix.listrate.InternalListRateApplication": {
        getStatusType: getListRateProcessStatus,
        processTypeShortName: "LRI"
    },
    "se.nordicfc.swedbank.refix.listrate.ExternalListRateApplication": {
        getStatusType: getListRateProcessStatus,
        processTypeShortName: "LRE"
    },
    "se.nordicfc.common.fxpriceservice.FxPriceServiceApplication": {
        getStatusType: processArray => {
            if (processArray.some(process => !process.processStatus?.secondaryActive)) {
                return processStatusTypes.WARNING;
            }
            for (const process of processArray) {
                const priceMonitorStatusList = process.processStatus?.priceMonitorStatusList;
                if (priceMonitorStatusList?.length) {
                    if (priceMonitorStatusList.some(monitorStatus => {
                        if (!monitorStatus.active) {
                            return true;
                        }
                        if (!monitorStatus.symbols) {
                            return false;
                        }
                        if (Object.keys(monitorStatus.symbols).some(key => !monitorStatus.symbols[key].active)) {
                            return true;
                        }
                    })) {
                        return processStatusTypes.WARNING
                    }
                }
            }
            return processStatusTypes.RUNNING;
        },
        processTypeShortName: "FPS"
    },
    "se.nordicfc.refix.market.MarketOrder": {
        getStatusType: processArray => {
            if (processArray.some(process => {
                if (!process.processStatus?.fixSessionsStatus) {
                    return true;
                }
                if (!Object.keys(process.processStatus?.fixSessionsStatus).length) {
                    return true;
                }
                const sessionsArray = Object.keys(process.processStatus?.fixSessionsStatus).map(key => process.processStatus?.fixSessionsStatus[key]);
                const atLeastOneSessionIsDown = sessionsArray.some(session => {
                    return session.status !== 'RUNNING'
                })
                return atLeastOneSessionIsDown;
            })) {
                return processStatusTypes.WARNING;
            }
            return processStatusTypes.RUNNING;
        },
        processTypeShortName: "MO"
    },
    "se.nordicfc.refix.referecerate.IndicativeFxServiceApplication": {
        processTypeShortName: "IR"
    },
    "se.nordicfc.refix.referecerate.ReferenceRateApp": {
        processTypeShortName: "RR"
    },
    "se.nordicfc.refix.api.RefixApi": {
        processTypeShortName: "API"
    },
}

function getListRateProcessStatus(processArray) {
    if (processArray.some(process => process.processStatus ? Object.keys(process.processStatus?.primarySurveillancePricesStatus).some(ccyPair => !process.processStatus?.primarySurveillancePricesStatus[ccyPair].active) : true)) {
        return processStatusTypes.WARNING;
    }
    if (processArray.some(process => process.processStatus ? Object.keys(process.processStatus?.secondarySurveillancePricesStatus).some(ccyPair => !process.processStatus?.secondarySurveillancePricesStatus[ccyPair].active) : true)) {
        return processStatusTypes.WARNING;
    }
    return processStatusTypes.RUNNING;
}


export const refixProcessStatusPackageConfig = {
    "se.nordicfc.common.integration.fix.common.heartbeat.model.FxPriceServiceStatus": {
        renderPackage: statusPackage => (
            <div>
                <div>Secondary price
                    source: {statusPackage.secondaryActive ? " ==> RUNNING" : " ==> DOWN (" + statusPackage.secondaryDownReason + ")"}</div>
                <div>{"\nPrice monitors:"}</div>
                {statusPackage.priceMonitorStatusList?.length ?
                    statusPackage.priceMonitorStatusList.map((priceMonitor, index) => (
                        <div key={index}>
                            <hr/>
                            <div>{priceMonitor.feedName} ({priceMonitor.sessionId}/{priceMonitor.account}/{priceMonitor.tenor})</div>
                            <div>{!priceMonitor.active ? " ==> DOWN (" + priceMonitor.streamedPriceDownReason + ")" : !Object.keys(priceMonitor.symbols).some(key => !priceMonitor.symbols[key].active) ? " ==> All currency pairs running" : null}</div>
                            {priceMonitor.symbols ?
                                Object.keys(priceMonitor.symbols).map(key => priceMonitor.symbols[key].active ? null : (
                                    <div
                                        key={key}>{key}:{" ==> DOWN (" + priceMonitor.symbols[key].streamedPriceDownReason + ")"}</div>)) : null}
                        </div>
                    )) : null
                }
            </div>
        )
    },
    "se.nordicfc.common.integration.fix.common.heartbeat.model.ListRateProcessStatus": {
        renderPackage: statusPackage => (
            <div>
                <div>Primary price source:</div>
                {renderCurrencyPairList(statusPackage?.primarySurveillancePricesStatus)}

                <div>Secondary price source:</div>
                {renderCurrencyPairList(statusPackage?.secondarySurveillancePricesStatus)}
            </div>
        )
    },
    "se.nordicfc.common.integration.fix.common.heartbeat.model.MarketOrderProcessStatus": {
        renderPackage: statusPackage => {
            if (!statusPackage?.fixSessionsStatus) {
                return false;
            }
            return (
                <div>
                    {Object.keys(statusPackage.fixSessionsStatus).length
                        ?
                        Object.keys(statusPackage.fixSessionsStatus).map((key, index) => (<div key={index}>
                            {statusPackage.fixSessionsStatus[key].sessionId}
                            {key + " ==> " + statusPackage.fixSessionsStatus[key].status}
                        </div>))
                        :
                        <XpTranslated trKey='processStatus.marketOrder.noSessionsAvailable'/>
                    }
                </div>
            )
        }
    }
}

function renderCurrencyPairList(list) {
    return Object.keys(list).map((ccyPair, index) => (<div key={index}>
        {ccyPair}:
        {list[ccyPair].active ? " ==> RUNNING" : " ==> DOWN (" + list[ccyPair].downReason + (list[ccyPair].message ? ":" + list[ccyPair].message : "") + ")"}
    </div>));
}

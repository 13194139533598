import {Nav, NavDropdown} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {OverallActionsDropDownMenu} from "features/currencyExposure/OverallCurrencyExposure";
import {pushModalWindow} from "redux-promising-modals";
import {useDispatch, useSelector} from "react-redux";
import {
    getOperatingUnitsWithFullClientCreateSelector,
    operatingUnitsWithFullClientCreateSelector,
    userSelector,
    usersSelector
} from "gui-common/orm/ormSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {SANDBOX_APP_ID_ACTIVATE_DIALOG} from "appConfig/appModals";
import {useSelectorInstance} from "gui-common/functions/hooks";
import XpDropDownMenuLinkItem from "gui-common/menuComponents/XpDropDownMenuLinkItem";
import XpMainMenuLinkItem from "gui-common/menuComponents/XpMainMenuLinkItem";

export const refixLeftMenuItems = [
    <XpMainMenuLinkItem         key={1} targetPath={'/dashboard'} trKey={'topMenu.dashBoard'}/>,
    <OverallActionsDropDownMenu key={2}/>
]

function SandboxActivateAppIdMenuItem() {
    const operatingUnits = useSelector(state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForAdmin'}));
    const isSandbox = useAppEnvProperty('isSandbox');
    const dispatch = useDispatch();

    if (!isSandbox || !operatingUnits?.length) return null;
    return (
        <div>
            <NavDropdown.Item
                key = 'activateAppId'
                onClick={() => dispatch(pushModalWindow(SANDBOX_APP_ID_ACTIVATE_DIALOG, {}))}
            >
                <XpTranslated trKey={'topMenu.sandboxActivateAppId'}/>
            </NavDropdown.Item>
        </div>
    )
}

export function RefixSettingsMenu(props) {
    const user           = useSelector(userSelector);
    const users          = useSelector(usersSelector);

    // const operatingUnitsWithFullClientCreate = useSelectorInstance(getOperatingUnitsWithFullClientCreateSelector, {nestedPropToCheck: 'nestedRightRequiredForWizard'});
    // const showClientCreateWizard = (operatingUnitsWithFullClientCreate.length > 0);

    const userCanAdminUsersConst            = (users && (users.length > 1));  // User endpoint will only return users that the user has rights to admin (and the user itself)
    const userCanReadFxRejectConfig         = userCan('Read'  , 'FxRejectConfiguration', user);
    const userCanManageFxRejectConfig       = userCan('Update', 'FxRejectConfiguration', user);
    const userCanReadCurrencyPairConfig     = userCan('Read'  , 'CurrencyPairConfig', user);
    const userCanManageCurrencyPairConfig   = userCan('Update', 'CurrencyPairConfig', user);
    const userCanManageSystemTexts          = userCan('Create', 'SystemText', user);
    const userCanReadPriceTolerances        = userCan('Read'  , 'PriceTolerance', user)
    const userCanManagePriceTolerances      = userCan('Update', 'PriceTolerance', user)

    const userCanManageMarginGroupConfig    = userCan('Create', 'PriceMarginGroup', user) || userCan('Update', 'PriceMarginGroup', user);
    const userCanReadMarginGroupConfig      = userCan('Read'  , 'PriceMarginGroup', user);
    const showAuditMenu                     = userCan('Read'  , 'Audit', user) || userCan('Read', 'SuperAudit', user);

    return (
        <NavDropdown title={<XpTranslated trKey={'topMenu.configDropdown'}/>} id="config-nav-dropdown" alignRight>

            {/*No authorization check on this menu. Only available clients will be visible.*/}
            <XpDropDownMenuLinkItem targetPath={'/clientSettings'}    trKey={'topMenu.clients'}             displayCondition={true}/>
            {/*<XpDropDownMenuLinkItem targetPath={'/refixClientWizard'} trKey={'topMenu.clientCreateWizard'}  displayCondition={showClientCreateWizard}/>*/}
            <XpDropDownMenuLinkItem targetPath={'/auditView'}         trKey={'topMenu.auditView'}           displayCondition={showAuditMenu}/>
            <XpDropDownMenuLinkItem targetPath={'/report'}            trKey={'topMenu.report'}              displayCondition={true}/>

            <NavDropdown.Divider/>
            <SandboxActivateAppIdMenuItem/>

            <XpDropDownMenuLinkItem targetPath={'/adminUserRights'}    trKey={'topMenu.adminUserRights'}                                                                                displayCondition={userCanAdminUsersConst}/>
            <XpDropDownMenuLinkItem targetPath={'/fxRejectConfig'}     trKey={userCanManageFxRejectConfig     ? 'topMenu.manageFxRejectConfig'     : 'topMenu.viewFxRejectConfig'}      displayCondition={userCanReadFxRejectConfig || userCanManageFxRejectConfig}/>
            <XpDropDownMenuLinkItem targetPath={'/currencyPairConfig'} trKey={userCanManageCurrencyPairConfig ? 'topMenu.manageCurrencyPairConfig' : 'topMenu.viewCurrencyPairConfig'}  displayCondition={userCanReadCurrencyPairConfig || userCanManageCurrencyPairConfig}/>
            <XpDropDownMenuLinkItem targetPath={'/marginGroupConfig'}  trKey={userCanManageMarginGroupConfig  ? 'topMenu.manageMarginGroupConfig'  : 'topMenu.viewMarginGroupConfig'}   displayCondition={userCanReadMarginGroupConfig  || userCanManageMarginGroupConfig}/>
            <XpDropDownMenuLinkItem targetPath={'/priceToleranceConfig'}  trKey={userCanManagePriceTolerances ? 'topMenu.managePriceToleranceConfig'  : 'topMenu.viewPriceToleranceConfig'}   displayCondition={userCanReadPriceTolerances  || userCanManagePriceTolerances}/>
            <XpDropDownMenuLinkItem targetPath={'/editTranslations'}   trKey={'topMenu.manageTranslations'}                                                                             displayCondition={userCanManageSystemTexts}/>

        </NavDropdown>
    )
}


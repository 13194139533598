import {Route} from "react-router-dom";
import React, {useContext} from "react";
import {ThemeProvider} from "@mui/material";
import UserMessagesDisplay from "gui-common/userMessages/UserMessagesDisplay";
import DevInfo from "gui-common/app/DevInfo";
import ContextMenuManager from "gui-common/contextMenu/ContextMenuManager";
import MainMenu from "gui-common/app/MainMenu";
import ModalManager from "gui-common/modals/ModalManager";
import {useApi} from "gui-common/api/apiHooks";
import {useMuiTheme} from "gui-common/css/xpMaterialUiStyling";
import {useAppConfig, useRouterPaths} from "gui-common/app/commonConfig";
import {oxygenAppContext} from "gui-common/app/oxygenContext";

function OxygenApp(props) {
    const muiTheme  = useMuiTheme(props.appConfig.styling);
    const apiHandle = useApi(props.appConfig, props.initialLoginKeys);
    useAppConfig(props.appConfig);
    const routerPaths = useRouterPaths(props.appConfig)

    return (
        <ThemeProvider theme = {muiTheme}>
            <oxygenAppContext.Provider value={{appConfig: props.appConfig, apiHandle: apiHandle}}>
                <UserMessagesDisplay/>
                <DevInfo/>
                <div className="body oxyGenApp" id="oxyGenApp">
                    {props.appConfig.modals      && <ModalManager />}
                    {props.appConfig.contextMenu && <ContextMenuManager/>}
                    {props.appConfig.mainMenu    && <MainMenu/>}
                    <div className="content" id="appContentContainer">
                        {routerPaths.map((item, index) => (
                            <Route path={item.path} key={index} component={item.component}/>
                        ))}
                    </div>
                </div>
            </oxygenAppContext.Provider>
        </ThemeProvider>
    )
}
export default OxygenApp;


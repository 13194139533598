import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {userSelector} from "gui-common/orm/ormSelectors";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {
    currentCurrencyPairConfigSelector,
    currencyPairConfigListData,
    savedCurrencyPairConfigSelector,
} from "gui-common/currencyPairConfig/currencyPairConfigSelectors";
import {getCurrencyPairConfigListColumns} from "gui-common/currencyPairConfig/currencyPairConfigFunctions";
import {
    currencyPairConfigSetChanged
} from "gui-common/currencyPairConfig/currencyPairConfigReducer";
import {setByPath} from "gui-common/functions/functions";
import cloneDeep from "lodash.clonedeep";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export default function CurrencyPairConfigList (props) {

    const thisUser               = useSelector(userSelector);
    const currencyPairEnvConfig  = useSelector(state => selectAppEnvProperty(state, 'currencyPairsConfig') )
    const dispatch               = useDispatch();

    const decDenLangState = useSelector(selectDecDenLangState);

    const currencyPairOrmConfigs     = useSelector(state => savedCurrencyPairConfigSelector(state, props));

    const currencyPairChangedConfigs = useSelector(state => currentCurrencyPairConfigSelector(state, props));
    const currencyPairChangedConfigsRef = useRef(currencyPairChangedConfigs); // Needed to transport updated translate hook to callback scope.
    currencyPairChangedConfigsRef.current = currencyPairChangedConfigs;

    const [cellValueBeforeEdit, setCellValueBeforeEdit] = useState(null);
    const cellValueBeforeEditRef = useRef(cellValueBeforeEdit); // Needed to transport updated translate hook to callback scope.
    cellValueBeforeEditRef.current = cellValueBeforeEdit;

    const [gridApi, setGridApi] = useState(null);

    const userCanUpdate = useMemo(() => {
        if (props.auditMode) return false;
        return userCan('Update', 'CurrencyPairConfig', thisUser);}, [thisUser]
    );

    // Will force cells to be refreshed when audit records is changed but value is the same with different before value..
    useEffect(() => {
        if (props.auditMode && gridApi) gridApi.refreshCells({force: true});
    }, [props.inputData]);

    function onCellEditingStarted(e){
        setCellValueBeforeEdit(e.value);
        if (props.setEditingCallback) props.setEditingCallback(true);
    }
    function onCellEditingStopped(e){
        // eslint-disable-next-line
        if (e.value != cellValueBeforeEditRef.current) {
            const modifiedItem = cloneDeep(currencyPairChangedConfigsRef.current.find(item => item.id === e.data.id));
            if (modifiedItem) {
                setByPath(modifiedItem.configObject, e.colDef.cellRendererParams.objectPath, e.value);
                dispatch(currencyPairConfigSetChanged(modifiedItem));
            }
        }
        e.api.refreshCells({force: true});
        // dispatch(actions.change(e.colDef.cellRendererParams.trackPath, {id: e.data.id}), e.value);
        if (props.setEditingCallback) props.setEditingCallback(false);
        setCellValueBeforeEdit(null);
        // eslint-disable-next-line
    }

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        onCellEditingStarted    : useCallback((e) => onCellEditingStarted(e), []),
        onCellEditingStopped    : useCallback((e) => onCellEditingStopped(e),[]),
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        onGridReadyCallback     : useCallback((params) => {
            setGridApi(params.api);
            if (props.onGridReady) props.onGridReady(params)
        }, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!props.itemSelectedCallback) return;
                props.itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [props.itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];

                let menuItems = [];

                if (params.node && params.column.colDef.editable && userCanUpdate) menuItems.push({
                    name: translate('currencyPairConfigList.editButton'),
                    action: function () {
                        let api = this.context.api;
                        api.startEditingCell({
                            rowIndex: this.context.node.rowIndex,
                            colKey: this.context.column.colId,
                        });
                    },
                    context: params,
                    disabled: false,
                })

                return menuItems;
            }, [translate]),
        rowClassRules   : {
            // 'xp-grid-rejected-order-row' : function(params) { return (params.data.guiStatus === "Rejected")},
            // 'xp-grid-inactive-row'       : function(params) { return (params.data.guiStatus === 'Booked')},
        },
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('currencyPairConfigList.noItemsToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? true : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                rowDataSelector         : currencyPairConfigListData,
                rowDataSelectorProps    : {...props.rowDataSelectorProps, ...props},
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'CurrencyPairConfig',
                stopEditingWhenGridLosesFocus: true,
                suppressRowClickSelection: true
            };
        },
        [translate, props.inputData]
    );
    const colDefs = useMemo(
        () => {
            return getCurrencyPairConfigListColumns(translate, currencyPairOrmConfigs, props.auditMode ? false : userCanUpdate, currencyPairEnvConfig, decDenLangState);
        },
        [translate, currencyPairOrmConfigs, userCanUpdate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={props.gridId ? props.gridId : "currencyPairSettingsGrid-" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
                fillAvailableSpace={false}
                addExtraHeight={45}
                disableFlash={true}
            >
            </XpGrid>
        </div>
    );
}

import {modalsReducer} from "redux-promising-modals";
import {apiReducer, awtReducer} from "gui-common/api/apiReducers"
import xpTranslatedReducer          from "gui-common/appLocale/xpTranslated/xpTranslatedReducer"
import numberFormatReducer          from "gui-common/numberFormat/numberFormatReducer"
import {appEnvReducer}              from "gui-common/app/appEnvReducer";

import {userMessagesReducer}        from "gui-common/userMessages/userMessagesReducer";
import {ormReducer}                 from "gui-common/orm/ormReducer";
import ormLoadingReducer            from "gui-common/orm/ormLoadingReducer"
import {dashboardReducer}           from "gui-common/dashboard/dashboardReducer";
import {contextMenuReducer}         from "gui-common/contextMenu/contextMenuReducer";
import xpGridsReducer               from "gui-common/xpGrid/xpGridsReducer";
import {wizardReducer}              from "gui-common/wizard/wizardReducer";
import userStateReducer             from "gui-common/userSettings/userState/userStateReducer"
import {xpAccordionReducer}         from "gui-common/xpAccordion/xpAccordionReducer"
import {adminUserRightsReducer}     from "gui-common/userRights/adminUserRightsReducer"
import savedUserStatesReducer       from "gui-common/userSettings/sharedSettings/savedUserStatesReducer";
import {formNumberFieldsReducer}    from "gui-common/xpForm/formNumberFieldsReducer";
import {combineReducers} from "redux";
import {userPreferencesReducer} from "gui-common/userSettings/userPreferencesReducer";
import {connectRouter} from "connected-react-router";
import {reportReducer} from "gui-common/report/reportReducer";
import {requestEntityStateReducer} from "gui-common/requestEntityState/requestEntityStateReducer";
import {currencyPairConfigReducer} from "gui-common/currencyPairConfig/currencyPairConfigReducer";
import {xpFormReducer} from "gui-common/xpForm/core/xpFormReducer";
import {xpTabsReducer} from "gui-common/components/xpTabs/xpTabsReducer";
import eventServiceReducer from "gui-common/eventService/eventServiceReducer";

const getRootReducer = (history, appConfig) => combineReducers({
    awtState:               awtReducer,
    apiState:               apiReducer,
    appEnvState:            appEnvReducer(appConfig.envParams),
    xpTranslatedState:      xpTranslatedReducer,
    router:                 connectRouter(history),
    modals:                 modalsReducer,
    numberFormatState:      numberFormatReducer,
    orm:                    ormReducer,
    ormLoadingState:        ormLoadingReducer,
    userPreferencesState:   userPreferencesReducer,
    userStateState:         userStateReducer,
    savedUserStatesState:   savedUserStatesReducer,
    reportState:            reportReducer,
    userMessagesState:      userMessagesReducer,
    dashboardState:         dashboardReducer,
    contextMenuState:       contextMenuReducer,
    xpGridsState:           xpGridsReducer,
    xpAccordionState:       xpAccordionReducer,
    xpTabsState:            xpTabsReducer,
    formNumberFieldsState:  formNumberFieldsReducer,
    wizardState:            wizardReducer,
    adminUserRightsState:   adminUserRightsReducer,
    currencyPairConfigState:currencyPairConfigReducer,
    requestEntityState:     requestEntityStateReducer,
    xpFormState:            xpFormReducer,
    eventServiceState:      eventServiceReducer,
    ...appConfig.reducers,
});
export default getRootReducer

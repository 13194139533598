import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {useDispatch, useSelector} from "react-redux";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {xpFormChangeField, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import ReactJson from "react-json-view";
import {selectIsDevMode, useAppEnvProperty} from "gui-common/app/appEnvSelectors";

// GET_TEST_PARAMETERS_DIALOG
function ModalGetTestParameters ({popModalWindow, headerText, messageText, parameters, label1, label2, label3, label4, label5, ormModel, ormItem}) {

    const formDataP1 = useXpFormField('getTestParametersForm.p1')
    const dispatch  = useDispatch();
    const devMode     = useAppEnvProperty('devMode');
    const devInfoMode = useAppEnvProperty('devInfoMode');

    function cancelAction() {
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }

    function handleSubmit(data) {
        popModalWindow({ status: MODAL_TYPE_CONFIRM, p1: data.p1, p2: data.p2, p3: data.p3, p4: data.p4, p5: data.p5 });
    }

    /*
        function getRenderValue(val) {
            if (typeof val === 'boolean') {
                return val ? "true" : "false";
            }
            if (typeof val === 'object') {
                return "Object";
            }
            return val;
        }

        const parameterList = useMemo(
            () => {
                if (!parameters?.length) return undefined;
                return (
                    <table style={{width: '100%', margin: '5px'}}>
                        <tbody>
                        <tr key="headerTop">
                            <th style={{width: '30%' }} className="blankHeader" > </th>
                            <th style={{width: '70%' }} className="blankHeader" > </th>
                        </tr>
                        {parameters.map((item, index) => {
                            const renderValue = getRenderValue(item.value);
                            return (
                                <tr key={index}>
                                    <td className="blankHeader" > {item.key}</td>
                                    <td className="blankHeader"> {renderValue}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                )
            },
            []
        );
    */

    const parametersSelect = useMemo(
        () => {
            if (!parameters?.length) return undefined;
            let retArray = [];
            parameters.forEach(item => {
                if (item.key === 'id') return;
                retArray.push({id: item.key, name: item.key});
            });
            return retArray;
        },
        []
    );

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalGenerateUserMessage"}};
    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Confirmation modal"
                    onRequestClose={cancelAction}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    <div>
                        <h3 className ="xpoolModalHeader">{headerText}</h3>
                        <hr/>
                        {/*{messageText && <p className="valueTextDark">{messageText}</p>}*/}
                        {/*{parameterList && <div className="valueTextDark">{parameterList}</div>}*/}
                        <ReactJson style={{fontSize: '12px', lineHeight: '10px'}} collapsed={1} displayObjectSize sortKeys name={'data'} iconStyle={'circle'} src={ormItem} />
                        <hr/>

                        {devMode &&
                        <XpForm
                            formModel="getTestParametersForm"
                            onSubmit={(data) => handleSubmit(data)}
                            initialUseState={XP_FORM_EDIT}
                        >
                            {label1 &&
                            ((parameters?.length) ?
                                    <XpFormSelectorInput
                                        selectList={parametersSelect}
                                        noTemplate
                                        field         = "p1"
                                        label         = {label1}
                                        autoFocus     = {true}
                                        maxLength     = {50}
                                        onChangeCallback={newValue => {
                                            const listItem = parameters.find(item => item.key === newValue);
                                            if (listItem && (typeof listItem.value === "string")) {
                                                dispatch(xpFormChangeField('getTestParametersForm.p2', ''));
                                                dispatch(xpFormChangeField('getTestParametersForm.p3', listItem.value));
                                            }
                                            if (listItem && (typeof listItem.value === "number")) {
                                                dispatch(xpFormChangeField('getTestParametersForm.p3', ''));
                                                dispatch(xpFormChangeField('getTestParametersForm.p2', listItem.value));
                                            }
                                        }}
                                    />
                                    :
                                    <XpFormTextInput
                                        field         = "p1"
                                        label         = {label1}
                                        autoFocus     = {true}
                                        maxLength     = {50}
                                    />
                            )}
                            {label2 &&
                            <XpFormTextInput
                                field         = "p2"
                                label         = {label2}
                                maxLength     = {50}
                            />}
                            {label3 &&
                            <XpFormTextInput
                                field         = "p3"
                                label         = {label3}
                                maxLength     = {50}
                            />}
                            {label4 &&
                            <XpFormTextInput
                                field         = "p4"
                                label         = {label4}
                                maxLength     = {50}
                            />}
                            {label5 &&
                            <XpFormTextInput
                                field         = "p5"
                                label         = {label5}
                                maxLength     = {50}
                            />}
                        </XpForm>}
                        <div className="xpoolModalButtons">
                            {(ormModel || formDataP1) && devMode &&
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonConfirm"}
                                label           = 'Confirm'
                                onClickCallback = {() => dispatch(xpFormSubmitForm("getTestParametersForm"))}
                            />}
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                label           = {devMode ? 'Cancel' : 'Close'}
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
ModalGetTestParameters.propTypes = {
    modalKey:       PropTypes.string,
    modalParams:    PropTypes.object,
    headerText:     PropTypes.string,
    messageText:    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cancelText:     PropTypes.string,
    confirmText:    PropTypes.string,
    resultTypes:    PropTypes.object.isRequired,
    popModalWindow: PropTypes.func.isRequired,
    ormModel:       PropTypes.string,
};

export default ModalGetTestParameters;


import React, {useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {prepareCurrencyExposureColumns} from "./currencyExposureFunctions";
import {selectCurrencyExposuresListData} from "./currencyExposureSelectors";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {userSelector} from "gui-common/orm/ormSelectors";
import {ListRateList} from "features/listRate/ListRateList";
import {AgreementsList} from "features/agreement/AgreementsList2";
import {DispatchTradeList} from "features/dispatchTrade/DispatchTradeList2";
import {ListRateOrdersList} from "features/listRateOrder/ListRateOrdersList";
import {useSelectorRef} from "gui-common/functions/hooks";
import {listRateProcessStatusSelector} from "features/listRate/listRateSelectors";
import {createListRatesMenuFunction} from "features/listRate/listRateFunctions";
import {createListRateAgreementMenuFunction} from "features/agreement/agreementFunctions";


const hiddenState = {
    currency                : false,
    BS                      : false,
    amountT                 : false,
    exposureBaseCurrencyT   : false,
    exposureAvgRateT        : false,
    marketRateT             : false,
    PNLT                    : false,
};

export function CurrencyExposureList ({itemSelectedCallback, instanceId, setHeightCallback, fillAvailableSpace, rowDataSelectorProps}) {

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const listRateProcessStatusRef = useSelectorRef(listRateProcessStatusSelector);

    const user      = useSelector(userSelector);
    const commentIsMandatoryConfig = useSelector(state => state.appEnvState.mandatoryListRateActionComment.currency);

    const dispatch  = useDispatch();

    const preparedColumns = useMemo(
        () => {
            return prepareCurrencyExposureColumns(translate, hiddenState);
        },
        [translate]
    );

    const userCanSuspend  = useMemo( () => userCan('Update', 'SuspendCurrency'  , user), []);
    const userCanResume   = useMemo( () => userCan('Update', 'ResumeCurrency'   , user), []);
    const userCanExpire   = useMemo( () => userCan('Update', 'ExpireCurrency'   , user), []);
    const userCanDispatch = useMemo( () => userCan('Update', 'DispatchCurrency' , user), []);


    function onGridCellClicked(params) {return true;}
    function onGridRowClicked(params) {return true;}
    function onGridObjectSelected(data) {
        if (!itemSelectedCallback) return;
        if (data === undefined) itemSelectedCallback(undefined);
        else itemSelectedCallback(data.id);
    }

    function gridContextMenuItems(params) {
        if (!params || !params.node || !params.node.data) return [];
        let menuItems = [];

        const createListRateAgreementMenuItem = (action) =>
            createListRateAgreementMenuFunction(params.node.data.agreements, params.node.data.listRates, action, {currencyId: params.node.data.id, type: 'ListRateAgreement'}, 'currencyExposureList.gridContextMenuItems', params.node.data.currency, commentIsMandatoryConfig, listRateProcessStatusRef, translateRef, dispatch);
        const createListRateMenuItem = (action) =>
            createListRatesMenuFunction(                                      params.node.data.listRates, action, {currencyId: params.node.data.id}                           , 'currencyExposureList.gridContextMenuItems', params.node.data.currency, commentIsMandatoryConfig, listRateProcessStatusRef, translateRef, dispatch);

        if (userCanSuspend) menuItems.push(createListRateAgreementMenuItem('suspend'));
        if (userCanResume)  menuItems.push(createListRateAgreementMenuItem('resume'));
        if (userCanExpire)   menuItems.push(createListRateMenuItem('expire'  ));
        if (userCanDispatch) menuItems.push(createListRateMenuItem('dispatch'));

        return menuItems;
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridCellClicked      = {(params) => onGridCellClicked(params)}
                gridRowClicked       = {(params) => onGridRowClicked(params)}
                gridObjectSelected   = {(data)   => onGridObjectSelected(data)}
                gridContextMenuItems = {(params) => gridContextMenuItems(params)}

                gridId={"currencyExposureGrid" + instanceId}
                instanceId={instanceId}

                columnDefs={preparedColumns}
                rowDataSelector = {selectCurrencyExposuresListData}
                rowDataSelectorProps = {rowDataSelectorProps}
                callingContext={this}

                treeData={false}
                masterDetail={true}
                xpDetailRendererProps= {{
                    availableComponents: [
                        {
                            detailType: 'listRates',
                            componentToRender: ListRateList,
                            propsToComponent: {
                                rowDataSelectorProps: {onlyIncludeActiveListRates: true, onlyIncludeActiveDispatchTrades: true},
                                rowDataFilterFunction: (item, parentItem) => {
                                    if (!item.currencyPair) return false;
                                    if (['Active', 'InExpiry'].indexOf(item.status) === -1) return false;
                                    return ((parentItem.id === item.currencyPair.baseCurrency.id) || (parentItem.id === item.currencyPair.quoteCurrency.id));

                                },
                            },
                        },
                        {
                            detailType: 'listRateAgreements',
                            componentToRender: AgreementsList,
                            propsToComponent: {
                                agreementTypes: ['ListRateAgreement'],
                                rowDataSelectorProps: {onlyIncludeActiveListRates: true, onlyIncludeActiveDispatchTrades: true},
                                rowDataFilterFunction: (item, parentItem) => {
                                    if (!item.listRates || !item.listRates.length) return false; // No active list rates
                                    return ((parentItem.id === item.currencyPair.baseCurrency.id) || (parentItem.id === item.currencyPair.quoteCurrency.id));
                                },
                            },
                        },
                        {
                            detailType: 'dispatchTrades',
                            componentToRender: DispatchTradeList,
                            propsToComponent: {
                                // rowDataSelectorProps: {onlyIncludeActiveListRates: true, onlyIncludeActiveDispatchTrades: true},
                                rowDataFilterFunction: (item, parentItem) => {
                                    if (item.status !== 'Active') return false;
                                    if (!item.currencyPair) return false;
                                    return ((parentItem.id === item.currencyPair.baseCurrency.id) || (parentItem.id === item.currencyPair.quoteCurrency.id));
                                },
                            },
                        },
                        {
                            detailType: 'listRateOrders',
                            componentToRender: ListRateOrdersList,
                            propsToComponent: {
                                rowDataSelectorProps: {onlyIncludeActiveListRates: true, onlyIncludeActiveDispatchTrades: true},
                                rowDataFilterFunction: (item, parentItem) => {
                                    if (!item.dispatchTradeStatus) return false;
                                    if (item.dispatchTradeStatus !== 'Active') return false;
                                    return ((parentItem.id === item.currencyPair.baseCurrency.id) || (parentItem.id === item.currencyPair.quoteCurrency.id));
                                },
                            },
                        },
                    ],
                    parentInstanceId: instanceId,
                }}
                rowClassRules={{
                    'xp-grid-inactive-row': function(params) { return !params.data.isActive},
                }}

                overlayNoRowsTemplate={translate('currencyExposureList.noCurrenciesToShow')}

                fillAvailableSpace={fillAvailableSpace}
                setHeightCallback={setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}

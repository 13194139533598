import {LinkContainer} from "react-router-bootstrap";
import {Nav} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import PropTypes from "prop-types";

function XpMainMenuLinkItem(props) {
    if (!props.displayCondition) {
        return null;
    }
    return (
        <LinkContainer to={props.targetPath} key={props.targetPath}>
            <Nav.Link xp-test-id={props.testId} href={props.targetPath}>
                <XpTranslated trKey={props.trKey}/>
            </Nav.Link>
        </LinkContainer>
    )
}
XpMainMenuLinkItem.propTypes = {
    displayCondition: PropTypes.bool,
    targetPath: PropTypes.string.isRequired,
    testId: PropTypes.string,
    trKey: PropTypes.string.isRequired,
};
XpMainMenuLinkItem.defaultProps = {
    displayCondition: true,
};

export default XpMainMenuLinkItem;

import {useCallback, useEffect, useMemo, useState} from "react";
import XpGrid from "../xpGrid/XpGrid3";
import {useDispatch, useSelector} from "react-redux";
import {selectTranslateFunction} from "../appLocale/xpTranslated/xpTranslatedSelectors";
import {prepareFixLogColumns} from "./fixLogFunctions";
import {
    fixLogContainsMessagesSelector,
    getFilteredFixLogListDataSelector
} from "./fxLogSelectors";
import {getOrmItemsFromApi} from "../orm/ormApi";
import {useSelectorInstance} from "../functions/hooks";
import {getOrmModelLoading} from "../orm/ormLoadingSelectors";
import {XP_FORM_EDIT} from "../xpForm/core/xpFormConstants";
import XpFormTextInput from "../xpForm/core/XpFormTextInput";
import XpForm from "../xpForm/core/XpForm";
import {useXpFormField} from "../xpForm/core/xpFormHooks";
import {dashboardSetComponentsState} from "gui-common/dashboard/dashboardReducer";
import {xpFormChangeField, xpFormRemoveForm} from "gui-common/xpForm/core/xpFormReducer";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {getFixLogFilterFromApi, setFixLogFilterToApi} from "gui-common/fixLog/fixLogApi";
import XpFormToggleInput from "gui-common/xpForm/core/XpFormToggleInput";

export default function FixLogList(props) {

    const fixLogListFilterForm = 'fixLogListFilterForm-' + props.instanceId;
    const fixLogBeFilterForm = 'fixLogBeFilterForm';
    const fixMessagesExist = useSelector(fixLogContainsMessagesSelector);
    const fixMessagesLoading = useSelectorInstance(getOrmModelLoading, {ormModel: 'FixMessage'})

    const fixLogDefaultFilter = useAppEnvProperty('fixLogDefaultFilter');

    const dispatch = useDispatch();
    const translate = useSelector(selectTranslateFunction);
    const formListRegExpFilter = useXpFormField(fixLogListFilterForm + '.listRegExpFilter')
    const formShowStreamedPrices = useXpFormField(fixLogBeFilterForm + '.showStreamedPrices')
    const formStreamedRegExpFilter = useXpFormField(fixLogBeFilterForm + '.streamedRegExpFilter')

    const [currentSearchStr, setCurrentSearchStr] = useState(props.componentData.state?.regexpString);

    useEffect(
        () => {
            dispatch(getFixLogFilterFromApi())
                .then(resp => {
                    if (!resp.response?.regexes?.length) {
                        dispatch(setFixLogFilterToApi(fixLogDefaultFilter));
                        dispatch(xpFormChangeField(fixLogBeFilterForm + '.showStreamedPrices', false))
                        dispatch(xpFormChangeField(fixLogBeFilterForm + '.streamedRegExpFilter', ""))
                    }
                    else if (resp.response.regexes[0] !== fixLogDefaultFilter[0]) {
                        dispatch(xpFormChangeField(fixLogBeFilterForm + '.showStreamedPrices', true))
                        dispatch(xpFormChangeField(fixLogBeFilterForm + '.streamedRegExpFilter', resp.response.regexes[0]))
                    }
                })
        },
        [],
    );

    useEffect(() => {
        if (fixMessagesExist) {
            return;
        }
        if (fixMessagesLoading) {
            return;
        }
        dispatch(getOrmItemsFromApi("FixMessage"));
    }, [fixMessagesExist, fixMessagesLoading])

    useEffect(() => {
        try {
            new RegExp(formListRegExpFilter);
            setCurrentSearchStr(formListRegExpFilter);
            dispatch(dashboardSetComponentsState(
                props.componentData.key,
                {regexpString: formListRegExpFilter}
            ));
        } catch (e) {
        }
    }, [formListRegExpFilter])

    useEffect(
        () => {
            if (props.componentData.state?.regexpString) {
                return
            }
            dispatch(xpFormChangeField(fixLogListFilterForm + '.regexpSearch', props.componentData.state?.regexpString))
            return () => {
                dispatch(xpFormRemoveForm(fixLogListFilterForm + '.regexpSearch'));
            }
        },
        [],
    );

    const gridCallbacks = {
        gridRowClicked: useCallback((params) => {
        }, []),
        gridCellClicked: useCallback((params) => {
        }, []),
        gridObjectSelected: useCallback((data) => {
        }, []),
        gridContextMenuItems: useCallback((params) => {
        }, []),
    }

    const gridOptions = useMemo(
        () => {
            return {
                instanceId: props.instanceId,
                overlayNoRowsTemplate: translate('fixLog.fixLogList.noMessagesToShow'),
                fillAvailableSpace: true,
                groupDefaultExpanded: 0,
                getRowDataSelector: getFilteredFixLogListDataSelector,
                treeData: false,
                masterDetail: false,
                ormModel: 'FixMessage',
                rowClassRules: {
                    'xp-grid-rejected-order-row': params => (['Reject', 'QuoteRequestReject'].includes(params.data.messageType)),
                    'xp-grid-onHold-order-row': params => (['QuoteCancel', 'OrderCancelRequest'].includes(params.data.messageType))
                }
            };
        },
        [translate]
    );

    const columnDefs = useMemo(() => {
        let hiddenState = props.hiddenState ? props.hiddenState : {
            id: true,
            logTime: false,
            sequenceNumber: false,
            guiDirection: false,
            messageType: false,
            guiMessageCat: false,
            account: false,
            message: false
        }
        return prepareFixLogColumns(translate, hiddenState);
    }, [translate]);

    function setStreamedRegExpFilter(regExp) {
        dispatch(setFixLogFilterToApi(regExp === '' ? ["^.*$"] : [regExp]));
    }


    return (
        <div style={{height: "100%"}}>

            <div style={{
                height: '60px',
                paddingLeft: '10px',
                paddingRight: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginTop: '-10px'
            }}>
                <div style={{width: '45%', marginRight: '5px'}}>
                    <XpForm
                        formModel={fixLogListFilterForm}
                        initialUseState={XP_FORM_EDIT}
                        initialFormData={{
                            regexpSearch: props.componentData.state?.regexpString ? props.componentData.state?.regexpString : "",
                        }}
                    >
                        <XpFormTextInput
                            field={'listRegExpFilter'}
                            noTemplate
                            labelKey='fixLog.fixLogList.form.listRegExpFilter'
                            // inLineDivider={65}
                            // inLineLayout
                        />
                    </XpForm>
                </div>
                <XpForm
                    formModel={fixLogBeFilterForm}
                    initialUseState={XP_FORM_EDIT}
                    style={{width: '50%', marginRight: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}
                >
                    <div style={{marginRight: '10px'}}>
                        <XpFormToggleInput
                            style={{width: '120px'}}
                            field="showStreamedPrices"
                            labelKey={'fixLog.fixLogList.form.' + formShowStreamedPrices ? 'hideStreamedPrices' : 'showStreamedPrices'}
                            labelKey='fixLog.fixLogList.form.showStreamedPrices'
                            defaultValue={false}
                            noLabelToolTip
                            onChangeCallback={showStreamedPrices => {
                                if (!showStreamedPrices) {
                                    dispatch(setFixLogFilterToApi(fixLogDefaultFilter));
                                } else {
                                    setStreamedRegExpFilter(formStreamedRegExpFilter);
                                }
                            }}
                        />
                    </div>
                    <div style={{width: 'calc(100% - 160px)', visibility: formShowStreamedPrices ? 'visible' : 'hidden'}}>
                        <XpFormTextInput
                            noTemplate
                            field={'streamedRegExpFilter'}
                            updateOn="blur"
                            labelKey='fixLog.fixLogList.form.streamedRegExpFilter'
                            defaultValue=""
                            // inLineDivider={65}
                            // inLineLayout
                            onChangeCallback={streamedRegExpFilter => {
                                if (formShowStreamedPrices) {
                                    setStreamedRegExpFilter(streamedRegExpFilter);
                                }
                            }}
                        />
                    </div>
                </XpForm>
            </div>

            <div style={{height: "calc(100% - 44px)"}}>
                <XpGrid
                    gridId={"fixLog" + props.instanceId}
                    {...gridCallbacks}
                    {...gridOptions}
                    rowDataSelectorProps={{regexpSearchString: currentSearchStr}}
                    columnDefs={columnDefs}
                    callingContext={this}
                />
            </div>

        </div>

    )

}

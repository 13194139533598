import {ClientBaseStatic} from "features/client/formComponents/ClientBaseStatic";
import LegalEntityBaseStatic from "features/legalEntity/formComponents/LegalEntityBaseStatic";
import {LegalEntityUnitBaseStatic} from "features/legalEntityUnit/formComponents/LegalEntityUnitBaseStatic";
import LegalEntitiesList from "features/legalEntity/LegalEntitiesList";
import LegalEntityUnitsList from "features/legalEntityUnit/LegalEntityUnitsList";
import EntityBaseStaticFormComponent from "gui-common/xpAdminForm/EntityBaseStaticFormComponent";
import {getSpecificClientSelector, newClientFormTemplateSelector} from "features/client/clientSelectors";
import {
    getLegalEntityUnitSelector,
    newLegalEntityUnitFormTemplateSelector
} from "features/legalEntityUnit/legalEntityUnitSelectors";
import {
    getLegalEntitySelector,
    newLegalEntityFormTemplateSelector
} from "features/legalEntity/legalEntitySelectors";
import XpAdminFormUseChildEntitySelectors from "gui-common/xpAdminForm/XpAdminFormUseChildEntitySelectors";
import {canClientBeDisabled} from "features/client/clientFunctions";
import {canLegalEntityBeDisabled} from "features/legalEntity/legalEntityFunctions";
import {canLegalEntityUnitBeDisabled} from "features/legalEntityUnit/legalEntityUnitFunctions";
import {
    getReFixAgreementSelector,
    newListRateAgreementFormTemplateSelector
} from "features/agreement/agreementSelectors";
import {canAgreementBeDisabled} from "features/agreement/agreementFunctions";
import {AgreementBaseStatic} from "features/agreement/formComponents/AgreementBaseStatic";
import {ListRateAgreementStatic2} from "features/agreement/formComponents/ListRateAgreementStatic2";
import {ListRateGraceAndPriceType} from "features/agreement/formComponents/ListRateGraceAndPriceType";
import {AgreementOpenTimesStatic} from "features/agreement/formComponents/AgreementOpenTimesStatic";
import {MarketOrderTenors} from "features/agreement/formComponents/MarketOrderTenors";
import {AgreementsList} from "features/agreement/AgreementsList2";
import {LegalEntityUnitFxSettings} from "features/legalEntityUnit/formComponents/LegalEntityUnitFxSettings";
import {ClientApiChannelSettings} from "features/client/formComponents/ClientApiChannelSettings";
import {LegalEntityUnitRestTimeZone} from "features/legalEntityUnit/formComponents/LegalEntityUnitRestTimeZone";
import {
    getSpecificMarginGroupSelector,
    newMarginGroupFormTemplateSelector
} from "features/marginGroup/marginGroupSelectors";
import {MarginGroupBaseSettings} from "features/marginGroup/formComponents/MarginGroupBaseSettings";
import RunConfigurationsList from "gui-common/runConfiguration/RunConfigurationsList";
import {ReferenceRateAgreementStatic} from "features/agreement/formComponents/ReferenceRateAgreementStatic";
import {PriceFeedAgreementStatic} from "features/agreement/formComponents/PriceFeedAgreementStatic";
import {PriceFeedOnlySwitch} from "features/legalEntityUnit/formComponents/PriceFeedOnlySwitch";
import {ExemptFromSuspendForm} from "features/legalEntityUnit/formComponents/ExemptFromSuspendForm";
import {
    getSpecificPriceToleranceSelector,
    newPriceToleranceFormTemplateSelector
} from "features/priceTolerances/priceToleranceSelectors";
import {PriceTolerancesSettings} from "features/priceTolerances/formComponents/PriceTolerancesSettings";
import {ReferenceRateServiceActivation} from "features/legalEntityUnit/formComponents/ReferenceRateServiceActivation";
import {ListRateServiceSettings} from "features/legalEntityUnit/formComponents/ListRateServiceSettings";
import {MarketOrderServicesActivation} from "features/legalEntityUnit/formComponents/MarketOrderServicesActivation";
import {
    PriceTolerancesOverride
} from "features/agreement/formComponents/PriceTolerancesOverride";
import PriceTolerancesBase from "features/priceTolerances/formComponents/PriceTolerancesBase";

const getLegalEntityUnitStandardConfig = () => ({
    formTemplate: 'legalEntityUnitForm',
    getCurrentDataSelector: getLegalEntityUnitSelector,
    newFormTemplateSelector: newLegalEntityUnitFormTemplateSelector,
    checkBeforeDisableFunction: canLegalEntityUnitBeDisabled,
    subEntitySuffix: ' (BU)',
    componentMatrix: [
        [EntityBaseStaticFormComponent, PriceFeedOnlySwitch],
        [LegalEntityUnitFxSettings, LegalEntityUnitBaseStatic, ReferenceRateServiceActivation, MarketOrderServicesActivation],
        [LegalEntityUnitRestTimeZone, ListRateServiceSettings]
    ],
    childEntities: [
        {
            ormModel: 'Agreement',
            inParentFormProperty: undefined,
            arrayProperty: 'agreements',
            listComponent: AgreementsList,
            rowDataFilterFunction: (item, parentItemData) => {
                if (!item.legalEntityUnitId) return false;
                return (item.legalEntityUnitId === parentItemData.id);
            },
        },
    ],
    editBaseUsed: false,
})
const getAgreementStandardConfig = () => ({
    formTemplate: 'agreementForm',
    getCurrentDataSelector: getReFixAgreementSelector,
    newFormTemplateSelector: newListRateAgreementFormTemplateSelector,
    checkBeforeDisableFunction: canAgreementBeDisabled,
    componentMatrix: [
        [AgreementBaseStatic],
        [ListRateAgreementStatic2, MarketOrderTenors, ReferenceRateAgreementStatic],
        [ListRateGraceAndPriceType, AgreementOpenTimesStatic, PriceFeedAgreementStatic, PriceTolerancesOverride]
    ],
    childEntities: undefined,
    editBaseUsed: false,
})

export const getClientAdminFormConfig = () => ({
    Client: {
        formTemplate: 'clientForm',
        getCurrentDataSelector: getSpecificClientSelector,
        newFormTemplateSelector: newClientFormTemplateSelector,
        checkBeforeDisableFunction: canClientBeDisabled,
        subEntitySuffix: ' (CL)',
        componentMatrix: [
            [EntityBaseStaticFormComponent, XpAdminFormUseChildEntitySelectors],
            [ClientBaseStatic],
            [ClientApiChannelSettings]
        ],
        childEntities: [
            {
                ormModel: 'LegalEntity',
                inParentFormProperty: 'useLegalEntities',
                arrayProperty: 'legalEntities',
                listComponent: LegalEntitiesList,
                rowDataFilterFunction: (item, parentItemData) => {
                    if (!item.clientId) return false;
                    return (item.clientId === parentItemData.id);
                },
            },
        ],
        editBaseUsed: false,
    },
    LegalEntity: {
        formTemplate: 'legalEntityForm',
        getCurrentDataSelector: getLegalEntitySelector,
        newFormTemplateSelector: newLegalEntityFormTemplateSelector,
        checkBeforeDisableFunction: canLegalEntityBeDisabled,
        subEntitySuffix: ' (LE)',
        componentMatrix: [
            [EntityBaseStaticFormComponent, XpAdminFormUseChildEntitySelectors],
            [LegalEntityBaseStatic],
        ],
        childEntities: [
            {
                ormModel: 'LegalEntityUnit',
                inParentFormProperty: 'useLegalEntityUnits',
                arrayProperty: 'legalEntityUnits',
                listComponent: LegalEntityUnitsList,
                rowDataFilterFunction: (item, parentItemData) => {
                    if (!item.legalEntityId) return false;
                    return (item.legalEntityId === parentItemData.id);
                },
            },
        ],
        editBaseUsed: false,
    },
    LegalEntityUnit: getLegalEntityUnitStandardConfig(),
    Agreement: getAgreementStandardConfig()
})

const MarginGroupOverrideConfigurations    = (props) => <RunConfigurationsList type={'marginGroupOverride'} runConfigurationBasePath="" {...props}/>
export const getMarginGroupAdminFormConfig = () => ({
    MarginGroup: {
        formTemplate: 'marginGroupForm',
        getCurrentDataSelector : getSpecificMarginGroupSelector,
        newFormTemplateSelector: newMarginGroupFormTemplateSelector,
        checkBeforeDisableFunction: (marginGroup) => {
            if (marginGroup.numberOfLeus) return {
                canBeDisabled: false,
                modalKey: 'marginGroupForm.modalLeusConnected',
                modalParams: {}
            }
            if (marginGroup.numberOfAgreements) return {
                canBeDisabled: false,
                modalKey: 'marginGroupForm.modalAgreementsConnected',
                modalParams: {}
            }
            return {
                canBeDisabled: true,
                modalKey: 'marginGroupForm.modalConfirmDisable',
                modalParams: {name: marginGroup.name}
            }
        },
        componentMatrix:[
            [EntityBaseStaticFormComponent],
            [MarginGroupBaseSettings],
            [MarginGroupOverrideConfigurations],
        ],
        childEntities: undefined,
    }
})

export const getPriceToleranceAdminFormConfig = () => ({
    PriceTolerances: {
        formTemplate: 'priceTolerancesForm',
        getCurrentDataSelector : getSpecificPriceToleranceSelector,
        newFormTemplateSelector: newPriceToleranceFormTemplateSelector,
        checkBeforeDisableFunction: (priceTolerance) => ({
            canBeDisabled: true,
            modalKey: 'priceTolerancesForm.modalConfirmDisable',
            modalParams: {name: priceTolerance.name}
        }),
        componentMatrix:[
            [PriceTolerancesBase],
            [PriceTolerancesSettings]
        ],
        childEntities: undefined,
    }
});

export const getPriceToleranceAgreementAdminFormConfig = () => ({
    Agreement: getAgreementStandardConfig()
});
export const getMarginGroupAgreementAdminFormConfig = () => ({
    Agreement: getAgreementStandardConfig()
});

export const getMarginGroupLegalEntityUnitAdminFormConfig = () => ({
    LegalEntityUnit: getLegalEntityUnitStandardConfig(),
    Agreement: getAgreementStandardConfig()
});





import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {createListRateAgreementMenuFunction, getAgreementsColumns} from "./agreementFunctions";
import {
    createListRatesMenuFunction,
    userCanExpireListRates
} from "features/listRate/listRateFunctions";
import {INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {pushModalWindow} from "redux-promising-modals";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {userCanResumeAgreement, userCanSuspendAgreement} from "features/agreement/agreementFunctions";
import {userCanDispatchTrades} from "features/dispatchTrade/dispatchTradeFunctions";
import {ListRateList} from "features/listRate/ListRateList";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {DispatchTradeList} from "features/dispatchTrade/DispatchTradeList2";
import {ListRateOrdersList} from "features/listRateOrder/ListRateOrdersList";
import {getAgreementsListDataSelector} from "features/agreement/agreementSelectors";
import {useSelectorRef} from "gui-common/functions/hooks";
import {listRateProcessStatusSelector} from "features/listRate/listRateSelectors";
import {
    getDispatchTradesForAgreementSelector,
    getDispatchTradesForListRateSelector
} from "features/dispatchTrade/dispatchTradeSelectors";

const agreementsHiddenState = {
    id                  : false,
    typeT               : false,
    name                : false,
    currencyPairStr     : true,
    tenor               : false,
    exemptFromGlobalSuspendT : false,
    todayCutOffTime     : true,
    listRatePeriodT     : false,
    dispatchTradePeriodT: false,
    dispatchLevel       : false,
    buySellType         : false,
    meansOfPaymentT     : true,
    gracePeriodT        : true,
    openTime            : false,
    closeTime           : false,
    comment             : true,
    guiStatusT          : false,
    suspendedByUser     : true,
    suspendedDateTime   : true,
    suspendedComment    : true,
    resumedByUser       : true,
    resumedDateTime     : true,
    resumedComment      : true,
    clientId            : true,
    clientName          : false,
    legalEntityId       : true,
    legalEntityName     : true,
    legalEntityUnitId   : true,
    legalEntityUnitName : true,
    clientApiChannelT   : false,
    buyAmount           : false,
    sellAmount          : false,
    timeExpire          : false,
    nextDispatchTime    : true,
    createdDateTime     : true,
    createdByUser       : true,
    changedDateTime     : true,
    changedByUser       : true,
};
const listRateAgreementsHiddenStateInDashBoard = {
    ...agreementsHiddenState,
    tenor               : true,
    listRatePeriodT     : true,
    dispatchLevelT      : true,
    gracePeriodT        : true,
    openTime            : true,
    closeTime           : true,
    nextDispatchTimeT   : false,
    createdDateTimeT    : true,
};

const agreementTypesInDashBoard = ['ListRateAgreement'];
export function AgreementsListInDashBoard ({instanceId}) {
    return (
        <AgreementsList
            useStaticObject={false}
            instanceId={instanceId}
            fillAvailableSpace={true}
            suppressRowClickSelection={false}
            agreementsHiddenState={listRateAgreementsHiddenStateInDashBoard}
            agreementTypes={agreementTypesInDashBoard}
        />
    )
}

AgreementsList.detailIcon = 'assignment';
AgreementsList.detailIconToolTipKey = 'agreementsList.detailIconToolTipKey';
export function AgreementsList ({itemSelectedCallback, setEditCallback, setHeightCallback, inputData, useStaticObject, instanceId, fillAvailableSpace, suppressRowClickSelection, hiddenState, agreementTypes, gridId, rowDataSelectorProps}) {

    const requestingStateOnModelSelector = useMemo(()=>{
        return getRequestingStateOnModelSelector();
    }, [])

    const commentRequiredMap = useSelector(state => state.appEnvState.mandatoryListRateActionComment.agreement);

    const agreementRequestState         = useSelector(state => requestingStateOnModelSelector(state, {model: 'Agreement'}))
    const agreementRequestStateRef = useRef(agreementRequestState); // Needed to transport updated hook to callback scope.
    agreementRequestStateRef.current = agreementRequestState;

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const listRateProcessStatusRef = useSelectorRef(listRateProcessStatusSelector);

    const dispatch      = useDispatch();

    const gridCallbacks = {
        gridRowClicked          : useCallback(
            (params) => {
                if (suppressRowClickSelection) {
                    dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'agreementsList.cantSelectWhenInEditOrNew'}));
                }
            }, [suppressRowClickSelection]),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!itemSelectedCallback) return;
                itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                if (params.node.data.type !== 'ListRateAgreement') return [];
                let menuItems = [];

                const userCanSuspend  = userCanSuspendAgreement(params.node.data);
                const userCanResume   = userCanResumeAgreement( params.node.data);
                const userCanExpire   = userCanExpireListRates( params.node.data);
                const userCanDispatch = userCanDispatchTrades(  params.node.data);

                const createListRateAgreementMenuItem = (action) =>
                    createListRateAgreementMenuFunction([params.node.data], params.node.data.listRates, action, {id: params.node.data.id, type: 'ListRateAgreement'}, 'agreementsList.gridContextMenuItems', "", commentRequiredMap, listRateProcessStatusRef, translateRef, dispatch);
                const createListRateMenuItem = (action) =>
                    createListRatesMenuFunction(                                      params.node.data.listRates, action, {agreementId: params.node.data.id}                  , 'agreementsList.gridContextMenuItems', "", commentRequiredMap, listRateProcessStatusRef, translateRef, dispatch);

                if (userCanSuspend)  menuItems.push(createListRateAgreementMenuItem('suspend'));
                if (userCanResume)   menuItems.push(createListRateAgreementMenuItem('resume'));
                if (userCanExpire)   menuItems.push(createListRateMenuItem('expire'  ));
                if (userCanDispatch) menuItems.push(createListRateMenuItem('dispatch'));

                return menuItems;
            }, [translate]),
        isRowMaster             : useCallback((params) => {
            return (params.type === 'ListRateAgreement');
        }, []),
        rowClassRules: {
            'xp-grid-rejected-order-row' : function(params) { return (params.data.status    === "Suspended" )},
            'xp-grid-inactive-row'       : function(params) { return (params.data.guiStatus === "InActive" )},
        },
    };

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : instanceId,
                overlayNoRowsTemplate   : translate('agreementsList.noListRateAgreementsToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : getAgreementsListDataSelector,
                rowDataSelectorProps    : {inputData: inputData, agreementTypes: agreementTypes, ...rowDataSelectorProps},
                treeData                : false,
                masterDetail            : true,
                ormModel                : 'Agreement',
                xpDetailRendererProps   : {
                    availableComponents: [
                        {
                            detailType: 'listRates',
                            componentToRender: ListRateList,
                            propsToComponent: {
                                rowDataFilterFunction: (item, parentItem) => {
                                    return (parentItem.id === item.agreementId);
                                },
                            },
                        },
                        {
                            detailType: 'dispatchTrades',
                            componentToRender: DispatchTradeList,
                            propsToComponent: {
                                rowDataFilterFunction: (item, parentItem) => {
                                    return (item.agreementId === parentItem.id);
                                },
                            },
                        },
                        {
                            detailType: 'listRateOrders',
                            componentToRender: ListRateOrdersList,
                            propsToComponent: {
                                rowDataFilterFunction: (item, parentItemData) => {
                                    if (!item.agreementId) return false;
                                    return (item.agreementId === parentItemData.id);
                                },
                            },
                        },
                    ],
                    parentInstanceId: instanceId,
                },
            };
        },
        [translate, inputData, agreementTypes, rowDataSelectorProps, fillAvailableSpace]
    );
    const colDefs = useMemo(
        () => {
            return getAgreementsColumns(translate, hiddenState ? hiddenState : agreementsHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={gridId ? gridId : "listRateAgreementsGrid-" + instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={setHeightCallback}
                suppressRowClickSelection={suppressRowClickSelection} // This must be a direct propagation of prop since the memoized grid options will no be updated by this prop.
            >
            </XpGrid>
        </div>
    );
}

import React, {useState} from "react";
import {Navbar} from "react-bootstrap";
import XpTooltip from "gui-common/components/XpTooltip";
import ProcessStatusMenuPanel from "gui-common/processStatus/ProcessStatusMenuPanel";
import BankHolidayIndicator from "gui-common/bankHolidays/BankHolidayIndicator";
import ApiStatusIcon from "gui-common/api/ApiStatusIcon";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";
import {setAppEnvParameter} from "gui-common/app/appEnvReducer";
import {useDispatch} from "react-redux";


function XpMenuTitle (props) {
    const oxygenAppContext = useOxygenAppContext();
    const dispatch = useDispatch();
    const [activateDevState, setActivateDevState] = useState({state: 0});
    return (
        <Navbar.Brand>
            <div style={{verticalAlign: 'top'}}>
                <ApiStatusIcon/>
                <ProcessStatusMenuPanel/>
                <XpTooltip trKey='general.appBuildInfo' trParams={{build: window.oxygen_env.BUILD_NUMBER, branch: window.oxygen_env.BRANCH_NAME, commit: window.oxygen_env.COMMIT_HASH}}>
                    <img
                        src={oxygenAppContext.appConfig.appLogo}
                        alt={oxygenAppContext.appConfig.appName ? oxygenAppContext.appConfig.appName : "App logo"}
                        className="NFCLogo"
                        onDoubleClick={() => {
                            if (activateDevState.state === 2) {
                                dispatch(setAppEnvParameter('devInfoMode', true));
                                console.log("Activating dev info mode");
                                activateDevState.state = 0;
                                return;
                            }
                            if (activateDevState.state !== 0) {
                                activateDevState.state = 0;
                                return;
                            }
                            // console.log("Set 1");
                            activateDevState.state = 1;
                            setTimeout(() => {
                                // console.log("activationState", activateDevState.state);
                                if (activateDevState.state === 1) {
                                    activateDevState.state = 0;
                                    console.log("Timeout");
                                }}, 2000);
                        }}
                        onClick={() => {
                            if (activateDevState.state === 2) {
                                return;
                            }
                            if (activateDevState.state !== 1) {
                                activateDevState.state = 0;
                                return;
                            }
                            // console.log("Set 2");
                            activateDevState.state = 2;
                            setTimeout(() => {
                                if (activateDevState.state === 2) {
                                    activateDevState.state = 0;
                                    console.log("Timeout");
                                }}, 2000);
                        }}
                    />
                </XpTooltip>
                <div style={{display: 'inline-block', position: 'relative', top: '-2px'}}>
                    <div style={{display: 'inline-block', width: '35px', marginLeft: '5px'}}>
                        <BankHolidayIndicator/>
                    </div>
                </div>
            </div>
        </Navbar.Brand>
    )
}

XpMenuTitle.propTypes = {
};
XpMenuTitle.defaultProps = {
};

export default XpMenuTitle

import {createReducer} from 'gui-common/functions/reducer.js'
import moment from "moment";
import {webSocketStatus} from "gui-common/api/apiConstants";

export const API_SET_DATA_LOADING_TR_KEY = 'API_SET_DATA_LOADING_TR_KEY';
export const API_USER_LOGIN_SUCCESS      = 'API_USER_LOGIN_SUCCESS';
export const API_SET_APP_READY_STATE     = 'API_SET_APP_READY_STATE';
export const API_SET_SOCKETS_STATE       = 'API_SET_SOCKETS_STATE';

export const API_PUSH_AWT  = 'API_PUSH_AWT';
export const API_POP_AWT   = 'API_POP_AWT';

// Action creators ********************************************************
// ************************************************************************
export function apiSetLoginMessage(trKey) {
    return { type: API_SET_DATA_LOADING_TR_KEY, payload: trKey};
}
export function apiSetAppReadyState(readyState) {
    return { type: API_SET_APP_READY_STATE,  payload: readyState};
}
export function apiSetUserLoginSuccess(referenceId) {
    return { type: API_USER_LOGIN_SUCCESS,   payload: referenceId};
}
export function apiSetWebSocketsState(state) {
    return { type: API_SET_SOCKETS_STATE,   payload: state};
}

export function apiPushAwt(awt) {
    return {type: API_PUSH_AWT, payload: awt};
}
export function apiPopAwt() {
    return {type: API_POP_AWT};
}

/************ API reducer *************************************/
function setLoadingTrKey(state, payload) {
    return {...state, loadingTrKey: payload};
}
function loginSuccess(state, payload) {
    return {
        ...state,
        userSystemId: payload ? Number(payload) : "",
        loginTimestamp: moment(),
        userLoggedIn: true,
        loginReceived: true,
        appReady: true
    };
}
function setAppState(state, payload) {
    return {...state, appReady: payload};
}
function setSocketsState(state, payload) {
    return {...state, socketsState: payload};
}

const initialState = {
    loadingTrKey    : undefined,
    userLoggedIn    : false,
    loginReceived   : false,
    appReady        : false,
    socketsState    : webSocketStatus.DOWN,
};

export const apiReducer =  createReducer(initialState, {
    [API_SET_DATA_LOADING_TR_KEY]: setLoadingTrKey,
    [API_USER_LOGIN_SUCCESS]      : loginSuccess,
    [API_SET_APP_READY_STATE]     : setAppState,
    [API_SET_SOCKETS_STATE]       : setSocketsState,
});

/************ AWT reducer *************************************/

function pushAwt(state, payload) {
    if (!payload || !payload.length) return state;
    return state.concat(payload);
}
function popAwt(  state, payload) {
    return state.slice(1);
}
export const awtReducer = createReducer([], {
    [API_PUSH_AWT]   : pushAwt,
    [API_POP_AWT]    : popAwt,
});

import PropTypes from "prop-types";
import React, {useEffect, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import ShowValueBeforeChange from "gui-common/xpForm/ShowBeforeChangeValue";
import XpTooltip from "gui-common/components/XpTooltip";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    numberLargerThanMaxAmount, numberLowerThanMinAmount,
    stringContainAlpha,
    stringContainSpace,
    stringIsOnlySpaces
} from "gui-common/xpForm/formComponentsValidators";
import {formNumberFieldAdd} from "gui-common/xpForm/formNumberFieldsReducer";
import {XpFormErrors, xpFormGenerateError} from "gui-common/xpForm/core/XpFormErrors";
import {
    useXpFormContext, useXpFormFields,
    useXpFormFieldState,
    useXpFormModel
} from "gui-common/xpForm/core/xpFormHooks";
import XpFormFieldController from "gui-common/xpForm/core/XpFormFieldController";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {getFieldValueFromContext, getXpFormBeforeChangeValueSelector} from "gui-common/xpForm/core/xpFormSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {xpFormSetFieldErrors} from "gui-common/xpForm/core/xpFormReducer";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {formatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


const Label = React.memo(props =>  {

    const labelClassName = useMemo(
        () => {
            // return props.inLineLayout  ? "inlineLabelFlex" : "";
            return "xpFormLabel";
        },
        []
    );
    const labelKey = useMemo(
        () => {
            if (props.labelKey) return props.labelKey;
            const formTemplate = props.formTemplate ? props.formTemplate : props.formContext.formTemplate;
            return formTemplate + "." + props.field + '.label';
        },
        [props.formTemplate, props.formContext]
    );
    const placeBeforeTextUnderLabel = useMemo(
        () => {
            if (props.placeBeforeTextUnderLabel) return true;
            if (props.inLineLayout && !props.isCheckboxField) return true;
            return false;
        },
        []
    );
    const toolTipTrKey = useMemo(
        () => {
            if (props.toolTipTrKey) return props.toolTipTrKey;
            const formTemplate = props.formTemplate ? props.formTemplate : props.formContext.formTemplate;
            return formTemplate + "." + props.field + '.toolTip';
        },
        [props.formTemplate, props.formContext]
    );
    function labelContent() {
        return (
            <span>
                {props.label ? props.label : <XpTranslated trKey={labelKey} trParams={props.labelTrParams}/>}
            </span>
        )
    }
    if (props.noLabel) return null;

    return (
        <div style={props.inLineLayout ? {width: (100 - props.inLineDivider) + '%'} : undefined}>
            <XpTooltip trKey={toolTipTrKey} trParams={props.toolTipTrParams ? props.toolTipTrParams : undefined} bypass={props.noLabelToolTip}>
                {(props.isCheckboxField || props.isSwitchField || props.isToggleField) ?
                    <span> {labelContent()}</span>
                    :
                    <label className={labelClassName}> {labelContent()}</label>}
            </XpTooltip>
            {(() => {
                if (props.isToggleField) return (
                    <div className="xpFormToggleFieldBeforeChange">
                        <ShowValueBeforeChange valueBeforeChange={props.valueBeforeChange}/>
                    </div>
                )
                if (!placeBeforeTextUnderLabel) return (
                    <ShowValueBeforeChange valueBeforeChange={props.valueBeforeChange}/>
                )
                if (props.valueBeforeChange) return (
                    <div style={{lineHeight: '14px', zIndex:'1'}} className={props.inLineLayout ? "xpFormBeforeChangeUnderInlineLabel" : ""}>
                        <ShowValueBeforeChange valueBeforeChange={props.valueBeforeChange}/>
                    </div>
                )
                return null;
            })()}
        </div>
    )
})

function useCurrentValue(fieldModel, formContext) {
    return useMemo(
        () => {
            if (!formContext?.currentData) return undefined;
            return getFieldValueFromContext(fieldModel, formContext, 'currentData');
        },
        [formContext?.currentData]
    );
}

function InputField(props) {
    const currentValue = useCurrentValue(props.fieldModel, props.formContext);

    const currentValueDisplay = useMemo(
        () => {

            const val = (props.readOnly && props.fieldState) ? props.fieldState.fieldValue : currentValue;

            const renderValue = props.valueRenderer ? props.valueRenderer(val, props) : val;

            return <div className={(props.inLineLayout && props.alignRight) ? "xpFormValueTextAlignRightInner" : "xpFormValueTextInner"}>
                {renderValue}
            </div>
        },
        [props.inLineLayout, props.alignRight, currentValue, props.fieldState]
    );

    // const inputFieldClassName = (props.alignRight ? " xpFormInputAlignRight" : "") + (props.noLabel ? " xpFormInputNoLabel" : "") + (props.inTable ?  " xpFormInputTableCell" : "");
    const disabledClassName   = "valueText" + (props.alignRight ? " disabledFieldAlignRight" : "") + (props.noLabel ? " xpFormInputNoLabel" : "");

    return (
        <div className={(props.isCheckboxField && props.inTable) ? "xpFormCheckboxInTable" : ""}>
            {props.noLabel &&
            <div className={props.isCheckboxField ? 'xpFormBeforeChangeInCheckboxInputCell' : props.alignRight ? 'xpFormBeforeChangeInInputCell' : 'xpFormBeforeChangeInInputCellAlignRight'}>
                <ShowValueBeforeChange valueBeforeChange={props.valueBeforeChange}/>
            </div>}
            {((props.formContext.formUseState === XP_FORM_VIEW) || (props.readOnly)) ?
                (() => {
                    if (props.isCheckboxField || props.isSwitchField || props.isToggleField) return (
                        <XpFormFieldController
                            {...props}
                            // className   = {props.inputClassName ? props.inputClassName : inputFieldClassName}
                            readOnly
                            disabled
                            value       = {props.forceDisabledUncheck ? false : (props.readOnly && props.fieldState) ? props.fieldState.fieldValue : currentValue}
                        />
                    );
                    return (
                        <div className = {props.disabledClassName ? props.disabledClassName : disabledClassName}>
                            {currentValueDisplay}
                        </div>
                    );
                })()
                :
                <XpFormFieldController
                    {...props}
                    // className   = {props.inputClassName ? props.inputClassName : inputFieldClassName}
                    validateOn  = {props.validateOn ? props.validateOn : "change"}
                    updateOn    = {props.updateOn ? props.updateOn : "change"}
                />
            }
        </div>
    )
}

function useBeforeChangeValue(fieldModel, formContext, fieldState, props) {
    const beforeValue = useSelectorInstance(getXpFormBeforeChangeValueSelector, {fieldModel: fieldModel, formContext: formContext, fieldState: fieldState, beforeChangeFunction: props.beforeChangeFunction});
    return useMemo(
        () => {
            if (beforeValue === null) return null;
            return props.valueRenderer ? props.valueRenderer(beforeValue, {...props, formContext: formContext}) : beforeValue;
        },
        [beforeValue]
    );
}

function XpFormLabeledInputOldStyle (props) {
    const formContext       = useXpFormContext();
    const fieldModel        = useXpFormModel(props, formContext)

    const fieldState        = useXpFormFieldState(props, fieldModel, formContext, props.defaultValue)
    const fieldStateRef     = useRef(fieldState);
    fieldStateRef.current    = fieldState;

    const decDenLangState = useSelector(selectDecDenLangState);
    const valueBeforeChange = useBeforeChangeValue(fieldModel, formContext, fieldState, props)
    const dependentFields   = useXpFormFields(props);
    const dispatch          = useDispatch();

    const translate = useSelector(selectTranslateFunction);

    const dependentProps = useMemo(
        () => {
            if (!props.dependentPropsFn) return {}
            return props.dependentPropsFn(dependentFields);
        },
        [dependentFields, props.dependentPropsFn]
    );


    const errors = useMemo(
        () => {
            if (props.disableErrors) return undefined;
            let errors = {};
            if (props.isRequired)      errors.isRequired      = val => ((val === undefined) || (val === null) || (String(val).trim().length === 0));
            if (props.checkOnlySpaces) errors.checkOnlySpaces = stringIsOnlySpaces;
            if (props.checkNoSpaces)   errors.checkNoSpaces   = stringContainSpace;
            if (props.checkOnlyDigits) errors.checkOnlyDigits = stringContainAlpha;
            if (props.exactLength)     errors.exactLength     = val => val && val.length && (val.length !== props.exactLength);

            if (props.maxAmount !== undefined) errors.maxAmount = val => numberLargerThanMaxAmount(val, props.maxAmount, decDenLangState);
            if (props.minAmount !== undefined) errors.minAmount = val => numberLowerThanMinAmount( val, props.minAmount, decDenLangState);

            if (props.maxLength && !props.exactLength) errors.maxLength       = val => val && (val.length > props.maxLength);
            if (props.minLength && !props.exactLength) errors.minLength       = val => val && val.length && (val.length < props.minLength);

            errors = {...errors, ...props.errors}

            return (Object.keys(errors).length) ? errors : undefined;
        },
        [props.errors, decDenLangState]
    );
    const errorCheckValues = useMemo(
        () => {
            if (props.disableErrors || !errors) return undefined;
            let errorCheckValues = {};
            if (props.maxAmount !== undefined) errorCheckValues.maxAmount = formatAmount(props.maxAmount, decDenLangState);
            if (props.minAmount !== undefined) errorCheckValues.minAmount = formatAmount(props.minAmount, decDenLangState);

            return (Object.keys(errorCheckValues).length) ? errorCheckValues : undefined;
        },
        [errors, decDenLangState]
    );
    const lineClassName = useMemo(
        () => {
            if (props.className) return props.className;
            return "xpFormFieldLine" + (props.inLineLayout ? " xpFormFieldInline" : "") + (props.noLabel ? " xpFormFieldLineNoBottomMargin" : "");
        },
        []
    );

    useEffect(
        () => {
            if (!fieldModel) return;
            if (props.isAmountField) dispatch(formNumberFieldAdd(fieldModel, true)); // subscribe for language changes.
        }, [fieldModel],
    );

    // The only purpose of this effect is to clear the field errors when the component is removed. Otherwise submit will be stopped...
    useEffect(
        () => {
            return () => {
                if (!fieldStateRef.current || !fieldStateRef.current.errors) return;
                dispatch(xpFormSetFieldErrors(fieldModel, undefined));
            }
        },
        []
    );

    const errorTooltip = useMemo(
        () => {
            if (!props.inCellError) return;
            if (!fieldState?.errors) return;
            return Object.keys(fieldState.errors).map((errorKey, index) => xpFormGenerateError(errorKey, fieldState.errors[errorKey], index, {...props, ...errorCheckValues, formContext: formContext}, translate));
        },
        [fieldState?.errors]
    );

    const highlightChangeClass = ((((formContext.formUseState === XP_FORM_VIEW) && formContext.auditMode) || props.readOnly) && (valueBeforeChange !== null)) ? " highlightChangedFormField" : "";

    const highlightErrorClass = errorTooltip ? " highlightErrorInFormFieldInGrid" : "";

    if (!formContext || !fieldModel) return null;

    const lineDivProps = {
        className: lineClassName + highlightChangeClass + highlightErrorClass,
        style    : {margin: props.noMargin ? '0' : undefined}
    }

    function renderLabel() {
        return (<Label {...props} {...dependentProps} valueBeforeChange={valueBeforeChange} formContext={formContext}/>)
    }

    function renderInput() {
        return (
            <XpTooltip
                bypass={!errorTooltip}
                title={errorTooltip}
                // title={componentIsVisible ? errorToolTip : undefined}
                // trKey={(differingValueBeforeChange && !activeError && componentIsVisible) ? 'general.gridFieldChanged' : undefined}
            >
                <div  style={(props.inLineLayout && !props.isCheckboxField && !props.isSwitchField && !props.isToggleField && !props.noLabel) ? {width: props.inLineDivider + '%'} : undefined}>
                    <InputField
                        {...props}
                        dependentProps      = {dependentProps}
                        errors              = {errors}
                        valueBeforeChange   = {valueBeforeChange}
                        fieldState          = {fieldState}
                        formContext         = {formContext}
                        fieldModel          = {fieldModel}
                        dependentFields     = {dependentFields}
                        label               = {((props.isCheckboxField || props.isSwitchField || props.isToggleField) && !props.inTable) ? renderLabel() : undefined}
                    />
                    {(errors && fieldState) && <XpFormErrors {...{...props, ...errorCheckValues, errors: (props.disabled ? undefined : errors), fieldState: fieldState, formContext: formContext, fieldModel: fieldModel, dependentFields: dependentFields}}/>}
                </div>
            </XpTooltip>
        )
    }


    // NB: Label is rendered by Material UI component.
    if (props.isCheckboxField || props.isSwitchField || props.isToggleField) return (
        <div {...lineDivProps} xp-test-id={"formInput-"+props.field}>
            {renderInput()}
        </div>
    )

    return (
        <div {...lineDivProps} xp-test-id={"formInput-"+props.field}>
            {renderLabel()}
            {renderInput()}
        </div>
    );
}







const LabelNewStyle = React.memo(props =>  {

    const labelKey = useMemo(
        () => {
            if (props.labelKey) return props.labelKey;
            const formTemplate = props.formTemplate ? props.formTemplate : props.formContext.formTemplate;
            return formTemplate + "." + props.field + '.label';
        },
        [props.formTemplate, props.formContext]
    );

    const toolTipTrKey = useMemo(
        () => {
            if (props.toolTipTrKey) return props.toolTipTrKey;
            const formTemplate = props.formTemplate ? props.formTemplate : props.formContext.formTemplate;
            return formTemplate + "." + props.field + '.toolTip';
        },
        [props.formTemplate, props.formContext]
    );
    if (props.noLabel) return null;

    return (
        <div>
            <XpTooltip trKey={toolTipTrKey} trParams={props.toolTipTrParams ? props.toolTipTrParams : undefined} bypass={props.noLabelToolTip}>
                <span>
                    {props.label ? props.label : <XpTranslated trKey={labelKey} trParams={props.labelTrParams}/>}
                </span>
            </XpTooltip>
            {(() => {
                if (props.isToggleField) return (
                    <div className="xpFormToggleFieldBeforeChange">
                        <ShowValueBeforeChange valueBeforeChange={props.valueBeforeChange}/>
                    </div>
                )
                if (!props.placeBeforeTextUnderLabel) return (
                    <ShowValueBeforeChange valueBeforeChange={props.valueBeforeChange}/>
                )
                if (props.valueBeforeChange) return (
                    <div style={{lineHeight: '14px', zIndex:'1'}}>
                        <ShowValueBeforeChange valueBeforeChange={props.valueBeforeChange}/>
                    </div>
                )
                return null;
            })()}
        </div>
    )
})

function InputFieldNewStyle(props) {
    const currentValue = useCurrentValue(props.fieldModel, props.formContext);

    const currentValueEnriched = useMemo(
        () => {
            const val = (props.readOnly && props.fieldState) ? props.fieldState.fieldValue : currentValue;
            if (typeof val === 'boolean') return val; // Must skip rendering function for switch and checkbox
            if (props.isSelectField) return val;

            const renderValue = props.valueRenderer ? props.valueRenderer(val, props) : val;
            return renderValue;
        },
        [props.inLineLayout, props.alignRight, currentValue, props.fieldState]
    );

    return (
        <div className={(props.isCheckboxField && props.inTable) ? "xpFormCheckboxInTable" : ""}>
            {props.noLabel &&
            <div className={props.isCheckboxField ? 'xpFormBeforeChangeInCheckboxInputCell' : props.alignRight ? 'xpFormBeforeChangeInInputCell' : 'xpFormBeforeChangeInInputCellAlignRight'}>
                <ShowValueBeforeChange valueBeforeChange={props.valueBeforeChange}/>
            </div>}
            {((props.formContext.formUseState === XP_FORM_VIEW) || (props.readOnly)) ?
                <XpFormFieldController
                    {...props}
                    // className   = {props.inputClassName ? props.inputClassName : inputFieldClassName}
                    readOnly
                    disabled
                    value       = {props.forceDisabledUncheck ? false : currentValueEnriched}
                />
                :
                <XpFormFieldController
                    {...props}
                    // className   = {props.inputClassName ? props.inputClassName : inputFieldClassName}
                    variant     = "filled"
                    validateOn  = {props.validateOn ? props.validateOn : "change"}
                    updateOn    = {props.updateOn ? props.updateOn : "change"}
                />
            }
        </div>
    )
}
function XpFormLabeledInputNewStyle (props) {
    const formContext       = useXpFormContext();
    const fieldModel        = useXpFormModel(props, formContext)
    const noFormValidation = useAppEnvProperty('noFormValidation');

    const fieldState        = useXpFormFieldState(props, fieldModel, formContext, props.defaultValue)
    const fieldStateRef     = useRef(fieldState);
    fieldStateRef.current    = fieldState;

    const decDenLangState = useSelector(selectDecDenLangState);
    const valueBeforeChange = useBeforeChangeValue(fieldModel, formContext, fieldState, props)
    const dependentFields   = useXpFormFields(props);
    const dispatch          = useDispatch();

    const translate = useSelector(selectTranslateFunction);

    const dependentProps = useMemo(
        () => {
            if (!props.dependentPropsFn) return {}
            return props.dependentPropsFn(dependentFields);
        },
        [dependentFields, props.dependentPropsFn]
    );


    const errors = useMemo(
        () => {
            if (props.disableErrors) return undefined;
            let errors = {};
            if (props.isRequired)      errors.isRequired      = val => ((val === undefined) || (val === null) || (String(val).trim().length === 0));
            if (props.checkOnlySpaces) errors.checkOnlySpaces = stringIsOnlySpaces;
            if (props.checkNoSpaces)   errors.checkNoSpaces   = stringContainSpace;
            if (props.checkOnlyDigits) errors.checkOnlyDigits = stringContainAlpha;
            if (props.exactLength)     errors.exactLength     = val => val && val.length && (val.length !== props.exactLength);

            if (props.maxAmount !== undefined) errors.maxAmount = val => numberLargerThanMaxAmount(val, props.maxAmount, decDenLangState);
            if (props.minAmount !== undefined) errors.minAmount = val => numberLowerThanMinAmount( val, props.minAmount, decDenLangState);

            if (props.maxLength && !props.exactLength) errors.maxLength       = val => val && (val.length > props.maxLength);
            if (props.minLength && !props.exactLength) errors.minLength       = val => val && val.length && (val.length < props.minLength);

            errors = {...errors, ...props.errors}

            return (Object.keys(errors).length) ? errors : undefined;
        },
        [props.errors, decDenLangState]
    );
    const errorCheckValues = useMemo(
        () => {
            if (props.disableErrors || !errors) return undefined;
            let errorCheckValues = {};
            if (props.maxAmount !== undefined) errorCheckValues.maxAmount = formatAmount(props.maxAmount, decDenLangState);
            if (props.minAmount !== undefined) errorCheckValues.minAmount = formatAmount(props.minAmount, decDenLangState);

            return (Object.keys(errorCheckValues).length) ? errorCheckValues : undefined;
        },
        [errors, decDenLangState]
    );
    const lineClassName = useMemo(
        () => {
            if (props.className) return props.className;
            return "xpFormFieldLine" + (props.noLabel ? " xpFormFieldLineNoBottomMargin" : "");
        },
        []
    );

    useEffect(
        () => {
            if (!fieldModel) return;
            if (props.isAmountField) dispatch(formNumberFieldAdd(fieldModel, true)); // subscribe for language changes.
        }, [fieldModel],
    );

    // The only purpose of this effect is to clear the field errors when the component is removed. Otherwise submit will be stopped...
    useEffect(
        () => {
            return () => {
                if (!fieldStateRef.current || !fieldStateRef.current.errors) return;
                dispatch(xpFormSetFieldErrors(fieldModel, undefined));
            }
        },
        []
    );

    const errorString = useMemo(
        () => {

            if (props.disableErrors) return;
            if (noFormValidation) return;
            if (!fieldState?.errors) return;
            if (!fieldState.isTouched) return;
            if (props.inCellError) return;

            return Object.keys(fieldState.errors).map((errorKey, index) => xpFormGenerateError(errorKey, fieldState.errors[errorKey], index, {...props, ...errorCheckValues, formContext: formContext}, translate));
        },
        [fieldState?.errors, fieldState?.isTouched]
    );

    const errorTooltip = useMemo(
        () => {
            if (!props.inCellError) return;
            if (!errorString) return;
            return errorString;
        },
        [errorString]
    );

    const highlightChangeClass = ((((formContext.formUseState === XP_FORM_VIEW) && formContext.auditMode) || props.readOnly) && (valueBeforeChange !== null)) ? " highlightChangedFormField" : "";

    const highlightErrorClass = errorTooltip ? " highlightErrorInFormFieldInGrid" : "";

    if (!formContext || !fieldModel) return null;

    const lineDivProps = {
        className: lineClassName + highlightChangeClass + highlightErrorClass,
        style    : {margin: props.noMargin ? '0' : undefined}
    }

    function renderInput() {
        return (
            <XpTooltip
                bypass={!errorTooltip}
                title={errorTooltip}
            >
                <div>
                    <InputFieldNewStyle
                        {...props}
                        dependentProps      = {dependentProps}
                        errors              = {errors}
                        helperText          = {errorString ? errorString : undefined}
                        valueBeforeChange   = {valueBeforeChange}
                        fieldState          = {fieldState}
                        formContext         = {formContext}
                        fieldModel          = {fieldModel}
                        dependentFields     = {dependentFields}
                        label               = {props.inTable ? undefined : <LabelNewStyle {...props} {...dependentProps} valueBeforeChange={valueBeforeChange} formContext={formContext}/>}
                    />
                    {(errors && fieldState) && <XpFormErrors {...{...props, ...errorCheckValues, errors: (props.disabled ? undefined : errors), fieldState: fieldState, formContext: formContext, fieldModel: fieldModel, dependentFields: dependentFields}}/>}
                </div>
            </XpTooltip>
        )
    }

    return (
        <div {...lineDivProps} xp-test-id={"formInput-"+props.field}>
            {renderInput()}
        </div>
    )
}

function XpFormLabeledInput(props) {
    const formContext       = useXpFormContext();

    if (props.isDatePickerField) {
        return (<XpFormLabeledInputOldStyle {...props}/>);
    }

    return (formContext.useMuiFieldStyle ?
        <XpFormLabeledInputNewStyle {...props}/>
        :
        <XpFormLabeledInputOldStyle {...props}/>
    )
}

const fieldOrModelRequired = (props, propName, componentName) => {
    if (!props.field && !props.fieldModel)                          {return new Error(`One of 'field' or 'fieldModel' is required by '${componentName}' component.`)}
    if ( props.field &&  props.fieldModel)                          {return new Error(`Only one of 'field' or 'fieldModel' may be defined in props to '${componentName}' component.`)}
    if (props.field && (typeof props.field !== 'string'))           {return new Error(`Field field must be of type string in props to '${componentName}' component.`)}
    if (props.fieldModel && (typeof props.fieldModel !== 'string')) {return new Error(`Field field must be of type string in props to '${componentName}' component.`)}
}
export const xpFormBasePropTypes = {
    field     : fieldOrModelRequired,
    fieldModel: fieldOrModelRequired,

    template:       PropTypes.string,
    noTemplate:     PropTypes.bool,
    showTemplateInView: PropTypes.bool,

    defaultValue:   PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),

    formTemplate:   PropTypes.string,
    label:          PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelKey:       PropTypes.string,
    labelTrParams:  PropTypes.object,
    noLabel:        PropTypes.bool,
    noLabelToolTip: PropTypes.bool,

    dependentPropsFn: PropTypes.func,
    controlProps:   PropTypes.object,
    dynamicControlProps:   PropTypes.func,
    updateOn:       PropTypes.string,

    dependentData: PropTypes.object,
    dependentFields: PropTypes.object,
    dependentFieldModels: PropTypes.object,

    onChangeThunk: PropTypes.func,
    onChangeCallback: PropTypes.func,

    className:      PropTypes.string,
    // inputClassName: PropTypes.string,

    alignRight:     PropTypes.bool,
    inLineLayout:   PropTypes.bool,
    inLineDivider:  PropTypes.number,
    inTable:        PropTypes.bool,

    isCheckboxField: PropTypes.bool,
    isRadioField   : PropTypes.bool,
    isSwitchField  : PropTypes.bool,
    isToggleField  : PropTypes.bool,
    isDatePickerField: PropTypes.bool,

    readOnly:       PropTypes.bool,
    disabled:       PropTypes.bool,
    autoFocus:      PropTypes.bool,
    validateOn:     PropTypes.string,

    disableErrors:  PropTypes.bool,
    errors:         PropTypes.object,
    errorMessages:  PropTypes.object,
    inCellError:    PropTypes.bool,

    isRequired:     PropTypes.bool,
    minLength:      PropTypes.number,
    maxLength:      PropTypes.number,
    minAmount:      PropTypes.number,
    maxAmount:      PropTypes.number,
    exactLength:    PropTypes.number,
    checkOnlyDigits:PropTypes.bool,
    checkNoSpaces:  PropTypes.bool,

    toolTipTrKey:   PropTypes.string,
    toolTipTrParams:PropTypes.object,

    parser:         PropTypes.func,
    placeBeforeTextUnderLabel: PropTypes.bool,

    tabIndex:       PropTypes.number,
};
const defaultProps = {
    validateOn:     "change",
    errors:         {},
    errorMessages:  {},
    inLineDivider:  50,
};
XpFormLabeledInput.propTypes    = xpFormBasePropTypes;
XpFormLabeledInput.defaultProps = defaultProps;

export default XpFormLabeledInput





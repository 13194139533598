import React, {useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {getUserSelector} from "gui-common/orm/ormSelectors"
import 'gui-common/userRights/adminUserRights.css'
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import UserList from "gui-common/user/UserList";
import AdminSystemRights, {AuditUserSystemRights} from "gui-common/userRights/AdminSystemRights";
import AdminDataRights from "gui-common/userRights/AdminDataRights";
import {AuditUserDataRights} from "gui-common/userRights/AdminDataRightsCollection";
import {SetViewCheckbox} from "gui-common/userRights/SetViewCheckbox";

export const rightsUserListInstanceId = "rightsUserList";

export function AdminUser(props) {
    const devMode   = useSelector(state => state.appEnvState.devMode);

    const userRightsConfig = useSelector( state => selectAppEnvProperty(state, 'userRightsConfig'));

    const [simpleMode    , setSimpleMode]     = useState(userRightsConfig.simpleAssignment);
    const [compressedMode, setCompressedMode] = useState(true);

    const user = props.selectedUser;
    if (!user && !props.auditMode) return null;

    return (
        <div>
            <div>
                {devMode &&
                <div>
                    <SetViewCheckbox
                        viewState={simpleMode}
                        setState={setSimpleMode}
                        label='Simple mode'
                    />
                    {(!simpleMode && !props.auditMode) && <SetViewCheckbox
                        viewState={compressedMode}
                        setState={setCompressedMode}
                        label='Compressed Mode'
                    />}
                </div>}

                {props.auditMode ?
                    <AuditUserSystemRights
                        currentData      = {props.currentData.systemRights}
                        beforeChangeData = {props.beforeChangeData.systemRights}
                        hideSystemRights = {simpleMode}
                    />
                    :
                    <AdminSystemRights
                        user             = {user}
                        hideSystemRights = {simpleMode}
                        readOnly         = {false}
                    />
                }
                {props.auditMode ?
                    <AuditUserDataRights
                        currentData         = {props.currentData.dataRightsCollection}
                        beforeChangeData    = {props.beforeChangeData.dataRightsCollection}
                        auditMode           = {true}
                        simpleMode          = {simpleMode}
                        compressedMode      = {false}
                        readOnly            = {true}
                    />
                    :
                    <AdminDataRights
                        user             = {user}
                        simpleMode       = {simpleMode}
                        compressedMode   = {compressedMode}
                    />}
            </div>
        </div>
    );
}


function AdminUsers(props) {

    const userGridState = useSelector(state => selectMyXpGridState(state, {instanceId: rightsUserListInstanceId}));
    const selectedUserId = userGridState ? userGridState.selectedId : undefined;

    const userSelector = useMemo(() => getUserSelector()    , []);
    const selectedUser = useSelector(state => userSelector(state, {userId: selectedUserId}));

    return (
        <div>
            <h2><XpTranslated trKey='adminUserRights.header'/></h2>
            <UserList
                // itemSelectedCallback = {(id) => userSelected(id)}
                instanceId           = {rightsUserListInstanceId}
            />
            <hr/>
            {selectedUserId ?
                <div>
                    <AdminUser
                        selectedUser = {selectedUser}
                    />
                </div>
                :
                <h4><XpTranslated trKey='adminUserRights.noUserSelected'/></h4>
            }
        </div>
    );
}

export default withRouter(AdminUsers);


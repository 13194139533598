import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {getClientHasActiveListRatesSelector} from "features/listRate/listRateSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";
import XpTooltip from "gui-common/components/XpTooltip";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";

export function ClientApiChannelSettings (props) {
    const formContext = useXpFormContext();
    const apiChannels = useAppEnvProperty('availableClientApiChannels');
    const embeddedLeuExemptFromGlobalSuspend = useXpFormField('legalEntities.0.legalEntityUnits.0.exemptFromGlobalSuspend')
    const useLe = useXpFormField('useLegalEntities')
    const embeddedLeUseLeu = useXpFormField('legalEntities.0.useLegalEntityUnits')
    const noFormValidation = useAppEnvProperty( 'noFormValidation');
    const clientHasActiveListRates = useSelectorInstance(getClientHasActiveListRatesSelector, {clientId: props.currentData?.id});

    const anyLeuExemptFromSuspend = useMemo(
        () => {
            if (noFormValidation) {
                return false;
            }
            if (!useLe && !embeddedLeUseLeu && embeddedLeuExemptFromGlobalSuspend !== undefined) {
                return embeddedLeuExemptFromGlobalSuspend;
            }
            return anyLeuInCurrentExemptFromSuspend(props.currentData);
        },
        [props.currentData, embeddedLeuExemptFromGlobalSuspend]
    );

    const disableApiChannelSelector = useMemo(
        () => {
            if (noFormValidation) {
                return false;
            }
            return clientHasActiveListRates;
        },
        [noFormValidation, clientHasActiveListRates]
    );



    return (
        <div className={props.className}>
            <XpTooltip bypass={!disableApiChannelSelector || (formContext.formUseState !== XP_FORM_EDIT)} trKey={'clientForm.clientApiChannel.disableToolTip'} >
                <div>
                    <XpFormSelectorInput
                        isRequired inLineLayout noTemplate
                        field="clientApiChannel"
                        selectList={apiChannels}
                        itemTextField="name"
                        defaultValue={apiChannels[0].id}
                        disabled={disableApiChannelSelector}
                        errors={{
                            exemptLeuFromSuspend: (val) => {
                                if (val === "INTERNAL") {
                                    return false;
                                }
                                return anyLeuExemptFromSuspend;
                            },
                        }}
                    />
                </div>
            </XpTooltip>
        </div>
    );
}

ClientApiChannelSettings.propTypes = {
    className:        PropTypes.string,
};

function anyLeuInCurrentExemptFromSuspend(client) {
    if (!client?.legalEntities?.length) {
        return false;
    }
    for (const le of client.legalEntities) {
        if (!le.legalEntityUnits?.length) {
            continue;
        }
        for (const leu of le.legalEntityUnits) {
            if (leu.exemptFromGlobalSuspend) {
                return true;
            }
        }
    }
    return false;
}

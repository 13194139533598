import React, {Component} from "react";
import {connect, useDispatch} from "react-redux";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import {xpGridSetDetailComponent} from "gui-common/xpGrid/xpGridsReducer";
import MaterialIcon from "material-icons-react";
import XpTooltip from "gui-common/components/XpTooltip";
import {calculateNewGridHeight} from "gui-common/xpGrid/xpGridFunctions";


function DetailTypeIcon ({component, detailType, parentInstanceId, setSelectedDetailType, isSelected}) {
    const dispatch  = useDispatch();
    return (
        <div
            className={"xpGridDetailIconSelected" + (isSelected ? "" : " xpGridDetailIconNotSelected")}
            onClick={isSelected ? undefined : () => {
                dispatch(xpGridSetDetailComponent(parentInstanceId, detailType));
                setSelectedDetailType(detailType);
            }}
        >
            <XpTooltip trKey={component.detailIconToolTipKey + (isSelected ? '-active' : '')}>
                <span className="icon-i"><MaterialIcon icon={component.detailIcon} size={16} invert/></span>
            </XpTooltip>
        </div>
    )
}


function getRenderingState(xpDetailRendererProps, detailType) {
    const availableComponents = xpDetailRendererProps.availableComponents;
    if (!availableComponents || !availableComponents.length) return {};

    const renderComponentData = ((availableComponents.length === 1) || !detailType) ? availableComponents[0] : availableComponents.find(item => item.detailType === detailType);
    if (!renderComponentData) return {};

    return {
        availableComponents     : availableComponents,
        instanceId              : xpDetailRendererProps.parentInstanceId + '-detail-' + renderComponentData.detailType,
        selectedDetailType      : renderComponentData.detailType,
        xpDetailRendererProps   : xpDetailRendererProps,
        componentToRender       : renderComponentData.componentToRender,
        propsToComponent        : renderComponentData.propsToComponent,
        // The height must be large enough to display detailType icons.
        minHeight               : availableComponents ? availableComponents.length * 27 : 0,
    };
}



class XpGridDetailCellRenderer extends Component {
    constructor(props) {
        super(props);
        const defaultHeight = this.props.xpDetailRendererProps.availableComponents ? this.props.xpDetailRendererProps.availableComponents.length * 27 : 40;
        this.state = {
            ...getRenderingState(this.props.xpDetailRendererProps, this.props.gridState ? this.props.gridState.detailType : undefined),
            myHeight: defaultHeight, // Just default. Will be changed from detail component height in setHeightCallback.
        };
    }
    refresh(params) {
        this.setState(getRenderingState(params.context.xpDetailRendererProps, this.state.selectedDetailType));
        return true;
    }

    render () {
        if (!this.state.componentToRender) return null;

        const filterFunction = this.state.propsToComponent.rowDataFilterFunction ? (item) => this.state.propsToComponent.rowDataFilterFunction(item, this.props.data) : undefined;
        const rowData        = this.state.propsToComponent.getRowDataFunction ? this.state.propsToComponent.getRowDataFunction(this.props.data) : undefined;
        const rowDataSelectorProps = this.state.propsToComponent.getRowDataSelectorProps ? this.state.propsToComponent.getRowDataSelectorProps(this.props.data) : undefined;
        const propsToComponent = this.state.propsToComponent.getPropsToComponent ? this.state.propsToComponent.getPropsToComponent(this.props.data) : undefined;

        const detailLineHeight = this.state.myHeight - this.state.minHeight - 30;

        return (
            <div style={{height: this.state.myHeight +'px'}} className="xpGridDetailContainer">
                <div className="xpGridDetailSelector">
                    {this.state.availableComponents.map(item => {return (
                        <div key={item.detailType}>
                            <DetailTypeIcon
                                component={item.componentToRender}
                                detailType={item.detailType}
                                parentInstanceId={this.props.xpDetailRendererProps.parentInstanceId}
                                setSelectedDetailType={type => {
                                    this.setState(getRenderingState(this.state.xpDetailRendererProps, type))
                                }}
                                isSelected={this.state.selectedDetailType === item.detailType}
                            />
                        </div>
                    )})}
                    {(detailLineHeight > 50) && <div className="xpGridDetailLine" style={{height: detailLineHeight +'px'}}><div className="xpGridDetailLineFill"/></div>}
                </div>
                <div className="xpGridDetailComponent">
                    <this.state.componentToRender
                        {...{...this.state.propsToComponent, ...propsToComponent, rowDataSelectorProps: {...this.state.propsToComponent.rowDataSelectorProps , ...rowDataSelectorProps, filterFunction: filterFunction, parentId: this.props.data.id, parentModel: this.props.context.ormModel}}}
                        fillAvailableSpace={false}
                        instanceId = {this.state.instanceId}
                        gridId     = {this.state.instanceId + '-' + this.props.data.id}
                        rowData    = {rowData}

                        setHeightCallback={newHeight => {
                            // console.log("setHeightCallback in detail renderer", this.state.xpDetailRendererProps.parentInstanceId, newHeight);

                            const setHeight = Math.max(this.state.minHeight, newHeight) + 40;
                            this.setState({myHeight: setHeight})

                            if (setHeight === this.props.node.rowHeight) {
                                // console.log("detail row height not changed", this.props.node.rowHeight);
                                return;
                            }

                            this.props.node.setRowHeight(setHeight);
                            this.props.api.onRowHeightChanged();

                            if (this.props.context.setHeightCallback) {
                                const newGridHeight =
                                    (this.props.xpDetailRendererProps.managedHeightStateRef?.current) ?
                                        this.props.xpDetailRendererProps.heightStateRef.current
                                        :
                                        calculateNewGridHeight(
                                            this.props.api.columnController.columnDefs,
                                            this.props.api.rowModel.rowsToDisplay,
                                            this.props.xpDetailRendererProps.maxRowsToDisplay
                                        );
                                this.props.context.setHeightCallback(newGridHeight)
                            }
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        gridState:  selectMyXpGridState(state, {instanceId: props.xpDetailRendererProps.parentInstanceId}),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        xpGridSetDetailComponent: (instanceId, detailType) => dispatch(xpGridSetDetailComponent(instanceId, detailType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridDetailCellRenderer);

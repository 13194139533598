import {ormFunctionMap} from "gui-common/api/ormSocketMethodMaps";
import {commonWebSocketEvents} from "gui-common/api/webSocketConfig";

/* -----------------------------------------------------------------------------------------------------------------
* Socket message dictionaries for models and message types specific to the application. Common messages in gui-common
* -----------------------------------------------------------------------------------------------------------------*/
export const refixWebSocketEvents = {
    ...commonWebSocketEvents,
    'CLIENT'                : 'se.nordicfc.refix.common.model.event.legal.ShallowClient',
    'LEGAL_ENTITY'          : 'se.nordicfc.refix.common.model.event.legal.ShallowLegalEntity',
    'LEGAL_ENTITY_UNIT'     : 'se.nordicfc.refix.common.model.event.legal.ShallowLegalEntityUnit',
    'AGREEMENT'             : 'se.nordicfc.refix.api.model.agreement.RefixApiShallowAgreement',
    'LIST_RATE'             : 'se.nordicfc.refix.api.model.listrate.RefixApiShallowListRate',
    'LIST_RATE_ORDER'       : 'se.nordicfc.refix.common.model.event.listrate.ShallowListRateOrder',
    'DISPATCH_TRADE'        : 'se.nordicfc.refix.api.model.listrate.RefixApiShallowDispatchTrade',
    'MARKET_ORDER'          : 'se.nordicfc.refix.api.model.marketorder.RefixApiMarketOrder',
    'PRICE_LEU'             : 'se.nordicfc.refix.common.model.event.legal.PriceEventLegalEntityUnit',
    'MARGIN_GROUP'          : 'se.nordicfc.refix.api.model.indicativerates.RefixApiPriceMarginGroup',
    'PRICE_TOLERANCE'       : 'se.nordicfc.refix.api.model.listrate.RefixApiPriceToleranceConfiguration',
    'LIST_RATE_ORDER_RESULT': 'se.nordicfc.refix.common.model.result.ListRateOrderResult',
}
export const refixProcessWebSocketEventMap = {
    [refixWebSocketEvents.CLIENT]                   : { batchPossible: false, model: 'Client'               , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LEGAL_ENTITY]             : { batchPossible: false, model: 'LegalEntity'          , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LEGAL_ENTITY_UNIT]        : { batchPossible: false, model: 'LegalEntityUnit'      , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.AGREEMENT]                : { batchPossible: false, model: 'Agreement'            , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LIST_RATE]                : { batchPossible: true , model: 'ListRate'             , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LIST_RATE_ORDER]          : { batchPossible: true , model: 'ListRateOrder'        , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.DISPATCH_TRADE]           : { batchPossible: true , model: 'DispatchTrade'        , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.MARKET_ORDER]             : { batchPossible: true , model: 'MarketOrder'          , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.PRICE_LEU]                : { batchPossible: true , model: 'LegalEntityUnit'      , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.MARGIN_GROUP]             : { batchPossible: true , model: 'MarginGroup'          , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.PRICE_TOLERANCE]          : { batchPossible: true , model: 'PriceTolerances'      , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LIST_RATE_ORDER_RESULT]   : { batchPossible: true , model: 'ListRateOrderResult'  , functionMap: ormFunctionMap, logMessage: false },
};
export const refixFilterWebSocketEventMap = {}

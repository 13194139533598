import {apiPopAwt, apiPushAwt} from "gui-common/api/apiReducers";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {selectNextAwtToken} from "gui-common/api/apiSelectors";
import {nonAuthorizedFetch} from "gui-common/api/apiCommon";
import {globalApiHandle} from "gui-common/api/apiConstants";

/* -----------------------------------------------------------------------------------------------------------------
* Functions for logging out user including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function logoutUserFromApi(rerouteUrl) {
    return (dispatch, getState) => {
        const redirectUrl = rerouteUrl ? rerouteUrl : selectAppEnvProperty(getState(), 'logoutUrl');
        window.location.replace(redirectUrl);
    }
}

export function apiTransformIncoming(item, model, getState, params) {
    const transformer = globalApiHandle.incomingTransformersMap[model];
    return transformer ? transformer(item, {...params, getState: getState}) : undefined;
}

export function apiTransformOutgoing(item, model, getState, params) {
    const transformer = globalApiHandle.incomingTransformersMap[model];
    return transformer ? transformer(item, {...params, getState: getState}) : undefined;
}

export function apiGetAwtToken(dispatch, state) {
    const key = selectNextAwtToken(state);
    if (!key) return null;
    dispatch(apiPopAwt());
    return key;
}

export function getFirstAwtBatchFromApi(initialLoginKeys) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get first AWT batch";
            let requestUrl =
                getState().appEnvState.baseUrl +
                globalApiHandle.baseUrls.signIn + "?" +
                'key=' + initialLoginKeys.key +
                '&sessionId=' + initialLoginKeys.sessionId +
                '&referenceId=' + initialLoginKeys.referenceId;

            dispatch(nonAuthorizedFetch(
                requestUrl,
                logText,
                (resp) => (dispatch, getState) => dispatch(apiPushAwt(resp)),
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                'POST',
                undefined, // no body in http request needed for GET.
                undefined
            )).then(result => {
                resolve(result)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

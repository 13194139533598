import {savedCurrencyPairConfigSelector} from "gui-common/currencyPairConfig/currencyPairConfigSelectors";
import {getUsersToAdminDataSelector} from "gui-common/user/userSelectors";
import {AuditUserSystemRights} from "gui-common/userRights/AdminSystemRights";
import {AuditUserDataRights} from "gui-common/userRights/AdminDataRightsCollection";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {bankHolidayAdminFormConfig} from "gui-common/xpAdminForm/xpAdminFormConfig";
import CurrencyPairConfigType from "gui-common/currencyPairConfig/CurrencyPairConfigType";
import {activeAuditActionTypesSelector} from "gui-common/audit/auditSelectors";
import FxRejectConfigType from "gui-common/fxRejectConfig/fxRejectConfigType";
import {AdminUser} from "gui-common/userRights/AdminUserRights";
import AuditBulkBankHolidays from "gui-common/bankHolidays/AuditBulkBankHolidays";
import {getHistoricAuditConfig} from "gui-common/audit/auditFunctions";

export const auditOperationTypes = [
    "Create",
    "Update",
    "Delete"
]
const commonAuditPrototypeMap = {
    'se.nordicfc.common.security.authorisation.model.audits.v1.UserRightsAudit'             : { model: 'User', rootObjectSortOrder: 10},
    'se.nordicfc.common.security.authorisation.model.audits.v1.DataRightsCollectionAudit'   : { model: 'DataRightsCollection' },
    'se.nordicfc.common.security.authorisation.model.audits.v1.SystemRightsAudit'           : { model: 'UserSystemRight' },

    'se.nordicfc.common.auditlog.model.audit.v1.UserActionAudit'                            : { model: 'AuditUserActionType', rootObjectSortOrder: 20 },

    'se.nordicfc.xpool.common.model.audits.v1.RootBankHolidayAudit'                         : { model: 'RootBankHoliday', rootObjectSortOrder: 30 },
    'se.nordicfc.xpool.common.model.audits.v1.BankHolidayAudit'                             : { model: 'BankHoliday'},
    'se.nordicfc.xpool.common.model.audits.v1.BankHolidayBulkAudit'                         : { model: 'BankHolidayBulkAudit'},

    'se.nordicfc.common.fxrejectconfiguration.model.audits.v1.FxRejectConfigurationAudit'   : { model: 'FxRejectConfiguration', rootObjectSortOrder: 40 },
    'se.nordicfc.common.currencypairsettings.model.audit.v1.CurrencyPairSetting'            : { model: 'CurrencyPairConfig', rootObjectSortOrder: 50},

/*
    'se.nordicfc.common.userdatarights.model.audit.v1.UserDataRights'                       : { model: 'UserDataRights'},
    'se.nordicfc.common.entitydataright.audit.v1.EntityDataRight'                           : { model: 'EntityDataRight' },
    'se.nordicfc.common.systemrights.audit.v1.EntityDataRight'                              : { model: 'SystemRights' },
*/

};
const commonAuditModelsFromRootModel = {
    "RootBankHoliday"      : ["BankHoliday", "RootBankHoliday", "BankHolidayBulkAudit"],
    // "BankHoliday"          : ["BankHoliday"],
    "FxRejectConfiguration": ["FxRejectConfiguration"],
    "AuditUserActionType"  : ["AuditUserActionType"],
}
const commonExcludeFields = ['createdDateTime', 'changedDateTime', 'disabledDateTime', 'disabledByUser', 'createdByUser', 'changedByUser']
const usersToAuditSelector = getUsersToAdminDataSelector();
const commonAuditConfig = {
    CurrencyPairConfig: {
        getRenderComponent: () => CurrencyPairConfigType,
        renderProps     : {instanceId: 'currencyPairConfigAudit', configType: 'audit'},
        isRoot          : true,
        rootProps       : {
            showBefore    : true,
            selector      : savedCurrencyPairConfigSelector,
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    FxRejectConfiguration: {
        getRenderComponent: () => FxRejectConfigType,
        renderProps     : {instanceId: 'fxRejectConfigAudit', configType: 'audit'},
        isRoot          : true,
        rootProps       : {
            showBefore    : true,
            selector      : undefined, // Should not be possible to select specific fx reject config
            selectorProps : undefined,
            hideRootObjectSelector: true,
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_3_2', 'API_2_0'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    RootBankHoliday: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'bankHolidayAudit', xpAdminFormConfig: bankHolidayAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : false,
            selector      : undefined, // Should not be possible to select specific bank holiday
            selectorProps : undefined,
            hideRootObjectSelector: true,
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    BankHoliday: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'bankHolidayAudit', xpAdminFormConfig: bankHolidayAdminFormConfig},
        isRoot            : false,
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields, 'startDateTime', 'endDateTime'],
                skipParentLevels: ['bankHolidays'],
            }
        ]),
    },
    BankHolidayBulkAudit: {
        getRenderComponent: () => AuditBulkBankHolidays,
        renderProps       : {},
        isRoot            : false,
        overrideAndUseRoot: true,
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    User: {
        getRenderComponent: () => AdminUser,
        renderProps     : {instanceId: 'userAudit'},
        isRoot          : true,
        // getNameFn       : item => item.firstName + ' ' + item.lastName,
        rootProps       : {
            showBefore    : true,
            selector      : usersToAuditSelector,
            selectorProps : {},
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    DataRightsCollection: {
        getRenderComponent: () => AuditUserDataRights,
        renderProps     : {},
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    UserSystemRight: {
        getRenderComponent: () => AuditUserSystemRights,
        renderProps     : {instanceId: 'userSystemRightsAudit'},
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    AuditUserActionType: {
        getRenderComponent: () => AuditUserDataRights,
        renderProps     : {},
        isRoot          : true,
        rootProps       : {
            showBefore    : false,
            selector      : activeAuditActionTypesSelector,
            selectorProps : {},
            getTrKey      : name => 'auditUserActionTypes.' + name + '.label'
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'RF_V30'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
};

export let auditConfig = {};
export let auditPrototypeMap = {};
export let auditModelsFromRootModel = {};
export let auditRootModelFromModelMap = {};
export let auditOrmToPrototypeMap = {};

export function setAuditConfig(appConfig) {
    auditConfig              = {...commonAuditConfig                , ...appConfig.audit?.modelConfig};
    auditPrototypeMap        = {...commonAuditPrototypeMap          , ...appConfig.audit?.prototypeMap};
    auditModelsFromRootModel = {...commonAuditModelsFromRootModel   , ...appConfig.audit?.modelsFromRootModels};
    auditRootModelFromModelMap = (() => {
        const retObj = {};
        Object.keys(auditModelsFromRootModel).forEach(key => {
            const auditModels = auditModelsFromRootModel[key];
            auditModels.forEach(item => retObj[item] = key);
        });
        return retObj;
    })();
    auditOrmToPrototypeMap = (() => {
        const retObj = {};
        Object.keys(auditPrototypeMap).forEach(key => retObj[auditPrototypeMap[key].model] = key);
        return retObj;
    })();
}

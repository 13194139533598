import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {allowAll, domainRightAllowed} from "gui-common/userRights/userRightsFunctions";
import {
    dispatchTradeSides,
    dispatchTradeStates,
    tradeReportStates
} from "features/dispatchTrade/dispatchTradeConstants";
import {useStore} from "react-redux";
import {getOrmItemSelectorGetter} from "gui-common/orm/ormSelectors";

export function prepareDispatchOrdersColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id'                , 100 , 100 , 'dispatchTradesList.id', {cellRenderer: 'agGroupCellRenderer', sort: 'desc', lockVisible: true, lockPosition: true});
    xpColCreate(translate, subArray, hiddenState, 'counterparty'      , 100, 50 , 'dispatchTradesList.counterparty');
    xpColCreate(translate, subArray, hiddenState, 'legalEntityUnitName', 100, 100, 'agreementsList.legalEntityUnitName');
    xpColCreate(translate, subArray, hiddenState, 'legalEntityUnitId'  , 50 , 50 , 'agreementsList.legalEntityUnitId');
    xpColCreate(translate, subArray, hiddenState, 'displaySideT'             , 50 , 50 , 'dispatchTradesList.side',
        {
            cellRenderer: 'xpTranslatedRenderer',
            cellRendererParams: {
                filterTrKeys: dispatchTradeSides.map(item => 'dispatchTradesList.sideTypes.' + item)
            },
            filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
        });
    xpColCreate(translate, subArray, hiddenState, 'rate'              , 50 , 50 , 'dispatchTradesList.rate');
    xpColCreate(translate, subArray, hiddenState, 'numberOfOrders'    , 100, 50 , 'dispatchTradesList.numberOfOrders', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'amount'            , 100, 50 , 'dispatchTradesList.amount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'amountCurrencyT'   , 50 , 50 , 'dispatchTradesList.amountCurrency');
    xpColCreate(translate, subArray, hiddenState, 'listRateId'        , 50 , 50 , 'dispatchTradesList.listRateId');
    xpColCreate(translate, subArray, hiddenState, 'currencyPairStr'   , 100, 50 , 'agreementsList.currencyPairStr');
    xpColCreate(translate, subArray, hiddenState, 'tenor'             , 50 , 50 , 'agreementsList.tenor');
    xpColCreate(translate, subArray, hiddenState, 'quoteCurrency'     , 50 , 50 , 'dispatchTradesList.quoteCurrency');
    xpColCreate(translate, subArray, hiddenState, 'settlementDate'    , 100, 50 , 'dispatchTradesList.settlementDate');
    xpColCreate(translate, subArray, hiddenState, 'clientSpotRate'    , 50 , 50 , 'dispatchTradesList.clientSpotRate');
    xpColCreate(translate, subArray, hiddenState, 'refSpotRate'       , 50 , 50 , 'dispatchTradesList.refSpotRate');
    xpColCreate(translate, subArray, hiddenState, 'ibSpotRate'        , 50 , 50 , 'dispatchTradesList.ibSpotRate');
    xpColCreate(translate, subArray, hiddenState, 'spotMargin'        , 50 , 50 , 'dispatchTradesList.spotMargin');
    xpColCreate(translate, subArray, hiddenState, 'refFwdPoints'      , 50 , 50 , 'dispatchTradesList.refFwdPoints');
    xpColCreate(translate, subArray, hiddenState, 'ibFwdPoints'       , 50 , 50 , 'dispatchTradesList.ibFwdPoints');
    xpColCreate(translate, subArray, hiddenState, 'riskPremium'       , 50 , 50 , 'dispatchTradesList.riskPremium');
    xpColCreate(translate, subArray, hiddenState, 'clientFwdPoints'   , 50 , 50 , 'dispatchTradesList.clientFwdPoints');
    xpColCreate(translate, subArray, hiddenState, 'fwdPointsMargin'   , 50 , 50 , 'dispatchTradesList.fwdPointsMargin');
    xpColCreate(translate, subArray, hiddenState, 'fwdPointsDiff'     , 50 , 50 , 'dispatchTradesList.fwdPointsDiff');
    xpColCreate(translate, subArray, hiddenState, 'refSpotRateRPpts'  , 50 , 50 , 'dispatchTradesList.refSpotRateRPpts');
    xpColCreate(translate, subArray, hiddenState, 'statusT'           , 200, 100, 'dispatchTradesList.status',
        {
            cellRenderer: 'xpTranslatedRenderer',
            cellRendererParams: {
                filterTrKeys: dispatchTradeStates.map(item => 'dispatchTradesList.statusTypes.' + item)
            },
            filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true},
            cellStyle: (params) => {
                if (params.data.status === "SentToBook") return {background: 'rgba(250,255,152,0.5)'};
                if (params.data.status === "Timeout")    return {background: 'rgba(255,150,130,0.5)'};
                return {background: 'none'};
            }
        });
    xpColCreate(translate, subArray, hiddenState, 'tradeReportStateT'           , 200, 100, 'dispatchTradesList.tradeReportState',
        {
            cellRenderer: 'xpTranslatedRenderer',
            cellRendererParams: {
                filterTrKeys: tradeReportStates.map(item => 'dispatchTradesList.tradeReportStateTypes.' + item)
            },
            filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true},
            cellStyle: (params) => {
                if (["Sent", "Processing"].includes(params.data.tradeReportState)) return {background: 'rgba(250,255,152,0.5)'};
                if (["Failed", "Timeout" ].includes(params.data.tradeReportState)) return {background: 'rgba(255,150,130,0.5)'};
                return {background: 'none'};
            }
        });
    xpColCreate(translate, subArray, hiddenState, 'bookingReference'  , 200, 100, 'dispatchTradesList.bookingReference');
    xpColCreate(translate, subArray, hiddenState, 'bookingReferenceOrigin', 100, 50, 'dispatchTradesList.bookingReferenceOrigin');
    xpColCreate(translate, subArray, hiddenState, 'bookedByUser'      , 200, 100, 'dispatchTradesList.bookedByUser', {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'bookingComment'    , 200, 100, 'dispatchTradesList.bookingComment', {tooltipField: 'bookingComment'});
    xpColCreate(translate, subArray, hiddenState, 'tradeTime'         , 200, 100, 'dispatchTradesList.tradeTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});

    return subArray;
}

export function userCanDispatchListRate(agreement) {
    if (allowAll) return true;
    if (!agreement) return false;
    return domainRightAllowed('ListRate', agreement,'Dispatch');
}
export function userCanDispatchTrades(parentEntity) {
    if (allowAll) return true;
    if (!parentEntity) return false;
    return domainRightAllowed('ListRate'    , parentEntity, 'Dispatch');
}
export function userCanConfirmDispatchTrade(agreement) {
    if (allowAll) return true;
    if (!agreement) return false;
    return domainRightAllowed('DispatchTrade', agreement,'ManualConfirm');
}

export function useListRateSelectorFunction() {
    const reduxStore = useStore();
    const selector = getOrmItemSelectorGetter('ListRate')();

    return (selectId) => {
        const reduxState = reduxStore.getState();
        const listRateModel = selector(reduxState, {selectId: selectId});

        if (!listRateModel) {
            return undefined
        }
        return listRateModel.ref;
    }
}

import {genericFetch}                                   from "gui-common/api/apiCommon";
import {reFixAgreementsSelector} from "features/agreement/agreementSelectors";
import {entityStateRequestedBulk} from "gui-common/requestEntityState/requestEntityStateReducer";
import {addRequestStateIfExpected, getRequestStatesOnListRates} from "features/listRate/listRateApi";
import {globalApiHandle} from "gui-common/api/apiConstants";

const actionToUrlMap = {
    'suspend': 'suspend',
    'resume' : 'resume',
}

function agreementMatchFilter(item, filterParams) {
    if (!filterParams) return true;
    if (filterParams.type && (item.type !== filterParams.type)) return false;
    if (filterParams.id && (item.id !== filterParams.id)) return false;
    if (filterParams.currencyId && (item.currencyPair.baseCurrency.id !== filterParams.currencyId) && (item.currencyPair.quoteCurrency.id !== filterParams.currencyId)) return false;
    if (filterParams.legalEntityUnitId && (item.legalEntityUnitId !== filterParams.legalEntityUnitId)) return false;
    return true;
}

function getAffectedAgreements(action, filterParams, getState) {
    let agreements = reFixAgreementsSelector(getState())

    let affectedAgreements = [];

    // Derive affected agreements based on filter parameters
    for (let agreement of agreements) {
        if (agreement.exemptFromGlobalSuspend) {
            continue;
        }
        if (!agreementMatchFilter(agreement, filterParams)) {
            continue;
        }
        affectedAgreements.push(agreement);
    }
    return affectedAgreements;
}

function setRequestedStateOnAgreements(action, agreements, dispatch) {
    let stateRequestArray = [];

    for (let agreement of agreements) {
        addRequestStateIfExpected('Agreement', agreement, action, stateRequestArray);
        if (!agreement.listRates) continue;
        stateRequestArray = stateRequestArray.concat(getRequestStatesOnListRates(action, agreement.listRates));
    }
    dispatch(entityStateRequestedBulk(stateRequestArray));
}

export function enrichUrlAndLogWithId(url, log, filter) {
    if (!filter || !filter.id) return [url, log];
    let returnUrl = url;
    let returnLog = log;
    returnUrl += filter.id + '/';
    returnLog += '. Id: ' + filter.id;
    return [returnUrl, returnLog];
}
export function enrichUrlAndLogWithParameter(url, log, filter, ulrName, propName) {
    if (!filter || !filter[propName]) return [url, log];
    let returnUrl = url;
    let returnLog = log;
    returnUrl += ulrName + '=' + filter[propName] + '&';
    returnLog += '. ' + ulrName + ': ' + filter[propName];
    return [returnUrl, returnLog];
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to operate with actions on Agreements with filter parameters.
* -----------------------------------------------------------------------------------------------------------------*/
export function putAgreementActionToApi(action, filterParams, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!action) {
                console.error("No action in putAgreementActionToApi");
                return;
            }
            if (!actionToUrlMap[action]) {
                console.error("Unsupported action in putAgreementActionToApi", action);
                return;
            }

            let logText = "Put agreement action :" + actionToUrlMap[action];
            let requestUrl = globalApiHandle.baseUrls.Agreement + '/';

            [requestUrl, logText] = enrichUrlAndLogWithId(requestUrl, logText, filterParams)

            requestUrl += actionToUrlMap[action] + '?';

            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'agreement-type'        , 'type');
            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'currency-id'           , 'currencyId');
            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'legal-entity-unit-id'  , 'legalEntityUnitId');

            dispatch(genericFetch(
                requestUrl,
                logText,
                responseHandler(action, filterParams),
                failHandler(action, filterParams),
                failHandler(action, filterParams),
                'PUT',
                {comment: comment},
            )).then(result => {
                resolve(result)
            }).catch(err => {
                // reject(err)
            })
        })
    }
}


function responseHandler(action, filterParams) {
    return (resp) => {
        return (dispatch, getState) => {
            let affectedAgreement = getAffectedAgreements(action, filterParams, getState)
            setRequestedStateOnAgreements(action, affectedAgreement, dispatch);
        }
    }
}
function failHandler(action, filterParams) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}

import {registerOrmModels} from "gui-common/orm/ormModels";
import refixLogo from "appConfig/refixLogo.png";
import RefixClientWizardPage from "features/refixClientWizard/RefixClientWizardPage";
import MarginGroupsPage from "features/marginGroup/MarginGroupsPage";
import ClientSettingsPage from "features/client/ClientSettingsPage";
import WelcomeScreen from "features/welcomeScreen/WelcomeScreen";
import {
    refixNestedOrmEntities,
    refixOrmModelCompareFunctions,
    refixOrmModelParentProperties,
    refixOrmModels
} from "appConfig/orm/ormConfig";
import {refixModals} from "appConfig/appModals";
import {
    refixAuditModelConfig,
    refixAuditModelsFromRootModel,
    refixAuditPrototypeMap
} from "appConfig/audit/auditConfig";
import {refixDashboardWidgets} from "appConfig/dashboardConfig";
import {refixReportingDataSetTypes} from "appConfig/report/appReportConstants";
import {
    fixAccountsSelector,
    marketSimulatorActiveConfigurationsSelector,
    simulatorAvailableTenorsSelector
} from "appConfig/simulatorsSelectors";
import {refixWizards} from "appConfig/wizardConfig";
import {refixLeftMenuItems, RefixSettingsMenu} from "appConfig/appMenuConfig";
import {shallowActiveClientsSelector} from "features/client/clientSelectors";
import {refixApiConfig} from "appConfig/api/apiConfig";
import {refixMaterialUiOverrides} from "appConfig/materialUiOverrides";
import {getRefixEnvParams} from "appConfig/appEnvConfig";
import {refixRunConfigurations} from "appConfig/runConfiguration/runConfigurationConstants";
import RefixApp from "appConfig/RefixApp";
import PriceTolerancesPage from "../features/priceTolerances/PriceTolerancesPage";
import {refixProcessStatusConfig, refixProcessStatusPackageConfig} from "appConfig/processStatusConfig";
import {activeCurrencyPairsSelector} from "gui-common/orm/ormSelectors";

export const refixAppConfig = {
    appLogo: refixLogo,
    appName: "restfx",
    useContextMenu: true,
    useFxRejectConfig: true,
    useCurrencyPairSettings: true,
    useLedger: false,

    useBankHolidays: true,
    useNoRunDates: true,

    useFx: true,
    styling: {
        muiStylingOverrides: refixMaterialUiOverrides,
    },
    orm: {
        models: refixOrmModels,
        nestedEntities: refixNestedOrmEntities,
        parentProperties: refixOrmModelParentProperties,
        compareFunction: refixOrmModelCompareFunctions,
        onUpdateThunks: undefined,
    },
    api: refixApiConfig,
    lang: {
        files: [
            require('appConfig/appSpecificLang.json'),
            require('features/legalEntity/legalEntityLang.json'),
            require('features/legalEntityUnit/legalEntityUnitLang.json'),
            require('features/client/clientLang.json'),
            require('features/listRate/listRateLang.json'),
            require('features/currencyExposure/currencyExposureLang'),
            require('features/dispatchTrade/dispatchTradeLang'),
            require('features/listRateOrder/listRateOrderLang'),
            require('features/listRateOrderAllocation/listRateOrderAllocationLang.json'),
            require('features/agreement/agreementLang'),
            require('appConfig/report/appReportLang.json'),
            require('features/marketOrder/marketOrderLang'),
            require('features/refixClientWizard/refixClientWizardLang'),
            require('features/welcomeScreen/welcomeScreenLang.json'),
            require('features/marginGroup/marginGroupLang.json'),
            require('features/priceTolerances/priceTolerancesLang.json'),
            require('appConfig/runConfiguration/runConfigurationLang.json'),
        ],
        skipCommon: false,
    },
    userRights: {
        modelToPrototypeMap: {
            OperatingUnit           : ["OperatingUnitEntity"],
            Client                  : ["ClientEntity"],
            LegalEntity             : ["LegalEntityEntity"],
            LegalEntityUnit         : ["LegalEntityUnitEntity"],
            Agreement               : ["ListRateAgreementEntity", "MarketOrderAgreementEntity", "MarketOrderAllAgreementEntity", "ReferenceRateAgreementEntity", "PriceFeedAgreementEntity"],
            ListRate                : ["ListRateEntity"],
            DispatchTrade           : ["DispatchTradeEntity"],
            ListRateOrder           : ["ListRateOrderEntity"],
        }
    },
    userSettings: {
        saveTimer: 5000,
        savedUserStates: undefined,
        widgets: []
    },
    audit: {
        modelConfig: refixAuditModelConfig,
        prototypeMap: refixAuditPrototypeMap,
        modelsFromRootModels: refixAuditModelsFromRootModel
    },
    runConfiguration: refixRunConfigurations,
    dashboard: {
        widgets: refixDashboardWidgets,
    },
    report: {
        dataSetTypes: refixReportingDataSetTypes,
    },
    wizard: {
        wizards: refixWizards,
    },
    simulators: {
        marketSimulator: {
            baseUrl: '/test-market/v1/',
            fixAccountsSelector: fixAccountsSelector,
            activeConfigurationsSelector: marketSimulatorActiveConfigurationsSelector,
            availableCurrencyPairsSelector: activeCurrencyPairsSelector,
            availableTenorsSelector: simulatorAvailableTenorsSelector,
        }
    },
    userMessages: {
        gotoClientThunks: undefined,
        clientsSelector: shallowActiveClientsSelector,
    },
    modals: refixModals,
    mainMenu: {
        leftMenuItems: refixLeftMenuItems,
        rightMenuItems: [],
        SettingsMenu: RefixSettingsMenu,
        devMenuItems: [],
    },
    routerPaths: [
        {path: "/welcome"          , component: WelcomeScreen},
        // {path: "/refixClientWizard", component: RefixClientWizardPage},
        {path: "/marginGroupConfig", component: MarginGroupsPage},
        {path: "/priceToleranceConfig", component: PriceTolerancesPage},
        {path: "/clientSettings"   , component: ClientSettingsPage},
    ],
    processStatus: {
        processConfig: refixProcessStatusConfig,
        packageConfig: refixProcessStatusPackageConfig
    },
    erp: undefined,
    reducers : {},
    envParams: getRefixEnvParams(),
    appComponent: RefixApp,
}
registerOrmModels(refixAppConfig);





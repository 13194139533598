import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {allowAll, domainRightAllowed} from "gui-common/userRights/userRightsFunctions";
import {listRateStates} from "features/listRate/listRateConstants";
import {CONFIRMATION_DIALOG, CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {putListRateActionToApi} from "features/listRate/listRateApi";
import {isArray} from "lodash";

export function getListRatesColumns(translate, hiddenState) {
    let subArray = [];
    xpColCreate(translate, subArray, hiddenState, 'id'                  , 150, 100, 'listRatesList.id', {cellRenderer: 'agGroupCellRenderer', cellStyle: {textAlign: "right"}, lockVisible: true, lockPosition: true});
    xpColCreate(translate, subArray, hiddenState, 'currencyPairStr'     , 100, 40 , 'agreementsList.currencyPairStr');
    xpColCreate(translate, subArray, hiddenState, 'legalEntityUnitName' , 100, 40 , 'agreementsList.legalEntityUnitName');
    xpColCreate(translate, subArray, hiddenState,'clientApiChannelT'    , 100, 50, 'clientForm.clientApiChannel.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState,'exemptFromGlobalSuspendT', 50, 50, 'legalEntityUnitForm.exemptFromGlobalSuspend.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'timeIssued'          , 200, 100, 'listRatesList.timeIssued'       , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState, 'buyPrice'            , 100, 40 , 'listRatesList.buyPrice'         , {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'sellPrice'           , 100, 40 , 'listRatesList.sellPrice'        , {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'totalBuyAmount'      , 200, 80 , 'listRatesList.totalBuyAmount'   , {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'totalSellAmount'     , 200, 80 , 'listRatesList.totalSellAmount'  , {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'nextDispatchTime'    , 200, 100, 'listRatesList.nextDispatchTime' , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState, 'tenor'               , 100, 40 , 'agreementsList.tenor');
    xpColCreate(translate, subArray, hiddenState, 'listRatePeriodT'     , 100, 40 , 'agreementsList.listRatePeriod');
    xpColCreate(translate, subArray, hiddenState, 'dispatchTradePeriodT', 100, 40 , 'agreementsList.dispatchTradePeriod');
    xpColCreate(translate, subArray, hiddenState, 'statusT'             , 100, 40 , 'listRatesList.status',
        {
            cellRenderer: 'xpTranslatedRenderer',
            cellRendererParams: {
                filterTrKeys: listRateStates.map(item => 'listRatesList.statusTypes.' + item)
            },
            filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true},
            cellStyle: (params) => {
                if (params.data.status === "InExpiry") return {background: 'rgba(250,255,152,0.5)'};
                return {background: 'none'};
            }
        });
    xpColCreate(translate, subArray, hiddenState, 'dispatchLevel'      , 200, 40 , 'agreementsList.dispatchLevel', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'buySellType'        , 100, 80 , 'agreementsList.buySellType');
    xpColCreate(translate, subArray, hiddenState, 'gracePeriodT'        , 200, 40 , 'agreementsList.gracePeriod', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'buyAmount'          , 200, 80 , 'listRatesList.buyAmount'   , {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'sellAmount'         , 200, 80 , 'listRatesList.sellAmount'  , {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'timeExpire'          , 200, 100, 'listRatesList.timeExpire', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState, 'originalExpire'      , 200, 100, 'listRatesList.originalExpire', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState, 'expireReason'        , 200, 50 , 'listRatesList.expireReason');
    xpColCreate(translate, subArray, hiddenState, 'expiredByUserId'     , 200, 50 , 'listRatesList.expiredByUserId', {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'expireComment'       , 200, 100, 'listRatesList.expireComment', {tooltipField: 'expireComment'});
    return subArray;
}

export function userCanCloseListRate(agreement) {
    if (allowAll) return true;
    if (!agreement) return false;
    return domainRightAllowed('ListRate'    , agreement, 'Close');
}
export function userCanExpireListRate(agreement) {
    if (allowAll) return true;
    if (!agreement) return false;
    return domainRightAllowed('ListRate'    , agreement, 'Expire');
}
export function userCanExpireListRates(parentEntity) {
    if (allowAll) return true;
    if (!parentEntity) return false;
    return domainRightAllowed('ListRate'    , parentEntity, 'Expire');
}

export function getActiveListRatesWithOwnerProcessDown(listRatesArrayOrObject, processStatus) {
    const listRates = isArray(listRatesArrayOrObject) ? listRatesArrayOrObject : [listRatesArrayOrObject];
    return listRates.filter(listRate => (listRate.state !== "Expired") && (!processStatus || !processStatus[listRate.listRateConfig.listRateProcessId] || !processStatus[listRate.listRateConfig.listRateProcessId].active) );
}

export function createListRatesMenuFunction(listRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, translateRef, dispatch) {
    const affectedListRates = getAffectedListRates(listRates, action);
    return {
        name: translateRef.current(trKeyBase + "." + action + ".menuItem", {count: affectedListRates.length, name: parentName}),
        action: () => setTimeout(() => launchListRatesAction(listRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, dispatch), 50),
        context: {dispatch: dispatch},
        disabled: affectedListRates.length === 0,
    }
}


export function launchListRatesAction(listRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, dispatch) {

    const affectedListRates= getAffectedListRates(listRates, action);
    const listRatesWithProcessDown      = getActiveListRatesWithOwnerProcessDown(affectedListRates, listRateProcessStatusRef.current);
    const listRatesWithProcessDownCount = listRatesWithProcessDown?.length;

    function launchModal() {
        dispatch(pushModalWindow(CONFIRMATION_WITH_COMMENT_DIALOG, {modalKey: trKeyBase + "." + action, modalParams: {count: affectedListRates.length, name: parentName}, commentIsRequired: commentRequiredMap[action]}))
            .then((result) => {
                if (result?.status === MODAL_TYPE_CONFIRM) {
                    dispatch(putListRateActionToApi(action, filter, result.comment));
                }
            })
    }

    if (listRatesWithProcessDown?.length === 0) {
        launchModal();
    }
    else {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: isArray(listRates) ? "listRates.confirmUnmanagedListRatesModal" : "listRates.confirmUnmanagedListRateModal", modalParams: {count: listRatesWithProcessDownCount}}))
            .then((result) => {
                if (result?.status === MODAL_TYPE_CONFIRM) {
                    launchModal();
                }
            })
    }
}

export function getAffectedListRates(listRatesArrayOrObject, action) {

    const listRates = isArray(listRatesArrayOrObject) ? listRatesArrayOrObject : [listRatesArrayOrObject];

    if (!listRates?.length) {
        return [];
    }
    if (['close', 'dispatch'].includes(action)) {
        return listRates.filter(item => ['Active', 'InExpiry'].includes(item.status) );
    }
    if (action === 'expire') {
        return listRates.filter(item => item.status === 'Active');
        // return listRates.filter(item => item.status === 'Active'  && (item.sellAmount > 0 || item.buyAmount > 0 ));
    }
    return [];
}









import {createSelector} from 'redux-orm';
import {userCanCreate} from "gui-common/userRights/userRightsFunctions";
import {dbStateSelector} from "./dbStateSelector";
import {getCurrencyPairString} from "gui-common/functions/functions";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {selectLoggedInUserId} from "gui-common/api/apiSelectors";
import {orm} from "gui-common/orm/orm";


export function getOrmObjectWithId(session, model, id) {
    if (!id) return undefined;
    return session[model].idExists(id) ? session[model].withId(id) : undefined;
}
export function getOrmObjectProperty(session, model, id, property) {
    if (!id) return undefined;
    const object = session[model].idExists(id) ? session[model].withId(id) : undefined;
    if (!object) return "";
    return object.ref[property];
}


/********* Other selectors *****************************************************************/
const agreementType      = (state, props) => props ? props.agreementType : undefined;
const agreementTypeArray = (state, props) => props ? props.agreementTypeArray : undefined;
export const activeTenorsSelector = createSelector(
    orm,
    dbStateSelector,
    agreementType,
    agreementTypeArray,
    (session, agreementType, agreementTypeArray) => {

        const checkArray = agreementTypeArray ? agreementTypeArray : [];
        if (agreementType) checkArray.push(agreementType);

        const includeTenor = (tenor) => checkArray.length ? (tenor.validAgreementTypes.indexOf(agreementType) !== -1) : true;

        let returnArray = session.Tenor.filter(includeTenor).orderBy('sortIndex', 'asc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);
export const activeBankAccountInstitutionsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.BankAccountInstitution.filter({enabled: true}).toModelArray().map(inst => inst.ref);
        return returnArray;
    }
);
export const activeCurrenciesSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.Currency.filter({enabled: true}).orderBy('sortOrder', 'asc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);
export const activeCurrenciesTextOnlySelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.Currency.filter({enabled: true}).orderBy('sortOrder', 'asc').toModelArray().map(item => { return {id: item.ref.currency, currency: item.ref.currency}});
        return returnArray;
    }
);
export const activeCountriesSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.Country.filter({enabled: true}).orderBy('sortOrder', 'asc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);
export const getActiveCurrencyPairsSelector = () => createSelector(
    orm,
    dbStateSelector,
    agreementType,
    agreementTypeArray,
    (session, agreementType, agreementTypeArray) => {

        const includeCurrencyPair = (currencyPair) => {
            if (!currencyPair.baseCurrency || !currencyPair.quoteCurrency) {
                console.log("Corrupted currency pair", currencyPair);
                return false;
            }
            if (!currencyPair.enabled) return false;
            if (!agreementType && !agreementTypeArray) return true;
            if (agreementType) return (currencyPair.validAgreementTypes.indexOf(agreementType) !== -1);
            // check if any of the types in agreementTypeArray is included in the validAgreementTypes array:
            for (let type of agreementTypeArray) {
                if (currencyPair.validAgreementTypes.indexOf(type) !== -1) return true;
            }
            return false;
        }
        let returnArray = session.CurrencyPair.filter(includeCurrencyPair).orderBy('key', 'asc').toRefArray().map(currencyPair => {return {
            ...currencyPair,
            name: getCurrencyPairString(currencyPair),
        }});
        // console.log("Active CurrencyPairs:", returnArray);
        return returnArray;
    }
);
export const activeCurrencyPairsSelector = getActiveCurrencyPairsSelector();

export const getCurrencyPairSelector = () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props : undefined,
    agreementTypeArray,
    (session, selectId) => {
        if (!selectId) return null;
        if (!session.CurrencyPair.idExists(selectId)) return null;

        let ccyPair = session.CurrencyPair.withId(selectId).ref;
        return {
            ...ccyPair,
            name: getCurrencyPairString(ccyPair),
        };
    }
);
export function useCurrencyPair(currencyPairId) {
    return useSelectorInstance(getCurrencyPairSelector, currencyPairId);
}

export const activeCurrencyPairStringsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = [];
        session.CurrencyPair.filter({enabled: true}).orderBy('key', 'asc').toModelArray().forEach(currencyPair => {
            const baseCurrency = session.Currency.idExists(currencyPair.ref.baseCurrencyId) ? session.Currency.withId(currencyPair.ref.baseCurrencyId).ref.currency : "";
            const quoteCurrency = session.Currency.idExists(currencyPair.ref.quoteCurrencyId) ? session.Currency.withId(currencyPair.ref.quoteCurrencyId).ref.currency : "";
            returnArray.push(baseCurrency + "/" + quoteCurrency)
        });
        return returnArray;
    }
);

export const activeFxPricesSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = [];
        session.FxPrice.all().toModelArray().forEach(fxPrice => {
            let currencyPair = session.CurrencyPair.idExists(fxPrice.ref.currencyPairId) ? session.CurrencyPair.withId(fxPrice.ref.currencyPairId) : undefined;
            if (!currencyPair) return;
            const obj = fxPrice.ref;
            returnArray.push(Object.assign({}, obj, {
                baseCurrencyId: currencyPair.ref.baseCurrencyId,
                baseCurrency: session.Currency.idExists(currencyPair.ref.baseCurrencyId) ? session.Currency.withId(currencyPair.ref.baseCurrencyId).ref.currency : "",
                quoteCurrencyId: currencyPair.ref.quoteCurrencyId,
                quoteCurrency: session.Currency.idExists(currencyPair.ref.quoteCurrencyId) ? session.Currency.withId(currencyPair.ref.quoteCurrencyId).ref.currency : "",
                currencyPair: currencyPair.ref.key,
            }));
        });
        /*
                console.log("Active FX prices:", returnArray);
        */
        return returnArray;
    }
);
export const shallowFxPricesSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        return session.FxPrice.all().toRefArray();
    }
);
export const getFxPriceSelector = () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.fromCurrencyId : undefined,
    (state, props) => props ? props.toCurrencyId   : undefined,
    (session, fromCurrencyId, toCurrencyId) => {
        if (fromCurrencyId === toCurrencyId) return 1;
        const prices = session.FxPrice.all().filter(item => ((item.fromCurrencyId === fromCurrencyId) && (item.toCurrencyId === toCurrencyId))).toRefArray();
        if (!prices.length) {
            console.error("FX price not found in getFxPriceSelector", fromCurrencyId, toCurrencyId);
            return undefined;
        }
        if (prices.length > 1) {
            console.error("More than one FX price found in getFxPriceSelector", fromCurrencyId, toCurrencyId, prices.length);
        }
        return prices[0];
    }
);
export const activeProvidersSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = [];
        session.Provider.filter({providerType: "SYSTEM", enabled: true}).orderBy('processPrototypeId', 'asc').toModelArray().forEach(provider => {
            const obj = provider.ref;
            returnArray.push(Object.assign({}, obj, {
                events: provider.events.all().orderBy('time', 'desc').toModelArray().map(event => event.ref),
            }));
        });
       // console.log("Providers: ", returnArray);
        return returnArray;
    }
);
export const activeFxProvidersSelector = createSelector(
    orm,
    dbStateSelector,
    session => session.Provider.filter({providerType: "FX", enabled: true}).orderBy('name', 'desc').toRefArray()
);
export const erpSystemsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.ErpSystem.orderBy('name', 'desc').toRefArray();
        // console.log("ErpSystems: ", returnArray);
        return returnArray;
    }
);
export const shallowActiveProvidersSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.Provider.filter({enabled: true}).orderBy('name', 'desc').toModelArray().map(item => item.ref);
//        console.log("Shallow providers: ", returnArray);
        return returnArray;
    }
);
export const activeSystemEventsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.SystemEvent.all().toModelArray().map(item => item.ref);
        return returnArray;
    }
);


export const topicsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.Topic.all().toModelArray().map(item => item.ref);
        return returnArray;
    }
);

export const operatingUnitsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.OperatingUnit.all().orderBy('name', 'desc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);
export const getOperatingUnitsWithFullClientCreateSelector = () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props.nestedPropToCheck,
    (session, nestedPropToCheck) => {
        let returnArray = session.OperatingUnit.all().toModelArray().map(item => item.ref);
        returnArray = returnArray.filter(unit => userCanCreate('Client', unit, nestedPropToCheck));
        return returnArray;
    }
);
export const operatingUnitsWithFullClientCreateSelector = getOperatingUnitsWithFullClientCreateSelector();
/*
export const operatingUnitsWithClientCreateSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.OperatingUnit.all().toModelArray().map(item => item.ref);
        returnArray = returnArray.filter(unit => userCanCreate('Client', unit));
        return returnArray;
    }
);
*/

export const noRunDateSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.NoRunDate.all().orderBy('date', 'asc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);

export const sharedSettingsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.SharedSetting.all().orderBy('name', 'desc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);

/********* User selectors *****************************************************************/
export const getUserSelector = () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.userId : undefined,
    selectLoggedInUserId,
    (session, userId, referenceId) => {
        const selectId = userId ? userId : referenceId;
        if (!session.User.idExists(selectId)) return undefined;
        const userModel = session.User.withId(selectId);
        return {
            ...userModel.ref,
            systemRights         : userModel.systemRights          ? userModel.systemRights.toRefArray()[0]       : undefined,
            dataRightsCollections: userModel.dataRightsCollections ? userModel.dataRightsCollections.toRefArray() : undefined,
        };
    }
);
export const userSelector = getUserSelector();
export const usersSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = [];
        session.User.all().toRefArray().forEach(user => {
            returnArray.push({
                ...user,
                fullName: user.firstName + " " + user.lastName,
                topics: user.topics ? user.topics : [],
            })
        });
//        console.log("Users: ", returnArray);
        return returnArray;
    }
);

/********* Generic selectors *****************************************************************/
export const getOrmItemsSelector = () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props.ormModel,
    (state, props) => props.itemIdArray,
    (session, ormModel, itemIdArray) => {
        if (!ormModel || !itemIdArray) return;

        const sessionModel = session[ormModel];
        if (!sessionModel) return;

        let returnArray = [];
        for (const itemId of itemIdArray) {
            if (!sessionModel.idExists(itemId)) continue;
            returnArray.push(sessionModel.withId(itemId).ref);
        }
        return returnArray;
    }
);
export const getOrmItemSelectorGetter = (ormModel) => () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props.selectId,
    (session, selectId) => {
        if (!ormModel || !selectId) return;

        const object = getOrmModelObjectById(session, ormModel, selectId);
        if (!object) return undefined;

        return object;
    }
);

export const getOrmModelItemSelector = () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props.selectId,
    (state, props) => props.ormModel,
    (session, selectId, ormModel) => {
        if (!ormModel || !selectId) return;

        const object = getOrmModelObjectById(session, ormModel, selectId);
        if (!object) return undefined;

        return object;
    }
);

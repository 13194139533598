import {ormItemArrayTransformer} from "gui-common/orm/ormApi";
import {
    getNestedIdProperty,
    getStringFromTimeObject, getTimeObjectFromString,
    setAuditUserFields, setNestedIdProperty, setParentId, setParentIdIfDefined, setParentIdPropertyInCreateBody,
} from "gui-common/functions/functions";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {createMarginGroupBody, marginGroupTransformer} from "features/marginGroup/marginGroupFunctions";
import {
    priceTolerancesIncomingTransformer,
    priceTolerancesOutgoingTransformer
} from "features/priceTolerances/priceTolerancesFunctions";


export const refixIncomingTransformersMap = {
    Client                  : clientResponseTransformer,
    LegalEntity             : legalEntityResponseTransformer,
    LegalEntityUnit         : legalEntityUnitTransformer,
    Agreement               : agreementTransformer,
    ListRate                : listRateTransformer,
    ListRatePeriod          : listRatePeriodTransformer,
    DispatchPeriod          : dispatchPeriodTransformer,
    MarketOrder             : marketOrderTransformer,
    DispatchTrade           : dispatchTradeTransformer,
    ListRateOrder           : listRateOrderTransformer,
    ListRateOrderAllocation : listRateOrderAllocationTransformer,
    MarginGroup             : marginGroupTransformer,
    PriceTolerances         : priceTolerancesIncomingTransformer,
    PriceFeed               : priceFeedTransformer,
    ListRateOrderResult     : listRateOrderResultTransformer,
};
export const refixOutgoingTransformersMap = {
    Client                  : createClientBody,
    LegalEntity             : createLegalEntityBody,
    LegalEntityUnit         : createLegalEntityUnitBody,
    Agreement               : createAgreementBody,
    Order                   : undefined,
    ListRate                : undefined,
    ListRatePeriod          : undefined,
    MarketOrder             : undefined,
    DispatchTrade           : undefined,
    ListRateOrder           : undefined,
    MarginGroup             : createMarginGroupBody,
    PriceTolerances         : priceTolerancesOutgoingTransformer,
    PriceFeed               : undefined,
};

export function clientResponseTransformer(client, params) {
    setAuditUserFields(client);
    // if (params && params.createMomentObjectsForAudit) setItemDateTimes(client);

    client.operatingUnitId           = getNestedIdProperty(client, 'operatingUnit');

    if (!client.id)                 client.id                = 0;
    if (!client.name)               client.name              = "";
    if (!client.description)        client.description       = "";
    if (!client.clientRefId)        client.clientRefId       = "";
    if (!client.timeZone)           client.timeZone          = "";

    if (client.useLegalEntities === undefined) client.useLegalEntities = true;
    if (client.useLegalEntities === null)      client.useLegalEntities = true;

    if (client.legalEntities) {
        client.legalEntities = ormItemArrayTransformer('LegalEntity', client.legalEntities, {...params, parentId: client.id});
    }
    return client;
}
function createClientBody(client, decDenLangState, method, getState) {

    let legalEntities = undefined;
    if (client.legalEntities) {
        legalEntities = client.legalEntities.map(item => createLegalEntityBody(item, decDenLangState, method, getState));
    }

    return {
        operatingUnit       : setNestedIdProperty(client, 'operatingUnitId'),
        id:                   client.id,
        name:                (client.name           !== "") ? client.name           : null,
        description:         (client.description    !== "") ? client.description    : null,
        clientRefId:         (client.clientRefId    !== "") ? client.clientRefId    : null,
        timeZone:             client.timeZone,
        netDispatchTrades:   (client.netDispatchTrades)     ? client.netDispatchTrades : false,

        useLegalEntities:     client.useLegalEntities,
        clientApiChannel:     client.clientApiChannel,
        legalEntities:        legalEntities,
    }
}

export function legalEntityResponseTransformer(legalEntity, params) {
    setAuditUserFields(legalEntity);
    // if (params && params.createMomentObjectsForAudit) setItemDateTimes(legalEntity);

    setParentId(legalEntity, 'clientId', params ? params.parentId : null);
    if (legalEntity.name                === null)       legalEntity.name = "";
    if (legalEntity.description         === null)       legalEntity.description = "";
    if (legalEntity.useLegalEntityUnits === null)       legalEntity.useLegalEntityUnits = true;
    if (legalEntity.useLegalEntityUnits === undefined)  legalEntity.useLegalEntityUnits = true;
    legalEntity.legalPersonType = (legalEntity.legalPersonType) ? legalEntity.legalPersonType : "";

    legalEntity.countryId           = getNestedIdProperty(legalEntity, 'country');

    if (legalEntity.legalEntityUnits) {
        legalEntity.legalEntityUnits = ormItemArrayTransformer('LegalEntityUnit', legalEntity.legalEntityUnits, {...params, parentId: legalEntity.id});
    }
    return legalEntity;
}
function createLegalEntityBody(legalEntity, decDenLangState, method, getState) {
    let legalEntityUnits = undefined;
    if (legalEntity.legalEntityUnits) {
        legalEntityUnits = legalEntity.legalEntityUnits.map(item => createLegalEntityUnitBody(item, decDenLangState, method, getState));
    }
    return {
        ...setParentIdPropertyInCreateBody(legalEntity, 'clientId', method),
        id:                  legalEntity.id,
        name:                legalEntity.name,
        description:         legalEntity.description,
        country:             setNestedIdProperty(legalEntity, 'countryId'),
        legalPersonType:     legalEntity.legalPersonType ? legalEntity.legalPersonType : null,
        useLegalEntityUnits: legalEntity.useLegalEntityUnits,
        legalEntityUnits:    legalEntityUnits,
    }
}


export function legalEntityUnitTransformer(legalEntityUnit, params) {
    setParentIdIfDefined(legalEntityUnit, 'legalEntityId', params ? params.parentId : null);

    if (legalEntityUnit.fxProvider) {
        legalEntityUnit.fxProviderId           = getNestedIdProperty(legalEntityUnit, 'fxProvider');
    }
    if (legalEntityUnit.tradingLimitCurrency) {
        legalEntityUnit.tradingLimitCurrencyId = getNestedIdProperty(legalEntityUnit, 'tradingLimitCurrency');
    }
    if (legalEntityUnit.tradingLimitType) {
        legalEntityUnit.netTradingLimit = (legalEntityUnit.tradingLimitType === "Net");
    }
    if (legalEntityUnit.agreements) {
        legalEntityUnit.agreements = ormItemArrayTransformer('Agreement', legalEntityUnit.agreements, {...params, parentId: legalEntityUnit.id});
    }

    if (legalEntityUnit.marginGroup) {
        legalEntityUnit.marginGroupId = getNestedIdProperty(legalEntityUnit, 'marginGroup');
        legalEntityUnit.marginGroupAssigned = true;
    }
    if (legalEntityUnit.marginGroup === null) {
        legalEntityUnit.marginGroupAssigned = false;
    }
    legalEntityUnit.tradingLimitUtilization = Math.abs(legalEntityUnit.tradingLimitUtilization);

    /*
        else {
            legalEntityUnit.marginGroupAssigned = false;
            legalEntityUnit.marginGroupId = null;
        }
    */

    return legalEntityUnit;
}
function createLegalEntityUnitBody(legalEntityUnit, decDenLangState, method, getState) {
    let agreements = undefined;
    if (legalEntityUnit.agreements && (method === 'POST')) {
        agreements = legalEntityUnit.agreements.map(item => createAgreementBody(item, decDenLangState, method, getState));
    }
    return {
        ...setParentIdPropertyInCreateBody(legalEntityUnit, 'legalEntityId', method),
        id:             legalEntityUnit.id,
        name:           legalEntityUnit.name,
        description:    legalEntityUnit.description,
        priceFeedOnly:  legalEntityUnit.priceFeedOnly,
        appId:          legalEntityUnit.appId,
        fxProvider:     setNestedIdProperty(legalEntityUnit, 'fxProviderId'),
        fxShortName:    legalEntityUnit.fxShortName ? legalEntityUnit.fxShortName : null,
        restTimeZone:   (legalEntityUnit.restTimeZone) ? legalEntityUnit.restTimeZone : "Europe/Stockholm",
        tradingLimit:   stringNumberToFloat(legalEntityUnit.tradingLimit, decDenLangState),
        tradingLimitCurrency:  setNestedIdProperty(legalEntityUnit, 'tradingLimitCurrencyId'),
        tradingLimitType:   (legalEntityUnit.netTradingLimit) ? "Net" : "Gross",
        todayLimitOverride: stringNumberToFloat(legalEntityUnit.todayLimitOverride, decDenLangState),
        exemptFromGlobalSuspend: legalEntityUnit.exemptFromGlobalSuspend,
        enableListrates      : legalEntityUnit.enableListrates,
        enableMarketOrder    : legalEntityUnit.enableMarketOrder,
        enableMarketOrderFileReader: legalEntityUnit.enableMarketOrderFileReader,
        useFallbackTenorForMarketOrderFile: legalEntityUnit.useFallbackTenorForMarketOrderFile,
        enableIndicativeRates: legalEntityUnit.enableIndicativeRates,
        netDispatchTrades: legalEntityUnit.netDispatchTrades,
        sendToAccounting: legalEntityUnit.sendToAccounting,
        marginGroup:   legalEntityUnit.marginGroupAssigned ? setNestedIdProperty(legalEntityUnit, 'marginGroupId') : null,
        agreements: agreements,
    }
}


function agreementTransformer(agreement, params) {
    setAuditUserFields(agreement);
    // if (params && params.createMomentObjectsForAudit) setItemDateTimes(agreement);
    if (params && params.addTenorBooleansToAgreement && agreement.tenors) agreement.tenors.forEach(tenor => agreement[tenor] = true);
    setParentId(agreement, 'legalEntityUnitId', params ? params.parentId : null);

    agreement.openTime  = agreement.openTime  ? getStringFromTimeObject(agreement.openTime  ) : "";
    agreement.closeTime = agreement.closeTime ? getStringFromTimeObject(agreement.closeTime ) : "";
    agreement.currencyPairId  = getNestedIdProperty(agreement, 'currencyPair');
    if (!agreement.comment) agreement.comment = "";

    if (!agreement.name) agreement.name = agreement.type;

    if (!agreement.type)              agreement.type = "";
    else if (agreement.type === 'ListRateAgreement') {
//        agreement.tenorId           = getNestedIdProperty(agreement, 'tenor');
        agreement.listRatePeriodId  = getNestedIdProperty(agreement, 'listRatePeriod');
        agreement.dispatchTradePeriodId  = getNestedIdProperty(agreement, 'dispatchTradePeriod');
        if (!agreement.dispatchTradePeriodId) agreement.dispatchTradePeriodId = ""; // Null does not render well in the input field...
        if (!agreement.dispatchLevel) agreement.dispatchLevel = "";

        if (agreement.buySellType === null)  agreement.buySellType     = "";
        if (!agreement.meansOfPayment )      agreement.meansOfPayment  = "HEDGE";
        agreement.todayCutOffTime = agreement.todayCutOffTime ? getStringFromTimeObject(agreement.todayCutOffTime ) : "";
        if (agreement.listRates) {
            agreement.listRates = ormItemArrayTransformer('ListRate', agreement.listRates, {...params, parentId: agreement.id});
        }

        agreement.overridePriceTolerances = agreement.priceTolerance != null
            && (agreement.priceTolerance.startBusinessHours  != null
                || agreement.priceTolerance.endBusinessHours != null
                || agreement.priceTolerance.priceToleranceInsideBusinessHours != null
                || agreement.priceTolerance.priceToleranceOutsideBusinessHours != null);

        agreement.startBusinessHours = agreement.priceTolerance?.startBusinessHours ? agreement.priceTolerance.startBusinessHours : null;
        agreement.endBusinessHours = agreement.priceTolerance?.endBusinessHours ? agreement.priceTolerance.endBusinessHours : null;
        agreement.priceToleranceInsideBusinessHours = agreement.priceTolerance?.priceToleranceInsideBusinessHours ? agreement.priceTolerance.priceToleranceInsideBusinessHours : null;
        agreement.priceToleranceOutsideBusinessHours = agreement.priceTolerance?.priceToleranceOutsideBusinessHours ? agreement.priceTolerance.priceToleranceOutsideBusinessHours : null;
    }
    else if (['MarketOrderAgreement', 'MarketOrderAllAgreement'].indexOf(agreement.type) !== -1) {
        let tenorsObject = {}
        if (agreement.tenors?.length) {
            agreement.tenors.forEach(tenor => tenorsObject[tenor] = true);
        }
        agreement.tenors = tenorsObject;
    }
    else if (['ReferenceRateAgreement', 'PriceFeedAgreement'].indexOf(agreement.type) !== -1) {
        agreement.priceFeedId   = getNestedIdProperty(agreement, 'priceFeed'  );
        agreement.marginGroupId = getNestedIdProperty(agreement, 'marginGroup');
        agreement.status        = 'Active'; // No status management for these agreement types.
        agreement.name          = (agreement.targetTopic ? (agreement.targetTopic + '-') : '') + agreement.priceFeed.name + '-' + agreement.marginGroup.name;
    }
    return agreement;
}
function createAgreementBody(agreement, decDenLangState, method, getState) {
    let typeData = {};
    if (!agreement.type) return {};
    else if (agreement.type === 'ListRateAgreement') {
//        typeData.tenor          = setNestedIdProperty(agreement, 'tenorId');
        typeData.tenor          = agreement.tenor;
        typeData.listRatePeriod = setNestedIdProperty(agreement, 'listRatePeriodId');
        typeData.dispatchTradePeriod = setNestedIdProperty(agreement, 'dispatchTradePeriodId');
        typeData.dispatchLevel  = agreement.dispatchLevel ? stringNumberToFloat(agreement.dispatchLevel, decDenLangState) : null;
        typeData.buySellType    = agreement.buySellType     ? agreement.buySellType     : 'BuySell';
        typeData.meansOfPayment = agreement.meansOfPayment  ? agreement.meansOfPayment  : 'HEDGE';
        typeData.gracePeriod    = agreement.gracePeriod     ? agreement.gracePeriod     : null;
        typeData.todayCutOffTime= agreement.todayCutOffTime ? getTimeObjectFromString(agreement.todayCutOffTime)  : null;

        typeData.priceTolerance = {};

        if (agreement.overridePriceTolerances === false) {
            typeData.priceTolerance.startBusinessHours = null;
            typeData.priceTolerance.endBusinessHours = null;
            typeData.priceTolerance.priceToleranceInsideBusinessHours = null;
            typeData.priceTolerance.priceToleranceOutsideBusinessHours = null;
        } else {
            typeData.priceTolerance.startBusinessHours = agreement.startBusinessHours;
            typeData.priceTolerance.endBusinessHours = agreement.endBusinessHours;
            typeData.priceTolerance.priceToleranceInsideBusinessHours = agreement.priceToleranceInsideBusinessHours;
            typeData.priceTolerance.priceToleranceOutsideBusinessHours = agreement.priceToleranceOutsideBusinessHours;
        }
    }
    else if (['MarketOrderAgreement', 'MarketOrderAllAgreement'].indexOf(agreement.type) !== -1) {
/*
        const tenors = activeTenorsSelector(getState(), {agreementType: agreement.type});
        const tenorsToMap = getTenorsToMap(tenors, agreement.tenors);
        tenorsToMap.forEach(tenor => {if (agreement[tenor]) typeData.tenors.push(tenor)})
*/
        typeData.tenors  = [];
        Object.keys(agreement.tenors).forEach(key => {
            if (agreement.tenors[key] === true) typeData.tenors.push(key);
        });
    }
    else if (['ReferenceRateAgreement', 'PriceFeedAgreement'].indexOf(agreement.type) !== -1) {
        typeData.priceFeed   = setNestedIdProperty(agreement, 'priceFeedId'  );
        typeData.marginGroup = setNestedIdProperty(agreement, 'marginGroupId');
        if (agreement.type === 'PriceFeedAgreement') typeData.targetTopic = agreement.targetTopic;
    }

    return {
        ...setParentIdPropertyInCreateBody(agreement, 'legalEntityUnitId', method),
        id           : agreement.id,
        type         : agreement.type,
        currencyPair : setNestedIdProperty(agreement, 'currencyPairId'),
        openTime     : agreement.openTime  ? getTimeObjectFromString(agreement.openTime)  : null,
        closeTime    : agreement.closeTime ? getTimeObjectFromString(agreement.closeTime) : null,
        comment      : agreement.comment,
        ...typeData,
    }
}
function listRateTransformer(item, params) {
    setParentIdIfDefined(item, 'agreementId', params ? params.parentId : null);
    if (item.dispatchTrades) {
        item.dispatchTrades = ormItemArrayTransformer('DispatchTrade', item.dispatchTrades, {...params, parentId: item.id});
    }
    return item;
}
function dispatchTradeTransformer(item, params) {
    setParentIdIfDefined(item, 'listRateId', params ? params.parentId : null);
    if (item.listRateOrders) {
        item.listRateOrders = ormItemArrayTransformer('ListRateOrder', item.listRateOrders, {...params, parentId: item.id});
    }
    return item;
}
function listRateOrderTransformer(item, params) {
    setParentIdIfDefined(item, 'dispatchTradeId', params ? params.parentId : null);
    if (item.listRateId === 0) {
        item.listRateId = "";
    }
    if (item.listRateOrderAllocations) {
        item.listRateOrderAllocations = ormItemArrayTransformer('ListRateOrderAllocation', item.listRateOrderAllocations, {...params, parentId: item.id});
    }
/*
    item.listRateOrderAllocations = [
        {
            id                  : 345,
            listRateOrderId     : 111,
            allocationAmount    : 300,
            customerRst         : "rst",
            customerBankId      : "bankId",
            customerNumber      : "number",
            clientAllocationReference: "fish",
        },
        {
            id                  : 346,
            listRateOrderId     : 111,
            allocationAmount    : 400,
            customerRst         : "rst",
            customerBankId      : "bankId",
            customerNumber      : "number",
            clientAllocationReference: "fish",
        }
    ]
*/
    return item;
}
function listRateOrderAllocationTransformer(item, params) {
    setParentIdIfDefined(item, 'listRateOrderId', params ? params.parentId : null);
    return item;
}

function marketOrderTransformer(item, removeExecutionRights) {
    return item;
}
function listRatePeriodTransformer(item) {
    item.id     = item.id   ? item.id   : null;
    item.period = item.period ? item.period : "";
    return item;
}
function dispatchPeriodTransformer(item) {
    item.id     = item.id   ? item.id   : null;
    item.period = item.period ? item.period : "";
    return item;
}
function priceFeedTransformer(item) {
    item.fxProviderId  = getNestedIdProperty(item, 'fxProvider');
    item.default = item.isDefault;
    return item;
}
function listRateOrderResultTransformer(item) {
    return {
        listRateOrder   : item.listRateOrder    ? listRateOrderTransformer(   item.listRateOrder)   : undefined,
        listRate        : item.listRate         ? listRateTransformer(        item.listRate)        : undefined,
        dispatchTrade   : item.dispatchTrade    ? dispatchTradeTransformer(   item.dispatchTrade)   : undefined,
        legalEntityUnit : item.legalEntityUnit  ? legalEntityUnitTransformer( item.legalEntityUnit) : undefined,
    };
}


import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import InstanceRights from "gui-common/userRights/InstanceRights"
import DomainRights from "gui-common/userRights/DomainRights"
import {
    getModifiedEntityDataRightSelector,
    getSelectedInstanceSelector
} from "gui-common/userRights/adminUserRightsSelectors"
import {
    userRightsAdminSetDomainRight,
} from "gui-common/userRights/adminUserRightsReducer"
import InstanceUserRights from "gui-common/userRights/InstanceUserRights"
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    addRemovedActiveDataRights,
    assignEntityDataRight, compareEntityName,
    extractDomainRights,
} from "gui-common/userRights/adminUserRightsFunctions";
import {ormItemLoading} from "gui-common/orm/ormLoadingSelectors";
import AdminDataRightsCollection from "gui-common/userRights/AdminDataRightsCollection";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function AddEntityComponent (props) {
    const dispatch  = useDispatch();
    const translate = useSelector(selectTranslateFunction);

    const [itemToAdd, setItemToAdd] = useState("");

    const dataRightsCollectionLoading = useSelector(state => ormItemLoading(state, {itemId: props.dataRightsCollection.id, ormModel: 'DataRightsCollection'}));

    const assignableEntityDataRights = useMemo(() => {
        return props.dataRightsCollection.assignable.filter(item => item.entityPrototypeName === props.entityPrototypeName);
    }, [props.dataRightsCollection.assigned, props.entityPrototypeName]);

    useEffect(
        () => {
            if (!itemToAdd) return;
            if (props.dataRightsCollection.assignable.find(item => item.entity.id === itemToAdd.entity.id)) return;
            if (props.entityPrototypeName === itemToAdd.entityPrototypeName) return;
            setItemToAdd("")
        },
        [itemToAdd, props.dataRightsCollection.assignable, props.entityPrototypeName],
    );

    const displayTypeForSelect = props.compressedMode ? "inline-block" :"block";

    return (
        <div style={{display: "inline-block", margin: '15px 10px 10px 0', verticalAlign: 'top'}}
             xp-test-id={"userRights-add-" + props.entityPrototypeName}>
            <div style={{display: displayTypeForSelect, verticalAlign: "middle"}}>
                <select
                    style={{width: "200px"}}
                    onChange={event => {
                        if (event.target.value === "") {
                            setItemToAdd("");
                            return;
                        }
                        const instanceToAdd = props.dataRightsCollection.assignable.find(item => item.entity.id === Number(event.target.value));
                        if (!instanceToAdd) {
                            console.error("No instance found to add in AdminEntityLevel", event.target.value, props.dataRightsCollection);
                            return;
                        }
                        setItemToAdd(instanceToAdd);
                    }}
                >
                    <option value={""}>
                        {translate("userRights.itemToAdd.template", {model: translate('general.prototypeNamesSmall.' + props.entityPrototypeName)})}
                    </option>
                    {assignableEntityDataRights.map(item => {
                        if (!item || !item.entity) return null;
                        return (
                            <option
                                value={item.entity.id}
                                key={item.entity.id}
                            >
                                {item.entity.name}
                            </option>
                        )
                    })}
                </select>
            </div>

            <div style={{display: displayTypeForSelect, marginLeft: "7px", verticalAlign: "middle"}}>
                <WaitingXpoolButton
                    // label = {<div><XpTranslated trKey={'userRights.addInstanceButton'}/><XpTranslated trKey={'general.prototypeNamesSmall.' + props.entityPrototypeName}/></div>}
                    label           = {translate('userRights.addInstanceButton', {model: translate('general.prototypeNamesSmall.' + props.entityPrototypeName)})}
                    onClickCallback = {() => assignEntityDataRight(props.dataRightsCollection, itemToAdd, dispatch)}
                    disabled        = {!itemToAdd}
                    waiting         = {dataRightsCollectionLoading}
                    toolTipKey      = {(dataRightsCollectionLoading && itemToAdd) ? 'userRights.waitingForUserRight' : undefined}
                    toolTipParams   = {(dataRightsCollectionLoading && itemToAdd) ? {name: itemToAdd.entity.name}: undefined}
                />
            </div>
        </div>
    )
}

function EntityLevelTreeView (props) {
    const translate         = useSelector(selectTranslateFunction);

    const entityDataRights = useMemo(() => addRemovedActiveDataRights(props.assignedEntityDataRights, props.activeAssignedEntityDataRights),
        [props.assignedEntityDataRights, props.activeAssignedEntityDataRights]
    );

    const sortedEntityList = entityDataRights.sort(compareEntityName);

    return (
        <div style={{whiteSpace: 'nowrap'}}>
            {(!props.readOnly && !props.isRemoved) &&
            <AddEntityComponent
                dataRightsCollection = {props.dataRightsCollection}
                entityPrototypeName  = {props.entityPrototypeName}
                compressedMode       = {false}
            />}
            <div style={{display: "inline-block"}}>
                {sortedEntityList.length ?
                    sortedEntityList.map(entityDataRight => {

                        const activeEntity   = props.activeAssignedEntityDataRights ? props.activeAssignedEntityDataRights.find(item => item.entity.id === entityDataRight.entity.id) : undefined;
                        const modifiedEntity = props.assignedEntityDataRights       ? props.assignedEntityDataRights.find(      item => item.entity.id === entityDataRight.entity.id) : undefined;

                        return (
                            <InstanceUserRights
                                key                     = {entityDataRight.id}
                                entityDataRight         = {entityDataRight}
                                activeEntityDataRight   = {activeEntity}
                                isAdded                 = {!activeEntity}
                                isRemoved               = {props.isRemoved || !modifiedEntity}
                                dataRightsCollection    = {props.dataRightsCollection}
                                readOnly                = {props.readOnly}
                                auditMode               = {props.auditMode}
                            />
                        )
                    })
                    :
                    <div>{translate('userRights.noInstancesAdded', {entityType: translate('general.prototypeNamesSmallPlural.' + props.entityPrototypeName)})}</div>
                }
            </div>
        </div>
    );
}

function EntityLevelCompressedView (props) {
    const dispatch  = useDispatch();

    const selectedInstanceSelector = useMemo(() => getSelectedInstanceSelector(), []);
    const selectedEntityDataRight  = useSelector(state => selectedInstanceSelector(state, {assigned: props.assignedEntityDataRights, prototype: props.entityPrototypeName}));

    const activeSelectedEntityDataRight = useMemo(() => {
        if (!selectedEntityDataRight || !props.activeAssignedEntityDataRights) return undefined;
        return props.activeAssignedEntityDataRights.find(item => item.entity.id === selectedEntityDataRight.entity.id);
    }, [props.activeAssignedEntityDataRights, selectedEntityDataRight]);

    const modifiedEntityDataRightSelector = useMemo(() => getModifiedEntityDataRightSelector(), []);
    const modifiedSelectedEntityDataRight  = useSelector(state => modifiedEntityDataRightSelector(state, {selectId: selectedEntityDataRight ? selectedEntityDataRight.id: null}));

    const selectedInstanceDomainRights = useMemo(() => {
        if (!selectedEntityDataRight) return [];
        return extractDomainRights(modifiedSelectedEntityDataRight ? modifiedSelectedEntityDataRight.domainRights : selectedEntityDataRight.domainRights, props.readOnly);
    }, [selectedEntityDataRight]);


    return (
        <div>
            <div>
                <div style={{display: "inline-block", verticalAlign: "top"}}>
                    <InstanceRights
                        dataRightsCollection       = {props.dataRightsCollection}
                        entityPrototypeName        = {props.entityPrototypeName}
                        assignedEntityDataRights       = {props.assignedEntityDataRights}
                        activeAssignedEntityDataRights = {props.activeAssignedEntityDataRights}
                        selectedEntityDataRight    = {selectedEntityDataRight}
                        readOnly                   = {props.readOnly}
                    />
                    {!props.readOnly &&
                    <AddEntityComponent
                        dataRightsCollection = {props.dataRightsCollection}
                        entityPrototypeName  = {props.entityPrototypeName}
                        compressedMode       = {true}
                    />}
                </div>
                {selectedEntityDataRight &&
                <DomainRights
                    instanceDomainRights = {modifiedSelectedEntityDataRight ? modifiedSelectedEntityDataRight.domainRights : selectedEntityDataRight.domainRights}
                    instanceDomainRightsBeforeChange = {activeSelectedEntityDataRight ? activeSelectedEntityDataRight.domainRights : undefined}
                    setDomainRight       = {(domain, userRight, isAllowed) => dispatch(userRightsAdminSetDomainRight(props.dataRightsCollection.user, selectedEntityDataRight, domain, userRight, isAllowed))}
                    domainRights         = {selectedInstanceDomainRights}
                    readOnly             = {props.readOnly}
                />}
            </div>
            {(selectedEntityDataRight && selectedEntityDataRight.childDataRightsCollectionId) &&
            <AdminDataRightsCollection
                dataRightsCollectionId       = {selectedEntityDataRight.childDataRightsCollectionId}
                activeDataRightsCollectionId = {activeSelectedEntityDataRight ? activeSelectedEntityDataRight.childDataRightsCollectionId : undefined}
                parentInstance         = {selectedEntityDataRight}
                readOnly               = {props.readOnly}
                compressedMode         = {true}
            />}
        </div>
    );
}

function AdminEntityLevel(props) {


    const assignedEntityDataRights = useMemo(() => {
        if (!props.dataRightsCollection || !props.entityPrototypeName) return null;
        return props.dataRightsCollection.assigned.filter(item => item.entityPrototypeName === props.entityPrototypeName);
    }, [props.dataRightsCollection.assigned, props.entityPrototypeName]);

    const activeAssignedEntityDataRights = useMemo(() => {
        if (!props.dataRightsCollection || !props.entityPrototypeName) return null;
        if (!props.activeDataRightsCollection) return [];
        return props.activeDataRightsCollection.assigned.filter(item => item.entityPrototypeName === props.entityPrototypeName);
    }, [props.activeDataRightsCollection, props.entityPrototypeName]);


    const header = (
        <span>
            <XpTranslated trKey={'adminUserRights.prototypeLevelHeader'}/>
            <XpTranslated trKey={'general.prototypeNamesSmallPlural.' + props.entityPrototypeName}/>
            {props.parentInstance && <XpTranslated trKey={'adminUserRights.prototypeLevelHeaderUnder'}/>}
            {props.parentInstance && <XpTranslated trKey={'general.prototypeNamesSmall.' + props.parentInstance.entityPrototypeName}/>}
            {props.parentInstance ? (" " + props.parentInstance.entity.name) : ""}
        </span>
    );

    const ComponentToRender = (props.compressedMode && !props.readOnly) ? EntityLevelCompressedView : EntityLevelTreeView;

    if (!props.dataRightsCollection || !props.entityPrototypeName) return null;

    return (
        <div  style={{display: 'inline-block'}}>
            <XpAccordion
                header={header}
                instanceId={"userRights-" + props.entityPrototypeName + (props.parentInstance ?props.parentInstance.id : "")}
                bypass={props.readOnly}
            >
                {props.readOnly && <h4>{header}</h4>}
                <ComponentToRender
                    dataRightsCollection       = {props.dataRightsCollection}
                    activeDataRightsCollection = {props.activeDataRightsCollection}
                    assignedEntityDataRights       = {assignedEntityDataRights}
                    activeAssignedEntityDataRights = {activeAssignedEntityDataRights}
                    entityPrototypeName        = {props.entityPrototypeName}
                    readOnly                   = {props.readOnly}
                    auditMode                  = {props.auditMode}
                    isRemoved                  = {props.isRemoved}
                />
            </XpAccordion>
        </div>
    );
}

AdminEntityLevel.propTypes = {
    dataRightsCollection : PropTypes.object.isRequired,
    entityPrototypeName  : PropTypes.string.isRequired,
    parentInstance       : PropTypes.object,
    readOnly             : PropTypes.bool,
    compressedMode       : PropTypes.bool,
};

export default AdminEntityLevel;

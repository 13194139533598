import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {isElement} from "gui-common/functions/functions";

export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        if (!props) return;
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

export function useSelectorInstance(getSelectorFunction, props) {
    const selectorInstance = useMemo(() => {
        if (!getSelectorFunction) return undefined;
        return getSelectorFunction();
    }, []);
    return useSelector(selectorInstance ? state => selectorInstance(state, props) : state => undefined);
}


export function useSelectorInstanceRef(getSelectorFunction, props) {
    const selected = useSelectorInstance(getSelectorFunction, props)
    const selectorRef = useRef(selected); // Needed to transport updated hook to callback scope.
    selectorRef.current = selected;
    return selectorRef;
}
export function useSelectorRef(selector, props) {
    const selected = useSelector(state => selector(state, props));
    const selectorRef = useRef(selected); // Needed to transport updated hook to callback scope.
    selectorRef.current = selected;
    return selectorRef;
}

export function createSelectItems(typesArray) {
    const returnArray = typesArray.map(item => { return {
        id: item,
        name: item,
    }});
    return returnArray;
}

export function useStateRef(initialValue) {
    const [stateVar, setStateVar] = useState(initialValue);
    const stateRef = useRef(stateVar);
    stateRef.current = stateVar;
    return [stateRef, setStateVar];
}

export function useResizeObserver(callbackFn) {
    const resizeObserver = useRef(new ResizeObserver((entries) => callbackFn(entries[0].contentRect)));

    return useCallback((container) => {
        if (container === null) {
            // When element is unmounted, ref callback is called with a null argument
            // => best time to cleanup the observer
            if (resizeObserver.current) resizeObserver.current.disconnect();
        }
        else if (container.current && isElement(container.current)) {
            // When used on react component, the container is a react ref with a current element
            resizeObserver.current.observe(container.current);
        }
        else if (isElement(container)) {
            resizeObserver.current.observe(container);
            callbackFn({height:container.scrollHeight, width: container.scrollWidth})
        }
        else {
            console.error("Incorrect reference passed to useResizeObserver: ", container);
        }
    }, [resizeObserver.current]);
}

export function useSelectedHomeCurrency() {
    return useSelector(state => state.userPreferencesState.homeCurrency);
}

import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {
    createListRatesMenuFunction,
    getListRatesColumns,
    userCanCloseListRate,
    userCanExpireListRate,
} from "features/listRate/listRateFunctions";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {stateRequestedOnId} from "gui-common/requestEntityState/requestEntityStateFunctions";
import {getListRatesListDataSelector, listRateProcessStatusSelector} from "features/listRate/listRateSelectors";
import {ListRateOrdersList} from "features/listRateOrder/ListRateOrdersList";
import {DispatchTradeList} from "features/dispatchTrade/DispatchTradeList2";
import {userCanDispatchListRate} from "features/dispatchTrade/dispatchTradeFunctions";
import {useAgreementSelectorFunction} from "features/agreement/agreementFunctions";
import {useSelectorRef} from "gui-common/functions/hooks";
import {getDispatchTradesForListRateSelector} from "features/dispatchTrade/dispatchTradeSelectors";
import {getListRateOrdersForListRateSelector} from "features/listRateOrder/listRateOrderSelectors";


const hiddenState = {
    id                   : false,
    currencyPairStr      : false,
    legalEntityUnitName  : false,
    clientApiChannelT    : false,
    exemptFromGlobalSuspendT : false,
    buyPrice             : false,
    sellPrice            : false,
    buyAmount            : false,
    sellAmount           : false,
    totalBuyAmount       : false,
    totalSellAmount      : false,
    timeIssued           : false,
    timeExpire           : false,
    tenor                : false,
    listRatePeriodT      : false,
    dispatchTradePeriodT : false,
    dispatchLevel        : false,
    buySellType          : false,
    gracePeriodT         : false,
    statusT              : false,
    expireReason         : true,
    expiredByUserId      : true,
    expireComment        : true,
    originalExpire       : true,
    nextDispatchTime     : false,
};

ListRateList.detailIcon = 'trending_flat';
ListRateList.detailIconToolTipKey = 'listRatesList.detailIconToolTipKey';
export function ListRateList ({itemSelectedCallback, instanceId, setHeightCallback, fillAvailableSpace, gridId, rowDataSelectorProps}) {

    const requestingStateOnModelSelector = useMemo(()=>{
        return getRequestingStateOnModelSelector();
    }, [])

    const translate      = useSelector(selectTranslateFunction);
    const translateRef   = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    // const user      = useSelector(userSelector);
    const commentRequiredMap     = useSelector(state => state.appEnvState.mandatoryListRateActionComment.listRate);

    const listRateRequestState         = useSelector(state => requestingStateOnModelSelector(state, {model: 'ListRate'}))
    const listRateRequestStateRef = useRef(listRateRequestState); // Needed to transport updated hook to callback scope.
    listRateRequestStateRef.current = listRateRequestState;

    const listRateProcessStatusRef = useSelectorRef(listRateProcessStatusSelector);

    const dispatch  = useDispatch();

    const agreementSelectorFunction = useAgreementSelectorFunction()

    function disableMenuItem(itemData, disableWhenInStatusArray, requestState) {
        if (disableWhenInStatusArray.indexOf(itemData.status) !== -1) return true;
        return stateRequestedOnId(requestState, itemData.id);
    }
    function disableDispatchListRateMenuItem(itemData, requestState) {
        if (itemData.status === 'Expired') return true;
        if ((itemData.sellAmount === 0) && (itemData.buyAmount === 0)) return true;
        return stateRequestedOnId(requestState, itemData.id);
    }
    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!itemSelectedCallback) return;
                itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                let menuItems = [];

                const listRateAgreement = agreementSelectorFunction(params.node?.data?.agreementId);
                if (!listRateAgreement) {
                    console.warn("Could not find list rate agreement in ListRateList", params.node?.data);
                    return []
                }
                const userCanClose    = userCanCloseListRate(   listRateAgreement);
                const userCanExpire   = userCanExpireListRate(  listRateAgreement);
                const userCanDispatch = userCanDispatchListRate(listRateAgreement);

                const createListRateMenuItem = (action) =>
                    createListRatesMenuFunction(params.node.data, action, {id: params.node.data.id}, 'listRatesList.gridContextMenuItems', "", commentRequiredMap, listRateProcessStatusRef, translateRef, dispatch);

                if (userCanClose)    menuItems.push(createListRateMenuItem('close'   ));
                if (userCanExpire)   menuItems.push(createListRateMenuItem('expire'  ));
                if (userCanDispatch) menuItems.push(createListRateMenuItem('dispatch'));

                return menuItems;

            }, [translate]),
        isRowMaster     : useCallback((params) => {return true;}, []),
        rowClassRules   : {
            'xp-grid-inactive-row': function(params) {return (params.data.status === "Expired")},
        },
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : instanceId,
                overlayNoRowsTemplate   : translate('listRatesList.noListRatesToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                // rowDataSelector         : selectListRatesListData,
                getRowDataSelector      : getListRatesListDataSelector,
                rowDataSelectorProps    : rowDataSelectorProps,
                treeData                : false,
                masterDetail            : true,
                ormModel                : 'ListRate',
                xpDetailRendererProps   : {
                    availableComponents: [
                        {
                            detailType: 'listRateOrders',
                            componentToRender: ListRateOrdersList,
                            propsToComponent: {
/*
                                rowDataFilterFunction: (item, parentItemData) => {
                                    return (item.listRateId === parentItemData.id);
                                }
*/
                                getRowDataSelector: getListRateOrdersForListRateSelector,
                                getRowDataSelectorProps: parentItemData => ({listRateId: parentItemData.id}),
                            },
                        },
                        {
                            detailType: 'dispatchTrades',
                            componentToRender: DispatchTradeList,
                            propsToComponent: {
/*
                                rowDataFilterFunction: (item, parentItemData) => {
                                    return (item.listRateId === parentItemData.id);
                                },
*/
                                getRowDataSelector: getDispatchTradesForListRateSelector,
                                getRowDataSelectorProps: parentItemData => ({listRateId: parentItemData.id}),
                            },
                        }
                    ],
                    parentInstanceId: instanceId,
                },
            };
        },
        [translate, fillAvailableSpace, rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return getListRatesColumns(translate, hiddenState);
        },
        [translate]
    );


    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={gridId ? gridId : "listRateGrid-" + instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}

import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {shallowActiveProvidersSelector} from "gui-common/orm/ormSelectors";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {
    timeEarlierOrSameThanParameter,
    timeLaterThanParameter,
    timeRequiredIfRelatedFieldsAreFilled
} from "gui-common/xpForm/formComponentsValidators";

export function LegalEntityUnitFxSettings (props) {
    const ormProviders = useSelector(shallowActiveProvidersSelector);
    const isSandbox = useAppEnvProperty('isSandbox');
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');
    const formDataFxProviderId = useXpFormField('fxProviderId');

    const fxProviders = useMemo(
        () => {
            return ormProviders.filter(item => item.providerType === 'FX');
        },
        [ormProviders]
    );


    if (formDataPriceFeedOnly) return null;
    return (
        <div className={props.className}>
            <XpFormSelectorInput
                inLineLayout showTemplateInView
                field="fxProviderId"
                selectList={fxProviders}
                itemTextField="name"
                // defaultValue={fxProviders?.length ? fxProviders[0].id : null}
                dependentFields={{
                    enableMarketOrder: 'enableMarketOrder',
                    enableListrates: 'enableListrates',
                }}
                errors={{
                    noFxProviderWithFxServicesActivated    : (val, fields) => !val && (fields.enableMarketOrder || fields.enableListrates),
                }}
            />
            {formDataFxProviderId &&
            <XpFormTextInput
                isRequired inLineLayout
                field= "fxShortName"
                checkNoSpaces={true}
                checkOnlySpaces={false}
                minLength={2}
                maxLength={isSandbox ? undefined : 20}
            />}
         </div>
    );
}

LegalEntityUnitFxSettings.propTypes = {
    className:        PropTypes.string,
};


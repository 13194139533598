import IntegrationStatusContainer from "gui-common/processStatus/ProcessStatusWidget";
import UserMessagesList from "gui-common/userMessages/UserMessagesList";
import FxPriceList from "gui-common/fxPrice/FxPriceList";
import MarketSimulator from "gui-common/simulators/fxMarketSimulator/MarketSimulator";
import FixLogList from "gui-common/fixLog/FixLogList";
import {userCan} from "gui-common/userRights/userRightsFunctions";

const commonDashboardWidgets = {
    'IntegrationStatusContainer': {
        component           : IntegrationStatusContainer,
        authorizedFunction  : null,
        langPath            : 'processStatus.dashboardComponent.',
        icon                : 'traffic',
        keyName             : 'traffic',
        defaultSize         : {w: 5, h: 15}
    },
    'UserMessagesList': {
        component           : UserMessagesList,
        authorizedFunction  : null,
        langPath            : 'userMessages.dashboardComponent.',
        icon                : 'announcement',
        keyName             : 'userMessages',
    },
    'FxPriceList': {
        component           : FxPriceList,
        authorizedFunction  : null,
        langPath            : 'fxPrices.dashboardComponent.',
        icon                : 'euro_symbol',
        keyName             : 'fxPrices',
        defaultSize         : {w: 5, h: 10}
    },

    'FixLogList': {
        component           : FixLogList,
        authorizedFunction  : (user) => userCan('Read', 'ShowFixLog', user),
        langPath            : 'fixLog.fixLogList.dashboardComponent.',
        icon                : 'question_answer',
        keyName             : 'fixLog',
        defaultSize         : {w: 10, h: 10}
    },

    'MarketSimulator': {
        component           : MarketSimulator,
        authorizedFunction  : () => (window.oxygen_env.DEV_MODE === true),
        langPath            : 'marketSimulator.dashboardComponent.',
        icon                : 'currency_exchange',
        keyName             : 'marketSimulator',
        defaultSize         : {w: 12, h: 12}
    },
};
export const dashboardConstants = {};
export function setGlobalDashboardConfig(appConfig) {
    dashboardConstants.widgets = {...commonDashboardWidgets, ...appConfig.dashboard?.widgets};
}

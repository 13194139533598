import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import DomainRights from "gui-common/userRights/DomainRights"
import {getModifiedUserSystemRightSelector} from "gui-common/userRights/adminUserRightsSelectors"
import {
    userRightsAdminResetSystemRight,
    userRightsAdminSetSystemRight
} from "gui-common/userRights/adminUserRightsReducer"
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {extractDomainRights} from "gui-common/userRights/adminUserRightsFunctions";
import {
    getUserSystemRightFromApi,
    putUserSystemRightFromApi
} from "gui-common/userRights/adminUserRightsApi";
import {ormItemLoading} from "gui-common/orm/ormLoadingSelectors";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import spinnerGif from "gui-common/resources/spinner.gif";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";

export function AuditUserSystemRights(props) {

    if (props.hideSystemRights) return null;
    if (!props.currentData      || !props.currentData.user      || !props.currentData.systemRights     ) return null;
    if (!props.beforeChangeData || !props.beforeChangeData.user || !props.beforeChangeData.systemRights) return null;
    return (
        <div>
            <AdminSystemRights
                user                = {props.currentData.user}
                beforeChangeData    = {props.beforeChangeData}
                currentData         = {props.currentData}
                auditMode           = {true}
                readOnly            = {true}
            />
        </div>
    );
}

function AdminSystemRights(props) {
    const dispatch  = useDispatch();

    const userSystemRight  = props.beforeChangeData ? props.beforeChangeData : props.user ? props.user.systemRights : undefined;

    const modifiedUserSystemRightSelector = useMemo(() => getModifiedUserSystemRightSelector(), []);
    const modifiedUserSystemRight  = useSelector(state => modifiedUserSystemRightSelector(state, {selectId: userSystemRight ? userSystemRight.id : null, ...props}));

    const userRightsLoading = useSelector(state => ormItemLoading(state, {itemId: props.user ? props.user.id : null, ormModel: 'User'}));
    const systemRightLoading = useSelector(state => ormItemLoading(state, {itemId: userSystemRight, ormModel: 'UserSystemRight'}));

    const [editMode, setEditMode] = useState(props.auditMode ? false :modifiedUserSystemRight !== undefined);

    useEffect(
        () => {
            if (props.hideSystemRights || !props.user || props.auditMode) return;
            if (!userSystemRight) dispatch(getUserSystemRightFromApi(props.user.id));
        },
        [props.user, userSystemRight, props.hideSystemRights],
    );

    useEffect(
        () => {
            if (!modifiedUserSystemRight && editMode) setEditMode(false);
        },
        [modifiedUserSystemRight],
    );

    const systemRights = useMemo(() => {
        if (!userSystemRight) return [];
        return extractDomainRights(userSystemRight.systemRights, props.readOnly, modifiedUserSystemRight);
    }, [userSystemRight, modifiedUserSystemRight]);

    function saveSystemRights() {
        if (props.user.state !== 'Active') {
            dispatch(putUserSystemRightFromApi(props.user.id, modifiedUserSystemRight))
            return;
        }
        // User is logged in. Request confirmation before saving.
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey:  'userRights.confirmSaveActiveUserModal'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    dispatch(putUserSystemRightFromApi(props.user.id, modifiedUserSystemRight))
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    if (props.hideSystemRights ||!props.user || !userSystemRight) return null;
    return (
        <div  style={{display: 'inline-block'}}>
            <div xp-test-id={'xpAdminSystemRights'}>
                <XpAccordion
                    headerKey='adminUserRights.systemRightsHeader'
                    instanceId={"userRights-systemRights"}
                    bypass = {props.readOnly}
                >
                    {(props.readOnly && !props.auditMode) && <h4><XpTranslated trKey={'adminUserRights.systemRightsHeader'}/></h4>}

                    {userRightsLoading ?
                        <div xp-test-id="auditEntrySpinner">
                            <XpTranslated trKey={'adminUserRights.loadingUser'}/>
                            <img className="spinner" src={spinnerGif} alt="waiting"/>
                        </div>
                        :
                        <DomainRights
                            instanceDomainRights = {modifiedUserSystemRight ? modifiedUserSystemRight.systemRights : userSystemRight.systemRights}
                            instanceDomainRightsBeforeChange = {userSystemRight.systemRights}
                            setDomainRight       = {(domain, userRight, isAllowed) => dispatch(userRightsAdminSetSystemRight(userSystemRight, domain, userRight, isAllowed))}
                            domainRights         = {systemRights}
                            readOnly             = {props.readOnly || !editMode}
                        />
                    }

                    {!props.readOnly &&
                    <div>
                        <hr/>
                        {!editMode &&
                        <WaitingXpoolButton
                            onClickCallback = {() => setEditMode(true)}
                            labelKey        = 'general.buttons.edit'
                        />}
                        {editMode &&
                        <WaitingXpoolButton
                            onClickCallback = {() => saveSystemRights()}
                            disabled        = {(!modifiedUserSystemRight)}
                            waiting         = {systemRightLoading}
                            labelKey        = 'adminUserRights.activateButton'
                            floatRight
                        />}
                        {editMode &&
                        <WaitingXpoolButton
                            onClickCallback = {() => {
                                dispatch(userRightsAdminResetSystemRight(userSystemRight));
                                setEditMode(false);
                            }}
                            labelKey        = 'general.buttons.cancel'
                        />}
                    </div>}

                </XpAccordion>
            </div>
        </div>
    );
}
AdminSystemRights.propTypes = {
    user      : PropTypes.object.isRequired,
    readOnly  : PropTypes.bool,
};

export default AdminSystemRights;


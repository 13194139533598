import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import XpFormToggleInput from "gui-common/xpForm/core/XpFormToggleInput";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {activeTenorsSelector} from "gui-common/orm/ormSelectors";
import {useSelector} from "react-redux";


export function MarketOrderTenors (props) {
    const formDataType   = useXpFormField('type');
    const ormTenors = useSelector(activeTenorsSelector);

    const marketOrderTenors = useMemo(
        () => {
            return ormTenors.filter(tenor => {
                if (tenor.name === 'BROKEN') return false;
                if (tenor.validAgreementTypes.includes('MarketOrderAgreement'   )) return true;
                if (tenor.validAgreementTypes.includes('MarketOrderAllAgreement')) return true;
                return false;
            });
        },
        [ormTenors]
    );


    if ((formDataType !== 'MarketOrderAgreement') && (formDataType !== 'MarketOrderAllAgreement')) return null;

    return (
        <XpFormFieldset
            field='tenors'
        >
            <label className='xpFormLabel'><XpTranslated trKey='agreementForm.marketOrderTenors' trParams={props.labelTrParams}/></label>
            <div>
                {marketOrderTenors.map(tenor => {
                    return (
                        <div style={{display: 'inline-block', marginRight: '5px'}} key = {tenor.name}>
                            <XpFormToggleInput
                                field    = {tenor.name}
                                label    = {tenor.name}
                                defaultValue={false}
                                noLabelToolTip
                            />
                        </div>
                    )
                })}
            </div>
        </XpFormFieldset>
    )
}
MarketOrderTenors.propTypes = {
    className:        PropTypes.string,
};

import React from 'react'
import PropTypes from 'prop-types';
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {onServiceActivationSwitchChange} from "features/legalEntityUnit/legalEntityUnitFunctions";


export function MarketOrderServicesActivation (props) {
    const leuName = useXpFormField('name');
    const fileReaderActive = useXpFormField('enableMarketOrderFileReader');
    const formContext = useXpFormContext()
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');

    if (formDataPriceFeedOnly) return null;
    return (
        <div className={props.className}>
            <XpFormSwitchInput
                field         = "enableMarketOrder"
                defaultValue  = {true}
                onChangeThunk={onServiceActivationSwitchChange(leuName)}
            />
            <XpFormFieldGroup
                isExpanded={(fileReaderActive === true)}
                disableAnimations = {formContext?.formUseState === XP_FORM_VIEW}
                controlComponent={
                    <XpFormSwitchInput
                        field         = "enableMarketOrderFileReader"
                        defaultValue  = {false}
                        onChangeThunk={onServiceActivationSwitchChange(leuName)}
                    />
                }
            >
                <XpFormSwitchInput
                    field         = "useFallbackTenorForMarketOrderFile"
                    defaultValue  = {false}
                />
            </XpFormFieldGroup>
        </div>
    );
}
MarketOrderServicesActivation.propTypes = {
    className:        PropTypes.string,
};

export const webSocketStatus = {
    'DOWN': 'down',
    'OPEN': 'open',
    'RUNNING': 'running',
    'STARTING': 'starting',
    'CLOSING': 'closing',
}
export function getCommonBaseUrls(appConfig) {
    const baseUrl    = appConfig.api.baseUrl;
    const erpBaseUrl = appConfig.api.erpBaseUrl;
    return {
        Country                 : baseUrl + "countries",
        Provider                : baseUrl + "providers",
        NFCProcessStatus        : baseUrl + "process-status",
        SystemEvent             : baseUrl + "system-events",
        User                    : baseUrl + "users",
        SharedSetting           : baseUrl + "shared-settings",

        userPreferences         : baseUrl + "user-settings",
        systemText              : baseUrl + "system-texts",
        signIn                  : "/aaa/v1/sign-in/",
        uploadFile              : baseUrl + "upload-file",

        Currency                : baseUrl + "currencies",
        CurrencyPair            : baseUrl + "currency-pairs",
        FxPrice                 : baseUrl + "fx-prices",
        Tenor                   : baseUrl + "tenors",
        FixMessage              : baseUrl + "fix-log",

        ErpSystem               : erpBaseUrl + "erp-systems",
        erpOathConnection       : erpBaseUrl + "oauth2/erp-integrations",
        erpDataRequest          : erpBaseUrl + "data-request",

        DataRightsCollection    : baseUrl + "data-rights-collection",
        UserSystemRight         : baseUrl + "user-system-rights",

        AuditRootModel          : baseUrl + "audit-entries/root-prototypes",
        AuditUserActionType     : baseUrl + "audit-entries/action-prototypes",
        audit                   : baseUrl + "audit-entries",
        historicAudit           : baseUrl + "audit-entries-historic",

        Topic                   : baseUrl + "user-messages/topics",
        userMessage             : baseUrl + "user-messages",

        NoRunDate               : baseUrl + "no-run-dates",

        BankHoliday             : baseUrl + "bank-holidays",

        LedgerAccount           : baseUrl + "ledger-accounts",

        CurrencyPairConfig      : baseUrl + "currency-pair-settings",

        fxRejectConfig          : baseUrl + "fx-reject-configurations",

        reportSet               : baseUrl + "report-sets",

        RunState                : baseUrl + "run-state",
    }
}
export const globalApiHandle = {};

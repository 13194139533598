import React from "react";
import {useDispatch, useSelector} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import { modalStandardParams} from "gui-common/modals/modalConstants";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {ormItemLoading} from "gui-common/orm/ormLoadingSelectors";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";


export function TradingLimitOverrideModal({popModalWindow, legalEntityUnit}) {

    const decDenLangState = useSelector(selectDecDenLangState);
    const unitLoading = useSelector(state => ormItemLoading(state, {itemId: legalEntityUnit.id, ormModel: 'LegalEntityUnit'}));
    const commentIsRequired = useSelector(state => state.appEnvState.tradingLimitOverrideForm.commentIsRequired);
    const dispatch    = useDispatch();
    const formDataComment         = useXpFormField('tradingLimitOverrideForm.comment');
    const formTodayLimitOverride  = useXpFormField('tradingLimitOverrideForm.todayLimitOverride');

//    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalGenerateUserMessage"}};

    function cancelAction() {
        popModalWindow({ status: MODAL_TYPE_CANCEL });
        dispatch(xpFormRemoveForm('tradingLimitOverrideForm'));
    }

    function handleSubmit() {
        popModalWindow({ status: MODAL_TYPE_CONFIRM, todayLimitOverride: formTodayLimitOverride, comment: formDataComment });
        dispatch(xpFormRemoveForm('tradingLimitOverrideForm'));
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Daily trading limit override dialog"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >

                    <div xp-test-id="overrideTradingLimitDialog">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={'tradingLimitOverrideForm.header'}/></h3>
                        <hr/>
                        <p className="valueTextDark"><XpTranslated trKey={'tradingLimitOverrideForm.message'} trParams={{name: legalEntityUnit.name, ccy: legalEntityUnit.tradingLimitCurrencyName, limitOverride: legalEntityUnit.todayLimitOverride, limit: legalEntityUnit.tradingLimit, limitUtilization: legalEntityUnit.tradingLimitUtilization}}/></p>
                        <hr/>
                        <XpForm
                            formModel='tradingLimitOverrideForm'
                            onSubmit={(data) => handleSubmit(data)}
                            initialUseState={XP_FORM_EDIT}
                            initialFormData={{todayLimitOverride: "", comment: ""}}
                        >
                            <XpFormAmountInput
                                isRequired autoFocus
                                field           = "todayLimitOverride"
                                inLineLayout    = {false}
                                errors={{
                                    lowerThanZero:     (val) => /\d/.test(val) && (stringNumberToFloat(val, decDenLangState) < 0),
                                }}
                            />
                            <XpFormTextAreaInput
                                field="comment"
                                isRequired={commentIsRequired}
                                maxLength={200}
                            />

                        </XpForm>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        = "tradingLimitOverrideForm.confirm"
                                onClickCallback = {() => dispatch(xpFormSubmitForm("tradingLimitOverrideForm"))}
                                waiting         = {unitLoading}
                                disabled        = {((formTodayLimitOverride === "") || (commentIsRequired && formDataComment === ""))}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}

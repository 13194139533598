import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import MarketSimulatorConfigItem from "gui-common/simulators/fxMarketSimulator/MarketSimulatorConfigItem";
import "gui-common/simulators/simulator.css";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {activeFxProvidersSelector} from "gui-common/orm/ormSelectors";
import {dashboardSetComponentsState} from "gui-common/dashboard/dashboardReducer";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {
    getClientPricesWithOverridesFromApi,
    getFixAccountsFromApi
} from "gui-common/simulators/fxMarketSimulator/marketSimulatorApi";
import {globalSimulatorsConfig} from "gui-common/simulators/simulatorConstants";
import DevInfo from "gui-common/app/DevInfo";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

function MarketSimulator (props) {

    const marketSimulatorForm = 'marketSimulatorForm-' + props.instanceId;
    const activeClientConfigurations = useSelector(globalSimulatorsConfig.fxMarketSimulator.activeConfigurationsSelector);
    const availableCurrencyPairs = useSelector(globalSimulatorsConfig.fxMarketSimulator.availableCurrencyPairsSelector);
    const availableTenors        = useSelector(globalSimulatorsConfig.fxMarketSimulator.availableTenorsSelector);
    const simConfig              = useAppEnvProperty('marketSimConfig');
    const ormFxProviders         = useSelector(activeFxProvidersSelector);
    const fixAccounts            = useSelector(globalSimulatorsConfig.fxMarketSimulator.fixAccountsSelector);

    const currencyPairIdToAdd   = useXpFormField(marketSimulatorForm + '.currencyPairIdToAdd')
    const tenorToAdd            = useXpFormField(marketSimulatorForm + '.tenorToAdd')
    const sessionIdToAdd        = useXpFormField(marketSimulatorForm + '.sessionIdToAdd')
    const fixAccountToAdd       = useXpFormField(marketSimulatorForm + '.fixAccountToAdd')

    const [configFixAccounts, setConfigFixAccounts] = useState([]);


    const dispatch  = useDispatch();

    useEffect(
        () => {
            if (props.componentData.state?.priceItems?.length) {
                return;
            }
            const priceItems = activeClientConfigurations?.length ? [...activeClientConfigurations] : [];
            dispatch(getClientPricesWithOverridesFromApi())
                .then(resp => {
                    if (resp?.response?.length) {
                        resp.response.forEach(itemWithOverride => {
                            const itemToAdd = {currencyPairId: itemWithOverride.currencyPair.id, tenor: itemWithOverride.tenor, sessionId: itemWithOverride.sessionId, fixAccount: itemWithOverride.fixAccount}
                            if (!priceItems.find(item => configIsSame(item, itemToAdd))) {
                                priceItems.push(itemToAdd);
                            }
                        })
                    }
                })
                .finally(resp => {
                    dispatch(dashboardSetComponentsState(
                        props.componentData.key,
                        {priceItems: priceItems}
                    ));
                })

            return () => {
                // dispatch(xpFormRemoveForm(marketSimulatorForm));
            }
        },
        [],
    );

    useEffect(
        () => {
            dispatch(getFixAccountsFromApi()).then(resp => {
                const accounts = Object.keys(resp.response);
                setConfigFixAccounts(accounts);
            })
        },
        [],
    );

    const tenorsAvailableToAdd = useMemo(
        () => {
            return availableTenors?.length ? availableTenors.map(item => ({id:item , name: item})) : [];
        },
        [availableTenors]
    );
    const sessionsAvailableToAdd = useMemo(
        () => {
            const ret = ormFxProviders?.length ? ormFxProviders.map(item => ({id: item.sessionID, name: item.sessionID})) : [];
            ret.push({id: "OpenExchange", name: "OpenExchange"})
            return ret;
        },
        [ormFxProviders]
    );
    const currencyPairsAvailableToAdd = useMemo(
        () => {
            if (!availableCurrencyPairs?.length) {
                return [];
            }
            let ccyPairsConfig = [];
            if (simConfig.currencyPairs?.length) {
                simConfig.currencyPairs.forEach(configCcyPair => {
                    const foundCcyPair = availableCurrencyPairs.find(cp => cp.key === configCcyPair);
                    if (foundCcyPair) {
                        ccyPairsConfig.push(foundCcyPair);
                    }
                })
            }
            else {
                ccyPairsConfig = availableCurrencyPairs;
            }
            if (sessionIdToAdd === "OpenExchange") {
                return [{id: "", name: "All"}].concat(ccyPairsConfig.filter(item => item.baseCurrency.currency === "USD" || item.quoteCurrency.currency === "USD"));
            }
            return ccyPairsConfig.map(item => ({id: item.id, name: item.key}));
        },
        [availableCurrencyPairs, sessionIdToAdd]
    );
    const fixAccountsAvailableToAdd = useMemo(
        () => {
            if (sessionIdToAdd === "OpenExchange") {
                return simConfig.openExchangeAccounts;
            }
            const accMap = {};
            fixAccounts.forEach(acc => accMap[acc] = true);
            if (configFixAccounts?.length) {
                configFixAccounts.forEach(acc => accMap[acc] = true);
            }
            return Object.keys(accMap).map(item => ({id: item, name: item}));
        },
        [fixAccounts, configFixAccounts, sessionIdToAdd]
    );
    useEffect(
        () => {
            console.log("Set currencyPairIdToAdd in effect ", sessionIdToAdd, fixAccountToAdd, currencyPairIdToAdd);
            if (sessionIdToAdd === "OpenExchange") {
                if (!fixAccountsAvailableToAdd.find(item => item.id === fixAccountToAdd)) {
                    dispatch(xpFormChangeField(marketSimulatorForm + '.fixAccountToAdd', fixAccountsAvailableToAdd[0].id));
                }
                if (!currencyPairsAvailableToAdd.find(item => item.id === currencyPairIdToAdd)) {
                    dispatch(xpFormChangeField(marketSimulatorForm + '.currencyPairIdToAdd', ""))
                }
                dispatch(xpFormChangeField(marketSimulatorForm + '.tenorToAdd', "SP"))
            }
            else {
                if (!fixAccountsAvailableToAdd.find(item => item.id === fixAccountToAdd)) {
                    dispatch(xpFormChangeField(marketSimulatorForm + '.fixAccountToAdd', ""));
                }
                if (!currencyPairsAvailableToAdd.find(item => item.id === currencyPairIdToAdd)) {
                    dispatch(xpFormChangeField(marketSimulatorForm + '.currencyPairIdToAdd', currencyPairsAvailableToAdd[0].id))
                }
            }
        },
        [sessionIdToAdd],
    );

    const addConfigFn = useCallback(
        (config, fixAccount) => {
            if (props.componentData.state.priceItems.find(item => configIsSame(item, {currencyPairId: config.currencyPairId, tenor: config.tenor, sessionId: config.sessionId, fixAccount: fixAccount}))) {
                return;
            }
            dispatch(dashboardSetComponentsState(
                props.componentData.key,
                {priceItems: [...props.componentData.state.priceItems, {currencyPairId: config.currencyPairId, tenor: config.tenor, sessionId: config.sessionId, fixAccount: fixAccount}]}
            ));
        }
        , [props.componentData]);

    const removeMeFn = useCallback(
        (config) => {
            dispatch(dashboardSetComponentsState(
                props.componentData.key,
                {priceItems: props.componentData.state.priceItems.filter(item => !configIsSame(item, config))}
            ));
        }
        , [props.componentData]);

    function configIsSame(item, config) {
        for (let key in item) {
            if (item[key] !== config[key]) {
                return false;
            }
        }
        for (let key in config) {
            if (item[key] !== config[key]) {
                return false;
            }
        }
        return true;
    }
    function getConfigKey(item) {
        let returnKey = ""
        for (let key in item) {
            returnKey += item[key];
        }
        return returnKey;
    }

    if (!props.componentData?.state?.priceItems) {
        return null;
    }

    return (
        <div>
            <DevInfo urlProp={'marketSimBaseUrl'}/>
            <XpForm
                formModel={marketSimulatorForm}
                initialUseState={XP_FORM_EDIT}
            >
                <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                    <div style={{width: '300px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            noTemplate
                            label={'FIX session'}
                            field="sessionIdToAdd"
                            selectList={sessionsAvailableToAdd}
                            defaultValue={sessionsAvailableToAdd[0].id}
                        />
                    </div>
                    <div style={{width: '110px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            noTemplate
                            label={'Currency pair'}
                            field="currencyPairIdToAdd"
                            selectList={currencyPairsAvailableToAdd}
                            defaultValue={currencyPairsAvailableToAdd[0].id}
                        />
                    </div>
                    {(sessionIdToAdd !== "OpenExchange") &&
                    <div style={{width: '70px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            noTemplate
                            label={'Tenor'}
                            field="tenorToAdd"
                            selectList={tenorsAvailableToAdd}
                            defaultValue={tenorsAvailableToAdd[0].id}
                        />
                    </div>}
                    <div style={{width: '200px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            noTemplate={sessionIdToAdd === "OpenExchange" ? true : undefined}
                            template={(sessionIdToAdd !== "OpenExchange") ? 'All fix accounts' : undefined}
                            label={'FIX account'}
                            field="fixAccountToAdd"
                            selectList={fixAccountsAvailableToAdd}
                        />
                    </div>
                    <div style={{marginTop: '17px'}}>
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            onClickCallback = {() => {
                                dispatch(dashboardSetComponentsState(
                                    props.componentData.key,
                                    {priceItems: [...props.componentData.state.priceItems, {currencyPairId: currencyPairIdToAdd, tenor: tenorToAdd, sessionId: sessionIdToAdd, fixAccount: fixAccountToAdd}]}
                                ));
                            }}
                            label    = 'Add configuration'
                            disabled = {undefined !== props.componentData.state.priceItems.find(item => configIsSame(item, {currencyPairId: currencyPairIdToAdd, tenor: tenorToAdd, sessionId: sessionIdToAdd, fixAccount: fixAccountToAdd}))}
                        />
                    </div>

                </div>
            </XpForm>
            <hr/>
            {props.componentData?.state?.priceItems.map(config => { return (
                <MarketSimulatorConfigItem
                    key            = {getConfigKey(config)}
                    sessionId      = {config.sessionId}
                    currencyPairId = {config.currencyPairId}
                    fixAccount     = {config.fixAccount}
                    config         = {config}
                    tenor          = {config.tenor}
                    addConfigFn    = {addConfigFn}
                    removeMeFn     = {removeMeFn}
                />)})
            }
        </div>
    )
}
MarketSimulator.propTypes = {
};
export default MarketSimulator

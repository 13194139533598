import React from 'react'
import Wizard from 'gui-common/wizard/Wizard';

function RefixClientWizardPage(props) {

    return (
        <div xp-test-id="refixClientWizardMainDiv">
            <Wizard wizardName = 'refixClientWizard'/>
        </div>
    )
}
export default RefixClientWizardPage

import {AgreementsListInDashBoard} from "features/agreement/AgreementsList2"
import {CurrencyExposureList} from "features/currencyExposure/CurrencyExposureList";
import {DispatchTradeList} from "features/dispatchTrade/DispatchTradeList2";
import {DashboardBusinessUnitList} from "features/legalEntityUnit/DashboardBusinessUnitList2";
import {OverallCurrencyExposure} from "features/currencyExposure/OverallCurrencyExposure";
import {ListRateOrdersList} from "features/listRateOrder/ListRateOrdersList";
import {MarketOrdersList} from "features/marketOrder/MarketOrdersList2";
import {ListRateList} from "features/listRate/ListRateList";
import {ListRateOrderAllocationsList} from "features/listRateOrderAllocation/ListRateOrderAllocationsList";


export const refixDashboardWidgets = {
    'MarketOrdersList': {
        component           : MarketOrdersList,
        authorizedFunction  : null,
        langPath            : 'marketOrdersList.dashboardComponent.',
        icon                : 'swap_horizontal_circle',
        keyName             : 'marketOrders',
        defaultSize         : {w: 18, h: 15}
    },
    'ClientsStatusList': {
        component           : DashboardBusinessUnitList,
        authorizedFunction  : null,
        langPath            : 'legalEntityUnitsList.dashboardComponent.',
        icon                : 'list',
        keyName             : 'businessUnits',
        defaultSize         : {w: 18, h: 15}
    },
    'ListRateAgreementsList': {
        component           : AgreementsListInDashBoard,
        authorizedFunction  : null,
        langPath            : 'agreementsList.dashboardComponent.',
        icon                : 'assignment',
        keyName             : 'listRateAgreements',
        defaultSize         : {w: 18, h: 15}
    },
    'ListRatesList': {
        component           : ListRateList,
        authorizedFunction  : null,
        langPath            : 'listRatesList.dashboardComponent.',
        icon                : 'trending_flat',
        keyName             : 'listRates',
        defaultSize         : {w: 18, h: 15}
    },
    'DispatchTradesList': {
        component           : DispatchTradeList,
        authorizedFunction  : null,
        langPath            : 'dispatchTradesList.dashboardComponent.',
        icon                : 'save_alt', // swap_horizontal_circle
        keyName             : 'dispatchTrades',
        defaultSize         : {w: 18, h: 15}
    },
    'ListRateOrdersList': {
        component           : ListRateOrdersList,
        authorizedFunction  : null,
        langPath            : 'listRateOrdersList.dashboardComponent.',
        icon                : 'exit_to_app',
        keyName             : 'listRateOrders',
        defaultSize         : {w: 18, h: 15}
    },
    'ListRateOrderAllocationsList': {
        component           : ListRateOrderAllocationsList,
        authorizedFunction  : null,
        langPath            : 'listRateOrderAllocationsList.dashboardComponent.',
        icon                : ListRateOrderAllocationsList.detailIcon,
        keyName             : 'listRateOrderAllocations',
        defaultSize         : {w: 18, h: 15}
    },
    'CurrencyExposureList': {
        component           : CurrencyExposureList,
        authorizedFunction  : null,
        langPath            : 'currencyExposureList.dashboardComponent.',
        icon                : 'speed',
        keyName             : 'currencyExposure',
        defaultSize         : {w: 12, h: 10}
    },
    'OverallExposure': {
        component           : OverallCurrencyExposure,
        authorizedFunction  : null,
        langPath            : 'overallExposure.dashboardComponent.',
        icon                : 'power_settings_new',
        keyName             : 'overallExposure',
        defaultSize         : {w: 6, h: 5}
    },
};


import React, {useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import 'gui-common/wizard/wizard.css';
import {
    markStepAsComplete,
    resetWizard, setJumpStep,
    setNextStep, setStepValidity, submittedWizard,
} from 'gui-common/wizard/wizardReducer';
import WaitingXpoolButton from 'gui-common/components/WaitingXpoolButton';
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushModalWindow} from "redux-promising-modals";
import spinnerGif from 'gui-common/resources/spinner.gif';
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {xpFormLoadForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getWizardStateSelector} from "gui-common/wizard/wizardSelectors";
import XpForm from "gui-common/xpForm/core/XpForm";
import {postOrmItemToApi} from "gui-common/orm/ormApi";
import {ormModelLoadingNew} from "gui-common/orm/ormLoadingSelectors";
import {getXpFormRootSelector} from "gui-common/xpForm/core/xpFormSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import FileReaderButton from "gui-common/components/FileReaderButton";
import {globalWizardConfig} from "gui-common/wizard/wizardConstants";

function WizardContent(props) {
    const contentRef  = useRef();
    const dispatch = useDispatch();
    const thisWizardState  = useSelectorInstance(getWizardStateSelector, props)
    const thisWizardStateRef = useRef(thisWizardState);
    thisWizardStateRef.current =  thisWizardState;

    const onLoadingNew    = useSelector(state => ormModelLoadingNew(state, {ormModel: globalWizardConfig[props.wizardName].ormModel}));
    const wizardFileSpec  = useSelector(state => selectAppEnvProperty(state, globalWizardConfig[props.wizardName].fileSpecification));

    useEffect(
        () => {
            document.addEventListener("keydown", checkUserInputFunction, false)
            document.addEventListener("click"  , checkUserInputFunction, false)
            return () => {
                document.removeEventListener("keydown", checkUserInputFunction)
                document.removeEventListener("click"  , checkUserInputFunction)
            }
        },
        [],
    );

    function checkUserInputFunction (event) {
        setTimeout(() => {
            const node = contentRef.current;
            const myWizard = thisWizardStateRef.current;
            if (!myWizard || !node) return;

            const errors = node.querySelectorAll('.xpFormError');
            // console.log([...node.querySelectorAll('.errors')]);
            if (!errors || !errors.length) {
                if (!myWizard.invalidSteps.includes(myWizard.wizardStep)) return;
                dispatch(setStepValidity({wizardName: props.wizardName, stepNo: myWizard.wizardStep, stepIsValid: true}));
            }
            else {
                if (myWizard.invalidSteps.includes(myWizard.wizardStep)) return;
                dispatch(setStepValidity({wizardName: props.wizardName, stepNo: myWizard.wizardStep, stepIsValid: false}));
            }
        }, 100);
    }
    useEffect(
        () => {
            checkUserInputFunction();
        },
    );

    function handleFormSubmit(submittedFormData) {
        dispatch(markStepAsComplete({wizardName: props.wizardName, stepNo: thisWizardStateRef.current.wizardStep}));
        dispatch(setNextStep(       {wizardName: props.wizardName, step: 1}));
    }

    function resetMyWizard() {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'wizard.confirmResetModal'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    console.log('Resetting wizardName : ', props.wizardName);
                    dispatch(resetWizard({wizardName: props.wizardName}));
                    dispatch(xpFormLoadForm(props.wizardFormName, props.defaultParameters, XP_FORM_NEW));
                }
                if (status === MODAL_TYPE_CANCEL) {}
            })
    }

    function finalStepSubmit() {
        return (dispatch, getState) => {
            dispatch(submittedWizard({wizardName: props.wizardName}));
            const formData = getXpFormRootSelector()(getState(), {model: props.wizardFormName})
            const clientToSubmit = globalWizardConfig[props.wizardName].submitTransformer(formData);
            dispatch(postOrmItemToApi(globalWizardConfig[props.wizardName].ormModel, clientToSubmit));
        }
    }

    function fileContentReady(params) {
        // console.log("Output: ", params);
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "wizard.fileUploadSuccessModal", modalParams: {clientName: params.dataTree.clients[0].name}}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    dispatch(xpFormLoadForm(props.wizardFormName, {...props.defaultParameters, ...params.dataTree.clients[0]}, XP_FORM_NEW));
                    props.stepInfo.forEach((step, index) => {
                        if (!step.fields?.length) return;
                        dispatch(markStepAsComplete({wizardName: props.wizardName, stepNo: index}));
                    });
                    dispatch(setJumpStep({wizardName: props.wizardName, stepNo: props.stepInfo.length - 1}));
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    const StepFormComponent = useMemo(
        () => {
            if (thisWizardState?.wizardStep === undefined) return undefined;
            return props.stepInfo[thisWizardState.wizardStep].formComponent;
        },
        [thisWizardState?.wizardStep]
    );

    if(!StepFormComponent) return (<div> </div>)
    const finalStep = (thisWizardState.wizardStep === thisWizardState.stepInfo?.length - 1);

    if (onLoadingNew) {
        return (
            <div>
                <p className="valueText">{<XpTranslated trKey='wizard.loading'/>} </p>
                <img className="spinner" src={spinnerGif} alt="waiting" />
            </div>
        )
    }
    return (
        <div className={ finalStep ? "" : "wizardContentPlacer"} xp-test-id="wizardFormPlacer" ref={contentRef}>
            <div className={ finalStep ? "" : "wizardFormPlacer"}>
                <XpForm
                    formModel={props.wizardFormName}
                    onSubmit={handleFormSubmit}
                    // formTemplate={'onboardingWizardForm'}
                    initialUseState={XP_FORM_NEW}
                    initialFormData={props.defaultParameters}
                >
                    <StepFormComponent
                        completedForm={step => dispatch(markStepAsComplete(step))}
                        myStep={thisWizardState.wizardStep}
                        wizardName={props.wizardName}
                    />
                </XpForm>
            </div>
            {thisWizardState.wizardStep !== 0 &&
            <WaitingXpoolButton
                onClickCallback={() => dispatch(setNextStep({wizardName: props.wizardName, step: -1}))}
                labelKey='wizardContent.previousButton'
                disabled={thisWizardState.wizardStep === 0}
                floatRight={false}
            />
            }
            <WaitingXpoolButton
                onClickCallback={resetMyWizard}
                labelKey='wizard.resetMyWizard'
                disabled={false}
                floatRight={false}
            />
            {(wizardFileSpec?.fileSpecification && (thisWizardState.wizardStep === 0)) &&
            <FileReaderButton
                instanceId      = {'wizardFileReader-' + props.wizardName}
                fileSpec        = {wizardFileSpec.fileSpecification}
                labelKey        = 'wizard.fileUpload'
                inLineLayout    = {true}
                onSuccess       = {(params) => {fileContentReady(params)}}
                onFail          = {(params) => {}}
                onStartBrowsing = {() => {}}
                onStartValidation = {() => {}}
                fieldLookUpToIds= {props.lookupValues}
                validationParams={props.validationParams}
                debugLog
            />
            }
            <WaitingXpoolButton
                labelKey={!finalStep ? 'wizardContent.nextButton' : 'wizardContent.finalStepButton'}
                onClickCallback={finalStep ? () => dispatch(finalStepSubmit()) : () => dispatch(xpFormSubmitForm(props.wizardFormName))}
                disabled={(thisWizardState.wizardStep > thisWizardState.stepInfo?.length -1) || (finalStep && (thisWizardState.invalidSteps.length > 0))}
                floatRight={true}
                xp-test-id="wizardNextButton"
            />

        </div>
    );
}
export default WizardContent;


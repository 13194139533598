import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import 'gui-common/bankHolidays/bankHolidays.css'
import BankHolidaysFilterForm from "gui-common/bankHolidays/BankHolidaysFilterForm";
import BankHolidaysDataList from "gui-common/bankHolidays/BankHolidaysDataList";
import {pushModalWindow} from "redux-promising-modals";
import {
    getBankHolidaysCalendarEventsSelector,
    newBankHolidayFormTemplateSelector
} from "gui-common/bankHolidays/bankHolidaysSelectors";
import XpCalendar from "gui-common/components/XpCalendar";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {deleteHolidayAfterConfirm} from "gui-common/bankHolidays/bankHolidaysFunctions";
import {BANK_HOLIDAY_DIALOG} from "gui-common/modals/modalConstants";
import {xpFormLoadForm} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_EDIT, XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {userSelector} from "gui-common/orm/ormSelectors";
import {userCan} from "gui-common/userRights/userRightsFunctions";

const accordionInstanceId = 'bankHolidays.calendarView';
function BankHolidaysPage () {

    const dispatch       = useDispatch();
    const newBankHoliday = useSelector(newBankHolidayFormTemplateSelector);
    const events         = useSelector(getBankHolidaysCalendarEventsSelector());
    const accordionOpen = useSelector(state => state.xpAccordionState[accordionInstanceId])
    const user              = useSelector(userSelector);

    function setEditNew(bankHoliday) {
        dispatch(xpFormLoadForm('xpAdminForm.bankHolidayForm.BankHoliday.' + (bankHoliday ? bankHoliday.id : 'new'), bankHoliday ? bankHoliday : newBankHoliday, bankHoliday ? XP_FORM_EDIT : XP_FORM_NEW));
        dispatch(pushModalWindow(BANK_HOLIDAY_DIALOG, {bankHoliday: bankHoliday}))
    }

    const heightValue = (accordionOpen ? 732 : 240);

    const contextMenu = useMemo(
        () => {
            const userCanEdit   = userCan('Update', 'BankHolidays', user);
            const userCanDelete = userCan('Delete', 'BankHolidays', user);
            const returnArray = [];
            if (userCanEdit) {
                returnArray.push({
                    trKey:   'bankHolidays.list.gridContextMenuItems.update.menuItem',
                    action: (event) => setEditNew(event)
                });
            }
            if (userCanDelete) {
                returnArray.push({
                    trKey:   'bankHolidays.list.gridContextMenuItems.delete.menuItem',
                    action: (event) => dispatch(deleteHolidayAfterConfirm(event))
                });
            }
            return returnArray;
        },
        []
    );


    return (
        <div style={{height: "100%"}}>
            <div>
                <h2><XpTranslated trKey={'bankHolidays.header'} /></h2>
                <hr/>
                <div style={{zIndex: '2'}}>
                    <BankHolidaysFilterForm setEditNew={setEditNew}/>
                </div>
                <div style={{zIndex: '1'}}>
                    <XpAccordion
                        header={<XpTranslated trKey='bankHolidays.calendarView'/>}
                        instanceId={accordionInstanceId}
                    >
                        <XpCalendar
                            containerStyles={{height: '450px'}}
                            events={events}

                            eventContextMenuItems={contextMenu?.length ? contextMenu : undefined}
                        />
                        <hr/>
                    </XpAccordion>
                </div>
            </div>

            <div style={{height: "calc(100vh - " + heightValue + "px)" }}>
                <BankHolidaysDataList instanceId={'bankHolidaysList'} setEditNew={setEditNew}/>
            </div>
        </div>
    );
}
export default BankHolidaysPage;

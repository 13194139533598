import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavDropdown} from "react-bootstrap";
import XpTooltip from "gui-common/components/XpTooltip";
import {pushModalWindow} from "redux-promising-modals";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {CREATE_UM_DIALOG} from "gui-common/modals/modalConstants";
import {userCan} from "gui-common/userRights/userRightsFunctions";
import {topicsSelector, userSelector} from "gui-common/orm/ormSelectors";

function CreateUserMessageMenuItem(props) {
    const topics    = useSelector(topicsSelector);
    const user                   = useSelector(userSelector);
    const dispatch  = useDispatch();

    const userCanCreateUserMessage = useMemo(() => {
        for (let topic of topics) if (userCan('Create', 'UmTopic' + topic.topic , user)) return true;
        return false;
    }, [topics, user]);

    if (!userCanCreateUserMessage) {
        return null;
    }
    return (
        <XpTooltip trKey={'topMenu.generateUserMessage.tooltip'}>
            <NavDropdown.Item
                key = 'generateUserMessage'
                onClick={() => dispatch(pushModalWindow(CREATE_UM_DIALOG))}
                disabled={false}
                xp-test-id="generateUserMessageItem"
            >
                <XpTranslated trKey={'topMenu.generateUserMessage.menuItem'}/>
            </NavDropdown.Item>
        </XpTooltip>
    )
}
export default CreateUserMessageMenuItem



